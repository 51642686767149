/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { ChatFileFragment } from './chat-file-fragment';
import { FormValueFragment } from './form-value-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
import { ChatFileFragmentDoc } from './chat-file-fragment';
import { FormValueFragmentDoc } from './form-value-fragment';
export type UserChatFragment = { __typename: 'UserChat' } & Pick<
  Types.UserChat,
  | 'id'
  | 'message'
  | 'messageContentState'
  | 'createdAt'
  | 'hidden'
  | 'nextInputType'
  | 'useAutoComplete'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    file: Types.Maybe<{ __typename: 'File' } & ChatFileFragment>;
    formValues: Types.Maybe<
      Array<Types.Maybe<{ __typename: 'FormValue' } & FormValueFragment>>
    >;
  };

export const UserChatFragmentDoc = gql`
  fragment UserChatFragment on UserChat {
    id
    message
    messageContentState @client
    createdAt
    hidden
    media {
      ...ChatMediaFragment
    }
    file {
      ...ChatFileFragment
    }
    nextInputType
    formValues {
      ...FormValueFragment
    }
    useAutoComplete
  }
  ${ChatMediaFragmentDoc}
  ${ChatFileFragmentDoc}
  ${FormValueFragmentDoc}
`;
