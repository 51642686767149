import { useCombinedRef } from '@allganize/hooks';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileDropzoneClasses } from './file-dropzone-classes';
import { FileDropzoneProps } from './file-dropzone-type-map';

export const FileDropzone = forwardRef<HTMLDivElement, FileDropzoneProps>(
  (props, ref) => {
    const {
      accept,
      autoFocus,
      children,
      classes,
      disabled,
      dragOverlay = 'Drop file here',
      dropzoneRef,
      getFilesFromEvent,
      inputProps: inputPropsProp,
      inputRef: inputRefProp,
      maxFiles,
      maxSize,
      minSize,
      multiple,
      noClick,
      noDrag,
      noDragEventsBubbling,
      noKeyboard,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
      onDropAccepted,
      onDropRejected,
      onFileDialogCancel,
      onFileDialogOpen,
      onError,
      preventDropOnDocument,
      useFsAccessApi,
      validator,
      ...other
    } = props;
    const theme = useTheme();
    const dropzone = useDropzone(props);
    const {
      getInputProps,
      getRootProps,
      inputRef,
      isDragAccept,
      isDragActive,
      isDragReject,
      isFileDialogActive,
      isFocused,
      open,
      rootRef,
    } = dropzone;
    const rootProps = getRootProps(other);
    const inputProps = getInputProps(inputPropsProp);
    const rootCombinedRef = useCombinedRef(rootRef, ref);
    const inputCombinedRef = useCombinedRef(inputRef, inputRefProp ?? null);

    useImperativeHandle(dropzoneRef, () => ({ open }), [open]);

    return (
      <div
        data-testid="file-dropzone"
        css={[
          css`
            position: relative;
            border-radius: ${theme.radius.sm}px;
            border: 1px dashed transparent;
            border-color: ${theme.palette.grayAlpha[300]};
            background-color: ${theme.palette.grayAlpha[50]};
            transition: ${theme.transitions.create('border-color', {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.leavingScreen,
            })};
          `,
          isDragActive &&
            css`
              border-color: ${theme.palette.grayAlpha[500]};
              transition: ${theme.transitions.create('border-color', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.enteringScreen,
              })};
            `,
          isDragReject &&
            css`
              color: ${theme.palette.error.main};
              border-color: ${theme.palette.error.main};
            `,
        ]}
        {...rootProps}
        ref={rootCombinedRef}
        className={clsx(
          fileDropzoneClasses.root,
          {
            [fileDropzoneClasses.dragAccept]: isDragAccept,
            [fileDropzoneClasses.dragActive]: isDragActive,
            [fileDropzoneClasses.dragReject]: isDragReject,
            [fileDropzoneClasses.fileDialogActive]: isFileDialogActive,
            [fileDropzoneClasses.focused]: isFocused,
          },
          classes?.root,
          {
            [classes?.dragAccept ?? '']: isDragAccept,
            [classes?.dragActive ?? '']: isDragActive,
            [classes?.dragReject ?? '']: isDragReject,
            [classes?.fileDialogActive ?? '']: isFileDialogActive,
            [classes?.focused ?? '']: isFocused,
          },
          rootProps.className,
        )}
      >
        {children}

        <input
          {...inputProps}
          ref={inputCombinedRef}
          className={clsx(
            fileDropzoneClasses.input,
            classes?.input,
            inputProps.className,
          )}
        />

        <div
          css={[
            css`
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              outline: none;
              border-radius: ${theme.radius.sm}px;
              background-color: ${theme.palette.grey[100]};
              opacity: 0;
              pointer-events: none;
              overflow: hidden;
              transition: ${theme.transitions.create('opacity', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.leavingScreen,
              })};
            `,
            isDragActive &&
              css`
                opacity: 1;
                transition: ${theme.transitions.create('opacity', {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.enteringScreen,
                })};
              `,
          ]}
          className={clsx(
            fileDropzoneClasses.dragOverlay,
            classes?.dragOverlay,
          )}
          tabIndex={-1}
        >
          {dragOverlay}
        </div>
      </div>
    );
  },
);
