import { FunctionComponent, useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

export const AuthOktaLogoutPage: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');

  const data = useMemo(() => {
    try {
      if (!state) {
        return null;
      }

      return JSON.parse(state);
    } catch {
      return null;
    }
  }, [state]);

  return (
    <Navigate
      to={data?.next || { pathname: '/', search: searchParams.toString() }}
      replace
    />
  );
};
