import { noop } from 'lodash-es';
import { createContext } from 'react';
import { ClosePreviewReason } from '../preview/preview-state';
import { LLMAppFragment } from '../graphql/fragments/llm-app-fragment';

export type SingleActionApp = LLMAppFragment['singleActionApp'];

export interface SingleActionPreviewState {
  id: string | number;
  publicToken: string;
}

interface SingleActionPreviewContextValue {
  singleActionPreviewState: SingleActionPreviewState | null;
  openSingleActionPreview(singleActionApp: SingleActionApp): void;
  closeSingleActionPreview(reason: ClosePreviewReason): void;
}

export const SingleActionPreviewContext =
  createContext<SingleActionPreviewContextValue>({
    singleActionPreviewState: null,
    openSingleActionPreview: noop,
    closeSingleActionPreview: noop,
  });
