/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KnowledgeBasesCountQueryVariables = UserQueryTypes.Exact<{
  where: UserQueryTypes.ProjectWhereUniqueInput;
}>;

export type KnowledgeBasesCountQuery = { __typename: 'UserQuery' } & {
  knowledgeBases: UserQueryTypes.Maybe<
    { __typename: 'KnowledgeBaseList' } & Pick<
      UserQueryTypes.KnowledgeBaseList,
      'count'
    >
  >;
};

export const KnowledgeBasesCountQueryDocument = gql`
  query KnowledgeBasesCountQuery($where: ProjectWhereUniqueInput!) {
    knowledgeBases(where: $where) {
      count
    }
  }
`;

/**
 * __useKnowledgeBasesCountQuery__
 *
 * To run a query within a React component, call `useKnowledgeBasesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeBasesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeBasesCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useKnowledgeBasesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    KnowledgeBasesCountQuery,
    KnowledgeBasesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    KnowledgeBasesCountQuery,
    KnowledgeBasesCountQueryVariables
  >(KnowledgeBasesCountQueryDocument, options);
}
export function useKnowledgeBasesCountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KnowledgeBasesCountQuery,
    KnowledgeBasesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    KnowledgeBasesCountQuery,
    KnowledgeBasesCountQueryVariables
  >(KnowledgeBasesCountQueryDocument, options);
}
export function useKnowledgeBasesCountQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    KnowledgeBasesCountQuery,
    KnowledgeBasesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    KnowledgeBasesCountQuery,
    KnowledgeBasesCountQueryVariables
  >(KnowledgeBasesCountQueryDocument, options);
}
export type KnowledgeBasesCountQueryHookResult = ReturnType<
  typeof useKnowledgeBasesCountQuery
>;
export type KnowledgeBasesCountQueryLazyQueryHookResult = ReturnType<
  typeof useKnowledgeBasesCountQueryLazyQuery
>;
export type KnowledgeBasesCountQuerySuspenseQueryHookResult = ReturnType<
  typeof useKnowledgeBasesCountQuerySuspenseQuery
>;
export type KnowledgeBasesCountQueryQueryResult = Apollo.QueryResult<
  KnowledgeBasesCountQuery,
  KnowledgeBasesCountQueryVariables
>;
