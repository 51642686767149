/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ChatKnowledgeBasePreviewFragment = {
  __typename: 'KnowledgeBasePreview';
} & Pick<
  Types.KnowledgeBasePreview,
  'pageNo' | 'totalPageCount' | 'highlightIndexes' | 'exactAnswer'
> & {
    knowledgeBase: Types.Maybe<
      | ({ __typename: 'CSVKnowledgeBase' } & Pick<
          Types.CSVKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'GoogleDocsKnowledgeBase' } & Pick<
          Types.GoogleDocsKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'HWPKnowledgeBase' } & Pick<
          Types.HWPKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'ImageKnowledgeBase' } & Pick<
          Types.ImageKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'MSDocsKnowledgeBase' } & Pick<
          Types.MSDocsKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'MSExcelKnowledgeBase' } & Pick<
          Types.MSExcelKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'MSPPTKnowledgeBase' } & Pick<
          Types.MSPPTKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'OldMSDocsKnowledgeBase' } & Pick<
          Types.OldMSDocsKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'PDFKnowledgeBase' } & Pick<
          Types.PDFKnowledgeBase,
          'id' | 'title'
        >)
      | ({ __typename: 'TextKnowledgeBase' } & Pick<
          Types.TextKnowledgeBase,
          'id' | 'title'
        >)
    >;
    draftjs: Types.Maybe<
      { __typename: 'KnowledgeBaseDraftjsPreview' } & Pick<
        Types.KnowledgeBaseDraftjsPreview,
        'id' | 'answer'
      >
    >;
    highlights: Types.Maybe<
      { __typename: 'KnowledgeBasePreviewHighlights' } & Pick<
        Types.KnowledgeBasePreviewHighlights,
        'indexes'
      > & {
          pageIndexes: Types.Maybe<
            Array<
              { __typename: 'KnowledgeBasePreviewPageHighlight' } & Pick<
                Types.KnowledgeBasePreviewPageHighlight,
                'page' | 'indexes'
              >
            >
          >;
        }
    >;
  };

export const ChatKnowledgeBasePreviewFragmentDoc = gql`
  fragment ChatKnowledgeBasePreviewFragment on KnowledgeBasePreview {
    knowledgeBase {
      ... on GoogleDocsKnowledgeBase {
        id
        title
      }
      ... on TextKnowledgeBase {
        id
        title
      }
      ... on ImageKnowledgeBase {
        id
        title
      }
      ... on MSDocsKnowledgeBase {
        id
        title
      }
      ... on PDFKnowledgeBase {
        id
        title
      }
      ... on MSExcelKnowledgeBase {
        id
        title
      }
      ... on MSPPTKnowledgeBase {
        id
        title
      }
      ... on OldMSDocsKnowledgeBase {
        id
        title
      }
      ... on CSVKnowledgeBase {
        id
        title
      }
      ... on HWPKnowledgeBase {
        id
        title
      }
    }
    pageNo
    totalPageCount
    highlightIndexes
    exactAnswer
    draftjs {
      id
      answer
    }
    highlights {
      indexes
      pageIndexes {
        page
        indexes
      }
    }
  }
`;
