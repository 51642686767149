import { FunctionComponent, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { Locale, defaultLocale, locales } from './locales';
import { LocaleContext, LocaleContextValue } from './locale-context';

interface LocaleProviderProps {
  initialValue?: Locale;
  children?: React.ReactNode;
}

export const LocaleProvider: FunctionComponent<LocaleProviderProps> = ({
  initialValue,
  children,
}) => {
  const [locale, setLocale] = useState<Locale>(initialValue ?? defaultLocale);
  const contextValue = useMemo<LocaleContextValue>(
    () => ({ locale, setLocale }),
    [locale, setLocale],
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={locales[locale]}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
