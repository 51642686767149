import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent, HTMLAttributes } from 'react';

export const Root: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...other
}) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        min-width: 320px;
        height: 100%;
        padding: 100px 24px;
        overflow-y: auto;
        color: ${theme.palette.unstable_foreground.default};
        background-color: ${theme.palette.unstable_background.gray.subtle};
      `}
      {...other}
    >
      {children}
    </div>
  );
};
