/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { CarouselOptionTextFragment } from './carousel-option-text-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
import { CarouselOptionTextFragmentDoc } from './carousel-option-text-fragment';
export type CarouselOptionFragment = { __typename: 'CarouselOption' } & Pick<
  Types.CarouselOption,
  'id' | 'url'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    primary: Types.Maybe<
      { __typename: 'CarouselOptionText' } & CarouselOptionTextFragment
    >;
    secondary: Types.Maybe<
      { __typename: 'CarouselOptionText' } & CarouselOptionTextFragment
    >;
    tertiary: Types.Maybe<
      { __typename: 'CarouselOptionText' } & CarouselOptionTextFragment
    >;
  };

export const CarouselOptionFragmentDoc = gql`
  fragment CarouselOptionFragment on CarouselOption {
    id
    url
    media {
      ...ChatMediaFragment
    }
    primary {
      ...CarouselOptionTextFragment
    }
    secondary {
      ...CarouselOptionTextFragment
    }
    tertiary {
      ...CarouselOptionTextFragment
    }
  }
  ${ChatMediaFragmentDoc}
  ${CarouselOptionTextFragmentDoc}
`;
