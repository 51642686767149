import { Truncate } from '@allganize/truncate';
import { IcSearch } from '@allganize/ui-icons';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@allganize/ui-list';
import { Paper } from '@allganize/ui-paper';
import { HighlightedText } from '@allganize/ui-select';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import Popper, { PopperProps } from '@mui/material/Popper';
import { UseAutocompleteReturnValue } from '@mui/material/useAutocomplete';
import { forwardRef } from 'react';

interface ChatFormAutocompleteProps extends Omit<PopperProps, 'open'> {
  autocomplete: UseAutocompleteReturnValue<string, false, true, true>;
}

export const ChatFormAutocomplete = forwardRef<
  HTMLDivElement,
  ChatFormAutocompleteProps
>((props, ref) => {
  const { autocomplete, ...other } = props;
  const theme = useTheme();

  return (
    <Popper
      css={css`
        z-index: ${theme.zIndex.modal};
        position: absolute;
      `}
      disablePortal
      role="presentation"
      anchorEl={autocomplete.anchorEl}
      open={autocomplete.popupOpen}
      placement="top"
      {...other}
      ref={ref}
      style={{
        width:
          autocomplete.anchorEl?.ownerDocument.documentElement.clientWidth ??
          undefined,
        ...other.style,
      }}
    >
      <Paper
        css={css`
          position: relative;
          overflow: auto;
          padding-top: 12px;
          margin-bottom: 6px;
          border-top-left-radius: ${theme.radius.xl}px;
          border-top-right-radius: ${theme.radius.xl}px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          clip-path: inset(-100px 0 0 0);
        `}
        elevation={3}
      >
        <List
          css={css`
            max-height: 488px;
            overflow: auto;
          `}
          {...autocomplete.getListboxProps()}
        >
          {autocomplete.groupedOptions.map((option, index) => {
            if (typeof option !== 'string') {
              return null;
            }

            const optionProps = autocomplete.getOptionProps({
              option,
              index,
            });

            return (
              <ListItem disablePadding {...optionProps}>
                <ListItemButton>
                  <ListItemIcon>
                    <IcSearch />
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <Truncate clamp={2}>
                        <HighlightedText
                          text={option}
                          query={autocomplete.inputValue}
                        />
                      </Truncate>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Popper>
  );
});
