/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { gql } from '@apollo/client';
export type SingleActionAppResultFragment = {
  __typename: 'SingleActionAppResult';
} & Pick<
  UserQueryTypes.SingleActionAppResult,
  'id' | 'output' | 'outputOriginal'
> & {
    documentResults: UserQueryTypes.Maybe<
      Array<
        { __typename: 'SingleActionAppDocumentMapReduceResult' } & Pick<
          UserQueryTypes.SingleActionAppDocumentMapReduceResult,
          'id' | 'output' | 'dynamicInputsItemName'
        >
      >
    >;
  };

export const SingleActionAppResultFragmentDoc = gql`
  fragment SingleActionAppResultFragment on SingleActionAppResult {
    id
    output
    outputOriginal
    documentResults {
      id
      output
      dynamicInputsItemName
    }
  }
`;
