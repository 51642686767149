/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleActionAppResultDownloadPublicSubscriptionVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    accessToken: UserQueryTypes.Scalars['String']['input'];
    token: UserQueryTypes.Scalars['String']['input'];
  }>;

export type SingleActionAppResultDownloadPublicSubscription = {
  __typename: 'UserSubscription';
} & {
  singleActionAppResultDownloadPublic: UserQueryTypes.Maybe<
    { __typename: 'DownloadFileTask' } & Pick<
      UserQueryTypes.DownloadFileTask,
      'id' | 'status' | 'fileName'
    > & {
        errors: UserQueryTypes.Maybe<
          Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
        >;
      }
  >;
};

export const SingleActionAppResultDownloadPublicSubscriptionDocument = gql`
  subscription SingleActionAppResultDownloadPublicSubscription(
    $where: ProjectWhereUniqueInput!
    $accessToken: String!
    $token: String!
  ) {
    singleActionAppResultDownloadPublic(
      projectWhere: $where
      accessToken: $accessToken
      token: $token
    ) {
      id
      status
      fileName
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentDoc}
`;

/**
 * __useSingleActionAppResultDownloadPublicSubscription__
 *
 * To run a query within a React component, call `useSingleActionAppResultDownloadPublicSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSingleActionAppResultDownloadPublicSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleActionAppResultDownloadPublicSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      accessToken: // value for 'accessToken'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSingleActionAppResultDownloadPublicSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SingleActionAppResultDownloadPublicSubscription,
    SingleActionAppResultDownloadPublicSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SingleActionAppResultDownloadPublicSubscription,
    SingleActionAppResultDownloadPublicSubscriptionVariables
  >(SingleActionAppResultDownloadPublicSubscriptionDocument, options);
}
export type SingleActionAppResultDownloadPublicSubscriptionHookResult =
  ReturnType<typeof useSingleActionAppResultDownloadPublicSubscription>;
export type SingleActionAppResultDownloadPublicSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<SingleActionAppResultDownloadPublicSubscription>;
