/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ChatOptionStyleFragment = { __typename: 'OptionStyle' } & Pick<
  Types.OptionStyle,
  'bgColor' | 'bold' | 'fontColor' | 'italic' | 'lineColor' | 'underline'
>;

export const ChatOptionStyleFragmentDoc = gql`
  fragment ChatOptionStyleFragment on OptionStyle {
    bgColor
    bold
    fontColor
    italic
    lineColor
    underline
  }
`;
