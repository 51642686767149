import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { markdownClasses } from './markdown-classes';
import {
  Blockquote,
  Code,
  Img,
  Pre,
  Table,
  Wrapper,
} from './markdown-styled-components';
import { MarkdownProps } from './markdown-type-map';

const Markdown = ({ classes, markdown }: MarkdownProps) => {
  const theme = useTheme();
  return (
    <Wrapper
      className={clsx(markdownClasses.root, classes?.root)}
      css={css`
        font-size: 14px;
        font-family: ${theme.typography.fontFamily};
        word-break: break-word;
      `}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          pre: Pre,
          code: Code,
          blockquote: Blockquote,
          table: Table,
          img: props => <Img {...props} />,
        }}
      >
        {markdown || ''}
      </ReactMarkdown>
    </Wrapper>
  );
};

export default Markdown;
