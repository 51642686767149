import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { InputComponent, InputRoot } from './input-slots';
import { InputProps } from './input-type-map';

export const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  const { classes, slots, ...other } = props;
  const theme = useTheme();
  const muiTheme = useMuiTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <ThemeProvider theme={{ ...muiTheme, transitions: theme.transitions }}>
          <OutlinedInput
            data-testid="input"
            label={null}
            notched={false}
            {...other}
            ref={ref}
            slots={{
              root: InputRoot,
              input: InputComponent,
              ...slots,
            }}
            classes={{
              ...classes,
              notchedOutline: cx(
                css`
                  top: 0;
                  padding: 0 12px;
                  border-color: ${theme.palette.grayAlpha[200]};

                  > legend {
                    line-height: 0;
                  }
                `,
                classes?.notchedOutline,
              ),
              input: cx(
                css`
                  &::placeholder {
                    color: ${theme.palette.unstable_foreground.placeholder};
                    opacity: 1;
                  }
                `,
                classes?.input,
              ),
            }}
          />
        </ThemeProvider>
      )}
    </ClassNames>
  );
});
