import { ContentBlock, ContentState, DraftDecorator } from 'draft-js';
import { DraftDocumentPlugin } from '../draft-document-plugin';
import { DraftPlugin } from '../draft-plugin/draft-plugin';
import { DraftLink, DraftLinkProps } from './draft-link';
import { DraftLinkClasses } from './draft-link-classes';

export interface DraftLinkPluginOptions {
  classes?: Partial<DraftLinkClasses>;
}

export class DraftLinkPlugin extends DraftPlugin {
  public static readonly ENTITY_TYPE = 'LINK';

  private static strategy(
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) {
    block.findEntityRanges(character => {
      const entityKey = character.getEntity();

      if (!entityKey) {
        return false;
      }

      const entity = contentState.getEntity(entityKey);

      if (!entity) {
        return false;
      }

      const documentEntity = DraftDocumentPlugin.isDocumentEntity(entity);

      if (documentEntity) {
        return false;
      }

      return entity.getType() === DraftLinkPlugin.ENTITY_TYPE;
    }, callback);
  }

  constructor(private options: DraftLinkPluginOptions = {}) {
    super();
  }

  public getDecorators(): DraftDecorator<DraftLinkProps>[] | null {
    return [
      {
        strategy: DraftLinkPlugin.strategy,
        component: DraftLink,
        props: {
          classes: this.options.classes,
        },
      },
    ];
  }
}
