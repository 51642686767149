import { Types } from '@allganize/alli-sdk-interfaces';
import { IcError } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface GeneratingErrorStateProps {
  state: Types.Maybe<Types.ChatCompleteState>;
}

export const GeneratingErrorState: FunctionComponent<
  GeneratingErrorStateProps
> = ({ state }) => {
  const theme = useTheme();

  return (
    <div
      data-testid="generating-error-state"
      css={css`
        padding-left: 16px;
        color: ${theme.palette.error.main};
      `}
    >
      <IcError
        fontSize="small"
        css={css`
          margin-right: 4px;
          vertical-align: middle;
        `}
      />

      <Text variant="body12" component="span">
        <FormattedMessage
          id={`ChatCompletedErr.${state}`}
          defaultMessage={`Something's wrong, please try again ${state}`}
          description={`Generative ans error msg for ${state} state`}
        />
      </Text>
    </div>
  );
};
