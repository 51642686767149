import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcArrowHideSide, IcArrowOpenSide, IcClose } from '@allganize/ui-icons';
import { FunctionComponent, useContext } from 'react';
import { PreviewContext } from '../preview/preview-context';

export const PreviewCloseButton: FunctionComponent<IconButtonProps> = props => {
  const { previewSidebarAnchor, shouldOpenPreviewInDialog } =
    useContext(PreviewContext);

  return (
    <IconButton {...props}>
      {shouldOpenPreviewInDialog && <IcClose />}
      {!shouldOpenPreviewInDialog &&
        (previewSidebarAnchor === 'left' ? (
          <IcArrowHideSide />
        ) : (
          <IcArrowOpenSide />
        ))}
    </IconButton>
  );
};
