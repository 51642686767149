import { NetworkStatus } from '@apollo/client/core';
import { createContext } from 'react';
import { CurrentUserQuerySuspenseQueryHookResult } from '../graphql/queries/current-user-query';

type CurrentUserContextValue = Pick<
  CurrentUserQuerySuspenseQueryHookResult,
  'data' | 'refetch' | 'networkStatus' | 'error'
>;

export const CurrentUserContext = createContext<CurrentUserContextValue>({
  data: {
    __typename: 'UserQuery',
    me: null,
  },
  error: undefined,
  networkStatus: NetworkStatus.ready,
  refetch() {
    return Promise.reject(new Error('Not initialized'));
  },
});
