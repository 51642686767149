import { useEnhancedEffect, useEventCallback } from '@allganize/hooks';
import { formControlState } from '@allganize/ui-form';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames, css } from '@emotion/react';
import { useFormControl } from '@mui/material/FormControl';
import clsx from 'clsx';
import {
  CompositeDecorator,
  ContentBlock,
  DefaultDraftBlockRenderMap,
  DraftBlockRenderConfig,
  DraftBlockRenderMap,
  DraftDecorator,
  DraftInlineStyle,
  DraftStyleMap,
  Editor,
  EditorState,
} from 'draft-js';
import { Map } from 'immutable';
import { camelCase } from 'lodash-es';
import { lighten } from 'polished';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PluginFunctions } from '../draft-plugin/draft-plugin';
import {
  draftInputClasses,
  draftInputInternalClasses,
} from './draft-input-classes';
import {
  CoreDraftBlockType,
  DraftInputProps,
  DraftInputRef,
} from './draft-input-type-map';

const defaultBlockRenderMap: DraftBlockRenderMap =
  DefaultDraftBlockRenderMap.merge(
    Map<CoreDraftBlockType, DraftBlockRenderConfig>({
      'header-one': {
        element: 'div',
      },
      'header-two': {
        element: 'div',
      },
      'header-three': {
        element: 'div',
      },
      'header-four': {
        element: 'div',
      },
      'header-five': {
        element: 'div',
      },
      'header-six': {
        element: 'div',
      },
    }),
  );

const headerStyleMap = {
  'header-one': {
    fontSize: 26,
  },
  'header-two': {
    fontSize: 24,
  },
  'header-three': {
    fontSize: 22,
  },
  'header-four': {
    fontSize: 20,
  },
  'header-five': {
    fontSize: 18,
  },
  'header-six': {
    fontSize: 16,
  },
} satisfies Partial<Record<CoreDraftBlockType, React.CSSProperties>>;

const defaultCustomStyleMap: DraftStyleMap = {
  BOLD: {
    fontWeight: 'bold',
  },
  ITALIC: {
    fontStyle: 'italic',
  },
  HEADER_ONE: headerStyleMap['header-one'],
  HEADER_TWO: headerStyleMap['header-two'],
  HEADER_THREE: headerStyleMap['header-three'],
  HEADER_FOUR: headerStyleMap['header-four'],
  HEADER_FIVE: headerStyleMap['header-five'],
  HEADER_SIX: headerStyleMap['header-six'],
  HIGHLIGHT: {},
};

export const DraftInput = forwardRef<DraftInputRef, DraftInputProps>(
  (props, ref) => {
    const {
      autoFocus = false,
      blockStyleFn: blockStyleFnProp,
      children,
      classes,
      className,
      color: colorProp,
      customStyleFn: customStyleFnProp,
      customStyleMap,
      disabled: disabledProp,
      error: errorProp,
      onBlur,
      onChange,
      onFocus,
      plugins,
      value,
      rootProps,
      ...other
    } = props;
    const theme = useTheme();
    const muiFormControl = useFormControl();
    const editorRef = useRef<Editor>(null);
    const [_focused, setFocused] = useState(false);
    const focused = _focused || muiFormControl?.focused;
    const fcs = formControlState({
      props,
      muiFormControl,
      states: ['color', 'disabled', 'error'],
    });
    const color = fcs.color || 'primary';
    const disabled = fcs.disabled || false;

    const setEditorState = useEventCallback((editorState: EditorState) => {
      onChange(editorState);
    });

    const getPluginFunctions = useEventCallback(
      (): PluginFunctions => ({
        getEditorState: () => value,
        setEditorState,
        getDisabled: () => disabled,
      }),
    );

    const decorators = useMemo(
      () =>
        plugins?.reduce<DraftDecorator[]>((acc, curr) => {
          const decorators = curr.getDecorators();

          if (decorators && decorators.length > 0) {
            return [...acc, ...decorators];
          }

          return acc;
        }, []),
      [plugins],
    );

    const decorator = useMemo(
      () =>
        decorators && decorators.length > 0
          ? new CompositeDecorator(decorators)
          : null,
      [decorators],
    );

    const focus = useEventCallback(() => {
      if (disabled) {
        return;
      }

      editorRef.current?.focus();
    });

    useImperativeHandle(
      ref,
      () => ({
        editor: editorRef.current?.editor,
        editorContainer: editorRef.current?.editorContainer,
        focus,
        blur: () => editorRef.current?.blur(),
      }),
      [focus],
    );

    useEnhancedEffect(() => {
      if (!autoFocus) {
        return;
      }

      focus();
    }, [autoFocus, focus]);

    useEffect(() => {
      if (value.getDecorator() !== decorator) {
        setEditorState(EditorState.set(value, { decorator }));
      }
    }, [decorator, setEditorState, value]);

    const handleFocus = (e: React.SyntheticEvent) => {
      setFocused(true);
      onFocus?.(e);
    };

    const handleBlur = (e: React.SyntheticEvent) => {
      setFocused(false);
      onBlur?.(e);
    };

    const customStyleFn = (
      style: DraftInlineStyle,
      block: ContentBlock,
    ): React.CSSProperties => {
      const color =
        style
          .filter(inlineStyle => !!inlineStyle?.startsWith('color-'))
          .toArray()[0]
          ?.split('-')[1] || undefined;

      return {
        color,
        ...customStyleFnProp?.(style, block),
      };
    };

    return (
      <div
        data-testid="draft-input"
        css={css`
          position: relative;
          ${theme.typography.body14}
          color: ${theme.palette.text.primary};

          figure {
            margin: 0;
          }

          .${draftInputInternalClasses.content} {
            border-radius: ${theme.radius.sm}px;
            border: 1px solid ${theme.palette.grayAlpha[200]};
            padding: 7px 11px;
          }

          .${draftInputInternalClasses.placeholderRoot} {
            position: absolute;
            padding: 8px 12px;
            top: 0;
            left: 0;
            right: 0;
            color: ${theme.palette.text.secondary};
            z-index: -1;
            user-select: none;
          }

          .${draftInputInternalClasses.placeholderInner} {
            overflow: hidden;
          }

          :hover .${draftInputInternalClasses.content} {
            border-color: ${theme.palette.grayAlpha[500]};

            @media (hover: none) {
              border-color: ${theme.palette.grayAlpha[200]};
            }
          }

          &.${draftInputClasses.focused} .${draftInputInternalClasses.content} {
            border-color: ${theme.taxPalette.primary[500]};
            box-shadow: 0 0 0 2px ${lighten(0.3, theme.taxPalette.primary[500])};
          }

          &.${draftInputClasses.error} .${draftInputInternalClasses.content} {
            border-color: ${theme.palette.error.main};
            box-shadow: 0 0 0 2px ${lighten(0.3, theme.palette.error.main)};
          }

          &.${draftInputClasses.disabled} {
            color: ${theme.palette.text.disabled};

            .${draftInputInternalClasses.content} {
              background-color: ${theme.palette.grayAlpha[50]};
              border-color: ${theme.palette.grayAlpha[100]};
              box-shadow: none;
            }
          }
        `}
        {...rootProps}
        className={clsx(
          draftInputClasses.root,
          {
            [draftInputClasses.focused]: focused,
            [draftInputClasses.error]: fcs.error,
            [draftInputClasses.disabled]: disabled,
          },
          classes?.root,
          {
            [classes?.focused ?? '']: focused,
            [classes?.error ?? '']: fcs.error,
            [classes?.disabled ?? '']: disabled,
          },
          rootProps?.className,
          className,
        )}
      >
        <ClassNames>
          {({ css }) => (
            <Editor
              webDriverTestID="draft-input__content"
              spellCheck
              preserveSelectionOnBlur
              blockRenderMap={defaultBlockRenderMap}
              onChange={setEditorState}
              readOnly={disabled}
              {...other}
              ref={editorRef}
              editorState={value}
              textDirectionality={theme.direction === 'rtl' ? 'RTL' : 'LTR'}
              blockRendererFn={block =>
                plugins?.reduce(
                  (acc, plugin) =>
                    acc || plugin.blockRendererFn(block, getPluginFunctions()),
                  null,
                )
              }
              blockStyleFn={block => {
                const blockType = block.getType();
                let headerBlockStyle: string | null = null;

                try {
                  if (
                    blockType === 'header-one' ||
                    blockType === 'header-two' ||
                    blockType === 'header-three' ||
                    blockType === 'header-four' ||
                    blockType === 'header-five' ||
                    blockType === 'header-six'
                  ) {
                    headerBlockStyle = css(headerStyleMap[blockType]);
                  }
                } catch {
                  headerBlockStyle = null;
                }

                return clsx(
                  draftInputClasses.block,
                  draftInputClasses[
                    camelCase(
                      `block-${blockType}`,
                    ) as keyof typeof draftInputClasses
                  ],
                  headerBlockStyle,
                  classes?.block,
                  classes?.[
                    camelCase(
                      `block-${blockType}`,
                    ) as keyof typeof draftInputClasses
                  ],
                  blockStyleFnProp?.(block),
                );
              }}
              customStyleFn={customStyleFn}
              customStyleMap={{
                ...defaultCustomStyleMap,
                ...customStyleMap,
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          )}
        </ClassNames>

        {children}
      </div>
    );
  },
);
