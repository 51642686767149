import { Types } from '@allganize/alli-sdk-interfaces';
import { defaultLocale, Locale, LOCALES } from './locales';

export const getBrowserLocale = () => {
  const navigatorLocales = (
    [
      window.navigator.language,
      ...window.navigator.languages,
      'userLanguage' in window.navigator &&
        typeof window.navigator.userLanguage === 'string' &&
        window.navigator.userLanguage,
      'browserLanguage' in window.navigator &&
        typeof window.navigator.browserLanguage === 'string' &&
        window.navigator.browserLanguage,
      'systemLanguage' in window.navigator &&
        typeof window.navigator.systemLanguage === 'string' &&
        window.navigator.systemLanguage,
    ].filter(Boolean) as string[]
  )
    .map(locale => locale.split('-')[0])
    .filter(locale => LOCALES.includes(locale as any)) as Locale[];

  return navigatorLocales[0] || defaultLocale;
};

export const localeToEnum: Record<Locale, Types.LocaleEnum> = {
  en: 'EN',
  ko: 'KO',
  ja: 'JA',
};

export const enumToLocale: Record<Types.LocaleEnum, Locale> = {
  EN: 'en',
  KO: 'ko',
  JA: 'ja',
  CN: defaultLocale,
  DE: defaultLocale,
  FR: defaultLocale,
};
