import { Iframe, IframeProps } from '@allganize/react-iframe';
import { useTheme as useBaseTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { useTheme } from '../styles/use-theme';

export const LauncherIframe = forwardRef<HTMLIFrameElement, IframeProps>(
  (props, ref) => {
    const theme = useTheme();
    const baseTheme = useBaseTheme();

    return (
      <Iframe
        css={css`
          width: ${theme.launcher.size}px;
          height: ${theme.launcher.size}px;
          border-radius: ${baseTheme.radius.round}px;
          border: 0 solid transparent;
          box-shadow: ${theme.launcher.boxShadow};
        `}
        {...props}
        ref={ref}
      />
    );
  },
);
