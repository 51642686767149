import { FunctionComponent, useEffect } from 'react';
import { analytics } from '../analytics';
import { useCurrentUserQuerySuspenseQuery } from '../graphql/queries/current-user-query';
import { useUser } from '../hooks/use-user';
import { hub } from '../sentry';
import { CurrentUserContext } from './current-user-context';

interface CurrentUserProviderProps {
  children?: React.ReactNode;
}

export const CurrentUserProvider: FunctionComponent<
  CurrentUserProviderProps
> = ({ children }) => {
  const result = useCurrentUserQuerySuspenseQuery();
  const { user } = useUser();
  const { data } = result;

  useEffect(() => {
    const currentUser = data?.me;

    if (currentUser) {
      const userId = currentUser.ownUserId ?? currentUser.id;

      analytics.setUserId(`${userId}`);
      analytics.user.mixpanel?.set({
        $email: currentUser.email,
        $phone: currentUser.phone,
        $first_name: currentUser.firstName,
        $last_name: currentUser.lastName,
        $name: currentUser.name,
        $avatar: currentUser.avatar,
        $created: currentUser.createdAt,
        locale: currentUser.locale,
        ownUserId: currentUser.ownUserId,
        temporary: currentUser.temporary,
        isTry: currentUser.isTry,
        variables: user.variables,
      });

      hub?.setUser({
        id: userId,
        email: currentUser.email ?? undefined,
        username: currentUser.ownUserId ?? undefined,
        locale: currentUser.locale,
        ownUserId: currentUser.ownUserId,
        temporary: currentUser.temporary,
        isTry: currentUser.isTry,
        variables: user.variables,
      });

      return;
    }

    if (user.id !== null) {
      analytics.setUserId(`${user.id}`);
      analytics.user.mixpanel?.set({
        $email: user.variables.EMAIL,
        $first_name: user.variables.FIRST_NAME,
        $last_name: user.variables.LAST_NAME,
        variables: user.variables,
      });

      hub?.setUser({
        id: user.id,
        email: user.variables.EMAIL?.toString(),
        username: `${user.id}`,
        ownUserId: user.id,
        variables: user.variables,
      });

      return;
    }

    analytics.setUserId(null);
    hub?.setUser(null);
  }, [data?.me, user.id, user.variables]);

  return (
    <CurrentUserContext.Provider value={result}>
      {children}
    </CurrentUserContext.Provider>
  );
};
