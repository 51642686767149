/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationFragment } from '../fragments/conversation-fragment';
import { ProjectFragment } from '../fragments/project-fragment';
import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import { ProjectFragmentDoc } from '../fragments/project-fragment';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EndConversationMutationVariables = Types.Exact<{
  where: Types.ConversationWhereUniqueInput;
}>;

export type EndConversationMutation = { __typename: 'UserMutation' } & {
  endConversation: Types.Maybe<
    { __typename: 'UserEndConversation' } & {
      conversation: Types.Maybe<
        { __typename: 'Conversation' } & {
          project: Types.Maybe<{ __typename: 'Project' } & ProjectFragment>;
        } & ConversationFragment
      >;
      errors: Types.Maybe<
        Array<Types.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const EndConversationMutationDocument = gql`
  mutation EndConversationMutation($where: ConversationWhereUniqueInput!) {
    endConversation(where: $where) {
      conversation {
        ...ConversationFragment
        project {
          ...ProjectFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ConversationFragmentDoc}
  ${ProjectFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EndConversationMutationMutationFn = Apollo.MutationFunction<
  EndConversationMutation,
  EndConversationMutationVariables
>;

/**
 * __useEndConversationMutation__
 *
 * To run a mutation, you first call `useEndConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endConversationMutation, { data, loading, error }] = useEndConversationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEndConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndConversationMutation,
    EndConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EndConversationMutation,
    EndConversationMutationVariables
  >(EndConversationMutationDocument, options);
}
export type EndConversationMutationHookResult = ReturnType<
  typeof useEndConversationMutation
>;
export type EndConversationMutationMutationResult =
  Apollo.MutationResult<EndConversationMutation>;
export type EndConversationMutationMutationOptions = Apollo.BaseMutationOptions<
  EndConversationMutation,
  EndConversationMutationVariables
>;
