/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { SlotValueFragment } from './slot-value-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
import { SlotValueFragmentDoc } from './slot-value-fragment';
export type FillSlotRichUXChatFragment = {
  __typename: 'FillSlotRichUXChat';
} & Pick<
  Types.FillSlotRichUXChat,
  | 'id'
  | 'message'
  | 'messageContentState'
  | 'createdAt'
  | 'hidden'
  | 'requiredInput'
  | 'nextInputType'
  | 'useAutoComplete'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    slotValues: Array<
      Types.Maybe<{ __typename: 'SlotValue' } & SlotValueFragment>
    >;
  };

export const FillSlotRichUXChatFragmentDoc = gql`
  fragment FillSlotRichUXChatFragment on FillSlotRichUXChat {
    id
    message
    messageContentState @client
    createdAt
    hidden
    media {
      ...ChatMediaFragment
    }
    requiredInput
    slotValues {
      ...SlotValueFragment
    }
    nextInputType
    useAutoComplete
  }
  ${ChatMediaFragmentDoc}
  ${SlotValueFragmentDoc}
`;
