import { AtomicBlockUtils, ContentBlock, EditorState } from 'draft-js';
import { DraftPlugin, PluginFunctions } from '../draft-plugin/draft-plugin';
import { DraftFile } from './draft-file';
import { DraftFileClasses } from './draft-file-classes';

export interface DraftFilePluginOptions {
  classes?: Partial<DraftFileClasses>;
}

export class DraftFilePlugin extends DraftPlugin {
  public static readonly ENTITY_TYPE = 'FILE';

  public static addFile(
    editorState: EditorState,
    url: string,
    extraData?: any,
  ) {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      DraftFilePlugin.ENTITY_TYPE,
      'IMMUTABLE',
      { ...extraData, src: url },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' ',
    );

    return EditorState.forceSelection(
      newEditorState,
      newEditorState.getCurrentContent().getSelectionAfter(),
    );
  }

  constructor(private options: DraftFilePluginOptions = {}) {
    super();
  }

  public blockRendererFn(
    block: ContentBlock,
    { getEditorState }: PluginFunctions,
  ) {
    if (block.getType() !== 'atomic') {
      return null;
    }

    const contentState = getEditorState().getCurrentContent();
    const entityKey = block.getEntityAt(0);

    if (!entityKey) {
      return null;
    }

    const entity = contentState.getEntity(entityKey);

    if (!entity) {
      return null;
    }

    const type = entity.getType();

    if (type !== DraftFilePlugin.ENTITY_TYPE) {
      return null;
    }

    const data = entity.getData();

    return {
      component: DraftFile,
      editable: false,
      props: {
        data,
        classes: this.options.classes,
      },
    };
  }
}
