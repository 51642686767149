import { css } from '@emotion/react';

import { Layout } from '../Layout';
import { LNB } from '../../lnb';
import { useProject } from '@allganize/alli-app-market-project';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Text } from '@allganize/ui-text';

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1.25, 0)};
  height: 58px;
`;
const Image = styled.img`
  height: 100%;
`;
const Content = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: auto;
`;

interface LNBLayoutProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  children: React.ReactNode;
}

export const LNBLayout = ({ children, ...other }: LNBLayoutProps) => {
  const project = useProject();
  const title = useMemo(() => {
    if (project.appCollectionSettings?.companyLogo?.url) {
      return (
        <Wrapper>
          <Image
            src={project.appCollectionSettings.companyLogo.url}
            alt="company logo"
          />
        </Wrapper>
      );
    }
    if (project.appCollectionSettings?.companyName) {
      return (
        <Text variant="title18">
          {project.appCollectionSettings.companyName}
        </Text>
      );
    }
    return undefined;
  }, [
    project.appCollectionSettings?.companyLogo?.url,
    project.appCollectionSettings?.companyName,
  ]);

  return (
    <Layout
      css={css`
        display: flex;
      `}
      {...other}
    >
      <LNB logo={title} />
      <Content>{children}</Content>
    </Layout>
  );
};
