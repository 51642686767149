/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { AnswerHighlightFragment } from '../fragments/answer-highlight-fragment';
import { PreviewCapacityFragment } from '../fragments/preview-capacity-fragment';
import { gql } from '@apollo/client';
import { AnswerHighlightFragmentDoc } from '../fragments/answer-highlight-fragment';
import { PreviewCapacityFragmentDoc } from '../fragments/preview-capacity-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KnowledgeBasePreviewQueryVariables = Types.Exact<{
  where: Types.KnowledgeBaseWhereUniqueInput;
  pageNo?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  highlightIndexes?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['Int']['input']>>
  >;
  highlights?: Types.InputMaybe<Types.KnowledgeBasePreviewHighlightsInput>;
  exactAnswer?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type KnowledgeBasePreviewQuery = { __typename: 'UserQuery' } & {
  knowledgeBasePreview: Types.Maybe<
    { __typename: 'KnowledgeBasePreview' } & Pick<
      Types.KnowledgeBasePreview,
      | 'totalPageCount'
      | 'pageNo'
      | 'css'
      | 'body'
      | 'highlightIndexes'
      | 'exactAnswer'
      | 'pdf'
    > & {
        highlights: Types.Maybe<
          { __typename: 'KnowledgeBasePreviewHighlights' } & Pick<
            Types.KnowledgeBasePreviewHighlights,
            'indexes'
          > & {
              pageIndexes: Types.Maybe<
                Array<
                  { __typename: 'KnowledgeBasePreviewPageHighlight' } & Pick<
                    Types.KnowledgeBasePreviewPageHighlight,
                    'page' | 'indexes'
                  >
                >
              >;
            }
        >;
        answerHighlights: Types.Maybe<
          { __typename: 'AnswerHighlightList' } & {
            highlights: Array<
              { __typename: 'AnswerHighlight' } & AnswerHighlightFragment
            >;
          }
        >;
        draftjs: Types.Maybe<
          { __typename: 'KnowledgeBaseDraftjsPreview' } & Pick<
            Types.KnowledgeBaseDraftjsPreview,
            'id' | 'answer'
          >
        >;
        knowledgeBase: Types.Maybe<
          | ({ __typename: 'CSVKnowledgeBase' } & Pick<
              Types.CSVKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'GoogleDocsKnowledgeBase' } & Pick<
              Types.GoogleDocsKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'HWPKnowledgeBase' } & Pick<
              Types.HWPKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'ImageKnowledgeBase' } & Pick<
              Types.ImageKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'MSDocsKnowledgeBase' } & Pick<
              Types.MSDocsKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'MSExcelKnowledgeBase' } & Pick<
              Types.MSExcelKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'MSPPTKnowledgeBase' } & Pick<
              Types.MSPPTKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'OldMSDocsKnowledgeBase' } & Pick<
              Types.OldMSDocsKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'PDFKnowledgeBase' } & Pick<
              Types.PDFKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
          | ({ __typename: 'TextKnowledgeBase' } & Pick<
              Types.TextKnowledgeBase,
              'id' | 'title'
            > & {
                previewCapacity: {
                  __typename: 'PreviewCapacity';
                } & PreviewCapacityFragment;
              })
        >;
      }
  >;
};

export const KnowledgeBasePreviewQueryDocument = gql`
  query KnowledgeBasePreviewQuery(
    $where: KnowledgeBaseWhereUniqueInput!
    $pageNo: Int
    $highlightIndexes: [Int]
    $highlights: KnowledgeBasePreviewHighlightsInput
    $exactAnswer: String
  ) {
    knowledgeBasePreview(
      where: $where
      pageNo: $pageNo
      highlightIndexes: $highlightIndexes
      highlights: $highlights
      exactAnswer: $exactAnswer
    ) {
      totalPageCount
      pageNo
      css
      body
      highlightIndexes
      exactAnswer
      pdf
      highlights {
        indexes
        pageIndexes {
          page
          indexes
        }
      }
      answerHighlights {
        highlights {
          ...AnswerHighlightFragment
        }
      }
      draftjs {
        id
        answer
      }
      knowledgeBase {
        ... on GoogleDocsKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on TextKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on ImageKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on MSDocsKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on PDFKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on MSExcelKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on MSPPTKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on OldMSDocsKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on CSVKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
        ... on HWPKnowledgeBase {
          id
          title
          previewCapacity {
            ...PreviewCapacityFragment
          }
        }
      }
    }
  }
  ${AnswerHighlightFragmentDoc}
  ${PreviewCapacityFragmentDoc}
`;

/**
 * __useKnowledgeBasePreviewQuery__
 *
 * To run a query within a React component, call `useKnowledgeBasePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeBasePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeBasePreviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pageNo: // value for 'pageNo'
 *      highlightIndexes: // value for 'highlightIndexes'
 *      highlights: // value for 'highlights'
 *      exactAnswer: // value for 'exactAnswer'
 *   },
 * });
 */
export function useKnowledgeBasePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    KnowledgeBasePreviewQuery,
    KnowledgeBasePreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    KnowledgeBasePreviewQuery,
    KnowledgeBasePreviewQueryVariables
  >(KnowledgeBasePreviewQueryDocument, options);
}
export function useKnowledgeBasePreviewQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KnowledgeBasePreviewQuery,
    KnowledgeBasePreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    KnowledgeBasePreviewQuery,
    KnowledgeBasePreviewQueryVariables
  >(KnowledgeBasePreviewQueryDocument, options);
}
export function useKnowledgeBasePreviewQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    KnowledgeBasePreviewQuery,
    KnowledgeBasePreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    KnowledgeBasePreviewQuery,
    KnowledgeBasePreviewQueryVariables
  >(KnowledgeBasePreviewQueryDocument, options);
}
export type KnowledgeBasePreviewQueryHookResult = ReturnType<
  typeof useKnowledgeBasePreviewQuery
>;
export type KnowledgeBasePreviewQueryLazyQueryHookResult = ReturnType<
  typeof useKnowledgeBasePreviewQueryLazyQuery
>;
export type KnowledgeBasePreviewQuerySuspenseQueryHookResult = ReturnType<
  typeof useKnowledgeBasePreviewQuerySuspenseQuery
>;
export type KnowledgeBasePreviewQueryQueryResult = Apollo.QueryResult<
  KnowledgeBasePreviewQuery,
  KnowledgeBasePreviewQueryVariables
>;
