import { InputField, InputFieldProps, inputClasses } from '@allganize/ui-input';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';

export const ChatFormInputField = forwardRef<
  HTMLDivElement,
  InputFieldProps & { ownerState?: any }
>((props, ref) => {
  const { ownerState, ...other } = props;
  const theme = useTheme();

  return (
    <InputField
      css={css`
        .${inputClasses.root} {
          min-height: 52px;
          border-radius: 28px;
          padding: 12px 16px 12px 12px;
          gap: 12px;

          :hover .${inputClasses.notchedOutline} {
            border-color: ${theme.taxPalette.primary[500]};
          }

          &.${inputClasses.focused} .${inputClasses.notchedOutline} {
            border-color: ${theme.taxPalette.primary[500]};
          }

          &.${inputClasses.disabled} {
            border-color: ${theme.taxPalette.grey[300]};
            background-color: ${theme.taxPalette.white};
            opacity: 0.4;
          }

          &.${inputClasses.adornedEnd} .${inputClasses.notchedOutline} {
            box-shadow: none;
            background-color: ${theme.palette.unstable_background.white};
            /* border-color: ${theme.palette.unstable_border.divider
              .default}; */
            z-index: -1;
          }
        }
      `}
      maxRows={7}
      fullWidth
      autoComplete="off"
      {...other}
      ref={ref}
      label={other.label ?? null}
    />
  );
});
