/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleActionResultDownloadFilePublicMutationVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    clientHashId?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
    input: UserQueryTypes.AsyncSingleActionResultDownloadFileInput;
    accessToken: UserQueryTypes.Scalars['String']['input'];
    token: UserQueryTypes.Scalars['String']['input'];
  }>;

export type SingleActionResultDownloadFilePublicMutation = {
  __typename: 'UserMutation';
} & {
  asyncSingleActionResultDownloadFilePublic: UserQueryTypes.Maybe<
    { __typename: 'AsyncSingleActionResultDownloadFilePublic' } & Pick<
      UserQueryTypes.AsyncSingleActionResultDownloadFilePublic,
      'taskId'
    > & {
        errors: UserQueryTypes.Maybe<
          Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
        >;
      }
  >;
};

export const SingleActionResultDownloadFilePublicMutationDocument = gql`
  mutation SingleActionResultDownloadFilePublicMutation(
    $where: ProjectWhereUniqueInput!
    $clientHashId: String
    $input: AsyncSingleActionResultDownloadFileInput!
    $accessToken: String!
    $token: String!
  ) {
    asyncSingleActionResultDownloadFilePublic(
      where: $where
      clientHashId: $clientHashId
      input: $input
      accessToken: $accessToken
      token: $token
    ) {
      taskId
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SingleActionResultDownloadFilePublicMutationMutationFn =
  Apollo.MutationFunction<
    SingleActionResultDownloadFilePublicMutation,
    SingleActionResultDownloadFilePublicMutationVariables
  >;

/**
 * __useSingleActionResultDownloadFilePublicMutation__
 *
 * To run a mutation, you first call `useSingleActionResultDownloadFilePublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleActionResultDownloadFilePublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleActionResultDownloadFilePublicMutation, { data, loading, error }] = useSingleActionResultDownloadFilePublicMutation({
 *   variables: {
 *      where: // value for 'where'
 *      clientHashId: // value for 'clientHashId'
 *      input: // value for 'input'
 *      accessToken: // value for 'accessToken'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSingleActionResultDownloadFilePublicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SingleActionResultDownloadFilePublicMutation,
    SingleActionResultDownloadFilePublicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SingleActionResultDownloadFilePublicMutation,
    SingleActionResultDownloadFilePublicMutationVariables
  >(SingleActionResultDownloadFilePublicMutationDocument, options);
}
export type SingleActionResultDownloadFilePublicMutationHookResult = ReturnType<
  typeof useSingleActionResultDownloadFilePublicMutation
>;
export type SingleActionResultDownloadFilePublicMutationMutationResult =
  Apollo.MutationResult<SingleActionResultDownloadFilePublicMutation>;
export type SingleActionResultDownloadFilePublicMutationMutationOptions =
  Apollo.BaseMutationOptions<
    SingleActionResultDownloadFilePublicMutation,
    SingleActionResultDownloadFilePublicMutationVariables
  >;
