import { CamelCase } from '@allganize/types';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiCircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { camelCase } from 'lodash-es';
import { forwardRef } from 'react';
import { circularProgressClasses } from './circular-progress-classes';
import {
  CircularProgressProps,
  CircularProgressSize,
} from './circular-progress-type-map';

const sizes: Record<CircularProgressSize, number> = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 36,
  xl: 48,
};

/**
 * Progress indicators commonly known as spinners, express an unspecified wait
 * time or display the length of a process.
 *
 * Progress indicators inform users about the status of ongoing processes,
 * such as loading an app, submitting a form, or saving updates. The variant
 * is "indeterminate". Indeterminate indicators visualize an unspecified
 * wait time. The animations of the components rely on CSS as much as possible
 * to work even before the JavaScript is loaded.
 */
export const CircularProgress = forwardRef<
  HTMLSpanElement,
  CircularProgressProps
>((props, ref) => {
  const theme = useTheme();
  const {
    classes,
    color = 'primary',
    size = 'md',
    variant = 'indeterminate',
    ...other
  } = props;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <MuiCircularProgress
          data-testid="circular-progress"
          color={color}
          size={sizes[size]}
          variant={variant}
          {...other}
          ref={ref}
          classes={{
            ...classes,
            determinate: cx(
              css`
                transition: ${theme.transitions.create('transform')};
              `,
              classes?.determinate,
            ),
            colorPrimary: cx(
              css`
                color: ${theme.taxPalette.primary[300]};
              `,
              classes?.colorPrimary,
            ),
            circleDeterminate: cx(
              css`
                transition: ${theme.transitions.create('stroke-dashoffset')};
              `,
              classes?.circleDeterminate,
            ),
          }}
          className={clsx(
            circularProgressClasses[
              camelCase(`size_${size}`) as keyof typeof circularProgressClasses
            ],
            classes?.[
              camelCase(
                `size_${size}`,
              ) as CamelCase<`size_${CircularProgressSize}`>
            ],
            other.className,
          )}
        />
      )}
    </ClassNames>
  );
});
