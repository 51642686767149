/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateSingleActionAppResultPublicMutationVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    appId: UserQueryTypes.Scalars['ID']['input'];
    accessToken: UserQueryTypes.Scalars['String']['input'];
    publicToken: UserQueryTypes.Scalars['String']['input'];
    userInput?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['JSONString']['input']
    >;
    singleActionAppSelectedDocumentInputInfo?: UserQueryTypes.InputMaybe<
      Array<UserQueryTypes.SingleActionAppSelectedDocumentInputInfo>
    >;
  }>;

export type GenerateSingleActionAppResultPublicMutation = {
  __typename: 'UserMutation';
} & {
  generateSingleActionAppResultPublic: UserQueryTypes.Maybe<
    { __typename: 'GenerateSingleActionAppResultPublic' } & {
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const GenerateSingleActionAppResultPublicMutationDocument = gql`
  mutation GenerateSingleActionAppResultPublicMutation(
    $where: ProjectWhereUniqueInput!
    $appId: ID!
    $accessToken: String!
    $publicToken: String!
    $userInput: JSONString
    $singleActionAppSelectedDocumentInputInfo: [SingleActionAppSelectedDocumentInputInfo!]
  ) {
    generateSingleActionAppResultPublic(
      projectWhere: $where
      singleActionAppId: $appId
      accessToken: $accessToken
      token: $publicToken
      userInput: $userInput
      singleActionAppSelectedDocumentInputInfo: $singleActionAppSelectedDocumentInputInfo
    ) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GenerateSingleActionAppResultPublicMutationMutationFn =
  Apollo.MutationFunction<
    GenerateSingleActionAppResultPublicMutation,
    GenerateSingleActionAppResultPublicMutationVariables
  >;

/**
 * __useGenerateSingleActionAppResultPublicMutation__
 *
 * To run a mutation, you first call `useGenerateSingleActionAppResultPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSingleActionAppResultPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSingleActionAppResultPublicMutation, { data, loading, error }] = useGenerateSingleActionAppResultPublicMutation({
 *   variables: {
 *      where: // value for 'where'
 *      appId: // value for 'appId'
 *      accessToken: // value for 'accessToken'
 *      publicToken: // value for 'publicToken'
 *      userInput: // value for 'userInput'
 *      singleActionAppSelectedDocumentInputInfo: // value for 'singleActionAppSelectedDocumentInputInfo'
 *   },
 * });
 */
export function useGenerateSingleActionAppResultPublicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSingleActionAppResultPublicMutation,
    GenerateSingleActionAppResultPublicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSingleActionAppResultPublicMutation,
    GenerateSingleActionAppResultPublicMutationVariables
  >(GenerateSingleActionAppResultPublicMutationDocument, options);
}
export type GenerateSingleActionAppResultPublicMutationHookResult = ReturnType<
  typeof useGenerateSingleActionAppResultPublicMutation
>;
export type GenerateSingleActionAppResultPublicMutationMutationResult =
  Apollo.MutationResult<GenerateSingleActionAppResultPublicMutation>;
export type GenerateSingleActionAppResultPublicMutationMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateSingleActionAppResultPublicMutation,
    GenerateSingleActionAppResultPublicMutationVariables
  >;
