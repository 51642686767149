import * as CookiesMod from 'js-cookie';

import { Converter, Storage, defaultConverter } from './storage';

const Cookies = (CookiesMod as any).default as typeof CookiesMod;

export class CookieStorage<T = string> implements Storage<T> {
  private cookies: Cookies.CookiesStatic;

  constructor(
    private converter: Converter<T> = defaultConverter as Converter<T>,
    private attributes: Cookies.CookieAttributes = { path: '/' },
  ) {
    this.cookies = Cookies.withAttributes(this.attributes);
  }

  public async getItem(key: string): Promise<T | undefined> {
    const value = this.cookies.get(key);
    return this.converter.read(value, key);
  }

  public async setItem(key: string, value: T): Promise<void> {
    this.cookies.set(key, this.converter.write(value, key));
  }

  public async removeItem(key: string): Promise<void> {
    this.cookies.remove(key);
  }
}
