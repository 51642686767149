/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { FormValueFragment } from './form-value-fragment';
import { ChatAgentFragment } from './chat-agent-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
import { FormValueFragmentDoc } from './form-value-fragment';
import { ChatAgentFragmentDoc } from './chat-agent-fragment';
export type SendFormChatFragment = { __typename: 'SendFormChat' } & Pick<
  Types.SendFormChat,
  | 'id'
  | 'createdAt'
  | 'message'
  | 'messageContentState'
  | 'hidden'
  | 'plainText'
  | 'nextInputType'
  | 'useAutoComplete'
  | 'enableAgentSelection'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    formValues: Types.Maybe<
      Array<Types.Maybe<{ __typename: 'FormValue' } & FormValueFragment>>
    >;
    targetAgents: Types.Maybe<
      Array<Types.Maybe<{ __typename: 'Agent' } & ChatAgentFragment>>
    >;
  };

export const SendFormChatFragmentDoc = gql`
  fragment SendFormChatFragment on SendFormChat {
    id
    createdAt
    message
    messageContentState @client
    hidden
    plainText
    media {
      ...ChatMediaFragment
    }
    nextInputType
    formValues {
      ...FormValueFragment
    }
    useAutoComplete
    enableAgentSelection
    targetAgents {
      ...ChatAgentFragment
    }
  }
  ${ChatMediaFragmentDoc}
  ${FormValueFragmentDoc}
  ${ChatAgentFragmentDoc}
`;
