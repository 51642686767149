/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ChatFileFragment = { __typename: 'File' } & Pick<
  Types.File,
  'id' | 'fileId' | 'metaInfo' | 'url' | 'createdAt' | 'modifiedAt'
>;

export const ChatFileFragmentDoc = gql`
  fragment ChatFileFragment on File {
    id
    fileId
    metaInfo
    url
    createdAt
    modifiedAt
  }
`;
