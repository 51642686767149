/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type PreviewCapacityFragment = { __typename: 'PreviewCapacity' } & Pick<
  Types.PreviewCapacity,
  'pdf' | 'html' | 'draftjs' | 'image'
>;

export const PreviewCapacityFragmentDoc = gql`
  fragment PreviewCapacityFragment on PreviewCapacity {
    pdf
    html
    draftjs
    image
  }
`;
