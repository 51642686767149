import { createSvgIcon } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiAvatar from '@mui/material/Avatar';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { camelCase } from 'lodash-es';
import { forwardRef } from 'react';
import { avatarClasses } from './avatar-classes';
import { AvatarTypeMap } from './avatar-type-map';

const Person = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_382_17463)">
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
        fill="#BCC2C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00003 9.77778C9.71825 9.77778 11.1111 8.38489 11.1111 6.66667C11.1111 4.94845 9.71825 3.55556 8.00003 3.55556C6.28181 3.55556 4.88892 4.94845 4.88892 6.66667C4.88892 8.38489 6.28181 9.77778 8.00003 9.77778ZM8.00003 10.6667C4.07267 10.6667 0.888916 13.8504 0.888916 17.7778H15.1111C15.1111 13.8504 11.9274 10.6667 8.00003 10.6667Z"
        fill="#F7F8F8"
      />
    </g>
    <path
      d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8Z"
      stroke="#BCC2C2"
    />
    <defs>
      <clipPath id="clip0_382_17463">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>,
  'Person',
);

// @ts-expect-error overridable component
export const Avatar: OverridableComponent<AvatarTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();
    const {
      children,
      classes,
      size = 'medium',
      variant = 'rounded',
      ...other
    } = props;
    const hasImg = other.src || other.srcSet;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiAvatar
            data-testid="avatar"
            variant={variant}
            {...other}
            ref={ref}
            className={cx(
              avatarClasses[
                camelCase(`size_${size}`) as keyof typeof avatarClasses
              ],
              classes?.[camelCase(`size_${size}`) as keyof typeof classes],
              other.className,
            )}
            classes={{
              ...classes,
              root: cx(
                size === 'smallest' &&
                  css`
                    ${theme.typography.title12}
                    width: 20px;
                    height: 20px;
                  `,
                size === 'small' &&
                  css`
                    ${theme.typography.title12}
                    width: 24px;
                    height: 24px;
                  `,
                size === 'medium' &&
                  css`
                    ${theme.typography.title14}
                    width: 36px;
                    height: 36px;
                  `,
                size === 'large' &&
                  css`
                    ${theme.typography.title16}
                    width: 48px;
                    height: 48px;
                  `,
                classes?.root,
              ),
              colorDefault: cx(
                css`
                  color: ${theme.palette.grey[50]};
                  background-color: ${theme.palette.grey[400]};
                `,
                classes?.colorDefault,
              ),
              rounded: cx(
                css`
                  border-radius: ${theme.radius.sm}px;
                `,
                classes?.rounded,
              ),
            }}
          >
            {children || (hasImg && other.alt?.[0]) || (
              <Person
                className={cx(
                  avatarClasses.fallback,
                  css`
                    width: 100%;
                    height: 100%;
                  `,
                  classes?.fallback,
                )}
              />
            )}
          </MuiAvatar>
        )}
      </ClassNames>
    );
  },
);
