export enum AIAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
}

export enum AIAnswerTypeEnum {
  CONVERSATION_HISTORY = 'CONVERSATION_HISTORY',
  FAQ = 'FAQ',
  MRC = 'MRC',
  SAVED_MESSAGE = 'SAVED_MESSAGE',
}

export enum AccessUserFilterEnum {
  CUSTOMER_GROUP = 'CUSTOMER_GROUP',
  CUSTOMER_ID = 'CUSTOMER_ID',
}

export enum AddOnType {
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',
  AGENTS = 'AGENTS',
  AGENT_IN_APP_LAUNCHER = 'AGENT_IN_APP_LAUNCHER',
  APP_LAUNCHER_CUSTOM_DOMAIN_TESTING = 'APP_LAUNCHER_CUSTOM_DOMAIN_TESTING',
  CONVERT_TABLE_TO_TEXT = 'CONVERT_TABLE_TO_TEXT',
  CREDIT_ANALYTICS = 'CREDIT_ANALYTICS',
  DAEWON_RECOMMEND_CHAIN = 'DAEWON_RECOMMEND_CHAIN',
  DEPRECATE_DAG = 'DEPRECATE_DAG',
  DISABLE_APP_LAUNCHER_IN_SDK_M1 = 'DISABLE_APP_LAUNCHER_IN_SDK_M1',
  DI_USE_GA_PDF = 'DI_USE_GA_PDF',
  FAQ_LINK_IN_GA = 'FAQ_LINK_IN_GA',
  GA_INPUT_VARIABLES = 'GA_INPUT_VARIABLES',
  GENERATIVE_ANSWER = 'GENERATIVE_ANSWER',
  GOOGLE_SHARED_DRIVE = 'GOOGLE_SHARED_DRIVE',
  HTML_TABLE_ENABLED = 'HTML_TABLE_ENABLED',
  IMAGE_DESCRIPTION = 'IMAGE_DESCRIPTION',
  IMAGE_PREVIEW = 'IMAGE_PREVIEW',
  INTENT_CLASSIFICATION = 'INTENT_CLASSIFICATION',
  LLM = 'LLM',
  LLM_APP_MARKET = 'LLM_APP_MARKET',
  LLM_APP_PACKAGE = 'LLM_APP_PACKAGE',
  LLM_APP_PUBLISH_LIMIT = 'LLM_APP_PUBLISH_LIMIT',
  LLM_GUARDRAILS = 'LLM_GUARDRAILS',
  LLM_INPUT_NODE_KB_UPLOAD_ON = 'LLM_INPUT_NODE_KB_UPLOAD_ON',
  LLM_QA_GEN = 'LLM_QA_GEN',
  LONG_TERM_MEMORY = 'LONG_TERM_MEMORY',
  RETRIEVAL_EMBEDDING = 'RETRIEVAL_EMBEDDING',
  SIMPLE_RECOMMEND_CHAIN = 'SIMPLE_RECOMMEND_CHAIN',
  SINGLE_ACTION_CREATOR = 'SINGLE_ACTION_CREATOR',
  SOURCE_INTEGRATION = 'SOURCE_INTEGRATION',
  SQL_CHAIN = 'SQL_CHAIN',
  WA_APP_LAUNCHER_NEW_HOME = 'WA_APP_LAUNCHER_NEW_HOME',
  YK_RECOMMEND_CHAIN = 'YK_RECOMMEND_CHAIN',
}

export enum AgentOrder {
  name__ASC = 'name__ASC',
  name__DESC = 'name__DESC',
}

export enum AgentPermissionEnum {
  ACCOUNT_POLICY_WRITE = 'ACCOUNT_POLICY_WRITE',
  AGENT_ACTION_LOG_DOWNLOAD_READ = 'AGENT_ACTION_LOG_DOWNLOAD_READ',
  AGENT_ASSIGNABLE = 'AGENT_ASSIGNABLE',
  AGENT_INVITABLE = 'AGENT_INVITABLE',
  ANALYTICS_DOWNLOAD = 'ANALYTICS_DOWNLOAD',
  API_WHITELIST_WRITE = 'API_WHITELIST_WRITE',
  CONVERSATIONS_WRITE = 'CONVERSATIONS_WRITE',
  CONVERSATION_DOWNLOAD = 'CONVERSATION_DOWNLOAD',
  CONVERSATION_STATE_WRITE = 'CONVERSATION_STATE_WRITE',
  DASHBOARD_ORDER_WRITE = 'DASHBOARD_ORDER_WRITE',
  DASHBOARD_WHITELIST_WRITE = 'DASHBOARD_WHITELIST_WRITE',
  DELETE_AGENT_CHAT_MESSAGE = 'DELETE_AGENT_CHAT_MESSAGE',
  DELETE_CHAT_MESSAGE = 'DELETE_CHAT_MESSAGE',
  DOC_SOURCE_READ = 'DOC_SOURCE_READ',
  DOC_SOURCE_WRITE = 'DOC_SOURCE_WRITE',
  ENTITY_EXTRACTION_DOWNLOAD = 'ENTITY_EXTRACTION_DOWNLOAD',
  ENTITY_EXTRACTION_EDITABLE = 'ENTITY_EXTRACTION_EDITABLE',
  ENTITY_EXTRACTION_READ = 'ENTITY_EXTRACTION_READ',
  FAQ_ADD_FOLDER_WRITE = 'FAQ_ADD_FOLDER_WRITE',
  FAQ_AUTO_TAGGING_READ = 'FAQ_AUTO_TAGGING_READ',
  FAQ_AUTO_TAGGING_WRITE = 'FAQ_AUTO_TAGGING_WRITE',
  FAQ_CUSTOM_COLUMNS_WRITE = 'FAQ_CUSTOM_COLUMNS_WRITE',
  FAQ_EDITABLE = 'FAQ_EDITABLE',
  FAQ_HISTORY_READ = 'FAQ_HISTORY_READ',
  FAQ_SETTING_READ = 'FAQ_SETTING_READ',
  FAQ_SETTING_WRITE = 'FAQ_SETTING_WRITE',
  FAQ_TRAIN_WRITE = 'FAQ_TRAIN_WRITE',
  FILES_WHITELIST_WRITE = 'FILES_WHITELIST_WRITE',
  KNOWLEDGE_BASE_ADD_FOLDER_WRITE = 'KNOWLEDGE_BASE_ADD_FOLDER_WRITE',
  KNOWLEDGE_BASE_EDITABLE = 'KNOWLEDGE_BASE_EDITABLE',
  KNOWLEDGE_BASE_HISTORY_READ = 'KNOWLEDGE_BASE_HISTORY_READ',
  KNOWLEDGE_BASE_SETTING_READ = 'KNOWLEDGE_BASE_SETTING_READ',
  KNOWLEDGE_BASE_SETTING_WRITE = 'KNOWLEDGE_BASE_SETTING_WRITE',
  KNOWLEDGE_BASE_TRAIN_WRITE = 'KNOWLEDGE_BASE_TRAIN_WRITE',
  MANAGE_AGENT_READ = 'MANAGE_AGENT_READ',
  MANAGE_AGENT_WRITE = 'MANAGE_AGENT_WRITE',
  OFFICE_HOURS_EDITABLE = 'OFFICE_HOURS_EDITABLE',
  PAYMENT = 'PAYMENT',
  PROJECT_SETTING_API_KEY_WRITE = 'PROJECT_SETTING_API_KEY_WRITE',
  PROJECT_SETTING_GENERAL_WRITE = 'PROJECT_SETTING_GENERAL_WRITE',
  PROJECT_SETTING_READ = 'PROJECT_SETTING_READ',
  PROJECT_SETTING_WRITE = 'PROJECT_SETTING_WRITE',
  QUERY_HISTORY_REDACTABLE = 'QUERY_HISTORY_REDACTABLE',
  QUERY_TRAINING_READ = 'QUERY_TRAINING_READ',
  QUERY_TRAINING_WRITE = 'QUERY_TRAINING_WRITE',
  REDACT_KEYWORD_WRITE = 'REDACT_KEYWORD_WRITE',
  SDK_WHITELIST_WRITE = 'SDK_WHITELIST_WRITE',
  SKILL_POPUP_URL_GENERATION = 'SKILL_POPUP_URL_GENERATION',
  SKILL_READ = 'SKILL_READ',
  SKILL_WRITE = 'SKILL_WRITE',
  SMALLTALK_EDITABLE = 'SMALLTALK_EDITABLE',
  SMALLTALK_READ = 'SMALLTALK_READ',
  TRAINING_SCHEDULE_WRITE = 'TRAINING_SCHEDULE_WRITE',
  USER_DOWNLOAD = 'USER_DOWNLOAD',
  USER_EDITABLE = 'USER_EDITABLE',
}

export enum AgentStatusEnum {
  ACTIVE = 'ACTIVE',
  DECOMMISSIONED = 'DECOMMISSIONED',
  DELETED = 'DELETED',
  INVITED = 'INVITED',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
}

export enum AgentTwoFactorStatus {
  AUTHENTICATED = 'AUTHENTICATED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}

export enum AgentTwoFactorType {
  EMAIL = 'EMAIL',
}

export enum AlliClientType {
  ANDROID_SDK = 'ANDROID_SDK',
  ANSWER_BOT_API = 'ANSWER_BOT_API',
  AUTOMATION = 'AUTOMATION',
  COGNITIVE_SEARCH_API = 'COGNITIVE_SEARCH_API',
  CUSTOM_SDK = 'CUSTOM_SDK',
  DASHBOARD = 'DASHBOARD',
  FACEBOOK = 'FACEBOOK',
  INTERNAL_SDK = 'INTERNAL_SDK',
  IOS_SDK = 'IOS_SDK',
  KAKAO = 'KAKAO',
  LINE = 'LINE',
  LINEWORKS = 'LINEWORKS',
  MSTEAMS = 'MSTEAMS',
  REST_API = 'REST_API',
  SEARCH_SDK = 'SEARCH_SDK',
  SLACK = 'SLACK',
  SLACK_APPS = 'SLACK_APPS',
  UNKNOWN = 'UNKNOWN',
  VOICE = 'VOICE',
  WEB_SDK = 'WEB_SDK',
}

export enum AlliSDKError {
  DUPLICATED = 'DUPLICATED',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  INVALID_SDK_TYPE = 'INVALID_SDK_TYPE',
  INVALID_STATE = 'INVALID_STATE',
  INVALID_USER = 'INVALID_USER',
  RACE_CONDITION = 'RACE_CONDITION',
}

export enum AnalyticsChannelEnum {
  ALL = 'ALL',
  API = 'API',
  DASHBOARD = 'DASHBOARD',
  SDK = 'SDK',
  SEARCH_SDK = 'SEARCH_SDK',
  THIRD = 'THIRD',
}

export enum AnalyticsCountOptionEnum {
  CUSTOM = 'CUSTOM',
  TOP_5 = 'TOP_5',
  TOP_10 = 'TOP_10',
  TOP_20 = 'TOP_20',
}

export enum AnalyticsSourceEnum {
  ALL = 'ALL',
  DOC = 'DOC',
  FAQ = 'FAQ',
}

export enum AnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
}

export enum AnalyticsSummaryChannel {
  CONVERSATION_APP = 'CONVERSATION_APP',
  GA_API = 'GA_API',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  SINGLE_ACTION_APP = 'SINGLE_ACTION_APP',
  SKILL = 'SKILL',
  TOTAL = 'TOTAL',
}

export enum AnalyticsSummaryMetric {
  CREDIT_COUNT = 'CREDIT_COUNT',
  LLM_CALL_COUNT = 'LLM_CALL_COUNT',
  REQUEST_COUNT = 'REQUEST_COUNT',
}

export enum AnswerType {
  IMAGE = 'IMAGE',
  PARAGRAPH = 'PARAGRAPH',
  TABLE = 'TABLE',
}

export enum AppLauncherCustomDomainStatus {
  COMPLETED = 'COMPLETED',
  COMPLETED_CONFIRMED = 'COMPLETED_CONFIRMED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
  SUBMMITED = 'SUBMMITED',
  WILL_EXPIRE = 'WILL_EXPIRE',
}

export enum AutoHashtagOrder {
  COUNT_DOC_ASC = 'COUNT_DOC_ASC',
  COUNT_DOC_DESC = 'COUNT_DOC_DESC',
  COUNT_FAQ_ASC = 'COUNT_FAQ_ASC',
  COUNT_FAQ_CANDIDATE_ASC = 'COUNT_FAQ_CANDIDATE_ASC',
  COUNT_FAQ_CANDIDATE_DESC = 'COUNT_FAQ_CANDIDATE_DESC',
  COUNT_FAQ_DESC = 'COUNT_FAQ_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  ON_OFF_ASC = 'ON_OFF_ASC',
  ON_OFF_DESC = 'ON_OFF_DESC',
}

export enum AutoHashtagState {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  SUGGESTED = 'SUGGESTED',
}

export enum CampaignOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  ORDER = 'ORDER',
  PROJECT_NAME_ASC = 'PROJECT_NAME_ASC',
  PROJECT_NAME_DESC = 'PROJECT_NAME_DESC',
}

export enum CampaignPopupOption {
  CUSTOM_SIZE = 'CUSTOM_SIZE',
  FULL_WINDOW = 'FULL_WINDOW',
}

export enum CampaignTypeEnum {
  NORMAL = 'NORMAL',
  OBSERVER = 'OBSERVER',
}

export enum CandidateSource {
  API = 'API',
  DOCUMENTS = 'DOCUMENTS',
  QNA = 'QNA',
  SDK = 'SDK',
}

export enum ChainExecutionHistoryOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum ChatBoxTemplate {
  DEFAULT = 'DEFAULT',
  NLU_OPTIONS = 'NLU_OPTIONS',
}

export enum ChatBubblePositionEnum {
  LEFT_BOTTOM = 'LEFT_BOTTOM',
  LEFT_TOP = 'LEFT_TOP',
  RIGHT_BOTTOM = 'RIGHT_BOTTOM',
  RIGHT_TOP = 'RIGHT_TOP',
}

export enum ChatBuilderEventTypeEnum {
  CLONE_CHAT_NODE = 'CLONE_CHAT_NODE',
  CREATE_CHAT_LINK = 'CREATE_CHAT_LINK',
  CREATE_CHAT_NODE = 'CREATE_CHAT_NODE',
  DELETE_CHAT_LINK = 'DELETE_CHAT_LINK',
  DELETE_CHAT_NODE = 'DELETE_CHAT_NODE',
  START_CAMPAIGN = 'START_CAMPAIGN',
  STOP_CAMPAIGN = 'STOP_CAMPAIGN',
  UPDATE_CHAT_LINK = 'UPDATE_CHAT_LINK',
  UPDATE_CHAT_NODE = 'UPDATE_CHAT_NODE',
  UPDATE_CHAT_NODE_OPTION = 'UPDATE_CHAT_NODE_OPTION',
}

export enum ChatCompleteState {
  CANCELED = 'CANCELED',
  OPENAI_ERROR = 'OPENAI_ERROR',
  PROGRESS = 'PROGRESS',
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
  STOPPED = 'STOPPED',
  SUCCESS = 'SUCCESS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum ChatEventEnum {
  AGENT_ENTER = 'AGENT_ENTER',
  AGENT_INTERNAL_MESSAGE = 'AGENT_INTERNAL_MESSAGE',
  SEND_CONVERSATION_TO_EMAIL = 'SEND_CONVERSATION_TO_EMAIL',
}

export enum ChatInputType {
  BOOLEAN = 'BOOLEAN',
  CHOICE = 'CHOICE',
  CONTACT_AGENT_BY_EMAIL_RICH_UX = 'CONTACT_AGENT_BY_EMAIL_RICH_UX',
  DATETIME = 'DATETIME',
  FILE = 'FILE',
  FILL_SLOT_RICH_UX = 'FILL_SLOT_RICH_UX',
  IMAGE = 'IMAGE',
  JSON = 'JSON',
  LIST_JSON = 'LIST_JSON',
  LIST_STRING = 'LIST_STRING',
  NUMBER = 'NUMBER',
  SEND_FORM_RICH_UX = 'SEND_FORM_RICH_UX',
  STRING = 'STRING',
}

export enum ChatLinkType {
  BROKEN = 'BROKEN',
  CURVED = 'CURVED',
  STRAIGHT = 'STRAIGHT',
}

export enum ChatNodeType {
  AgentChatNode = 'AgentChatNode',
  AssignVariableChatNode = 'AssignVariableChatNode',
  ConditionChatNode = 'ConditionChatNode',
  CustomUIChatNode = 'CustomUIChatNode',
  ExportVariablesChatNode = 'ExportVariablesChatNode',
  ExternalDataAdvancedChatNode = 'ExternalDataAdvancedChatNode',
  ExternalDataChatNode = 'ExternalDataChatNode',
  FAQChatNode = 'FAQChatNode',
  FillSlotsChatNode = 'FillSlotsChatNode',
  GenerativeAnswerChatNode = 'GenerativeAnswerChatNode',
  GotoChatNode = 'GotoChatNode',
  ImageMessageChatNode = 'ImageMessageChatNode',
  IntentChatNode = 'IntentChatNode',
  InternalChatNode = 'InternalChatNode',
  JumpPointChatNode = 'JumpPointChatNode',
  LLMChatNode = 'LLMChatNode',
  LLMInputChatNode = 'LLMInputChatNode',
  MRCChatNode = 'MRCChatNode',
  ObserverChatNode = 'ObserverChatNode',
  SendFormChatNode = 'SendFormChatNode',
  StartChatNode = 'StartChatNode',
  TextMessageChatNode = 'TextMessageChatNode',
}

export enum ChatOptionAlign {
  DEFAULT = 'DEFAULT',
  HORIZONTAL = 'HORIZONTAL',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ChatTextAlignEnum {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ChatWindowStyle {
  COMPACT = 'COMPACT',
  NORMAL = 'NORMAL',
}

export enum ClueFeedbackUploadState {
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  UPDATED = 'UPDATED',
}

export enum CognitiveHistoryOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
}

export enum CognitiveSearchSource {
  ANSWER_FEEDBACK = 'ANSWER_FEEDBACK',
  BING = 'BING',
  DOCUMENT = 'DOCUMENT',
  FAQ = 'FAQ',
  GOOGLE = 'GOOGLE',
}

export enum CognitiveSearchSourceType {
  DOCUMENT = 'DOCUMENT',
  FAQ = 'FAQ',
  WEB = 'WEB',
}

export enum CompareEnum {
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  DOES_NOT_END_WITH = 'DOES_NOT_END_WITH',
  DOES_NOT_START_WITH = 'DOES_NOT_START_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EQUAL_TO = 'EQUAL_TO',
  GREATER_EQUAL_TO = 'GREATER_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  LESS_EQUAL_TO = 'LESS_EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  NOT_SIMILAR_TO = 'NOT_SIMILAR_TO',
  NOT_WITHIN = 'NOT_WITHIN',
  SIMILAR_TO = 'SIMILAR_TO',
  STARTS_WITH = 'STARTS_WITH',
  WITHIN = 'WITHIN',
}

export enum ContactMethod {
  CHAT = 'CHAT',
  CHAT_SELECTED_AGENT = 'CHAT_SELECTED_AGENT',
  EMAIL = 'EMAIL',
  EMAIL_IF_NO_AGENT = 'EMAIL_IF_NO_AGENT',
}

export enum ConversationAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_CAMPAIGN = 'NO_CAMPAIGN',
  NO_DATA = 'NO_DATA',
}

export enum ConversationCategoryAddedFrom {
  AGENT = 'AGENT',
  AI_MODEL = 'AI_MODEL',
}

export enum ConversationOrder {
  CAMPAIGN_NAME_ASC = 'CAMPAIGN_NAME_ASC',
  CAMPAIGN_NAME_DESC = 'CAMPAIGN_NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  LAST_CHAT_TIME_ASC = 'LAST_CHAT_TIME_ASC',
  LAST_CHAT_TIME_DESC = 'LAST_CHAT_TIME_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
}

export enum ConversationStateEnum {
  END_BY_AGENT = 'END_BY_AGENT',
  END_BY_USER = 'END_BY_USER',
  END_OF_CHATBOT = 'END_OF_CHATBOT',
  END_OF_OBSERVER = 'END_OF_OBSERVER',
  INIT = 'INIT',
  INTERRUPTED = 'INTERRUPTED',
  PENDING = 'PENDING',
  WAIT_AGENT = 'WAIT_AGENT',
  WAIT_USER_ANSWER = 'WAIT_USER_ANSWER',
  WAIT_USER_ANSWER_2 = 'WAIT_USER_ANSWER_2',
}

export enum CurrencyEnum {
  JPY = 'JPY',
  KRW = 'KRW',
  USD = 'USD',
}

export enum DashboardMenuOption {
  ANALYTICS = 'ANALYTICS',
  CONVERSATIONS = 'CONVERSATIONS',
  CUSTOMERS = 'CUSTOMERS',
  ENTITY_EXTRACTION = 'ENTITY_EXTRACTION',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  LLM_APP_COLLECTION = 'LLM_APP_COLLECTION',
  LLM_APP_MARKET = 'LLM_APP_MARKET',
  SKILLS = 'SKILLS',
  SMALLTALK = 'SMALLTALK',
}

export enum DocSourceIndexStatus {
  ERROR = 'ERROR',
  PROGRESS = 'PROGRESS',
  SUCCESS = 'SUCCESS',
}

export enum DocTypeEnum {
  DOCUMENT = 'DOCUMENT',
  FAQ = 'FAQ',
}

export enum DocumentAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
  NO_DOCUMENT = 'NO_DOCUMENT',
}

export enum DocumentUsageType {
  ENTITY_EXTRACTION = 'ENTITY_EXTRACTION',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
}

export enum DocumentsVersion {
  NEW = 'NEW',
  OLD = 'OLD',
}

export enum EmailNotificationGapEnum {
  ALWAYS = 'ALWAYS',
  ONCE_1_HOUR = 'ONCE_1_HOUR',
  ONCE_2_HOUR = 'ONCE_2_HOUR',
  ONCE_3_HOUR = 'ONCE_3_HOUR',
  ONCE_4_HOUR = 'ONCE_4_HOUR',
  ONCE_6_HOUR = 'ONCE_6_HOUR',
  ONCE_A_DAY = 'ONCE_A_DAY',
}

export enum EmailReplyToOptionEnum {
  ANOTHER = 'ANOTHER',
  EACH_AGENT = 'EACH_AGENT',
  NO_REPLY = 'NO_REPLY',
}

export enum EntityExtractionBuildDetailState {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INIT = 'INIT',
  NO_TRAINING_DATA = 'NO_TRAINING_DATA',
  STARTED = 'STARTED',
  WAITING = 'WAITING',
}

export enum EntityExtractionBuildState {
  BUILDING = 'BUILDING',
  COMPLETE = 'COMPLETE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum EntityExtractionFrequencyEnum {
  DOCUMENT_ADDED = 'DOCUMENT_ADDED',
  MANUAL = 'MANUAL',
  ONCE_A_DAY = 'ONCE_A_DAY',
}

export enum EntityExtractionMappingDataSearchTypeEnum {
  KNOWLEDGE_BASE_NAME = 'KNOWLEDGE_BASE_NAME',
  MAPPING_RULE_NAME = 'MAPPING_RULE_NAME',
}

export enum EntityExtractionMappingDataTypeEnum {
  EXTRACTED = 'EXTRACTED',
  UPLOADED = 'UPLOADED',
}

export enum EntityExtractionMappingRuleDataTypeEnum {
  COLUMN = 'COLUMN',
  FILE = 'FILE',
  LABEL = 'LABEL',
}

export enum EntityExtractionResultOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DOCUMENT_ASC = 'DOCUMENT_ASC',
  DOCUMENT_DESC = 'DOCUMENT_DESC',
  ENTITY_ASC = 'ENTITY_ASC',
  ENTITY_DESC = 'ENTITY_DESC',
  EXTRACTED_AT_ASC = 'EXTRACTED_AT_ASC',
  EXTRACTED_AT_DESC = 'EXTRACTED_AT_DESC',
  MODEL_VERSION_ASC = 'MODEL_VERSION_ASC',
  MODEL_VERSION_DESC = 'MODEL_VERSION_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  PAGE_NO_ASC = 'PAGE_NO_ASC',
  PAGE_NO_DESC = 'PAGE_NO_DESC',
  RESULT_ASC = 'RESULT_ASC',
  RESULT_DESC = 'RESULT_DESC',
}

export enum EntityExtractionResultSearchTypeEnum {
  ENTITY_NAME = 'ENTITY_NAME',
  RESULT = 'RESULT',
}

export enum EntityExtractionResultStateEnum {
  COMPLETED = 'COMPLETED',
  CONVERTED = 'CONVERTED',
  CONVERTING = 'CONVERTING',
  CONVERT_FAILED = 'CONVERT_FAILED',
  EXTRACTED = 'EXTRACTED',
  EXTRACTING = 'EXTRACTING',
  EXTRACTION_FAILED = 'EXTRACTION_FAILED',
  INIT = 'INIT',
}

export enum EntityExtractionSetOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum EntityExtractionUsageType {
  INVOICE = 'INVOICE',
  NETZERO = 'NETZERO',
  PARAGRAPHS = 'PARAGRAPHS',
  TABLED_STRUCTURED = 'TABLED_STRUCTURED',
}

export enum ExternalDataAdvancedChatNodeService {
  SLACK = 'SLACK',
  TEAMS = 'TEAMS',
}

export enum ExternalDataAdvancedChatNodeSlackServiceAction {
  SEND_MESSAGE = 'SEND_MESSAGE',
}

export enum ExternalDataAdvancedChatNodeSlackServiceActor {
  ALLI = 'ALLI',
  USER = 'USER',
}

export enum ExternalDataAdvancedChatNodeTeamsServiceAction {
  SEND_MESSAGE = 'SEND_MESSAGE',
}

export enum ExternalNodeTypeEnum {
  ADVANCED = 'ADVANCED',
  SIMPLE = 'SIMPLE',
}

export enum FAQAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
  NO_FAQ = 'NO_FAQ',
}

export enum FAQCandidateAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
  NO_FAQ_CANDIDATE = 'NO_FAQ_CANDIDATE',
}

export enum FAQCandidateOrder {
  ASKED_ASC = 'ASKED_ASC',
  ASKED_DESC = 'ASKED_DESC',
}

export enum FAQCandidateSuggestStateEnum {
  COMPLETED = 'COMPLETED',
  ENQUEUED = 'ENQUEUED',
  FAIL = 'FAIL',
  PROCESSING = 'PROCESSING',
}

export enum FAQChangeHistorySummary {
  CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION',
  TRAIN_MODEL = 'TRAIN_MODEL',
}

export enum FAQChangeHistoryType {
  CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION',
  TRAIN_MODEL = 'TRAIN_MODEL',
}

export enum FAQDirectThresholdMethodEnum {
  ADAPTIVE = 'ADAPTIVE',
  SIMPLE = 'SIMPLE',
}

export enum FAQHistorySource {
  API = 'API',
  DASHBOARD = 'DASHBOARD',
  SDK = 'SDK',
  THIRD = 'THIRD',
  UNKNOWN = 'UNKNOWN',
}

export enum FAQOrder {
  SELECTED_ASC = 'SELECTED_ASC',
  SELECTED_DESC = 'SELECTED_DESC',
  SELECTED_RATIO_ASC = 'SELECTED_RATIO_ASC',
  SELECTED_RATIO_DESC = 'SELECTED_RATIO_DESC',
  SHOWN_ASC = 'SHOWN_ASC',
  SHOWN_DESC = 'SHOWN_DESC',
}

export enum FAQSimilarQuestionAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
  NO_FAQ_SIMILAR_QUESTION = 'NO_FAQ_SIMILAR_QUESTION',
}

export enum FAQSimilarQuestionOrder {
  ADDED_ASC = 'ADDED_ASC',
  ADDED_DESC = 'ADDED_DESC',
}

export enum FAQUploadState {
  DELETED = 'DELETED',
  DUPLICATED = 'DUPLICATED',
  EXCEEDED_LIMIT = 'EXCEEDED_LIMIT',
  FAQ_QUESTION_LENGTH_LIMIT_EXCEEDED = 'FAQ_QUESTION_LENGTH_LIMIT_EXCEEDED',
  GROUP_DOES_NOT_EXIST = 'GROUP_DOES_NOT_EXIST',
  HASHTAG_COUNT_LIMIT_EXCEEDED = 'HASHTAG_COUNT_LIMIT_EXCEEDED',
  HASHTAG_LENGTH_LIMIT_EXCEEDED = 'HASHTAG_LENGTH_LIMIT_EXCEEDED',
  INVALID_ANSWER = 'INVALID_ANSWER',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_FAQ_PERMISSION = 'INVALID_FAQ_PERMISSION',
  INVALID_FOLDER = 'INVALID_FOLDER',
  INVALID_FOLDER_PERMISSION = 'INVALID_FOLDER_PERMISSION',
  INVALID_HASHTAG = 'INVALID_HASHTAG',
  INVALID_USER_FILTER_FORMAT = 'INVALID_USER_FILTER_FORMAT',
  PARSING_ERROR = 'PARSING_ERROR',
  PAST_STATUS_AT = 'PAST_STATUS_AT',
  SHEET_PARSING_ERROR = 'SHEET_PARSING_ERROR',
  TOO_MANY_QUESTIONS = 'TOO_MANY_QUESTIONS',
  UPDATED = 'UPDATED',
  USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST',
}

export enum FaqBuildState {
  BUILDING = 'BUILDING',
  COMPLETE = 'COMPLETE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum FaqCandidateOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  FREQUENCY_ASC = 'FREQUENCY_ASC',
  FREQUENCY_DESC = 'FREQUENCY_DESC',
}

export enum FaqCandidateState {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
}

export enum FaqHashtagSelectableEnum {
  RESOLVED = 'RESOLVED',
  SUGGESTED = 'SUGGESTED',
}

export enum FaqOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  HARD_SUGGEST_COUNT_ASC = 'HARD_SUGGEST_COUNT_ASC',
  HARD_SUGGEST_COUNT_DESC = 'HARD_SUGGEST_COUNT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  SELECTED_COUNT_ASC = 'SELECTED_COUNT_ASC',
  SELECTED_COUNT_DESC = 'SELECTED_COUNT_DESC',
  SUGGEST_COUNT_ASC = 'SUGGEST_COUNT_ASC',
  SUGGEST_COUNT_DESC = 'SUGGEST_COUNT_DESC',
}

export enum FaqSimilarQuestionOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  FREQUENCY_ASC = 'FREQUENCY_ASC',
  FREQUENCY_DESC = 'FREQUENCY_DESC',
}

export enum FeedbackAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
  NO_SOURCE = 'NO_SOURCE',
}

export enum FeedbackSourceEnum {
  DOC = 'DOC',
  FAQ = 'FAQ',
}

export enum FeedbackTypeEnum {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export enum FileFormatEnum {
  CSV = 'CSV',
  TSV = 'TSV',
  XLSX = 'XLSX',
}

export enum FileProcessState {
  BUILDING = 'BUILDING',
  READY = 'READY',
  UPLOADING = 'UPLOADING',
}

export enum FileShareTypeEnum {
  ANYONE_IN_THE_GROUP = 'ANYONE_IN_THE_GROUP',
  ANYONE_WITH_THE_LINK = 'ANYONE_WITH_THE_LINK',
  RESTRICTED = 'RESTRICTED',
}

export enum FileSourceEnum {
  AGENT_ACTION_LOG = 'AGENT_ACTION_LOG',
  ANALYTICS = 'ANALYTICS',
  ANTONYM = 'ANTONYM',
  CONVERSATION = 'CONVERSATION',
  CUSTOMER = 'CUSTOMER',
  ENTITY_EXTRACTION = 'ENTITY_EXTRACTION',
  FAQ = 'FAQ',
  FAQ_ANALYTICS = 'FAQ_ANALYTICS',
  FAQ_CANDIDATE = 'FAQ_CANDIDATE',
  FAQ_HISTORY = 'FAQ_HISTORY',
  FAQ_SIMILAR_QUESTION = 'FAQ_SIMILAR_QUESTION',
  FEEDBACK_MANAGEMENT = 'FEEDBACK_MANAGEMENT',
  MRC_HISTORY = 'MRC_HISTORY',
  SINGLE_ACTION_RESULT = 'SINGLE_ACTION_RESULT',
  SINGLE_ACTION_RESULT_PUBLIC = 'SINGLE_ACTION_RESULT_PUBLIC',
  SYNONYM = 'SYNONYM',
  TRAINED_QUERY_LIST = 'TRAINED_QUERY_LIST',
}

export enum FileTypeEnum {
  CSV = 'CSV',
  DOC = 'DOC',
  DOCX = 'DOCX',
  GOOGLE_DOCS = 'GOOGLE_DOCS',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  GOOGLE_SLIDES = 'GOOGLE_SLIDES',
  HTML = 'HTML',
  JPEG = 'JPEG',
  JPG = 'JPG',
  PDF = 'PDF',
  PNG = 'PNG',
  PPT = 'PPT',
  PPTX = 'PPTX',
  TSV = 'TSV',
  TXT = 'TXT',
  XLS = 'XLS',
  XLSX = 'XLSX',
  ZIP = 'ZIP',
}

export enum FolderOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum FolderType {
  DOC = 'DOC',
  FAQ = 'FAQ',
}

export enum FormVariableInputTypeEnum {
  BUTTON = 'BUTTON',
  DROPDOWN = 'DROPDOWN',
  FILE = 'FILE',
  MEDIA = 'MEDIA',
  TEXT = 'TEXT',
}

export enum FormVariableOptionTypeEnum {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum GenerativeAnswerClueStatus {
  AVAILABLE = 'AVAILABLE',
  DELETED = 'DELETED',
  DENIED = 'DENIED',
}

export enum GenerativeAnswerFeedbackOrder {
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
}

export enum GenerativeAnswerNodeNextStepEnum {
  NEXT_NODE = 'NEXT_NODE',
  QUESTION_AGAIN = 'QUESTION_AGAIN',
}

export enum GenerativeAnswerUserFeedbackRatingFilter {
  ALL = 'ALL',
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export enum GuardrailCategory {
  HATE = 'HATE',
  SELFHARM = 'SELFHARM',
  SEXUAL = 'SEXUAL',
  VIOLENCE = 'VIOLENCE',
}

export enum HashtagAutomationTriggerEvent {
  DOCUMENT_AUTOHASHTAG_ASSIGNED = 'DOCUMENT_AUTOHASHTAG_ASSIGNED',
  DOCUMENT_SELECTED = 'DOCUMENT_SELECTED',
  QNA_AUTOHASHTAG_ASSIGNED = 'QNA_AUTOHASHTAG_ASSIGNED',
  QNA_SELECTED = 'QNA_SELECTED',
}

export enum HistoryResult {
  ANSWER_FOUND = 'ANSWER_FOUND',
  NO_ANSWER_FOUND = 'NO_ANSWER_FOUND',
}

export enum HtmlDocSourceNameEnum {
  USE_CUSTOM = 'USE_CUSTOM',
  USE_TITLE = 'USE_TITLE',
  USE_URL = 'USE_URL',
}

export enum HtmlDocSourceStateEnum {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  INIT = 'INIT',
  SCRAPING = 'SCRAPING',
  TRAINING = 'TRAINING',
}

export enum IdentityProviderEnum {
  ALLI = 'ALLI',
  GOOGLE = 'GOOGLE',
  SSO = 'SSO',
}

export enum InputSourceEnum {
  USER_INPUT = 'USER_INPUT',
  VARIABLE = 'VARIABLE',
}

export enum IntegrationConnectionStatus {
  AVAILABLE = 'AVAILABLE',
  CONNECTED = 'CONNECTED',
  REQUESTED = 'REQUESTED',
  SYNCING = 'SYNCING',
  UNAVAILABLE = 'UNAVAILABLE',
  WAIT_LIST = 'WAIT_LIST',
}

export enum IntegrationModel {
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  KNOWLEDGE_SNIPPET = 'KNOWLEDGE_SNIPPET',
}

export enum IntentClassificationIntents {
  CREATE_APP = 'CREATE_APP',
  NO_RECOMMEND = 'NO_RECOMMEND',
  RECOMMEND_MORE_APPS = 'RECOMMEND_MORE_APPS',
  RECOMMEND_OTHER_APPS = 'RECOMMEND_OTHER_APPS',
  SEARCH = 'SEARCH',
  SMALLTALK = 'SMALLTALK',
}

export enum KeywordAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
}

export enum KnowledgeBaseOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  FILE_NAME_ASC = 'FILE_NAME_ASC',
  FILE_NAME_DESC = 'FILE_NAME_DESC',
  SCORE = 'SCORE',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  TRAINING_ASC = 'TRAINING_ASC',
  TRAINING_DESC = 'TRAINING_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
}

export enum KnowledgeSnippetOrder {
  CONTENTS_ASC = 'CONTENTS_ASC',
  CONTENTS_DESC = 'CONTENTS_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum LLMAgentType {
  ALLI_AGENT = 'ALLI_AGENT',
  APP_MARKET = 'APP_MARKET',
  CSV_AGENT = 'CSV_AGENT',
  DAEWON_RECOMMEND_CHAIN = 'DAEWON_RECOMMEND_CHAIN',
  INTENT_CLASSIFICATION = 'INTENT_CLASSIFICATION',
  JSON_AGENT = 'JSON_AGENT',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  MAP_REDUCE = 'MAP_REDUCE',
  MULTI_QUERY_RETRIEVER = 'MULTI_QUERY_RETRIEVER',
  RETRIEVAL_AGENT = 'RETRIEVAL_AGENT',
  SELF_QUERYING_RETRIEVER = 'SELF_QUERYING_RETRIEVER',
  SIMPLE_RECOMMEND_CHAIN = 'SIMPLE_RECOMMEND_CHAIN',
  SINGLE_ACTION = 'SINGLE_ACTION',
  SINGLE_ACTION_AGENT = 'SINGLE_ACTION_AGENT',
  SINGLE_PROMPT = 'SINGLE_PROMPT',
  SNIPPETS_QA = 'SNIPPETS_QA',
  SQL_CHAIN = 'SQL_CHAIN',
  SQL_CHAIN_2 = 'SQL_CHAIN_2',
  SQL_CHAIN_3 = 'SQL_CHAIN_3',
  YK_RECOMMEND_CHAIN = 'YK_RECOMMEND_CHAIN',
}

export enum LLMAppFrom {
  FIRST_PARTY = 'FIRST_PARTY',
  FIRST_PARTY_PREVIEW = 'FIRST_PARTY_PREVIEW',
  SECOND_PARTY = 'SECOND_PARTY',
}

export enum LLMAppOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  WEIGHT_DESC = 'WEIGHT_DESC',
}

export enum LLMAppType {
  CAMPAIGN = 'CAMPAIGN',
  SINGLE_ACTION = 'SINGLE_ACTION',
}

export enum LLMAppTypeFilter {
  ALL = 'ALL',
  CAMPAIGN = 'CAMPAIGN',
  SINGLE_ACTION = 'SINGLE_ACTION',
}

export enum LLMCreditUsageCategory {
  DOCUMENT_CUSTOM = 'DOCUMENT_CUSTOM',
  DOCUMENT_INGESTION = 'DOCUMENT_INGESTION',
  DOCUMENT_INGESTION_EMBEDDING = 'DOCUMENT_INGESTION_EMBEDDING',
  DOCUMENT_INGESTION_IMAGE_DESCRIPTION = 'DOCUMENT_INGESTION_IMAGE_DESCRIPTION',
  DOCUMENT_QA_GEN = 'DOCUMENT_QA_GEN',
  DOCUMENT_SUMMARY = 'DOCUMENT_SUMMARY',
  FAQ_SEARCH = 'FAQ_SEARCH',
  GENERATIVE_ANSWER = 'GENERATIVE_ANSWER',
  GENERATIVE_ANSWER_WITH_GOOGLE = 'GENERATIVE_ANSWER_WITH_GOOGLE',
  LLM_NODE = 'LLM_NODE',
  MRC_SEARCH = 'MRC_SEARCH',
  OTHERS = 'OTHERS',
  SINGLE_ACTION = 'SINGLE_ACTION',
}

export enum LLMInputChatNodeInputSourceEnum {
  DOCUMENTS = 'DOCUMENTS',
  SNIPPET = 'SNIPPET',
  UPLOAD = 'UPLOAD',
}

export enum LLMKnowledgeBaseTaskType {
  CUSTOM = 'CUSTOM',
  QA_GEN = 'QA_GEN',
  SUMMARY = 'SUMMARY',
}

export enum LLMKnowledgeTaskStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  QA_GEN_COMPLETED = 'QA_GEN_COMPLETED',
  QA_GEN_FAILED = 'QA_GEN_FAILED',
  QA_GEN_STARTED = 'QA_GEN_STARTED',
  STARTED = 'STARTED',
}

export enum LLMModel {
  ALLGANIZE_FINANCE_13B = 'ALLGANIZE_FINANCE_13B',
  ALPHA_F_V2_EEVE = 'ALPHA_F_V2_EEVE',
  ALPHA_KO_LLAMA3 = 'ALPHA_KO_LLAMA3',
  ANTHROPIC_CLAUDE_2 = 'ANTHROPIC_CLAUDE_2',
  ANTHROPIC_CLAUDE_3_HAIKU = 'ANTHROPIC_CLAUDE_3_HAIKU',
  ANTHROPIC_CLAUDE_3_OPUS = 'ANTHROPIC_CLAUDE_3_OPUS',
  ANTHROPIC_CLAUDE_3_SONNET = 'ANTHROPIC_CLAUDE_3_SONNET',
  AZURE_DAVINCI = 'AZURE_DAVINCI',
  AZURE_GPT4 = 'AZURE_GPT4',
  AZURE_GPT4_TURBO = 'AZURE_GPT4_TURBO',
  AZURE_TURBO = 'AZURE_TURBO',
  AZURE_TURBO_16K = 'AZURE_TURBO_16K',
  AZURE_TURBO_JA = 'AZURE_TURBO_JA',
  DAVINCI = 'DAVINCI',
  DOCOMO = 'DOCOMO',
  GEMINI_15_FLASH = 'GEMINI_15_FLASH',
  GEMINI_15_PRO = 'GEMINI_15_PRO',
  GEMINI_PRO = 'GEMINI_PRO',
  GEMINI_PRO_VISION = 'GEMINI_PRO_VISION',
  GPT4 = 'GPT4',
  GPT4_32K = 'GPT4_32K',
  GPT4_O = 'GPT4_O',
  GPT4_TURBO = 'GPT4_TURBO',
  GPT4_TURBO_VISION = 'GPT4_TURBO_VISION',
  HYPER_CLOVA = 'HYPER_CLOVA',
  TURBO = 'TURBO',
  TURBO_16K = 'TURBO_16K',
  TURBO_INSTRUCT = 'TURBO_INSTRUCT',
  VERTEX_CHAT_BISON = 'VERTEX_CHAT_BISON',
  VERTEX_TEXT_BISON = 'VERTEX_TEXT_BISON',
}

export enum LLMNodeTypeEnum {
  LLM_AGENT = 'LLM_AGENT',
  SINGLE_PROMPT = 'SINGLE_PROMPT',
}

export enum LLMPromptCategory {
  AGENT = 'AGENT',
  ANSWER_CITATION = 'ANSWER_CITATION',
  ANSWER_CITATION_FULL = 'ANSWER_CITATION_FULL',
  CLARIFYING_INTENT = 'CLARIFYING_INTENT',
  CLUE_FACT_CHECK = 'CLUE_FACT_CHECK',
  CLUE_SUMMARY = 'CLUE_SUMMARY',
  CUSTOM = 'CUSTOM',
  DETERMINE_FU_QUESTION = 'DETERMINE_FU_QUESTION',
  DOCUMENT_SUMMARY = 'DOCUMENT_SUMMARY',
  GENERATE_FU_QUESTION = 'GENERATE_FU_QUESTION',
  GENERATE_LONG_TERM_MEMORY = 'GENERATE_LONG_TERM_MEMORY',
  GEN_RETRIEVAL_QUESTION = 'GEN_RETRIEVAL_QUESTION',
  GEN_RETRIEVAL_SIMILAR_QUESTION = 'GEN_RETRIEVAL_SIMILAR_QUESTION',
  IMAGE_DESCRIPTION = 'IMAGE_DESCRIPTION',
  QA_GEN_ANSWER_QUESTION = 'QA_GEN_ANSWER_QUESTION',
  QA_GEN_COMPLEX_QUESTION = 'QA_GEN_COMPLEX_QUESTION',
  QA_GEN_CREATE_QUESTION = 'QA_GEN_CREATE_QUESTION',
  QA_GEN_DOCUMENT = 'QA_GEN_DOCUMENT',
  QA_GEN_FILTER_QUESTION = 'QA_GEN_FILTER_QUESTION',
  QUERY_NORMALIZATION = 'QUERY_NORMALIZATION',
  SINGLE_ACTION = 'SINGLE_ACTION',
  SMALLTALK = 'SMALLTALK',
  SMALLTALK_NO_CLUES = 'SMALLTALK_NO_CLUES',
  SNIPPETS_QA = 'SNIPPETS_QA',
  SPLIT_SENTENCE = 'SPLIT_SENTENCE',
}

export enum LLMPromptDynamicInputType {
  SINGLE_ACTION = 'SINGLE_ACTION',
  SYSTEM = 'SYSTEM',
  VARIABLE = 'VARIABLE',
}

export enum LLMPromptGroupCategory {
  GENERATIVE_ANSWER = 'GENERATIVE_ANSWER',
  QA_GEN = 'QA_GEN',
}

export enum LLMPromptGroupOrder {
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum LLMPromptMessageRole {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum LLMPromptOrder {
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum LocaleEnum {
  CN = 'CN',
  DE = 'DE',
  EN = 'EN',
  FR = 'FR',
  JA = 'JA',
  KO = 'KO',
}

export enum MRCAnswerDisplayFormat {
  DRAFTJS = 'DRAFTJS',
  HTML = 'HTML',
  MARKDOWN = 'MARKDOWN',
  TEXT = 'TEXT',
}

export enum MRCAnswerType {
  GENERATED = 'GENERATED',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export enum MRCBuildDetailState {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INIT = 'INIT',
  NO_TRAINING_DATA = 'NO_TRAINING_DATA',
  STARTED = 'STARTED',
  UPDATING_VECTORS = 'UPDATING_VECTORS',
  WAITING = 'WAITING',
}

export enum MRCBuildState {
  BUILDING = 'BUILDING',
  COMPLETE = 'COMPLETE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum MRCChangeHistorySummary {
  CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION',
  TRAIN_CLIP_MODEL = 'TRAIN_CLIP_MODEL',
  TRAIN_READER_MODEL = 'TRAIN_READER_MODEL',
  TRAIN_RETRIEVER_MODEL = 'TRAIN_RETRIEVER_MODEL',
}

export enum MRCChangeHistoryType {
  CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION',
  TRAIN_MODEL = 'TRAIN_MODEL',
}

export enum MRCCustomAnswerType {
  CREATED = 'CREATED',
  EDITED = 'EDITED',
  FROM_ES = 'FROM_ES',
}

export enum MRCHistorySource {
  API = 'API',
  DASHBOARD = 'DASHBOARD',
  SDK = 'SDK',
  THIRD = 'THIRD',
  UNKNOWN = 'UNKNOWN',
}

export enum MRCModelStatusEnum {
  DEPLOYED = 'DEPLOYED',
  DEPLOYING = 'DEPLOYING',
  INIT = 'INIT',
  NORMAL = 'NORMAL',
  TESTED = 'TESTED',
  TESTING = 'TESTING',
  TRAINED = 'TRAINED',
  TRAINING = 'TRAINING',
}

export enum MRCRemoveSimilarResultOption {
  ALL = 'ALL',
  DOC = 'DOC',
  HASHTAG = 'HASHTAG',
}

export enum MRCSearchDashboardResponse {
  ANSWER_NOT_FOUND = 'ANSWER_NOT_FOUND',
}

export enum MallyError {
  ACCESS_USER_FILTER_PER_OBJECT_EXCEEDED = 'ACCESS_USER_FILTER_PER_OBJECT_EXCEEDED',
  ADMIN_GROUP_COULD_NOT_DEMOTED = 'ADMIN_GROUP_COULD_NOT_DEMOTED',
  ADMIN_GROUP_SHOULD_NOT_EMPTY = 'ADMIN_GROUP_SHOULD_NOT_EMPTY',
  ALLI_LOGIN_NOT_ALLOW_SSO = 'ALLI_LOGIN_NOT_ALLOW_SSO',
  ALLI_LOGIN_PROJECT_POLICY = 'ALLI_LOGIN_PROJECT_POLICY',
  ALREADY_ACTIVATED = 'ALREADY_ACTIVATED',
  ALREADY_ASSIGNED = 'ALREADY_ASSIGNED',
  ALREADY_EXISTS_USER = 'ALREADY_EXISTS_USER',
  ALREADY_FAQ_BUILDING = 'ALREADY_FAQ_BUILDING',
  ALREADY_HAS_SLUG = 'ALREADY_HAS_SLUG',
  ALREADY_INTEGRATED_OTHER_PROJECT = 'ALREADY_INTEGRATED_OTHER_PROJECT',
  ALREADY_MRC_BUILDING = 'ALREADY_MRC_BUILDING',
  ALREADY_SIGNUP = 'ALREADY_SIGNUP',
  ANOTHER_LOGIN = 'ANOTHER_LOGIN',
  API_NOT_OPENED = 'API_NOT_OPENED',
  CAMPAIGN_ALREADY_STARTED = 'CAMPAIGN_ALREADY_STARTED',
  CAMPAIGN_DATE_FAILURE = 'CAMPAIGN_DATE_FAILURE',
  CAMPAIGN_EMPTY_CHAT_FLOW = 'CAMPAIGN_EMPTY_CHAT_FLOW',
  CANCEL = 'CANCEL',
  CANDIDATE_SHOULD_BE_ARCHIVED = 'CANDIDATE_SHOULD_BE_ARCHIVED',
  CANNOT_DELETE_CHAT_BY_STATE = 'CANNOT_DELETE_CHAT_BY_STATE',
  CANNOT_DELETE_CHAT_BY_TYPE = 'CANNOT_DELETE_CHAT_BY_TYPE',
  CAN_NOT_CANCEL_SUBSCRIPTION = 'CAN_NOT_CANCEL_SUBSCRIPTION',
  CAN_NOT_CONNECT_TO_DIRECTORY = 'CAN_NOT_CONNECT_TO_DIRECTORY',
  CAN_NOT_DELETE = 'CAN_NOT_DELETE',
  CAN_NOT_DISCONNECT_WHILE_RUNNING = 'CAN_NOT_DISCONNECT_WHILE_RUNNING',
  CAN_NOT_MODIFY_PAYMENT_INFO = 'CAN_NOT_MODIFY_PAYMENT_INFO',
  CAN_NOT_MODIFY_PLAN = 'CAN_NOT_MODIFY_PLAN',
  CAN_NOT_MODIFY_PLAN_FEATURE = 'CAN_NOT_MODIFY_PLAN_FEATURE',
  CAN_NOT_UPDATE = 'CAN_NOT_UPDATE',
  CAN_NOT_UPDATE_PREV_TRAINING_DATA = 'CAN_NOT_UPDATE_PREV_TRAINING_DATA',
  CATEGORY_ELEMENT_COUNT_PER_VARIABLE_EXCEEDED = 'CATEGORY_ELEMENT_COUNT_PER_VARIABLE_EXCEEDED',
  CERTIFICATE_EXPIRED = 'CERTIFICATE_EXPIRED',
  CLOUDINARY_UPLOAD_ERROR = 'CLOUDINARY_UPLOAD_ERROR',
  CLUSTER_NOT_AVAILABLE = 'CLUSTER_NOT_AVAILABLE',
  CONFLUENCE_INVALID_JWT = 'CONFLUENCE_INVALID_JWT',
  CONSISTENCY_ERROR = 'CONSISTENCY_ERROR',
  CONVERSATION_CATEGORY_LIMIT_EXCEEDED = 'CONVERSATION_CATEGORY_LIMIT_EXCEEDED',
  DETAIL_ERROR = 'DETAIL_ERROR',
  DOC_SOURCE_ALREADY_STARTED = 'DOC_SOURCE_ALREADY_STARTED',
  DOC_SOURCE_EXPIRED_TOKEN = 'DOC_SOURCE_EXPIRED_TOKEN',
  DOC_SOURCE_NO_FILE_TYPES = 'DOC_SOURCE_NO_FILE_TYPES',
  DOC_SOURCE_UNCAUGHT_ERROR = 'DOC_SOURCE_UNCAUGHT_ERROR',
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  DOES_NOT_EXIST_SUBSCRIPTION = 'DOES_NOT_EXIST_SUBSCRIPTION',
  DOES_NOT_HAVE_PROJECT = 'DOES_NOT_HAVE_PROJECT',
  DOES_NOT_SIGN_UP = 'DOES_NOT_SIGN_UP',
  DOMAIN_ALREADY_REGISTERED = 'DOMAIN_ALREADY_REGISTERED',
  DOMAIN_NOT_COVERED_BY_CERTIFICATE = 'DOMAIN_NOT_COVERED_BY_CERTIFICATE',
  DRAFTJS_EXPORTER_FAIL = 'DRAFTJS_EXPORTER_FAIL',
  DUPLICATED = 'DUPLICATED',
  DUPLICATED_AGENT = 'DUPLICATED_AGENT',
  DUPLICATED_OBSERVER_NODE = 'DUPLICATED_OBSERVER_NODE',
  DUPLICATED_QUESTION = 'DUPLICATED_QUESTION',
  DUPLICATED_SLUG = 'DUPLICATED_SLUG',
  DUPLICATED_VALIDATION = 'DUPLICATED_VALIDATION',
  DUPLICATE_LABEL = 'DUPLICATE_LABEL',
  EXECUTION_TIMEOUT = 'EXECUTION_TIMEOUT',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  FAQ_ANSWER_HTML_LENGTH_LIMIT_EXCEEDED = 'FAQ_ANSWER_HTML_LENGTH_LIMIT_EXCEEDED',
  FAQ_ANSWER_TEXT_LENGTH_LIMIT_EXCEEDED = 'FAQ_ANSWER_TEXT_LENGTH_LIMIT_EXCEEDED',
  FAQ_COUNT_LIMIT_EXCEEDED = 'FAQ_COUNT_LIMIT_EXCEEDED',
  FAQ_FILE_COUNT_LIMIT_EXCEEDED = 'FAQ_FILE_COUNT_LIMIT_EXCEEDED',
  FAQ_FILE_DOES_NOT_EXIST = 'FAQ_FILE_DOES_NOT_EXIST',
  FAQ_FILE_SIZE_LIMIT_EXCEEDED = 'FAQ_FILE_SIZE_LIMIT_EXCEEDED',
  FAQ_HASHTAG_COUNT_LIMIT_EXCEEDED = 'FAQ_HASHTAG_COUNT_LIMIT_EXCEEDED',
  FAQ_HASHTAG_LENGTH_LIMIT_EXCEEDED = 'FAQ_HASHTAG_LENGTH_LIMIT_EXCEEDED',
  FAQ_QUESTION_LENGTH_LIMIT_EXCEEDED = 'FAQ_QUESTION_LENGTH_LIMIT_EXCEEDED',
  FIND_ANSWER_EMPTY = 'FIND_ANSWER_EMPTY',
  FIND_ANSWER_FAILED = 'FIND_ANSWER_FAILED',
  FOLDER_COUNT_LIMIT_EXCEEDED = 'FOLDER_COUNT_LIMIT_EXCEEDED',
  GOOGLE_DRIVE_API_ERROR = 'GOOGLE_DRIVE_API_ERROR',
  GOOGLE_DRIVE_NO_FILE_SHARE_TYPES = 'GOOGLE_DRIVE_NO_FILE_SHARE_TYPES',
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  HAS_NOT_SLUG = 'HAS_NOT_SLUG',
  IMAGE_FILE_TOO_LARGE = 'IMAGE_FILE_TOO_LARGE',
  INTEGRATION_FAIL = 'INTEGRATION_FAIL',
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  INVALID_ACTIVATION_CODE = 'INVALID_ACTIVATION_CODE',
  INVALID_AGENT = 'INVALID_AGENT',
  INVALID_CERT_FILE = 'INVALID_CERT_FILE',
  INVALID_CHAT_FLOW_VERSION = 'INVALID_CHAT_FLOW_VERSION',
  INVALID_CHAT_ID = 'INVALID_CHAT_ID',
  INVALID_CHAT_LINK = 'INVALID_CHAT_LINK',
  INVALID_CHAT_NODE = 'INVALID_CHAT_NODE',
  INVALID_CLUSTER = 'INVALID_CLUSTER',
  INVALID_CLUSTER_SECRET = 'INVALID_CLUSTER_SECRET',
  INVALID_CURSOR = 'INVALID_CURSOR',
  INVALID_DATA_HEADER = 'INVALID_DATA_HEADER',
  INVALID_DOC_SOURCE_PATH = 'INVALID_DOC_SOURCE_PATH',
  INVALID_EMAIL_OR_PASSWORD = 'INVALID_EMAIL_OR_PASSWORD',
  INVALID_FILE_PATH = 'INVALID_FILE_PATH',
  INVALID_FORMAT_CERTIFICATE_BODY = 'INVALID_FORMAT_CERTIFICATE_BODY',
  INVALID_FORMAT_CERTIFICATE_PRIVATE_KEY = 'INVALID_FORMAT_CERTIFICATE_PRIVATE_KEY',
  INVALID_GOOGLE_DRIVE_PATH = 'INVALID_GOOGLE_DRIVE_PATH',
  INVALID_IMAGE_FILE = 'INVALID_IMAGE_FILE',
  INVALID_IP_ADDRESS = 'INVALID_IP_ADDRESS',
  INVALID_IP_ADDRESS_RANGE = 'INVALID_IP_ADDRESS_RANGE',
  INVALID_MODEL = 'INVALID_MODEL',
  INVALID_OBSERVER_CONDITION = 'INVALID_OBSERVER_CONDITION',
  INVALID_PAGE_RANGE = 'INVALID_PAGE_RANGE',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  INVALID_PERMISSION = 'INVALID_PERMISSION',
  INVALID_PUBLIC_TOKEN = 'INVALID_PUBLIC_TOKEN',
  INVALID_RECAPTCHA = 'INVALID_RECAPTCHA',
  INVALID_SELECTED_PROJECT = 'INVALID_SELECTED_PROJECT',
  INVALID_SLUG = 'INVALID_SLUG',
  INVALID_STATE = 'INVALID_STATE',
  INVALID_STATE_TO_PENDING = 'INVALID_STATE_TO_PENDING',
  INVALID_TIME_RANGE = 'INVALID_TIME_RANGE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_TWO_FACTOR = 'INVALID_TWO_FACTOR',
  INVALID_USER = 'INVALID_USER',
  INVALID_USER_IN_DIRECTORY = 'INVALID_USER_IN_DIRECTORY',
  KB_FILE_COUNT_LIMIT_EXCEEDED = 'KB_FILE_COUNT_LIMIT_EXCEEDED',
  KB_FILE_SIZE_LIMIT_EXCEEDED = 'KB_FILE_SIZE_LIMIT_EXCEEDED',
  KB_HASHTAG_COUNT_LIMIT_EXCEEDED = 'KB_HASHTAG_COUNT_LIMIT_EXCEEDED',
  KB_HASHTAG_LENGTH_LIMIT_EXCEEDED = 'KB_HASHTAG_LENGTH_LIMIT_EXCEEDED',
  KNOWLEDGE_BASE_DOES_NOT_EXIST = 'KNOWLEDGE_BASE_DOES_NOT_EXIST',
  LINEWORKS_UNAUTHORIZED = 'LINEWORKS_UNAUTHORIZED',
  LINEWORKS_USER_LIST_CHECK_FAIL = 'LINEWORKS_USER_LIST_CHECK_FAIL',
  LLM_APP_PUBLISH_LIMIT_EXCEEDED = 'LLM_APP_PUBLISH_LIMIT_EXCEEDED',
  LLM_SAMPLING_ATTEMPS_EXCEEDED = 'LLM_SAMPLING_ATTEMPS_EXCEEDED',
  MAXIMUM_SESSIONS_EXCEEDED = 'MAXIMUM_SESSIONS_EXCEEDED',
  MAX_AGENT_COUNT_LIMIT_EXCEEDED = 'MAX_AGENT_COUNT_LIMIT_EXCEEDED',
  MAX_CHAR_LENGTH_LIMIT_EXCEEDED = 'MAX_CHAR_LENGTH_LIMIT_EXCEEDED',
  MAX_CONVERSATION_CATEGORY_LENGTH_EXCEEDED = 'MAX_CONVERSATION_CATEGORY_LENGTH_EXCEEDED',
  MAX_CONVERSATION_CATEGORY_PER_CONVERSATION_EXCEEDED = 'MAX_CONVERSATION_CATEGORY_PER_CONVERSATION_EXCEEDED',
  MRC_MODEL_TEST_DATA_LIMIT_EXCEEDED = 'MRC_MODEL_TEST_DATA_LIMIT_EXCEEDED',
  MRC_TOTAL_SIZE_LIMIT_EXCEEDED = 'MRC_TOTAL_SIZE_LIMIT_EXCEEDED',
  MULTIPLE_FAQ_FILE = 'MULTIPLE_FAQ_FILE',
  MULTIPLE_SMALLTALK_FILE = 'MULTIPLE_SMALLTALK_FILE',
  MULTIPLE_USERS_IN_DIRECTORY = 'MULTIPLE_USERS_IN_DIRECTORY',
  NEED_ANSWER_COLUMN = 'NEED_ANSWER_COLUMN',
  NEED_ID_COLUMN = 'NEED_ID_COLUMN',
  NEED_QUESTION_AND_DOC_TITLE_COLUMNS = 'NEED_QUESTION_AND_DOC_TITLE_COLUMNS',
  NEED_QUESTION_COLUMN = 'NEED_QUESTION_COLUMN',
  NEED_QUESTION_FIELD = 'NEED_QUESTION_FIELD',
  NEED_TWO_COLUMNS = 'NEED_TWO_COLUMNS',
  NEED_TWO_OR_THREE_COLUMNS = 'NEED_TWO_OR_THREE_COLUMNS',
  NON_COPYABLE = 'NON_COPYABLE',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  NOT_CLOSED_CONVERSATION = 'NOT_CLOSED_CONVERSATION',
  NOT_MASTER_CLUSTER = 'NOT_MASTER_CLUSTER',
  NOT_MATCH_TARGET = 'NOT_MATCH_TARGET',
  NOT_SUPPORTED_FEATURE = 'NOT_SUPPORTED_FEATURE',
  NOT_SUPPORT_FILE = 'NOT_SUPPORT_FILE',
  NOT_WORK_EMAIL = 'NOT_WORK_EMAIL',
  NO_AVAILABLE_ENDPOINT = 'NO_AVAILABLE_ENDPOINT',
  ONLY_ONE_OBSERVER_CAMPAIGN_CAN_BE_STARTED_AT_A_TIME = 'ONLY_ONE_OBSERVER_CAMPAIGN_CAN_BE_STARTED_AT_A_TIME',
  ON_DEMAND_MAX_ALREADY_OVER = 'ON_DEMAND_MAX_ALREADY_OVER',
  OPENAI_ERROR = 'OPENAI_ERROR',
  OWNER_DOES_NOT_EXISTS = 'OWNER_DOES_NOT_EXISTS',
  PAGE_LIMIT_EXCEEDED = 'PAGE_LIMIT_EXCEEDED',
  PASSWORD_IS_EMPTY = 'PASSWORD_IS_EMPTY',
  PASSWORD_POLICY_VIOLATION = 'PASSWORD_POLICY_VIOLATION',
  PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_FEATURE_NOT_INCLUDED = 'PAYMENT_FEATURE_NOT_INCLUDED',
  PAYMENT_FEATURE_NOT_INCLUDED_AUTOMATION = 'PAYMENT_FEATURE_NOT_INCLUDED_AUTOMATION',
  PAYMENT_FEATURE_NOT_INCLUDED_DIRECTORY = 'PAYMENT_FEATURE_NOT_INCLUDED_DIRECTORY',
  PAYMENT_FEATURE_NOT_INCLUDED_HASHTAGS = 'PAYMENT_FEATURE_NOT_INCLUDED_HASHTAGS',
  PAYMENT_FEATURE_NOT_INCLUDED_SECURITY = 'PAYMENT_FEATURE_NOT_INCLUDED_SECURITY',
  PAYMENT_FEATURE_NOT_INCLUDED_SOURCE = 'PAYMENT_FEATURE_NOT_INCLUDED_SOURCE',
  PAYMENT_INVALID_METHOD = 'PAYMENT_INVALID_METHOD',
  PAYMENT_NOT_ALLOWED = 'PAYMENT_NOT_ALLOWED',
  PAYMENT_ON_DEMAND_MAX_OVER = 'PAYMENT_ON_DEMAND_MAX_OVER',
  PAYMENT_OVER_DUE = 'PAYMENT_OVER_DUE',
  PAYMENT_OVER_LIMIT = 'PAYMENT_OVER_LIMIT',
  PRIVATE_KEY_CERTIFICATE_MISMATCH = 'PRIVATE_KEY_CERTIFICATE_MISMATCH',
  PROJECT_HAS_NOT_SLUG = 'PROJECT_HAS_NOT_SLUG',
  PROTECTED_FILE = 'PROTECTED_FILE',
  PROVIDER_NOT_INTEGRATED = 'PROVIDER_NOT_INTEGRATED',
  QNA_POST_PROCESS_FAILED = 'QNA_POST_PROCESS_FAILED',
  RACE_CONDITION = 'RACE_CONDITION',
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
  RELOAD_CONVERSATION = 'RELOAD_CONVERSATION',
  REMOTE_CLUSTER_ERROR = 'REMOTE_CLUSTER_ERROR',
  REMOVED_DOC = 'REMOVED_DOC',
  REMOVED_FAQ = 'REMOVED_FAQ',
  RESTRICTED_FILE = 'RESTRICTED_FILE',
  SEND_FORM_INVALID_TYPE_ONLY_SUPPORT_FILE = 'SEND_FORM_INVALID_TYPE_ONLY_SUPPORT_FILE',
  SEND_FORM_INVALID_TYPE_ONLY_SUPPORT_STRINGIFIABLE = 'SEND_FORM_INVALID_TYPE_ONLY_SUPPORT_STRINGIFIABLE',
  SEND_FORM_INVALID_TYPE_ONLY_SUPPORT_STRINGIFIABLE_OR_STRING_LIST = 'SEND_FORM_INVALID_TYPE_ONLY_SUPPORT_STRINGIFIABLE_OR_STRING_LIST',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  SINGLE_ACTION_INPUTS_EXCEEDED = 'SINGLE_ACTION_INPUTS_EXCEEDED',
  SKILL_COUNT_LIMIT_EXCEEDED = 'SKILL_COUNT_LIMIT_EXCEEDED',
  SLUG_INVALID_CHAR = 'SLUG_INVALID_CHAR',
  SLUG_IS_TOO_LONG = 'SLUG_IS_TOO_LONG',
  SMALLTALK_ANSWER_HTML_LENGTH_LIMIT_EXCEEDED = 'SMALLTALK_ANSWER_HTML_LENGTH_LIMIT_EXCEEDED',
  SMALLTALK_ANSWER_TEXT_LENGTH_LIMIT_EXCEEDED = 'SMALLTALK_ANSWER_TEXT_LENGTH_LIMIT_EXCEEDED',
  SMALLTALK_FILE_COUNT_LIMIT_EXCEEDED = 'SMALLTALK_FILE_COUNT_LIMIT_EXCEEDED',
  SMALLTALK_FILE_DOES_NOT_EXIST = 'SMALLTALK_FILE_DOES_NOT_EXIST',
  SMALLTALK_FILE_SIZE_LIMIT_EXCEEDED = 'SMALLTALK_FILE_SIZE_LIMIT_EXCEEDED',
  SMALLTALK_QUESTION_LENGTH_LIMIT_EXCEEDED = 'SMALLTALK_QUESTION_LENGTH_LIMIT_EXCEEDED',
  SSO_LOGIN = 'SSO_LOGIN',
  SSO_LOGIN_PROJECT_POLICY = 'SSO_LOGIN_PROJECT_POLICY',
  TEAMS_INVALID_URL = 'TEAMS_INVALID_URL',
  TERMINATE = 'TERMINATE',
  TOO_MANY = 'TOO_MANY',
  TOO_MANY_CUSTOM_VARIABLES = 'TOO_MANY_CUSTOM_VARIABLES',
  TOO_MANY_FAILED_LOGIN = 'TOO_MANY_FAILED_LOGIN',
  TOO_MANY_QUESTIONS = 'TOO_MANY_QUESTIONS',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
  TRITON_CREATE_STATUS_ERROR = 'TRITON_CREATE_STATUS_ERROR',
  TRITON_DEPLOYING_STATUS_ERROR = 'TRITON_DEPLOYING_STATUS_ERROR',
  TRITON_DEPLOY_STATUS_ERROR = 'TRITON_DEPLOY_STATUS_ERROR',
  TRITON_DOES_NOT_EXIST_ERROR = 'TRITON_DOES_NOT_EXIST_ERROR',
  TRITON_FREEZE_STATUS_ERROR = 'TRITON_FREEZE_STATUS_ERROR',
  TRITON_INVALID_PARAMETERS = 'TRITON_INVALID_PARAMETERS',
  TRITON_READY_STATUS_ERROR = 'TRITON_READY_STATUS_ERROR',
  TRITON_REDEPLOYING_STATUS_ERROR = 'TRITON_REDEPLOYING_STATUS_ERROR',
  TRITON_TIMEOUT = 'TRITON_TIMEOUT',
  TRITON_UNKNOWN_ERROR = 'TRITON_UNKNOWN_ERROR',
  TWO_FACTOR_FAILED = 'TWO_FACTOR_FAILED',
  UNAUTHORIZED_IP_ADDRESS = 'UNAUTHORIZED_IP_ADDRESS',
  UNAUTHORIZED_OKTA_SSO = 'UNAUTHORIZED_OKTA_SSO',
  UNAUTHORIZED_PARAMETER = 'UNAUTHORIZED_PARAMETER',
  UNKNOWN = 'UNKNOWN',
  UNSAFE_CONTENT = 'UNSAFE_CONTENT',
  UPLOAD_TIME_OUT = 'UPLOAD_TIME_OUT',
  USED_FORMAT = 'USED_FORMAT',
  USED_INTEGRATION = 'USED_INTEGRATION',
  USED_LABEL = 'USED_LABEL',
  USED_PROMPT = 'USED_PROMPT',
  USED_PROMPT_GROUP = 'USED_PROMPT_GROUP',
  USED_RULE = 'USED_RULE',
  USED_TARGET = 'USED_TARGET',
  USED_VARIABLE = 'USED_VARIABLE',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
}

export enum MappingSetOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  ORDER = 'ORDER',
}

export enum MediaOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum MediaTypeEnum {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum MessageAnswerEnum {
  BINARY = 'BINARY',
  BUTTON = 'BUTTON',
  TEXT = 'TEXT',
}

export enum MessageAnswerOptionTypeEnum {
  DYNAMIC = 'DYNAMIC',
  FAQ = 'FAQ',
  STATIC = 'STATIC',
}

export enum ModelDatasetStateEnum {
  DUPLICATE = 'DUPLICATE',
  EXCEED = 'EXCEED',
  MISSING_BOTH = 'MISSING_BOTH',
  MISSING_DOC = 'MISSING_DOC',
  MISSING_QUESTION = 'MISSING_QUESTION',
  UPLOADED = 'UPLOADED',
}

export enum NLUChatType {
  CHOICE = 'CHOICE',
  FAQ = 'FAQ',
  SMALL_TALK = 'SMALL_TALK',
}

export enum NLUModelDomainEnum {
  COMMERCE = 'COMMERCE',
  CUSTOM_TRAVELERS = 'CUSTOM_TRAVELERS',
  FINANCE = 'FINANCE',
  GAME = 'GAME',
  INSURANCE = 'INSURANCE',
  OTHER = 'OTHER',
  TELCO = 'TELCO',
}

export enum NLUModelLanguageEnum {
  CN = 'CN',
  CNS = 'CNS',
  EN = 'EN',
  JA = 'JA',
  KO = 'KO',
  OTHER = 'OTHER',
}

export enum OAuthState {
  EXISTS = 'EXISTS',
  EXIST_AND_NEED_INTEGRATION = 'EXIST_AND_NEED_INTEGRATION',
  NOT_EXISTS = 'NOT_EXISTS',
}

export enum ObjectAccessType {
  EDITOR = 'EDITOR',
  READER = 'READER',
}

export enum ObserverCompareEnum {
  CHANGED = 'CHANGED',
  NOT_CHANGED = 'NOT_CHANGED',
}

export enum OfficeHoursDayEnum {
  EVERYDAY = 'EVERYDAY',
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  WEEKDAYS = 'WEEKDAYS',
  WEEKEND = 'WEEKEND',
}

export enum OptionBoxTemplate {
  DEFAULT = 'DEFAULT',
  NEXT_PAGE = 'NEXT_PAGE',
}

export enum PasswordExpirationDays {
  NINETY_DAYS = 'NINETY_DAYS',
  ONE_HUNDRED_TWENTY_DAYS = 'ONE_HUNDRED_TWENTY_DAYS',
  SIXTY_DAYS = 'SIXTY_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
}

export enum PaymentPlanEnum {
  BOT_ENTERPRISE = 'BOT_ENTERPRISE',
  BOT_GROWTH = 'BOT_GROWTH',
  ENTERPRISE = 'ENTERPRISE',
}

export enum PaymentStatusEnum {
  INVALID_METHOD = 'INVALID_METHOD',
  NORMAL = 'NORMAL',
  OVERDUE = 'OVERDUE',
  TRIALING = 'TRIALING',
}

export enum PopUpBoxIconOption {
  AVATAR = 'AVATAR',
  CUSTOM = 'CUSTOM',
  NO_ICON = 'NO_ICON',
}

export enum PredefinedValidation {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum PrepareFileStatusEnum {
  FAILURE = 'FAILURE',
  READY = 'READY',
  SUCCESS = 'SUCCESS',
  WAITING = 'WAITING',
}

export enum ProcessStateEnum {
  COMPLETED = 'COMPLETED',
  INIT = 'INIT',
  PARSING = 'PARSING',
  PARSING_FAIL = 'PARSING_FAIL',
  RETRYING = 'RETRYING',
}

export enum ProjectMachineTier {
  TIER1 = 'TIER1',
  TIER2 = 'TIER2',
}

export enum ProjectOrder {
  name__ASC = 'name__ASC',
  name__DESC = 'name__DESC',
}

export enum ProjectSlackServiceChannelType {
  CHANNEL = 'CHANNEL',
  DM = 'DM',
}

export enum ProjectTeamsServiceChannelType {
  CHANNEL = 'CHANNEL',
  DM = 'DM',
}

export enum ProjectTemplateType {
  RETRY_EXHAUSTED = 'RETRY_EXHAUSTED',
  RETRY_INTENT = 'RETRY_INTENT',
  RETRY_SLOT = 'RETRY_SLOT',
}

export enum ProjectUsageType {
  ALL = 'ALL',
  ANSWERBOT_AND_LLM = 'ANSWERBOT_AND_LLM',
  ANSWER_BOT = 'ANSWER_BOT',
  CAPTURE = 'CAPTURE',
  LLM_APPS = 'LLM_APPS',
}

export enum ProviderFilter {
  AGENT = 'AGENT',
  ALL = 'ALL',
  USER = 'USER',
}

export enum QueryAnalyticsStateEnum {
  NORMAL = 'NORMAL',
  NO_DATA = 'NO_DATA',
}

export enum QueryAuthor {
  AGENT = 'AGENT',
  CUSTOMER = 'CUSTOMER',
}

export enum QueryHistoryOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum QuestionGenKbStatus {
  COMPLETED = 'COMPLETED',
  INIT = 'INIT',
  NONE = 'NONE',
  PROCESSING = 'PROCESSING',
}

export enum RequestMethodEnum {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export enum RetrieverOptimizationRequestOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum RetrieverOptimizationRequestStatus {
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OPTIMIZE_FAILED = 'OPTIMIZE_FAILED',
  OPTIMIZE_RUNNING = 'OPTIMIZE_RUNNING',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  TRAIN_FAILED = 'TRAIN_FAILED',
  TRAIN_RUNNING = 'TRAIN_RUNNING',
  TRAIN_SUCCESS = 'TRAIN_SUCCESS',
}

export enum SDKEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  IOS_OLD = 'IOS_OLD',
  WEB = 'WEB',
}

export enum SDKVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export enum SavedMessageOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
}

export enum SearchContextStep {
  CLARIFYING_INTENT = 'CLARIFYING_INTENT',
  COMPLETE = 'COMPLETE',
  GENERATING_ANSWER = 'GENERATING_ANSWER',
  GENERATING_FOLLOW_UP_QUESTION = 'GENERATING_FOLLOW_UP_QUESTION',
  PREPARE = 'PREPARE',
  PREPARE_CLUE = 'PREPARE_CLUE',
}

export enum SearchDocumentsByTypeEnum {
  CONTENTS = 'CONTENTS',
  TITLE = 'TITLE',
}

export enum SearchFromTypeEnum {
  DOCUMENT = 'DOCUMENT',
  QNA = 'QNA',
  WEB = 'WEB',
}

export enum SearchOperatorType {
  AND = 'AND',
  OR = 'OR',
}

export enum SenderEnum {
  AGENT = 'AGENT',
  BOT = 'BOT',
  EVENT = 'EVENT',
  USER = 'USER',
}

export enum ServiceRegionEnum {
  ASIA = 'ASIA',
  EUROPE = 'EUROPE',
  OTHER = 'OTHER',
  US = 'US',
}

export enum SessionTimeout {
  FIFTEEN_MINS = 'FIFTEEN_MINS',
  FIVE_MINS = 'FIVE_MINS',
  FOUR_HOURS = 'FOUR_HOURS',
  ONE_DAY = 'ONE_DAY',
  ONE_HOUR = 'ONE_HOUR',
  TEN_MINS = 'TEN_MINS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  THIRTY_MINS = 'THIRTY_MINS',
}

export enum SingleActionAppResultOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum SingleActionInputType {
  DOCUMENT_ADVANCED = 'DOCUMENT_ADVANCED',
  DOCUMENT_BASIC = 'DOCUMENT_BASIC',
  IMAGE_ADVANCED = 'IMAGE_ADVANCED',
  IMAGE_BASIC = 'IMAGE_BASIC',
  MULTI = 'MULTI',
  PARAGRAPH = 'PARAGRAPH',
  SINGLE = 'SINGLE',
  TEXT = 'TEXT',
}

export enum SingleActionStatus {
  COMPLETED = 'COMPLETED',
  GENERATING_RESULT = 'GENERATING_RESULT',
  INITIAL = 'INITIAL',
  MAP_REDUCE = 'MAP_REDUCE',
  PARSING = 'PARSING',
}

export enum SmallTalkOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum SmallTalkUploadState {
  DELETED = 'DELETED',
  DUPLICATED = 'DUPLICATED',
  EXCEEDED_LIMIT = 'EXCEEDED_LIMIT',
  INVALID_ANSWER = 'INVALID_ANSWER',
  PARSING_ERROR = 'PARSING_ERROR',
  SHEET_PARSING_ERROR = 'SHEET_PARSING_ERROR',
  UPDATED = 'UPDATED',
}

export enum SortDirectionType {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum SuggestionAccuracyType {
  NUMBER = 'NUMBER',
  PERCENT = 'PERCENT',
}

export enum TrialExpiredMessageTypeEnum {
  CREDIT_EXCEEDED = 'CREDIT_EXCEEDED',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
}

export enum UserAnswerFeedbackSelection {
  INACCURATE = 'INACCURATE',
  NOT_BEST_ANSWER = 'NOT_BEST_ANSWER',
  NOT_RELEVANT = 'NOT_RELEVANT',
}

export enum UserOrder {
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  LAST_UPDATE_DATE_ASC = 'LAST_UPDATE_DATE_ASC',
  LAST_UPDATE_DATE_DESC = 'LAST_UPDATE_DATE_DESC',
  LAST_VISIT_DATE_ASC = 'LAST_VISIT_DATE_ASC',
  LAST_VISIT_DATE_DESC = 'LAST_VISIT_DATE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  OWN_USER_ID_ASC = 'OWN_USER_ID_ASC',
  OWN_USER_ID_DESC = 'OWN_USER_ID_DESC',
}

export enum VariableCategory {
  CUSTOM = 'CUSTOM',
  NLU = 'NLU',
  SDK = 'SDK',
}

export enum VariableOrder {
  FAVORITE_ASC = 'FAVORITE_ASC',
  FAVORITE_DESC = 'FAVORITE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum VariableScope {
  CONVERSATION = 'CONVERSATION',
  USER = 'USER',
}

export enum VariableType {
  BOOLEAN = 'BOOLEAN',
  CATEGORY = 'CATEGORY',
  DATETIME = 'DATETIME',
  DOCUMENTS = 'DOCUMENTS',
  FILE = 'FILE',
  JSON = 'JSON',
  LIST_JSON = 'LIST_JSON',
  LIST_STRING = 'LIST_STRING',
  NUMBER = 'NUMBER',
  SNIPPETS = 'SNIPPETS',
  STRING = 'STRING',
}
