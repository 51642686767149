import { ExtendButtonBase } from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiListItemButton from '@mui/material/ListItemButton';
import { lighten } from 'polished';
import { forwardRef } from 'react';
import { listItemButtonClasses } from './list-item-button-classes';
import { ListItemButtonContext } from './list-item-button-context';
import { ListItemButtonTypeMap } from './list-item-button-type-map';

// @ts-expect-error overridable component
export const ListItemButton: ExtendButtonBase<ListItemButtonTypeMap> =
  forwardRef((props, ref) => {
    const theme = useTheme();

    return (
      <ListItemButtonContext.Provider value={{ disabled: props.disabled }}>
        <MuiListItemButton
          data-testid="list-item-button"
          css={css`
            padding: 4px 8px;
            transition: ${theme.transitions.create('background-color', {
              duration: theme.transitions.duration.shortest,
            })};

            &:hover,
            &.${listItemButtonClasses.focusVisible} {
              background-color: ${theme.palette.action.hover};

              @media (hover: none) {
                background-color: transparent;
              }
            }

            &:active {
              background-color: ${theme.palette.action.selected};
            }

            &.${listItemButtonClasses.selected} {
              background-color: ${lighten(0.4, theme.palette.primary.main)};

              &:hover,
              &.${listItemButtonClasses.focusVisible} {
                background-color: ${theme.palette.action.hover};

                @media (hover: none) {
                  background-color: ${lighten(0.4, theme.palette.primary.main)};
                }
              }

              &:active {
                background-color: ${theme.palette.action.selected};
              }
            }

            &.${listItemButtonClasses.disabled} {
              opacity: 1;
              background-color: transparent;
            }
          `}
          {...props}
          ref={ref}
          centerRipple={false}
          disableRipple
          disableTouchRipple
          focusRipple={false}
        />
      </ListItemButtonContext.Provider>
    );
  });
