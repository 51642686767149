/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { SingleActionAppResultFragment } from '../fragments/single-action-app-result-fragment';
import { gql } from '@apollo/client';
import { SingleActionAppResultFragmentDoc } from '../fragments/single-action-app-result-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleActionAppResultPublicSubscriptionVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    accessToken: UserQueryTypes.Scalars['String']['input'];
    publicToken: UserQueryTypes.Scalars['String']['input'];
  }>;

export type SingleActionAppResultPublicSubscription = {
  __typename: 'UserSubscription';
} & {
  singleActionAppResultPublic: UserQueryTypes.Maybe<
    { __typename: 'SingleActionAppResult' } & SingleActionAppResultFragment
  >;
};

export const SingleActionAppResultPublicSubscriptionDocument = gql`
  subscription SingleActionAppResultPublicSubscription(
    $where: ProjectWhereUniqueInput!
    $accessToken: String!
    $publicToken: String!
  ) {
    singleActionAppResultPublic(
      projectWhere: $where
      accessToken: $accessToken
      token: $publicToken
    ) {
      ...SingleActionAppResultFragment
    }
  }
  ${SingleActionAppResultFragmentDoc}
`;

/**
 * __useSingleActionAppResultPublicSubscription__
 *
 * To run a query within a React component, call `useSingleActionAppResultPublicSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSingleActionAppResultPublicSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleActionAppResultPublicSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      accessToken: // value for 'accessToken'
 *      publicToken: // value for 'publicToken'
 *   },
 * });
 */
export function useSingleActionAppResultPublicSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SingleActionAppResultPublicSubscription,
    SingleActionAppResultPublicSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SingleActionAppResultPublicSubscription,
    SingleActionAppResultPublicSubscriptionVariables
  >(SingleActionAppResultPublicSubscriptionDocument, options);
}
export type SingleActionAppResultPublicSubscriptionHookResult = ReturnType<
  typeof useSingleActionAppResultPublicSubscription
>;
export type SingleActionAppResultPublicSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<SingleActionAppResultPublicSubscription>;
