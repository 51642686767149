import { iconButtonClasses } from '@allganize/ui-button';
import { InputAdornment, inputAdornmentClasses } from '@allganize/ui-input';
import styled from '@emotion/styled';

export const ChatFormInputAdornment = styled(InputAdornment)`
  height: auto;
  margin: 0;
  align-self: end;

  &.${inputAdornmentClasses.positionEnd} {
    margin-right: -2px;
  }

  &.${inputAdornmentClasses.positionStart} {
    margin-left: -2px;
  }

  .${iconButtonClasses.sizeMedium} {
    margin-bottom: 2px;
  }
`;
