import { css } from '@emotion/react';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { environment } from '../../environments/environment';
import { getRegion } from '../../utils/get-region';
import { FullscreenLayout } from '../../components/layout/templates/FullscreenLayout';
import { analytics } from '../../analytics';

export const SingleActionPage: FunctionComponent = () => {
  const intl = useIntl();
  const params = useParams<{ publicToken: string }>();
  const [searchParams] = useSearchParams();

  const iframeUrl = useMemo(() => {
    const region = getRegion(searchParams);
    const url = new URL(
      `${environment.dashboard.baseUrl}/singleAction/${params.publicToken}`,
    );

    url.searchParams.append('embeddedFrom', 'alli-app-market');
    url.searchParams.append('locale', intl.locale);

    if (region === 'ja' || region === 'kr') {
      url.searchParams.append('region', `ALLI_${region.toUpperCase()}`);
    }

    return url.toString();
  }, [intl.locale, params.publicToken, searchParams]);

  useEffect(() => {
    if (!params.publicToken) return;
    analytics.track('view_single_action_detail', {
      publicToken: params.publicToken,
    });
  }, [params.publicToken]);

  if (!params.publicToken) {
    throw new Error('publicToken is required');
  }

  return (
    <FullscreenLayout>
      <iframe
        src={iframeUrl}
        title={iframeUrl}
        allowFullScreen
        css={css`
          flex-grow: 1;
          width: 100%;
          border: 0;
        `}
      />
    </FullscreenLayout>
  );
};
