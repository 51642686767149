import { Types } from '@allganize/alli-sdk-interfaces';
import { IcGenerate } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css, keyframes } from '@emotion/react';
import { forwardRef } from 'react';

const ellipsisAnimation = keyframes`
  0% {
    content: '.';
  }
  25% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '.';
  }
`;

interface GeneratingStateProps extends React.HTMLAttributes<HTMLDivElement> {
  state: Types.SearchContextStep;
}

export const GeneratingState = forwardRef<HTMLDivElement, GeneratingStateProps>(
  ({ state, ...other }, ref) => {
    const theme = useTheme();

    return (
      <div
        data-testid="generating-state-message"
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          gap: 2px;
          height: 20px;
          padding: 1px 6px;
          border-radius: ${theme.radius.xs}px;
          background-color: ${theme.taxPalette.grey[100]};
        `}
        {...other}
        ref={ref}
      >
        <IcGenerate
          color="inherit"
          css={css`
            vertical-align: middle;
            width: 12px;
            height: 12px;
          `}
        />

        <Text
          variant="label12"
          component="span"
          css={css`
            color: ${theme.taxPalette.grey[950]};
          `}
        >
          답변 생성 중
        </Text>
      </div>
    );
  },
);
