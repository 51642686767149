import { LoginCallback } from '@okta/okta-react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

import { App } from './app/App';
import { AuthOktaLogoutPage } from './pages/auth/okta/logout';
import { SingleActionPage } from './pages/single-actions/[publicToken]';
import { SkillPage } from './pages/skills';
import { SecureRoute } from './services/okta/secure-route';
import { LNBLayout } from './components/layout/templates/LNBLayout';
import { ConversationLayout } from './components/layout/templates/ConversationLayout';
import { ConversationDetailLayout } from './components/layout/templates/ConversationDetailLayout';

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Home = lazy(() => import('./pages/home'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <SecureRoute>
            <Dashboard />
          </SecureRoute>
        ),
      },
      {
        path: 'single-actions/:publicToken',
        element: (
          <SecureRoute>
            <SingleActionPage />
          </SecureRoute>
        ),
      },
      {
        path: 'skills',
        element: (
          <SecureRoute>
            <SkillPage />
          </SecureRoute>
        ),
      },
      {
        path: 'auth/okta/callback',
        element: <LoginCallback />,
      },
      {
        path: 'auth/okta/logout',
        element: <AuthOktaLogoutPage />,
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);

export const routerNew = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: (
          <SecureRoute>
            <Outlet />
          </SecureRoute>
        ),
        children: [
          {
            element: (
              <ConversationLayout>
                <LNBLayout>
                  <Outlet />
                </LNBLayout>
              </ConversationLayout>
            ),
            children: [
              {
                index: true,
                element: <Home />,
              },
              {
                path: 'apps',
                element: <Dashboard />,
              },
              {
                path: 'conversations/new',
                lazy: () => import('./pages/conversations/new'),
              },
              {
                path: 'conversations/:conversationId',
                element: <ConversationDetailLayout />,
                children: [
                  {
                    index: true,
                    lazy: () =>
                      import('./pages/conversations/[conversationId]'),
                  },
                ],
              },
              {
                path: 'single-actions/:publicToken',
                element: <SingleActionPage />,
              },
            ],
          },
          {
            path: 'skills',
            element: <SkillPage />,
          },
        ],
      },
      {
        path: 'auth/okta/callback',
        element: <LoginCallback />,
      },
      {
        path: 'auth/okta/logout',
        element: <AuthOktaLogoutPage />,
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);
