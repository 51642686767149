import { AlliAppMarketEnvironment } from './environment.types';

export const environment: AlliAppMarketEnvironment = {
  production: true,
  api: {
    default: {
      http: 'https://backend.alli.ai/d/user/',
      ws: 'wss://backend.alli.ai/d/ws/user',
    },
    ja: {
      http: 'https://backend-ja.alli.ai/d/user/',
      ws: 'wss://backend-ja.alli.ai/d/ws/user',
    },
    kr: {
      http: 'https://backend-kr.alli.ai/d/user/',
      ws: 'wss://backend-kr.alli.ai/d/ws/user',
    },
  },
  dashboard: {
    baseUrl: 'https://app.alli.ai',
  },
  mixpanel: {
    token: 'b84fcd8bfd53eba6ddd7325f992ce0bc',
  },
  sdk: {
    entrypoint(version: string) {
      return `https://sdk.alli.ai/${version}/alli.esm.min.js`;
    },
  },
  sentry: {
    dsn: 'https://9b531a571aebf30fbc6a7c63e8e4b28c@o133561.ingest.sentry.io/4505933220741120',
    enabled: true,
  },
};
