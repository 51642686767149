import { IcVideoOff } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { BlockRendererProps } from '../draft-plugin/draft-plugin';
import { DraftVideoClasses, draftVideoClasses } from './draft-video-classes';

export interface DraftVideoBlockProps {
  classes?: Partial<DraftVideoClasses>;
  src?: string | null;
}

export const DraftVideo = forwardRef<
  HTMLDivElement,
  BlockRendererProps<DraftVideoBlockProps>
>((props, ref) => {
  const { blockProps } = props;
  const theme = useTheme();

  return (
    <div
      data-testid="draft-video"
      css={css`
        position: relative;
        width: 100%;
        min-width: 232px;
        max-width: 480px;
        border: 1px solid ${theme.palette.divider};
        border-radius: ${theme.radius.xs}px;
        overflow: hidden;
        background-color: ${theme.palette.grey[200]};

        &::before {
          content: '';
          display: block;
          /* 16:9 aspect ratio */
          padding-bottom: 56.25%;
        }
      `}
      className={clsx(draftVideoClasses.root, blockProps?.classes?.root)}
      ref={ref}
    >
      {blockProps?.src ? (
        <iframe
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${theme.palette.common.white};
          `}
          className={clsx(
            draftVideoClasses.iframe,
            blockProps?.classes?.iframe,
          )}
          src={blockProps.src}
          title={blockProps.src}
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      ) : (
        <span
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            color: ${theme.palette.text.secondary};
            font-size: 36px;
          `}
          className={clsx(
            draftVideoClasses.messageNotFound,
            blockProps?.classes?.messageNotFound,
          )}
        >
          <IcVideoOff color="inherit" fontSize="inherit" />
        </span>
      )}
    </div>
  );
});
