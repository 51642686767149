import './styles/class-name';
import {
  ProjectQuery,
  ProjectQueryDocument,
} from '@allganize/alli-app-market-project';
import { ApolloProvider } from '@apollo/client/react';
import { OktaAuth } from '@okta/okta-auth-js';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import './sentry';

import { analytics } from './analytics';
import { AuthClient } from './auth/auth-client';
import { AuthClientContext } from './auth/auth-client-context';
import { getClient } from './graphql/client';
import { LocaleProvider } from './i18n/locale-provider';
import { router, routerNew } from './router';
import { ThemeProvider } from './styles/theme-provider';
import { getBrowserLocale } from './utils/get-browser-locale';
import { getRegion } from './utils/get-region';
import { AddOnType } from '@allganize/alli-interfaces/user-query';

const main = async () => {
  const locale = getBrowserLocale(window.navigator);
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  let defaultRouter = router;
  let themeColor = '';

  const authClient = new AuthClient();
  const { client, subscriptionClient } = await getClient(authClient);

  try {
    const result = await client.query<ProjectQuery>({
      query: ProjectQueryDocument,
    });

    if (result.data.project) {
      analytics.register(
        { projectId: result.data.project.id },
        { mixpanel: { persistent: false } },
      );
    } else {
      analytics.unregister('projectId', { mixpanel: { persistent: false } });
    }

    if (result.data.project?.appCollectionSettings?.themeColor) {
      themeColor = result.data.project.appCollectionSettings.themeColor;
    }

    if (result.data.project?.oktaOidcInfo) {
      const searchParams = new URLSearchParams(window.location.search);
      const projectToken = searchParams.get('project_token');
      const region = getRegion(searchParams);
      const newSearchParams = new URLSearchParams();

      if (projectToken) {
        newSearchParams.set('project_token', projectToken);
      }

      if (region) {
        newSearchParams.set('region', region);
      }

      const search = newSearchParams.toString();

      authClient.setOktaAuth(
        new OktaAuth({
          clientId: result.data.project.oktaOidcInfo.clientId,
          issuer: result.data.project.oktaOidcInfo.issuerUrl,
          redirectUri: `${window.location.origin}/auth/okta/callback${
            search ? `?${search}` : ''
          }`,
          postLogoutRedirectUri: `${window.location.origin}/auth/okta/logout${
            search ? `?${search}` : ''
          }`,
          pkce: true,
        }),
      );
    }

    if (
      result.data.project?.enabledAddOns?.includes(
        AddOnType.WA_APP_LAUNCHER_NEW_HOME,
      )
    ) {
      defaultRouter = routerNew;
    }
  } catch {
    // do nothing
  }

  const reset = () => {
    subscriptionClient.restart();
    client.resetStore();
  };

  authClient.on('oktaAuthStateChanged', reset);
  authClient.on('oktaAuthChanged', reset);

  root.render(
    <StrictMode>
      <HelmetProvider>
        <AuthClientContext.Provider value={authClient}>
          <ApolloProvider client={client}>
            <LocaleProvider initialValue={locale}>
              <ThemeProvider themeColor={themeColor}>
                <RouterProvider router={defaultRouter} />
              </ThemeProvider>
            </LocaleProvider>
          </ApolloProvider>
        </AuthClientContext.Provider>
      </HelmetProvider>
    </StrictMode>,
  );
};

main();
