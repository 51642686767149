import { useCombinedRef, useId } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { InputAdornment as InternalInputAdornment } from '@allganize/ui-input';
import { useSlotProps } from '@mui/base/utils';
import { useRef } from 'react';
import { LocalizationProvider } from '../../../localization-provider';
import { BaseSingleInputFieldProps, FieldSection } from '../../../models';
import { PickersLayout } from '../../../pickers-layout';
import { PickersPopper } from '../../../pickers-popper';
import { DateOrTimeViewWithMeridiem } from '../../models';
import { UsePickerValueFieldResponse, usePicker } from '../use-picker';
import { useUtils } from '../use-utils';
import { InferError } from '../use-validation';
import {
  UseDesktopPickerParams,
  UseDesktopPickerProps,
  UseDesktopPickerSlotsComponentsProps,
} from './use-desktop-picker.types';

export const useDesktopPicker = <
  TView extends DateOrTimeViewWithMeridiem,
  TExternalProps extends UseDesktopPickerProps<TView, any, TExternalProps>,
>({
  props,
  getOpenDialogAriaText,
  ...pickerParams
}: UseDesktopPickerParams<TView, TExternalProps>) => {
  const {
    slots,
    slotProps: innerSlotProps,
    className,
    format,
    formatDensity,
    timezone,
    name,
    label,
    inputRef,
    readOnly,
    disabled,
    autoFocus,
    localeText,
    reduceAnimations,
  } = props;

  const utils = useUtils<Date>();
  const internalInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelId = useId();
  const isToolbarHidden = innerSlotProps?.toolbar?.hidden ?? false;

  const {
    open,
    actions,
    hasUIView,
    layoutProps,
    renderCurrentView,
    shouldRestoreFocus,
    fieldProps: pickerFieldProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
  } = usePicker<Date | null, Date, TView, FieldSection, TExternalProps, {}>({
    ...pickerParams,
    props,
    inputRef: internalInputRef,
    autoFocusView: true,
    additionalViewProps: {},
    wrapperVariant: 'desktop',
  });

  const InputAdornment = slots.inputAdornment ?? InternalInputAdornment;
  const { ownerState: inputAdornmentOwnerState, ...inputAdornmentProps } =
    useSlotProps({
      elementType: InputAdornment,
      externalSlotProps: innerSlotProps?.inputAdornment,
      additionalProps: {
        position: 'end' as const,
      },
      ownerState: props,
    });

  const OpenPickerButton = slots.openPickerButton ?? IconButton;
  const { ownerState: openPickerButtonOwnerState, ...openPickerButtonProps } =
    useSlotProps({
      elementType: OpenPickerButton,
      externalSlotProps: innerSlotProps?.openPickerButton,
      additionalProps: {
        size: 'small' as const,
        disabled: disabled || readOnly,
        onClick: open ? actions.onClose : actions.onOpen,
        'aria-label': getOpenDialogAriaText(pickerFieldProps.value, utils),
        edge: inputAdornmentProps.position,
      },
      ownerState: props,
    });

  const OpenPickerIcon = slots.openPickerIcon;

  const Field = slots.field;
  const fieldProps: BaseSingleInputFieldProps<
    Date | null,
    Date,
    FieldSection,
    InferError<TExternalProps>
  > = useSlotProps<
    typeof Field,
    UseDesktopPickerSlotsComponentsProps<TView>['field'],
    UsePickerValueFieldResponse<
      Date | null,
      FieldSection,
      InferError<TExternalProps>
    > &
      Partial<
        Pick<
          UseDesktopPickerProps<TView, any, TExternalProps>,
          | 'readOnly'
          | 'disabled'
          | 'className'
          | 'format'
          | 'formatDensity'
          | 'timezone'
          | 'label'
          | 'name'
          | 'autoFocus'
        > & { focused: true | undefined }
      >,
    TExternalProps
  >({
    elementType: Field,
    // @ts-expect-error internal prop
    externalSlotProps: innerSlotProps?.field,
    additionalProps: {
      ...pickerFieldProps,
      ...(isToolbarHidden && { id: labelId }),
      readOnly,
      disabled,
      className,
      format,
      formatDensity,
      timezone,
      label,
      name,
      autoFocus: autoFocus && !props.open,
      focused: open ? true : undefined,
    },
    ownerState: props,
  });

  // TODO: Move to `useSlotProps` when https://github.com/mui/material-ui/pull/35088 will be merged
  if (hasUIView) {
    fieldProps.InputProps = {
      ...fieldProps.InputProps,
      ref: containerRef,
      [`${inputAdornmentProps.position}Adornment`]: (
        <InputAdornment {...inputAdornmentProps}>
          <OpenPickerButton {...openPickerButtonProps}>
            <OpenPickerIcon {...innerSlotProps?.openPickerIcon} />
          </OpenPickerButton>
        </InputAdornment>
      ),
    };
  }

  const slotsForField: BaseSingleInputFieldProps<
    Date | null,
    Date,
    FieldSection,
    unknown
  >['slots'] = {
    inputField: slots.inputField,
    clearIcon: slots.clearIcon,
    clearButton: slots.clearButton,
    ...fieldProps.slots,
  };

  const Layout = slots.layout ?? PickersLayout;

  const handleInputRef = useCombinedRef(
    internalInputRef,
    fieldProps.inputRef ?? null,
    inputRef ?? null,
  );

  let labelledById: string | undefined = labelId;
  if (isToolbarHidden) {
    if (label) {
      labelledById = `${labelId}-label`;
    } else {
      labelledById = undefined;
    }
  }
  const slotProps = {
    ...innerSlotProps,
    toolbar: {
      ...innerSlotProps?.toolbar,
      titleId: labelId,
    },
    popper: {
      'aria-labelledby': labelledById,
      ...innerSlotProps?.popper,
    },
  };

  const renderPicker = () => (
    <LocalizationProvider localeText={localeText}>
      <Field
        {...fieldProps}
        slots={slotsForField}
        slotProps={slotProps}
        inputRef={handleInputRef}
      />
      <PickersPopper
        role="dialog"
        placement="bottom-start"
        anchorEl={containerRef.current}
        {...actions}
        open={open}
        slots={slots}
        slotProps={slotProps}
        shouldRestoreFocus={shouldRestoreFocus}
        reduceAnimations={reduceAnimations}
      >
        <Layout
          {...layoutProps}
          {...slotProps?.layout}
          slots={slots}
          slotProps={slotProps}
        >
          {renderCurrentView()}
        </Layout>
      </PickersPopper>
    </LocalizationProvider>
  );

  return { renderPicker };
};
