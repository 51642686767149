import { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { EventChatFragment } from '../graphql/fragments/event-chat-fragment';

interface EventChatMessageProps {
  chat: EventChatFragment;
}

export const EventChatMessage: FunctionComponent<EventChatMessageProps> = ({
  chat,
}) => {
  if (chat.eventType === 'AGENT_ENTER' && chat.enteringAgent) {
    return (
      <FormattedMessage
        id="event-chat-message.agent-enter"
        defaultMessage="Agent {displayName} joined this conversation."
        description="Conversation agent entrance text"
        values={{
          displayName:
            chat.enteringAgent.displayName ||
            chat.enteringAgent.firstName ||
            chat.enteringAgent.lastName,
        }}
      />
    );
  }

  if (chat.eventType === 'SEND_CONVERSATION_TO_EMAIL') {
    const eventAdditionalInfo = JSON.parse(chat.eventAdditionalInfo || '{}');

    if (eventAdditionalInfo.email) {
      return (
        <FormattedMessage
          id="event-chat-message.send-conversation-to-email"
          defaultMessage="User sent transcript to {email}"
          description="Conversation user get transcript text"
          values={{
            email: eventAdditionalInfo.email,
          }}
        />
      );
    }
  }

  return <Fragment>{chat.eventFallbackMessage}</Fragment>;
};
