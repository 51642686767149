import { getDocumentEntities } from '@allganize/alli-sdk-chat';
import { useEventCallback } from '@allganize/hooks';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { DocumentPreviewContext } from '../document-preview/document-preview-context';
import { DocumentPreviewState } from '../document-preview/document-preview-state';
import { BotChatFragment } from '../graphql/fragments/bot-chat-fragment';
import { CarouselChatFragment } from '../graphql/fragments/carousel-chat-fragment';
import { ChatFragment } from '../graphql/fragments/chat-fragment';
import { PreviewContext } from '../preview/preview-context';
import { ChatEdgeWithRef } from './use-chat-list';

interface Props {
  edge: ChatEdgeWithRef | null;
}

const isCarouselChat = (
  chat?: ChatFragment | null,
): chat is CarouselChatFragment => {
  return chat?.__typename === 'CarouselChat';
};

const isBotChat = (chat?: ChatFragment | null): chat is BotChatFragment => {
  return chat?.__typename === 'BotChat';
};

const getKnowledgeBasePreview = (chat?: ChatFragment | null) => {
  if (isCarouselChat(chat)) {
    return chat.chatMrcInfos?.[0]?.knowledgeBasePreview;
  }

  if (isBotChat(chat)) {
    return chat.knowledgeBasePreview;
  }

  return null;
};

interface GenerateDocumentPreviewOptions {
  chat: ChatFragment | null;
  documentPreviewState?: DocumentPreviewState | null;
  fallbackText?: string;
}

export const generateDocumentPreview = ({
  chat: node,
  documentPreviewState,
  fallbackText,
}: GenerateDocumentPreviewOptions) => {
  let chat: ChatFragment | null = null;

  if (isBotChat(node)) {
    const isCompletedGAAnswer =
      node.mrcAnswerType === 'GENERATED' && node.completeState === 'SUCCESS';

    if (isCompletedGAAnswer) {
      chat = node;
    } else {
      const isNewAnswerGroup = !(
        isBotChat(documentPreviewState?.chat) &&
        node.knowledgeBaseUserAskId ===
          documentPreviewState?.chat.knowledgeBaseUserAskId
      );

      if (isNewAnswerGroup) {
        chat = node;
      }
    }
  }

  if (isCarouselChat(node)) {
    const isMrcInfoCarousel = !!getKnowledgeBasePreview(node);

    if (isMrcInfoCarousel) {
      chat = node;
    }
  }

  if (!chat) {
    return null;
  }

  const [firstDocument] = getDocumentEntities({
    contentState: chat?.messageContentState ?? undefined,
    knowledgeBasePreview: getKnowledgeBasePreview(chat),
    fallbackText,
  });

  if (!firstDocument) {
    return null;
  }

  return { entity: firstDocument, chat };
};

export const useAutoOpenDocumentPreview = ({ edge }: Props) => {
  const intl = useIntl();
  const { shouldOpenPreviewInDialog } = useContext(PreviewContext);
  const { documentPreviewState, openDocumentPreview } = useContext(
    DocumentPreviewContext,
  );

  const canOpenDocumentPreview = !shouldOpenPreviewInDialog;

  const openFirstDocument = useEventCallback((node: ChatFragment | null) => {
    if (!canOpenDocumentPreview) {
      return;
    }

    const documentPreviewOptions = generateDocumentPreview({
      chat: node,
      documentPreviewState,
      fallbackText: intl.formatMessage(
        {
          id: 'preview',
          defaultMessage: '{count, plural, one {Preview} other {Previews}}',
          description: 'Document preview title missing fallback',
        },
        { count: 1 },
      ),
    });

    if (!documentPreviewOptions) {
      return;
    }

    openDocumentPreview({
      ...documentPreviewOptions,
      trigger: 'auto',
    });
  });

  const node = edge?.node ?? null;

  useEffect(() => {
    openFirstDocument(node);
  }, [node, openFirstDocument]);
};
