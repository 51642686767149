/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationFragment } from '../fragments/conversation-fragment';
import { ProjectFragment } from '../fragments/project-fragment';
import { gql } from '@apollo/client';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import { ProjectFragmentDoc } from '../fragments/project-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversationQueryVariables = Types.Exact<{
  where: Types.ConversationWhereUniqueInput;
}>;

export type ConversationQuery = { __typename: 'UserQuery' } & {
  conversation: Types.Maybe<
    { __typename: 'Conversation' } & {
      project: Types.Maybe<{ __typename: 'Project' } & ProjectFragment>;
    } & ConversationFragment
  >;
};

export const ConversationQueryDocument = gql`
  query ConversationQuery($where: ConversationWhereUniqueInput!) {
    conversation(where: $where) {
      ...ConversationFragment
      project {
        ...ProjectFragment
      }
    }
  }
  ${ConversationFragmentDoc}
  ${ProjectFragmentDoc}
`;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConversationQuery,
    ConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(
    ConversationQueryDocument,
    options,
  );
}
export function useConversationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversationQuery,
    ConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(
    ConversationQueryDocument,
    options,
  );
}
export function useConversationQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ConversationQuery,
    ConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ConversationQuery, ConversationQueryVariables>(
    ConversationQueryDocument,
    options,
  );
}
export type ConversationQueryHookResult = ReturnType<
  typeof useConversationQuery
>;
export type ConversationQueryLazyQueryHookResult = ReturnType<
  typeof useConversationQueryLazyQuery
>;
export type ConversationQuerySuspenseQueryHookResult = ReturnType<
  typeof useConversationQuerySuspenseQuery
>;
export type ConversationQueryQueryResult = Apollo.QueryResult<
  ConversationQuery,
  ConversationQueryVariables
>;
