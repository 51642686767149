import { PdfPageStyles } from '@allganize/alli-document';
import {
  AgentSelectContext,
  ModalContext,
  TypingContext,
  ValidationContext,
} from '@allganize/alli-sdk-chat';
import { AlliModalContainer } from '@allganize/alli-sdk/alli-modal-container/alli-modal-container';
import { useAlliModalContainer } from '@allganize/alli-sdk/alli-modal-container/use-alli-modal-container';
import { ChatListProvider } from '@allganize/alli-sdk/chat-list/chat-list-provider';
import { validation } from '@allganize/alli-sdk/config/validation';
import { ConversationDetailErrorState } from '@allganize/alli-sdk/conversation-detail/conversation-detail-error-state';
import { ConversationDetailLoadingState } from '@allganize/alli-sdk/conversation-detail/conversation-detail-loading-state';
import { ConversationProvider } from '@allganize/alli-sdk/conversation-detail/conversation-provider';
import { DocumentPreviewContext } from '@allganize/alli-sdk/document-preview/document-preview-context';
import { useDocumentPreview } from '@allganize/alli-sdk/document-preview/use-document-preview';
import { useAgentSelect } from '@allganize/alli-sdk/hooks/use-agent-select';
import { useTyping } from '@allganize/alli-sdk/hooks/use-typing';
import { locales as sdkLocales } from '@allganize/alli-sdk/i18n/locales';
import { Preview } from '@allganize/alli-sdk/preview/preview';
import { PreviewContext } from '@allganize/alli-sdk/preview/preview-context';
import { usePreview } from '@allganize/alli-sdk/preview/use-preview';
import { useProject } from '@allganize/alli-sdk/project/use-project';
import { SingleActionPreviewContext } from '@allganize/alli-sdk/single-action-preview/single-action-preview-context';
import { useSingleActionPreview } from '@allganize/alli-sdk/single-action-preview/use-single-action-preview';
import { CarouselStyles } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { Outlet, useParams } from 'react-router-dom';
import { Grow } from '../Layout';
import { Footer } from '../../conversation/footer';

export const contentMaxWidth = 920;

const AppContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledPreview = styled(Preview)`
  width: 0;
  margin-right: 0;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const MessengerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 380px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: ${contentMaxWidth}px;
  flex: 1 0 auto;
`;

export const ConversationDetailLayout = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const project = useProject();
  const alliModalContainer = useAlliModalContainer();
  const preview = usePreview();
  const previewContextProps = {
    previewSidebarAnchor: preview.previewSidebarAnchor,
    shouldOpenPreviewInDialog: preview.shouldOpenPreviewInDialog,
    setPreviewType: preview.setPreviewType,
  };
  const documentPreview = useDocumentPreview(previewContextProps);
  const singleActionPreview = useSingleActionPreview(previewContextProps);
  const agentSelect = useAgentSelect();
  const onTyping = useTyping({ conversationId });

  if (!conversationId) {
    throw new Error('conversationId is required');
  }

  if (!project) {
    return null;
  }

  return (
    <IntlProvider
      locale={'ko'}
      defaultLocale={'ko'}
      messages={sdkLocales['ko']}
    >
      <ModalContext.Provider value={alliModalContainer}>
        <PreviewContext.Provider value={preview}>
          <SingleActionPreviewContext.Provider value={singleActionPreview}>
            <DocumentPreviewContext.Provider value={documentPreview}>
              <CarouselStyles />
              <PdfPageStyles />
              <AppContainer className="alli-app">
                <StyledPreview
                  variants={{
                    open: {
                      width: 'auto',
                      opacity: 1,
                      flexGrow: 1,
                    },
                    closed: {
                      width: 0,
                      opacity: 0,
                      flexGrow: 0,
                    },
                  }}
                />
                <MessengerContainer
                  css={
                    preview.previewOpen &&
                    css`
                      flex-grow: 0;
                    `
                  }
                >
                  <ErrorBoundary
                    fallbackRender={({ resetErrorBoundary }) => (
                      <ContentWrapper>
                        <ConversationDetailErrorState
                          conversationId={conversationId}
                          onRetry={resetErrorBoundary}
                        />
                      </ContentWrapper>
                    )}
                  >
                    <Suspense
                      fallback={
                        <ContentWrapper>
                          <Grow />
                          <ConversationDetailLoadingState />
                        </ContentWrapper>
                      }
                    >
                      <AgentSelectContext.Provider value={agentSelect}>
                        <ConversationProvider conversationId={conversationId}>
                          <ChatListProvider conversationId={conversationId}>
                            <ValidationContext.Provider value={validation}>
                              <TypingContext.Provider value={{ onTyping }}>
                                <ContentWrapper>
                                  <Content
                                    css={
                                      preview.previewOpen &&
                                      css`
                                        max-width: 380px;
                                      `
                                    }
                                  >
                                    <Outlet />
                                  </Content>
                                </ContentWrapper>
                                <Footer />
                              </TypingContext.Provider>
                            </ValidationContext.Provider>
                          </ChatListProvider>
                        </ConversationProvider>
                      </AgentSelectContext.Provider>
                    </Suspense>
                  </ErrorBoundary>
                </MessengerContainer>
                <AlliModalContainer />
              </AppContainer>
            </DocumentPreviewContext.Provider>
          </SingleActionPreviewContext.Provider>
        </PreviewContext.Provider>
      </ModalContext.Provider>
    </IntlProvider>
  );
};
