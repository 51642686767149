/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { PageInfoFragment } from '../fragments/page-info-fragment';
import {
  ChatFragment_AgentChat_,
  ChatFragment_BotChat_,
  ChatFragment_CarouselChat_,
  ChatFragment_ContactAgentByEmailChat_,
  ChatFragment_CustomUIChat_,
  ChatFragment_EventChat_,
  ChatFragment_FillSlotRichUXChat_,
  ChatFragment_SendFormChat_,
  ChatFragment_UserChat_,
} from '../fragments/chat-fragment';
import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../fragments/page-info-fragment';
import { ChatFragmentDoc } from '../fragments/chat-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatsQueryVariables = Types.Exact<{
  where: Types.ChatWhereInput;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ChatsQuery = { __typename: 'UserQuery' } & {
  chats: Types.Maybe<
    { __typename: 'ChatConnection' } & {
      pageInfo: { __typename: 'PageInfo' } & PageInfoFragment;
      edges: Array<
        Types.Maybe<
          { __typename: 'ChatEdge' } & Pick<Types.ChatEdge, 'cursor'> & {
              node: Types.Maybe<
                | ({ __typename: 'AgentChat' } & ChatFragment_AgentChat_)
                | ({ __typename: 'BotChat' } & ChatFragment_BotChat_)
                | ({ __typename: 'CarouselChat' } & ChatFragment_CarouselChat_)
                | ({
                    __typename: 'ContactAgentByEmailChat';
                  } & ChatFragment_ContactAgentByEmailChat_)
                | ({ __typename: 'CustomUIChat' } & ChatFragment_CustomUIChat_)
                | ({ __typename: 'EventChat' } & ChatFragment_EventChat_)
                | ({
                    __typename: 'FillSlotRichUXChat';
                  } & ChatFragment_FillSlotRichUXChat_)
                | ({ __typename: 'SendFormChat' } & ChatFragment_SendFormChat_)
                | ({ __typename: 'UserChat' } & ChatFragment_UserChat_)
              >;
            }
        >
      >;
    }
  >;
};

export const ChatsQueryDocument = gql`
  query ChatsQuery(
    $where: ChatWhereInput!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    chats(
      where: $where
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...ChatFragment
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${ChatFragmentDoc}
`;

/**
 * __useChatsQuery__
 *
 * To run a query within a React component, call `useChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useChatsQuery(
  baseOptions: Apollo.QueryHookOptions<ChatsQuery, ChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsQueryDocument,
    options,
  );
}
export function useChatsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatsQuery, ChatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsQueryDocument,
    options,
  );
}
export function useChatsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ChatsQuery,
    ChatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsQueryDocument,
    options,
  );
}
export type ChatsQueryHookResult = ReturnType<typeof useChatsQuery>;
export type ChatsQueryLazyQueryHookResult = ReturnType<
  typeof useChatsQueryLazyQuery
>;
export type ChatsQuerySuspenseQueryHookResult = ReturnType<
  typeof useChatsQuerySuspenseQuery
>;
export type ChatsQueryQueryResult = Apollo.QueryResult<
  ChatsQuery,
  ChatsQueryVariables
>;
