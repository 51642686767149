/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ConversationUserFragment = { __typename: 'User' } & Pick<
  Types.User,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'avatar'
  | 'locale'
  | 'ownUserId'
  | 'temporary'
  | 'email'
>;

export const ConversationUserFragmentDoc = gql`
  fragment ConversationUserFragment on User {
    id
    firstName
    lastName
    avatar
    locale
    ownUserId
    temporary
    email
  }
`;
