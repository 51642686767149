import { useId } from '@allganize/hooks';
import { LoadingButton } from '@allganize/ui-button';
import { Checkbox } from '@allganize/ui-checkbox';
import { FormControl, FormGroup, FormHelperText } from '@allganize/ui-form';
import { Radio } from '@allganize/ui-radio';
import { css } from '@emotion/react';
import { FunctionComponent, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { FormattedMessage } from 'react-intl';
import { ChatOptionInfo } from './chat-option-info';
import { chatOptionInfoClasses } from './chat-option-info-classes';
import { ChatOptionInfoList } from './chat-option-info-list';
import { ChatOptionInfoListItem } from './chat-option-info-list-item';
import {
  ChatOptionInfoFormValue,
  UseChatOptionInfoFormOptions,
  useChatOptionInfoForm,
} from './use-chat-option-info-form';

interface ChatOptionInfoFormProps extends UseChatOptionInfoFormOptions {
  carousel?: boolean;
  wrap?: boolean;
  className?: string;
  isChatOptionInfoDisabled?(option: ChatOptionInfoFormValue): boolean;
  readOnly?: boolean;
}

export const ChatOptionInfoForm: FunctionComponent<ChatOptionInfoFormProps> = ({
  carousel = false,
  wrap,
  chatOptionInfos,
  className,
  isChatOptionInfoDisabled,
  multipleOption,
  onSubmit,
  readOnly,
}) => {
  const formId = useId();
  const { form, options, submit } = useChatOptionInfoForm({
    chatOptionInfos,
    multipleOption,
    onSubmit,
  });
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = form;

  useEffect(() => {
    let subscription: Subscription | null = null;

    if (!multipleOption) {
      subscription = watch(values => {
        if (values.multipleOption || values.chatOptionInfo) {
          handleSubmit(submit)();
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
    };
  }, [handleSubmit, multipleOption, submit, watch]);

  return (
    <form
      css={css`
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
      className={className}
      onSubmit={handleSubmit(submit)}
    >
      {multipleOption ? (
        <Controller
          control={control}
          name="chatOptionInfos"
          render={({ field, fieldState }) => {
            return (
              <FormControl
                component="fieldset"
                fullWidth
                required
                error={fieldState.invalid}
                disabled={readOnly}
              >
                <FormGroup role="group">
                  <ChatOptionInfoList
                    carousel={carousel}
                    wrap={wrap}
                    css={
                      !carousel &&
                      css`
                        margin: -4px;
                      `
                    }
                  >
                    {options.map(option => {
                      const labelId = `chat-option-info-form-${formId}-${option.value}`;
                      const checked =
                        field.value.findIndex(
                          opt => opt.value === option.value,
                        ) >= 0;
                      const disabled =
                        readOnly || isChatOptionInfoDisabled?.(option);

                      return (
                        <ChatOptionInfoListItem
                          key={option.value}
                          carousel={carousel}
                        >
                          <ChatOptionInfo
                            control={
                              <Checkbox
                                edge="start"
                                checked={checked}
                                tabIndex={-1}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            }
                            data={option}
                            readOnly={disabled}
                            textProps={{ id: labelId }}
                            onClick={() => {
                              field.onChange(
                                checked
                                  ? field.value.filter(
                                      opt => opt.value !== option.value,
                                    )
                                  : [...field.value, option],
                              );
                            }}
                            onBlur={field.onBlur}
                          />
                        </ChatOptionInfoListItem>
                      );
                    })}
                  </ChatOptionInfoList>
                </FormGroup>

                {fieldState.error?.message && (
                  <FormHelperText
                    css={css`
                      text-align: right;
                    `}
                  >
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </FormControl>
            );
          }}
        />
      ) : (
        <Controller
          control={control}
          name="chatOptionInfo"
          render={({ field, fieldState }) => {
            return (
              <FormControl
                fullWidth
                required
                error={fieldState.invalid}
                disabled={readOnly}
              >
                <FormGroup role="radiogroup" onBlur={field.onBlur}>
                  <ChatOptionInfoList
                    carousel={carousel}
                    wrap={wrap}
                    css={
                      !carousel &&
                      css`
                        margin: -4px;
                      `
                    }
                  >
                    {options.map(option => {
                      const labelId = `chat-option-info-form-${formId}-${option.value}`;
                      const checked = option.value === field.value?.value;
                      // form gets submitted on click for single choice, so we have to disable
                      const disabled =
                        isSubmitting ||
                        readOnly ||
                        isChatOptionInfoDisabled?.(option);

                      return (
                        <ChatOptionInfoListItem
                          key={option.value}
                          carousel={carousel}
                        >
                          <ChatOptionInfo
                            css={css`
                              .${chatOptionInfoClasses.checkbox} {
                                display: none;
                              }
                            `}
                            control={
                              <Radio
                                edge="start"
                                checked={checked}
                                tabIndex={-1}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            }
                            data={option}
                            readOnly={disabled}
                            textProps={{ id: labelId }}
                            onClick={() => {
                              if (!checked) {
                                field.onChange(option);
                              }
                            }}
                            onBlur={field.onBlur}
                          />
                        </ChatOptionInfoListItem>
                      );
                    })}
                  </ChatOptionInfoList>
                </FormGroup>

                {fieldState.error?.message && (
                  <FormHelperText
                    css={css`
                      text-align: right;
                    `}
                  >
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </FormControl>
            );
          }}
        />
      )}

      {errors.root?.message && (
        <FormHelperText error>{errors.root?.message}</FormHelperText>
      )}

      {multipleOption && !readOnly && (
        <LoadingButton
          css={[
            css`
              margin-top: 8px;
            `,
            carousel &&
              css`
                margin-left: 16px;
                margin-right: 16px;
              `,
          ]}
          type="submit"
          color="primary"
          size="large"
          variant="filled"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      )}
    </form>
  );
};
