/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
export type CustomUIChatFragment = { __typename: 'CustomUIChat' } & Pick<
  Types.CustomUIChat,
  | 'id'
  | 'message'
  | 'messageContentState'
  | 'createdAt'
  | 'hidden'
  | 'nextInputType'
  | 'useAutoComplete'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    uiValues: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename: 'CustomUIValue' } & Pick<
            Types.CustomUIValue,
            'key' | 'value'
          >
        >
      >
    >;
  };

export const CustomUIChatFragmentDoc = gql`
  fragment CustomUIChatFragment on CustomUIChat {
    id
    message
    messageContentState @client
    createdAt
    hidden
    media {
      ...ChatMediaFragment
    }
    uiValues {
      key
      value
    }
    nextInputType
    useAutoComplete
  }
  ${ChatMediaFragmentDoc}
`;
