import { useUnmount } from '@allganize/hooks';
import { IframeContext } from '@allganize/react-iframe';
import { useEffect, useCallback, useState, useRef, useContext } from 'react';

import { useUserTypingMutation } from '../graphql/mutations/user-typing-mutation';

interface Props {
  conversationId?: string | number;
  timeout?: number;
}

export const useTyping = ({ conversationId, timeout = 30000 }: Props) => {
  const { window } = useContext(IframeContext);
  const timeoutRef = useRef<number | null>(null);
  const [mutate] = useUserTypingMutation();
  const [isTyping, setIsTyping] = useState<boolean | null>(null);

  const sendTyping = useCallback(
    (isTyping: boolean) => {
      if (conversationId) {
        mutate({
          variables: {
            isTyping,
            where: {
              id: conversationId,
            },
          },
        });
      }
    },
    [conversationId, mutate],
  );

  // Reset the isTyping state periodically based on a timeout.
  useEffect(() => {
    const clearTimeoutRef = () => {
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };

    if (isTyping !== null) {
      clearTimeoutRef();
      timeoutRef.current = window.setTimeout(() => setIsTyping(null), timeout);
    }

    return clearTimeoutRef;
  }, [isTyping, setIsTyping, timeout, window]);

  // Send a user typing when the isTyping state changes.
  useEffect(() => {
    if (isTyping !== null) {
      sendTyping(isTyping);
    }
  }, [isTyping, conversationId, sendTyping]);

  // Send user typing `false` when the user focuses out from window or the component unmounts.
  useEffect(() => {
    if (conversationId) {
      const cancelUserTyping = () => {
        if (isTyping !== false) {
          sendTyping(false);
        }
      };

      window.addEventListener('blur', cancelUserTyping);
      return () => window.removeEventListener('blur', cancelUserTyping);
    }
  }, [window, conversationId, sendTyping, isTyping]);

  useUnmount(() => {
    sendTyping(false);
  });

  const onTyping = useCallback((isTyping: boolean) => {
    setIsTyping(isTyping);
  }, []);

  return onTyping;
};
