import { AlliBranding } from '@allganize/alli-sdk-ui';
import { FunctionComponent } from 'react';
import { ChatListRoot } from '../chat-list/chat-list-root';
import { ChatListLoadingState } from '../chat-list/chat-list-loading-state';
import { analytics } from '../analytics';

export const ConversationDetailLoadingState: FunctionComponent<{
  conversationId?: string | number;
}> = ({ conversationId }) => {
  return (
    <ChatListRoot>
      <ChatListLoadingState />

      <li>
        <AlliBranding
          onClick={() =>
            analytics.track('click_alli_branding', {
              conversationId,
              source: 'conversation-detail',
            })
          }
        />
      </li>
    </ChatListRoot>
  );
};
