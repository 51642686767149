import { setContext } from '@apollo/client/link/context';
import { AuthClient } from '../auth/auth-client';
import { environment } from '../environments/environment';
import { getRegion } from '../utils/get-region';
import { createApolloClient, createRequestLink } from './apollo-client';
import { createSubscriptionClient } from './subscription-client';

const getParams = () => {
  const params = new URLSearchParams(window.location.search);
  const projectToken = params.get('project_token');

  return {
    'PROJECT-TOKEN': projectToken,
  };
};

export const getClient = async (authClient: AuthClient) => {
  const subscriptionClient = createSubscriptionClient({
    lazy: true,
    shouldRetry: () => true,
    url() {
      const region = getRegion();

      return region === 'ja' || region === 'kr'
        ? environment.api[region].ws
        : environment.api.default.ws;
    },
    async connectionParams() {
      return {
        ...(await authClient.getHeaders()),
        ...getParams(),
      };
    },
  });
  const requestLink = createRequestLink(subscriptionClient);

  const client = createApolloClient(
    authClient,
    setContext(async (operation, prev) => {
      const region = getRegion();

      return {
        ...prev,
        uri:
          region === 'ja' || region === 'kr'
            ? environment.api[region].http
            : environment.api.default.http,
        headers: {
          ...prev.headers,
          ...(await authClient.getHeaders()),
          ...getParams(),
        },
      };
    }),
    requestLink,
  );

  return { client, subscriptionClient };
};
