import { IcCheckCircle, IcError, IcWarning } from '@allganize/ui-icons';
import { FunctionComponent } from 'react';
import { IconProps } from 'react-toastify';

export const ToastIcon: FunctionComponent<IconProps> = ({ theme, type }) => {
  if (type === 'success') {
    return <IcCheckCircle fontSize="medium" />;
  }

  if (type === 'error') {
    return <IcError fontSize="medium" />;
  }

  if (type === 'warning') {
    return <IcWarning fontSize="medium" />;
  }

  return null;
};
