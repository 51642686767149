import { Types } from '@allganize/alli-sdk-interfaces';
import {
  DraftFilePlugin,
  EditorState,
  removeBlockIfEmpty,
} from '@allganize/draft-input';
import { useMemo } from 'react';
import { ChatFileFragment } from '../graphql/fragments/chat-file-fragment';

export const getChatFileContentState = ({ file }: UseChatFileOptions) => {
  const contentStateWithFile = (Array.isArray(file) ? file : [file])
    .reduce((acc, curr) => {
      if (!curr) {
        return acc;
      }

      return DraftFilePlugin.addFile(acc, curr.url, curr);
    }, EditorState.createEmpty())
    .getCurrentContent();
  const firstBlockRemoved = removeBlockIfEmpty(
    contentStateWithFile,
    contentStateWithFile.getBlockMap().first(),
  );
  return removeBlockIfEmpty(
    firstBlockRemoved,
    firstBlockRemoved.getBlockMap().last(),
  );
};

export interface UseChatFileOptions {
  file: Types.Maybe<ChatFileFragment> | Types.Maybe<ChatFileFragment>[];
}

export const useChatFile = ({ file }: UseChatFileOptions) => {
  const contentState = useMemo(() => getChatFileContentState({ file }), [file]);
  return { contentState };
};
