/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ChatMediaFragment = { __typename: 'Media' } & Pick<
  Types.Media,
  'id' | 'mediaType' | 'metaInfo' | 'url' | 'width' | 'height'
>;

export const ChatMediaFragmentDoc = gql`
  fragment ChatMediaFragment on Media {
    id
    mediaType
    metaInfo
    url
    width
    height
  }
`;
