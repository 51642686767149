import { onError } from '@apollo/client/link/error';
import { AuthClient } from '../auth/auth-client';
import { logger } from '../core/logger/logger';

export const createErrorLink = (authClient: AuthClient) => {
  return onError(error => {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        logger.error(
          `[GraphQL error]: Message: ${graphQLError.message}, Location: ${graphQLError.locations}, Path: ${graphQLError.path}`,
        );
      });
    }

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`);

      if ('statusCode' in networkError && networkError.statusCode === 403) {
        authClient.signOut();
      }
    }
  });
};
