/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationUserFragment } from './conversation-user-fragment';
import { ChatAgentFragment } from './chat-agent-fragment';
import {
  ChatFragment_AgentChat_,
  ChatFragment_BotChat_,
  ChatFragment_CarouselChat_,
  ChatFragment_ContactAgentByEmailChat_,
  ChatFragment_CustomUIChat_,
  ChatFragment_EventChat_,
  ChatFragment_FillSlotRichUXChat_,
  ChatFragment_SendFormChat_,
  ChatFragment_UserChat_,
} from './chat-fragment';
import { gql } from '@apollo/client';
import { ConversationUserFragmentDoc } from './conversation-user-fragment';
import { ChatAgentFragmentDoc } from './chat-agent-fragment';
import { ChatFragmentDoc } from './chat-fragment';
export type ConversationFragment = { __typename: 'Conversation' } & Pick<
  Types.Conversation,
  'id' | 'state' | 'isTry' | 'lastChatTime' | 'preview' | 'title' | 'shareUrl'
> & {
    user: Types.Maybe<{ __typename: 'User' } & ConversationUserFragment>;
    assignee: Types.Maybe<{ __typename: 'Agent' } & ChatAgentFragment>;
    typingInfo: { __typename: 'TypingInfo' } & Pick<
      Types.TypingInfo,
      'agentTyping'
    > & {
        agent: Types.Maybe<
          { __typename: 'Agent' } & Pick<
            Types.Agent,
            'id' | 'firstName' | 'lastName' | 'displayName'
          > & {
              avatar: Types.Maybe<
                { __typename: 'Media' } & Pick<
                  Types.Media,
                  'id' | 'url' | 'width' | 'height'
                >
              >;
            }
        >;
      };
    lastChat: Types.Maybe<
      | ({ __typename: 'AgentChat' } & ChatFragment_AgentChat_)
      | ({ __typename: 'BotChat' } & ChatFragment_BotChat_)
      | ({ __typename: 'CarouselChat' } & ChatFragment_CarouselChat_)
      | ({
          __typename: 'ContactAgentByEmailChat';
        } & ChatFragment_ContactAgentByEmailChat_)
      | ({ __typename: 'CustomUIChat' } & ChatFragment_CustomUIChat_)
      | ({ __typename: 'EventChat' } & ChatFragment_EventChat_)
      | ({
          __typename: 'FillSlotRichUXChat';
        } & ChatFragment_FillSlotRichUXChat_)
      | ({ __typename: 'SendFormChat' } & ChatFragment_SendFormChat_)
      | ({ __typename: 'UserChat' } & ChatFragment_UserChat_)
    >;
    readInfo: Types.Maybe<
      { __typename: 'ReadInfo' } & Pick<
        Types.ReadInfo,
        | 'agentLastReadChatId'
        | 'agentLastReadTime'
        | 'agentUnreadChatCount'
        | 'userLastReadChatId'
        | 'userLastReadTime'
        | 'userUnreadChatCount'
      >
    >;
    llmApp: Types.Maybe<
      { __typename: 'LLMApp' } & Pick<
        Types.LLMApp,
        'id' | 'icon' | 'name' | 'description'
      > & {
          campaign: Types.Maybe<
            { __typename: 'Campaign' } & Pick<
              Types.Campaign,
              'id' | 'campaignToken'
            >
          >;
        }
    >;
  };

export const ConversationFragmentDoc = gql`
  fragment ConversationFragment on Conversation {
    id
    state
    isTry
    lastChatTime
    preview
    title
    shareUrl
    user {
      ...ConversationUserFragment
    }
    assignee {
      ...ChatAgentFragment
    }
    typingInfo {
      agentTyping
      agent {
        id
        firstName
        lastName
        displayName
        avatar {
          id
          url
          width
          height
        }
      }
    }
    lastChat {
      ...ChatFragment
    }
    readInfo {
      agentLastReadChatId
      agentLastReadTime
      agentUnreadChatCount
      userLastReadChatId
      userLastReadTime
      userUnreadChatCount
    }
    llmApp {
      id
      icon
      name
      description
      campaign {
        id
        campaignToken
      }
    }
  }
  ${ConversationUserFragmentDoc}
  ${ChatAgentFragmentDoc}
  ${ChatFragmentDoc}
`;
