/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { PageInfoFragment } from '../fragments/page-info-fragment';
import { ConversationFragment } from '../fragments/conversation-fragment';
import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../fragments/page-info-fragment';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversationsQueryVariables = Types.Exact<{
  order?: Types.InputMaybe<Types.ConversationOrder>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ConversationsQuery = { __typename: 'UserQuery' } & {
  conversations: Types.Maybe<
    { __typename: 'ConversationConnection' } & {
      pageInfo: { __typename: 'PageInfo' } & PageInfoFragment;
      edges: Array<
        Types.Maybe<
          { __typename: 'ConversationEdge' } & Pick<
            Types.ConversationEdge,
            'cursor'
          > & {
              node: Types.Maybe<
                { __typename: 'Conversation' } & ConversationFragment
              >;
            }
        >
      >;
    }
  >;
};

export const ConversationsQueryDocument = gql`
  query ConversationsQuery(
    $order: ConversationOrder
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    conversations(
      order: $order
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...ConversationFragment
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${ConversationFragmentDoc}
`;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useConversationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConversationsQuery,
    ConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(
    ConversationsQueryDocument,
    options,
  );
}
export function useConversationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversationsQuery,
    ConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(
    ConversationsQueryDocument,
    options,
  );
}
export function useConversationsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ConversationsQuery,
    ConversationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ConversationsQuery,
    ConversationsQueryVariables
  >(ConversationsQueryDocument, options);
}
export type ConversationsQueryHookResult = ReturnType<
  typeof useConversationsQuery
>;
export type ConversationsQueryLazyQueryHookResult = ReturnType<
  typeof useConversationsQueryLazyQuery
>;
export type ConversationsQuerySuspenseQueryHookResult = ReturnType<
  typeof useConversationsQuerySuspenseQuery
>;
export type ConversationsQueryQueryResult = Apollo.QueryResult<
  ConversationsQuery,
  ConversationsQueryVariables
>;
