import { ExtendButtonBase } from '@allganize/ui-button';
import { dividerClasses } from '@allganize/ui-divider';
import { ListItemButtonContext, listItemIconClasses } from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiMenuItem from '@mui/material/MenuItem';
import { lighten } from 'polished';
import { forwardRef } from 'react';
import { menuItemClasses } from './menu-item-classes';
import { MenuItemTypeMap } from './menu-item-type-map';

// @ts-expect-error overridable component
export const MenuItem: ExtendButtonBase<MenuItemTypeMap> = forwardRef(
  (props, ref) => {
    const theme = useTheme();

    return (
      <ListItemButtonContext.Provider value={{ disabled: props.disabled }}>
        <MuiMenuItem
          data-testid="menu-item"
          css={[
            css`
              ${theme.typography.title14}
              min-height: auto;
              padding: 4px 12px;
            `,
            css`
              &:hover,
              &.${menuItemClasses.focusVisible} {
                background-color: ${theme.taxPalette.grey[100]};

                @media (hover: none) {
                  background-color: transparent;
                }
              }

              &:active {
                background-color: ${theme.palette.action.selected};
              }

              &.${menuItemClasses.selected} {
                background-color: ${lighten(0.4, theme.palette.primary.main)};

                &:hover,
                &.${menuItemClasses.focusVisible} {
                  background-color: ${theme.palette.action.hover};

                  @media (hover: none) {
                    background-color: ${lighten(
                      0.4,
                      theme.palette.primary.main,
                    )};
                  }
                }

                &:active {
                  background-color: ${theme.palette.action.selected};
                }
              }

              &.${menuItemClasses.disabled} {
                opacity: 1;
                background-color: transparent;
              }

              & + .${dividerClasses.root} {
                margin-top: 8px;
                margin-bottom: 8px;
              }

              & + .${dividerClasses.inset} {
                margin-left: 52px;
              }

              & .${listItemIconClasses.root} {
                min-width: auto;
              }
            `,
          ]}
          {...props}
          ref={ref}
          centerRipple={false}
          disableRipple
          disableTouchRipple
          focusRipple={false}
        />
      </ListItemButtonContext.Provider>
    );
  },
);
