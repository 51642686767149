import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { LOCALES } from '../i18n/locales';

export const Meta: FunctionComponent = () => {
  const { locale } = useIntl();
  const title = 'DailyTax';
  const description =
    '최신 개정세법부터, 예규판례까지 사람에게 물어보듯 편히 검색하세요.';

  return (
    <Helmet defaultTitle={title}>
      <html lang={locale} />

      <meta name="application-name" content={title} />
      <meta name="description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary" />

      <meta name="og:site_name" property="og:site_name" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:locale" property="og:locale" content={locale} />

      {LOCALES.map(
        loc =>
          loc !== locale && (
            <meta
              key={loc}
              name="og:locale:alternate"
              property="og:locale:alternate"
              content={loc}
            />
          ),
      )}
    </Helmet>
  );
};
