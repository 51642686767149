/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatVariableFragment } from './chat-variable-fragment';
import { ChatOptionStyleFragment } from './chat-option-style-fragment';
import { ChatMediaFragment } from './chat-media-fragment';
import { gql } from '@apollo/client';
import { ChatVariableFragmentDoc } from './chat-variable-fragment';
import { ChatOptionStyleFragmentDoc } from './chat-option-style-fragment';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
export type ChatOptionInfoFragment = { __typename: 'ChatOptionInfo' } & Pick<
  Types.ChatOptionInfo,
  'shortenOption' | 'longOption' | 'url' | 'optionBoxTemplate' | 'reusable'
> & {
    urlVariable: Types.Maybe<{ __typename: 'Variable' } & ChatVariableFragment>;
    style: { __typename: 'OptionStyle' } & ChatOptionStyleFragment;
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
  };

export const ChatOptionInfoFragmentDoc = gql`
  fragment ChatOptionInfoFragment on ChatOptionInfo {
    shortenOption
    longOption
    url
    urlVariable {
      ...ChatVariableFragment
    }
    optionBoxTemplate
    style {
      ...ChatOptionStyleFragment
    }
    reusable
    media {
      ...ChatMediaFragment
    }
  }
  ${ChatVariableFragmentDoc}
  ${ChatOptionStyleFragmentDoc}
  ${ChatMediaFragmentDoc}
`;
