/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StopSingleActionAppResultPublicMutationVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    singleActionAppResultId: UserQueryTypes.Scalars['ID']['input'];
    publicToken: UserQueryTypes.Scalars['String']['input'];
  }>;

export type StopSingleActionAppResultPublicMutation = {
  __typename: 'UserMutation';
} & {
  stopSingleActionAppResultPublic: UserQueryTypes.Maybe<
    { __typename: 'StopSingleActionAppResultPublic' } & {
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const StopSingleActionAppResultPublicMutationDocument = gql`
  mutation StopSingleActionAppResultPublicMutation(
    $where: ProjectWhereUniqueInput!
    $singleActionAppResultId: ID!
    $publicToken: String!
  ) {
    stopSingleActionAppResultPublic(
      projectWhere: $where
      singleActionAppResultId: $singleActionAppResultId
      token: $publicToken
    ) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type StopSingleActionAppResultPublicMutationMutationFn =
  Apollo.MutationFunction<
    StopSingleActionAppResultPublicMutation,
    StopSingleActionAppResultPublicMutationVariables
  >;

/**
 * __useStopSingleActionAppResultPublicMutation__
 *
 * To run a mutation, you first call `useStopSingleActionAppResultPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSingleActionAppResultPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSingleActionAppResultPublicMutation, { data, loading, error }] = useStopSingleActionAppResultPublicMutation({
 *   variables: {
 *      where: // value for 'where'
 *      singleActionAppResultId: // value for 'singleActionAppResultId'
 *      publicToken: // value for 'publicToken'
 *   },
 * });
 */
export function useStopSingleActionAppResultPublicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopSingleActionAppResultPublicMutation,
    StopSingleActionAppResultPublicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StopSingleActionAppResultPublicMutation,
    StopSingleActionAppResultPublicMutationVariables
  >(StopSingleActionAppResultPublicMutationDocument, options);
}
export type StopSingleActionAppResultPublicMutationHookResult = ReturnType<
  typeof useStopSingleActionAppResultPublicMutation
>;
export type StopSingleActionAppResultPublicMutationMutationResult =
  Apollo.MutationResult<StopSingleActionAppResultPublicMutation>;
export type StopSingleActionAppResultPublicMutationMutationOptions =
  Apollo.BaseMutationOptions<
    StopSingleActionAppResultPublicMutation,
    StopSingleActionAppResultPublicMutationVariables
  >;
