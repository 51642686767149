import { Button } from '@allganize/ui-button';
import { Text } from '@allganize/ui-text';
import { IcRefresh } from '@allganize/ui-icons';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import linkExpired from '../assets/link-expired.png';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

interface ErrorPageProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
  onAction?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ErrorPage = ({
  title,
  action,
  children,
  onAction,
  ...other
}: ErrorPageProps) => {
  const theme = useTheme();

  return (
    <Root {...other}>
      <img
        src={linkExpired}
        width={150}
        height={150}
        alt="Link expired error"
      />

      {title && (
        <Text
          variant="title20"
          css={css`
            margin-top: ${theme.spacing(3)};
            color: ${theme.palette.text.secondary};
          `}
        >
          {title}
        </Text>
      )}

      {children && (
        <Text
          variant="body16"
          css={css`
            margin-top: ${theme.spacing(1.5)};
            color: ${theme.palette.text.secondary};
          `}
        >
          {children}
        </Text>
      )}

      {onAction && action && (
        <Button
          color="primary"
          startIcon={<IcRefresh />}
          css={css`
            margin-top: ${theme.spacing(1.5)};
          `}
          onClick={onAction}
        >
          {action}
        </Button>
      )}
    </Root>
  );
};
