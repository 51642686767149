/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { gql } from '@apollo/client';
export type SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_ = {
  __typename: 'CSVKnowledgeBase';
} & Pick<
  UserQueryTypes.CSVKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_ = {
  __typename: 'GoogleDocsKnowledgeBase';
} & Pick<UserQueryTypes.GoogleDocsKnowledgeBase, 'id' | 'title'>;

export type SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_ = {
  __typename: 'HWPKnowledgeBase';
} & Pick<
  UserQueryTypes.HWPKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_ = {
  __typename: 'ImageKnowledgeBase';
} & Pick<
  UserQueryTypes.ImageKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_ = {
  __typename: 'MSDocsKnowledgeBase';
} & Pick<
  UserQueryTypes.MSDocsKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_ = {
  __typename: 'MSExcelKnowledgeBase';
} & Pick<
  UserQueryTypes.MSExcelKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_ = {
  __typename: 'MSPPTKnowledgeBase';
} & Pick<
  UserQueryTypes.MSPPTKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_ = {
  __typename: 'OldMSDocsKnowledgeBase';
} & Pick<
  UserQueryTypes.OldMSDocsKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_ = {
  __typename: 'PDFKnowledgeBase';
} & Pick<
  UserQueryTypes.PDFKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_ = {
  __typename: 'TextKnowledgeBase';
} & Pick<
  UserQueryTypes.TextKnowledgeBase,
  'id' | 'title' | 'processState' | 'processRetryAt'
>;

export type SingleActionInputKnowledgeBaseFragment =
  | SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_
  | SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_;

export const SingleActionInputKnowledgeBaseFragmentDoc = gql`
  fragment SingleActionInputKnowledgeBaseFragment on KnowledgeBase {
    ... on GoogleDocsKnowledgeBase {
      id
      title
    }
    ... on TextKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on ImageKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on MSDocsKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on PDFKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on MSExcelKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on MSPPTKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on OldMSDocsKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on CSVKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
    ... on HWPKnowledgeBase {
      id
      title
      processState
      processRetryAt
    }
  }
`;
