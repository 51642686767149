import { Link, LinkProps } from '@allganize/ui-link';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { DraftDecoratorComponentProps } from 'draft-js';
import { forwardRef, useContext } from 'react';
import { DraftLinkClasses, draftLinkClasses } from './draft-link-classes';
import { DraftLinkContext } from './draft-link-context';

export interface DraftLinkProps extends Omit<LinkProps, 'classes'> {
  classes?: Partial<DraftLinkClasses>;
}

export const DraftLink = forwardRef<
  HTMLAnchorElement,
  DraftDecoratorComponentProps & DraftLinkProps
>((props, ref) => {
  const {
    blockKey,
    classes,
    contentState,
    decoratedText,
    dir,
    end,
    entityKey,
    offsetKey,
    start,
    ...other
  } = props;
  const entity = entityKey ? contentState.getEntity(entityKey) : null;
  const entityData = entity?.getData();
  const href = entityData?.url || undefined;
  const target = useContext(DraftLinkContext).getLinkTarget(href);

  return (
    <Link
      css={css`
        word-break: break-all;
      `}
      data-testid="draft-link"
      href={href}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      target={target}
      {...other}
      ref={ref}
      className={clsx(draftLinkClasses.root, classes?.root, other.className)}
    />
  );
});
