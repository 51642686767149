import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Fragment, FunctionComponent } from 'react';
import { Button } from '@allganize/ui-button';
import { Text } from '@allganize/ui-text';
import { Divider } from '@allganize/ui-divider';
import {
  IcDescription,
  IcDocumentSearch,
  IcSendMessage,
  IcGavel,
} from '@allganize/ui-icons';
import { LLMAppFragment } from '../graphql/fragments/llm-app-fragment';
import { ConversationFragment } from '../graphql/fragments/conversation-fragment';

const LlmAppsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0 16px 16px 16px;
`;

export interface OtherSkillListProps {
  lastUserChatMessage: string;
  onClickOtherApp: (appName: string, lastUserChatMessage: string) => void;
  conversation: ConversationFragment | null;
  llmApps: LLMAppFragment[];
}

export const OtherSkillList: FunctionComponent<OtherSkillListProps> = props => {
  const { onClickOtherApp, lastUserChatMessage, conversation, llmApps } = props;

  return (
    <Fragment>
      <Text
        variant="body14"
        css={css`
          padding: 16px;
        `}
      >
        답변이 마음에 드셨나요? 해당 검색어로 다른 앱에서도 빠르게 탐색
        가능해요.
      </Text>

      <LlmAppsWrapper>
        {llmApps
          ?.filter(app => app.id !== conversation?.llmApp?.id)
          .map(app => {
            switch (app.name) {
              case '통합':
                return (
                  <Button
                    key={app.id}
                    color="secondary"
                    variant="outlined"
                    startIcon={<IcDocumentSearch />}
                    onClick={() =>
                      onClickOtherApp(app.name, lastUserChatMessage)
                    }
                  >
                    {app.name}
                  </Button>
                );
              case '법령':
                return (
                  <Button
                    key={app.id}
                    color="secondary"
                    variant="outlined"
                    startIcon={<IcDescription />}
                    onClick={() =>
                      onClickOtherApp(app.name, lastUserChatMessage)
                    }
                  >
                    {app.name}
                  </Button>
                );
              case '세법해석례':
                return (
                  <Button
                    key={app.id}
                    color="secondary"
                    variant="outlined"
                    startIcon={<IcSendMessage />}
                    onClick={() =>
                      onClickOtherApp(app.name, lastUserChatMessage)
                    }
                  >
                    {app.name}
                  </Button>
                );
              case '판례・결정례':
                return (
                  <Button
                    key={app.id}
                    color="secondary"
                    variant="outlined"
                    startIcon={<IcGavel />}
                    onClick={() =>
                      onClickOtherApp(app.name, lastUserChatMessage)
                    }
                  >
                    {app.name}
                  </Button>
                );
            }
          })}
      </LlmAppsWrapper>
      <Divider />
    </Fragment>
  );
};
