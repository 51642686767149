import { ChatSkeleton } from '@allganize/alli-sdk-chat';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

interface ChatListLoadingStateProps {
  count?: number;
}

export const ChatListLoadingState: FunctionComponent<
  ChatListLoadingStateProps
> = ({ count = 4 }) => {
  return Array.from({ length: count }).map((_, index) => {
    return (
      <li
        css={css`
          margin: 16px 0;
        `}
        key={index}
      >
        <ChatSkeleton />
      </li>
    );
  });
};
