import { Types } from '@allganize/alli-sdk-interfaces';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { css } from '@emotion/react';
import { Fragment, FunctionComponent, useMemo } from 'react';
import { getAgentSelectOption } from '../agent-select/agent-select-option';
import {
  ChatContentBubble,
  ChatContentBubbleProps,
} from '../chat-content-bubble/chat-content-bubble';
import { ChatContentBubbleClasses } from '../chat-content-bubble/chat-content-bubble-classes';
import {
  ChatContentRow,
  ChatContentRowProps,
} from '../chat-content-row/chat-content-row';
import { ChatFile } from '../chat-file/chat-file';
import { ChatForm } from '../chat-form/chat-form';
import { ChatMedia } from '../chat-media/chat-media';
import { ContactAgentByEmailForm } from '../contact-agent-by-email-chat/contact-agent-by-email-form';
import { DraftDocumentList } from '../draft-document-list/draft-document-list';
import { useDocumentEntities } from '../draft-document-list/use-document-entities';
import { FormValueForm } from '../form-value/form-value-form';
import { formValuesReducer } from '../form-value/use-form-value-form';
import { ChatAgentFragment } from '../graphql/fragments/chat-agent-fragment';
import { UserChatFragment } from '../graphql/fragments/user-chat-fragment';
import { RichText } from '../rich-text/rich-text';
import { useRichText } from '../rich-text/use-rich-text';
import { TimestampTooltip } from '../timestamp-tooltip/timestamp-tooltip';
import styled from '@emotion/styled';

export interface UserChatProps
  extends Partial<Pick<ChatContentRowProps, 'align' | 'classes'>>,
    Pick<ChatContentBubbleProps, 'color' | 'loading'> {
  assignee?: ChatAgentFragment | null;
  BubbleClasses?: Partial<ChatContentBubbleClasses>;
  chat: UserChatFragment;
  onContactAgentByEmailFormSubmit(
    values: Types.ContactAgentByEmailInput,
  ): Promise<any>;
  readOnly?: Partial<Record<Types.ChatInputType, boolean>> | boolean;
}

const RichTextWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  margin-right: -16px;
  container-type: inline-size;
  text-align: right;
`;

export const UserChat: FunctionComponent<UserChatProps> = ({
  assignee,
  BubbleClasses,
  chat,
  classes,
  color = 'default',
  loading,
  onContactAgentByEmailFormSubmit,
  readOnly,
}) => {
  const { value: message, onChange: onMessageChange } = useRichText({
    data: chat.messageContentState,
  });
  const { documentEntities } = useDocumentEntities({ data: message });
  const formValues = useMemo(
    () => (chat.formValues ?? []).reduce(formValuesReducer, []),
    [chat.formValues],
  );
  const hasFormValues = formValues && formValues.length > 0;
  const hasMedia = !!chat.media;
  const hasFile = !!chat.file;
  const hasMessage =
    !hasFormValues && !hasMedia && !hasFile && !isDraftInputEmpty(message);
  const hasDocumentEntities = documentEntities.length > 0;
  const hasContactAgentByEmailForm = chat.nextInputType.includes(
    'CONTACT_AGENT_BY_EMAIL_RICH_UX',
  );
  const contactAgentByEmailFormReadOnly =
    typeof readOnly === 'boolean'
      ? readOnly
      : readOnly?.CONTACT_AGENT_BY_EMAIL_RICH_UX;

  return (
    <Fragment>
      {hasMessage && (
        <ChatContentRow classes={classes}>
          <TimestampTooltip timestamp={chat.createdAt}>
            <ChatContentBubble
              css={css`
                padding: 16px;
                border: none;
              `}
              classes={BubbleClasses}
              color={color}
              loading={loading}
            >
              <RichTextWrapper>
                <RichText
                  value={message}
                  onChange={onMessageChange}
                  css={css`
                    grid-column: 3 / span 3;

                    @container (width < 380px) {
                      grid-column: 1 / span 5;
                      margin-right: 16px;
                      text-align: justify;
                    }
                  `}
                />
              </RichTextWrapper>
              {hasDocumentEntities && (
                <DraftDocumentList data={documentEntities} />
              )}
            </ChatContentBubble>
          </TimestampTooltip>
        </ChatContentRow>
      )}

      {chat.media && (
        <ChatContentRow
          css={
            hasMessage &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <TimestampTooltip timestamp={chat.createdAt}>
            <ChatContentBubble
              classes={BubbleClasses}
              color={color}
              loading={loading}
            >
              <ChatMedia media={chat.media} />
            </ChatContentBubble>
          </TimestampTooltip>
        </ChatContentRow>
      )}

      {chat.file && (
        <ChatContentRow
          css={
            (hasMessage || hasMedia) &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <TimestampTooltip timestamp={chat.createdAt}>
            <ChatContentBubble
              classes={BubbleClasses}
              color={color}
              loading={loading}
            >
              <ChatFile file={chat.file} />
            </ChatContentBubble>
          </TimestampTooltip>
        </ChatContentRow>
      )}

      {hasFormValues && (
        <ChatContentRow
          css={
            (hasMessage || hasMedia || hasFile) &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <ChatForm>
            <FormValueForm defaultValues={{ formValues }} readOnly />
          </ChatForm>
        </ChatContentRow>
      )}

      {hasContactAgentByEmailForm && !contactAgentByEmailFormReadOnly && (
        <ChatContentRow
          css={
            (hasMessage || hasMedia || hasFile || hasFormValues) &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <ChatForm>
            <ContactAgentByEmailForm
              defaultValues={{
                agent: assignee ? getAgentSelectOption(assignee) : null,
              }}
              onSubmit={onContactAgentByEmailFormSubmit}
              readOnly={contactAgentByEmailFormReadOnly}
            />
          </ChatForm>
        </ChatContentRow>
      )}
    </Fragment>
  );
};
