import { Types } from '@allganize/alli-sdk-interfaces';
import { useEventCallback } from '@allganize/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { ChatMediaFragment } from '../graphql/fragments/chat-media-fragment';
import { ChatOptionInfoFragment } from '../graphql/fragments/chat-option-info-fragment';
import { ChatOptionStyleFragment } from '../graphql/fragments/chat-option-style-fragment';

export interface ChatOptionInfoFormValue {
  readonly optionBoxTemplate: Types.OptionBoxTemplate | null;
  readonly reusable: boolean;
  value: number;
  text: string;
  url: string | null;
  media: ChatMediaFragment | null;
  style: ChatOptionStyleFragment;
}

export interface ChatOptionInfoFormValues {
  readonly multipleOption: boolean;
  chatOptionInfo: ChatOptionInfoFormValue | null;
  chatOptionInfos: ChatOptionInfoFormValue[];
}

const errorMessages = defineMessages({
  'chatOptionInfo-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  'chatOptionInfos-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  const chatMediaSchema = z.object({
    __typename: z.enum(['Media']),
    id: z.union([z.string(), z.number()]),
    mediaType: z.string().nullable(),
    metaInfo: z.string().nullable(),
    url: z.string(),
    width: z.number().nullable(),
    height: z.number().nullable(),
  });

  const chatOptionInfoFormValueSchema = z.object({
    optionBoxTemplate: z.string().nullable(),
    reusable: z.boolean(),
    value: z.number(),
    text: z.string(),
    url: z.string().nullable(),
    media: chatMediaSchema.nullable(),
  });

  return z
    .object({
      multipleOption: z.boolean(),
      chatOptionInfo: chatOptionInfoFormValueSchema.nullable(),
      chatOptionInfos: z.array(chatOptionInfoFormValueSchema),
    })
    .refine(
      schema => {
        if (!schema.multipleOption) {
          return true;
        }

        return schema.chatOptionInfos.length > 0;
      },
      {
        path: ['chatOptionInfos'],
        message: intl.formatMessage(errorMessages['chatOptionInfos-required']),
      },
    );
};

export interface UseChatOptionInfoFormOptions {
  chatOptionInfos?: Types.Maybe<ChatOptionInfoFragment>[];
  multipleOption?: boolean;
  onSubmit: SubmitHandler<ChatOptionInfoFormValues>;
}

export const useChatOptionInfoForm = ({
  chatOptionInfos,
  multipleOption = false,
  onSubmit,
}: UseChatOptionInfoFormOptions) => {
  const intl = useIntl();
  const validationSchema = useMemo(
    () => zodResolver(createValidationSchema(intl)),
    [intl],
  );

  const options = useMemo(
    () =>
      (chatOptionInfos ?? []).reduce<ChatOptionInfoFormValue[]>(
        (acc, curr, i) => {
          if (!curr) {
            return acc;
          }

          return [
            ...acc,
            {
              optionBoxTemplate: curr.optionBoxTemplate,
              reusable: curr.reusable ?? false,
              value: i,
              text: curr.longOption,
              url: curr.url || curr.urlVariable?.displayText || null,
              media: curr.media,
              style: curr.style,
            },
          ];
        },
        [],
      ),
    [chatOptionInfos],
  );

  const hasImageOption = useMemo(
    () => options.findIndex(opt => opt.media !== null) >= 0,
    [options],
  );

  const form = useForm<ChatOptionInfoFormValues>({
    mode: 'all',
    defaultValues: {
      multipleOption,
      chatOptionInfo: null,
      chatOptionInfos: [],
    },
    resolver: validationSchema,
  });

  const { reset, setError } = form;

  const submit = useEventCallback(async (values: ChatOptionInfoFormValues) => {
    try {
      await onSubmit?.(values);

      if (!values.multipleOption) {
        reset();
      }
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }
    }
  });

  return {
    form,
    hasImageOption,
    options,
    submit,
  };
};
