/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ProjectFragment } from '../fragments/project-fragment';
import { gql } from '@apollo/client';
import { ProjectFragmentDoc } from '../fragments/project-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProjectQuery = { __typename: 'UserQuery' } & {
  project: Types.Maybe<{ __typename: 'Project' } & ProjectFragment>;
};

export const ProjectQueryDocument = gql`
  query ProjectQuery {
    project {
      ...ProjectFragment
    }
  }
  ${ProjectFragmentDoc}
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectQueryDocument,
    options,
  );
}
export function useProjectQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectQuery,
    ProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectQueryDocument,
    options,
  );
}
export function useProjectQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectQuery,
    ProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectQuery, ProjectQueryVariables>(
    ProjectQueryDocument,
    options,
  );
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectQueryLazyQueryHookResult = ReturnType<
  typeof useProjectQueryLazyQuery
>;
export type ProjectQuerySuspenseQueryHookResult = ReturnType<
  typeof useProjectQuerySuspenseQuery
>;
export type ProjectQueryQueryResult = Apollo.QueryResult<
  ProjectQuery,
  ProjectQueryVariables
>;
