import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcSend } from '@allganize/ui-icons';
import { forwardRef } from 'react';

interface ChatFormSubmitButtonProps extends IconButtonProps {
  isSubmitting?: boolean;
}

export const ChatFormSubmitButton = forwardRef<
  HTMLButtonElement,
  ChatFormSubmitButtonProps
>(({ disabled, isSubmitting, ...other }, ref) => (
  <IconButton
    type="submit"
    color="primary"
    edge="end"
    disabled={disabled}
    size="large"
    {...other}
    ref={ref}
  >
    {isSubmitting ? <CircularProgress size="sm" color="inherit" /> : <IcSend />}
  </IconButton>
));
