/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationFragment } from '../fragments/conversation-fragment';
import { gql } from '@apollo/client';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversationsSubscriptionVariables = Types.Exact<{
  order?: Types.InputMaybe<Types.ConversationOrder>;
  before?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  after?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type ConversationsSubscription = { __typename: 'UserSubscription' } & {
  conversations: Types.Maybe<
    { __typename: 'ConversationEdge' } & Pick<
      Types.ConversationEdge,
      'cursor'
    > & {
        node: Types.Maybe<
          { __typename: 'Conversation' } & ConversationFragment
        >;
      }
  >;
};

export const ConversationsSubscriptionDocument = gql`
  subscription ConversationsSubscription(
    $order: ConversationOrder
    $before: ID
    $after: ID
  ) {
    conversations(order: $order, before: $before, after: $after) {
      node {
        ...ConversationFragment
      }
      cursor
    }
  }
  ${ConversationFragmentDoc}
`;

/**
 * __useConversationsSubscription__
 *
 * To run a query within a React component, call `useConversationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsSubscription({
 *   variables: {
 *      order: // value for 'order'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useConversationsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ConversationsSubscription,
    ConversationsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ConversationsSubscription,
    ConversationsSubscriptionVariables
  >(ConversationsSubscriptionDocument, options);
}
export type ConversationsSubscriptionHookResult = ReturnType<
  typeof useConversationsSubscription
>;
export type ConversationsSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ConversationsSubscription>;
