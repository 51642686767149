import { DraftLinkContext, draftImageClasses } from '@allganize/draft-input';
import { IcArrowExpandMore } from '@allganize/ui-icons';
import {
  ListItem,
  ListItemButton,
  ListItemCheckbox,
  ListItemText,
  ListItemTextProps,
  listItemButtonClasses,
  listItemTextClasses,
} from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent, useContext } from 'react';
import { ChatMedia } from '../chat-media/chat-media';
import {
  ChatOptionInfoClasses,
  chatOptionInfoClasses,
} from './chat-option-info-classes';
import { ChatOptionInfoFormValue } from './use-chat-option-info-form';

interface ChatOptionInfoProps {
  classes?: Partial<ChatOptionInfoClasses>;
  className?: string;
  control: React.ReactNode;
  data: ChatOptionInfoFormValue;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readOnly?: boolean;
  textProps?: Omit<ListItemTextProps, 'ref'>;
}

export const ChatOptionInfo: FunctionComponent<ChatOptionInfoProps> = ({
  classes,
  className,
  control,
  data,
  onBlur,
  onClick,
  readOnly,
  textProps,
}) => {
  const theme = useTheme();
  const { getLinkTarget } = useContext(DraftLinkContext);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    if (data.url) {
      const target = getLinkTarget(data.url);

      window.open(
        data.url,
        target,
        target === '_blank' ? 'noopener,noreferrer' : undefined,
      );
    }

    onClick?.(ev);
  };

  return (
    <ListItem
      css={[
        css`
          display: inline-flex;
          flex-direction: column;
          align-items: stretch;
          border-radius: ${theme.radius.md}px;
          border: 1px solid ${data.style.lineColor};
          overflow: hidden;
          width: auto;
          max-width: 480px;
        `,
        readOnly &&
          css`
            border-color: ${theme.palette.action.disabledBackground};
          `,
      ]}
      component="div"
      disablePadding
      className={clsx(chatOptionInfoClasses.root, classes?.root, className)}
    >
      {data.media && (
        <ChatMedia
          css={css`
            .${draftImageClasses.root} {
              border: 0;
              border-radius: 0;
            }
          `}
          media={data.media}
          isOptionType
        />
      )}

      <ListItemButton
        css={[
          css`
            padding: 7px 15px;
            align-items: flex-start;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: ${data.style.fontColor};
              opacity: 0;
            }

            &,
            &:hover,
            &:active {
              background-color: ${data.style.bgColor};
            }

            &:hover::before {
              opacity: 0.1;
            }

            &:active::before {
              opacity: 0.2;
            }
          `,
        ]}
        role={undefined}
        onClick={handleClick}
        onBlur={onBlur}
        disabled={readOnly}
        className={clsx(chatOptionInfoClasses.button, classes?.button)}
      >
        <ListItemCheckbox
          className={clsx(chatOptionInfoClasses.checkbox, classes?.checkbox)}
        >
          {control}
        </ListItemCheckbox>

        <ListItemText
          primary={data.text}
          {...textProps}
          primaryTextProps={{
            variant:
              data.optionBoxTemplate === 'NEXT_PAGE' ? 'title14' : undefined,
            ...textProps?.primaryTextProps,
          }}
          css={[
            css`
              word-break: break-word;
              color: ${readOnly
                ? theme.palette.text.disabled
                : data.style.fontColor};
            `,
            data.style.italic &&
              css`
                font-style: italic;
              `,
            data.style.underline &&
              css`
                text-decoration: underline;
              `,
            data.style.bold &&
              css`
                .${listItemTextClasses.primary} {
                  font-weight: ${theme.typography.fontWeightBold};
                }
              `,
          ]}
        />

        {data.optionBoxTemplate === 'NEXT_PAGE' && (
          <div
            css={css`
              margin-left: 4px;
              padding: 4px 0;
            `}
          >
            <IcArrowExpandMore
              css={css`
                .${listItemButtonClasses.disabled} & {
                  color: ${theme.palette.text.disabled};
                }
              `}
            />
          </div>
        )}
      </ListItemButton>
    </ListItem>
  );
};
