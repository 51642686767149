import generateUtilityClasses from '@mui/material/generateUtilityClasses';
import { SimpleBarOptions } from 'simplebar-core';

export type ScrollerClassKey =
  | 'root'
  | 'scrollableNode'
  | 'content'
  | 'heightAutoObserverWrapper'
  | 'heightAutoObserver'
  | keyof Omit<
      NonNullable<SimpleBarOptions['classNames']>,
      'contentEl' | 'heightAutoObserverWrapperEl' | 'heightAutoObserverEl'
    >;
export type ScrollerClasses = Record<ScrollerClassKey, string>;

export const scrollerClasses: ScrollerClasses = {
  ...generateUtilityClasses('Scroller', [
    'root',
    'content',
    'contentWrapper',
    'scrollableNode',
    'offset',
    'mask',
    'wrapper',
    'placeholder',
    'scrollbar',
    'track',
    'heightAutoObserverWrapper',
    'heightAutoObserver',
    'visible',
    'hover',
    'dragging',
    'scrolling',
    'scrollable',
    'mouseEntered',
  ]),
  horizontal: 'simplebar-horizontal',
  vertical: 'simplebar-vertical',
};
