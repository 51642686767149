export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Chat: [
      'AgentChat',
      'BotChat',
      'CarouselChat',
      'ContactAgentByEmailChat',
      'CustomUIChat',
      'EventChat',
      'FillSlotRichUXChat',
      'SendFormChat',
      'UserChat',
    ],
    ChatNode: [
      'AgentChatNode',
      'AssignVariableChatNode',
      'ConditionChatNode',
      'CustomUIChatNode',
      'ExportVariablesChatNode',
      'ExternalDataAdvancedChatNode',
      'ExternalDataChatNode',
      'FAQChatNode',
      'FillSlotsChatNode',
      'GenerativeAnswerChatNode',
      'GotoChatNode',
      'ImageMessageChatNode',
      'IntentChatNode',
      'InternalChatNode',
      'JumpPointChatNode',
      'LLMChatNode',
      'LLMInputChatNode',
      'MRCChatNode',
      'ObserverChatNode',
      'SendFormChatNode',
      'StartChatNode',
      'TextMessageChatNode',
    ],
    KnowledgeBase: [
      'CSVKnowledgeBase',
      'GoogleDocsKnowledgeBase',
      'HWPKnowledgeBase',
      'ImageKnowledgeBase',
      'MSDocsKnowledgeBase',
      'MSExcelKnowledgeBase',
      'MSPPTKnowledgeBase',
      'OldMSDocsKnowledgeBase',
      'PDFKnowledgeBase',
      'TextKnowledgeBase',
    ],
    KnowledgeBaseLLMTaskProgress: [
      'KnowledgeBaseLLMTaskCustomProgress',
      'KnowledgeBaseLLMTaskQNAProgress',
      'KnowledgeBaseLLMTaskSummaryProgress',
    ],
    Node: [
      'Agent',
      'AutoHashtag',
      'Campaign',
      'ClusterInfo',
      'Conversation',
      'ConversationCategory',
      'DocSourceSchedule',
      'DocSourceScheduleHistory',
      'EntityDefinition',
      'EntityExtractionBox',
      'EntityExtractionModel',
      'EntityExtractionResult',
      'EntityExtractionSet',
      'FAQ',
      'FAQAutoHashtag',
      'FAQCandidate',
      'FAQCandidateAutoHashtag',
      'File',
      'Folder',
      'KnowledgeBaseAutoHashtag',
      'KnowledgeBaseAutoHashtagEntity',
      'KnowledgeBaseLLMTaskInfo',
      'LLMApp',
      'LLMPrompt',
      'LLMPromptDynamicInput',
      'LLMPromptGroup',
      'Media',
      'PermissionGroup',
      'Placement',
      'Project',
      'ProjectSlackServiceConnection',
      'ProjectTeamsServiceConnection',
      'SavedMessage',
      'SingleActionApp',
      'SingleActionAppDocumentMapReduceResult',
      'SingleActionAppResult',
      'Target',
      'User',
      'Variable',
    ],
    PaymentMethod: ['PaymentCard'],
    UserFeedbackResult: ['FAQUserFeedbackResult', 'MRCUserFeedbackResult'],
  },
};
export default result;
