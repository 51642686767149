import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiFormLabel from '@mui/material/FormLabel';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { forwardRef } from 'react';
import { formLabelClasses } from './form-label-classes';
import { FormLabelTypeMap } from './form-label-type-map';

// @ts-expect-error overridable component
export const FormLabel: OverridableComponent<FormLabelTypeMap> = forwardRef(
  (props, ref) => {
    const { classes, ...other } = props;
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiFormLabel
            data-testid="form-label"
            {...other}
            ref={ref}
            classes={{
              ...classes,
              root: cx(
                css`
                  ${theme.typography.title12}
                  line-height: 20px;
                  margin-bottom: 8px;

                  &,
                  &.${formLabelClasses.focused}, &.${formLabelClasses.error} {
                    color: ${theme.palette.text.primary};
                  }

                  &.${formLabelClasses.disabled} {
                    color: ${theme.palette.text.disabled};
                  }
                `,
                classes?.root,
              ),
              asterisk: cx(
                css`
                  color: ${theme.palette.error.main};
                `,
                classes?.asterisk,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
