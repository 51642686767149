import { CSSObject } from '@emotion/react';
import {
  FontStyle,
  FontStyleOptions,
  TypographyUtils,
} from '@mui/material/styles/createTypography';
import deepmerge from '@mui/utils/deepmerge';
import { Palette } from '../palette/palette';

const round = (value: number) => Math.round(value * 1e5) / 1e5;

export type Variant =
  | 'title24'
  | 'title18'
  | 'title16'
  | 'title14'
  | 'title12'
  | 'subtitle16'
  | 'subtitle14'
  | 'subtitle12'
  | 'body16'
  | 'body14'
  | 'body12'
  | 'label14'
  | 'label12';
export type TypographyStyle = CSSObject;
export interface TypographyStyleOptions extends TypographyStyle {}

export interface Typography
  extends Record<Variant, TypographyStyle>,
    FontStyle,
    TypographyUtils {}

export interface TypographyOptions
  extends Partial<
    Record<Variant, TypographyStyleOptions> & FontStyleOptions & TypographyUtils
  > {}

const defaultFontFamily =
  '"Noto Sans JP Variable", "Noto Sans JP", "Noto Sans KR", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif';

export const createTypography = (
  palette: Palette,
  typography: TypographyOptions | ((palette: Palette) => TypographyOptions),
): Typography => {
  const {
    fontFamily = defaultFontFamily,
    fontSize = 14,
    fontWeightLight = 300,
    fontWeightRegular = 400,
    fontWeightMedium = 500,
    fontWeightBold = 700,
    htmlFontSize = 16,
    allVariants,
    pxToRem: pxToRem2,
    ...other
  } = typeof typography === 'function' ? typography(palette) : typography;

  const coef = fontSize / 14;
  const pxToRem =
    pxToRem2 || ((size: number) => `${(size / htmlFontSize) * coef}rem`);

  const buildVariant = (
    fontWeight: TypographyStyle['fontWeight'],
    size: number,
    lineHeight: TypographyStyle['lineHeight'],
    letterSpacing: number,
  ): TypographyStyle => ({
    fontFamily,
    fontWeight,
    fontSize: pxToRem(size),
    lineHeight,
    letterSpacing: `${round(letterSpacing / size)}em`,
    ...allVariants,
  });

  const variants = {
    title24: buildVariant(fontWeightBold, 24, 28 / 24, 0),
    title18: buildVariant(fontWeightBold, 18, 28 / 18, 0),
    title16: buildVariant(fontWeightBold, 16, 24 / 16, 0),
    title14: buildVariant(fontWeightBold, 14, 20 / 14, 0),
    title12: buildVariant(fontWeightBold, 12, 18 / 12, 0),
    subtitle16: buildVariant(fontWeightMedium, 16, 24 / 16, -0.2),
    subtitle14: buildVariant(fontWeightMedium, 14, 20 / 14, 0),
    subtitle12: buildVariant(fontWeightMedium, 12, 18 / 12, 0),
    body16: buildVariant(fontWeightRegular, 16, 24 / 16, -0.2),
    body14: buildVariant(fontWeightRegular, 14, 20 / 14, 0),
    body12: buildVariant(fontWeightMedium, 12, 18 / 12, 0),
    label14: buildVariant(fontWeightMedium, 14, 20 / 14, 0),
    label12: buildVariant(fontWeightMedium, 12, 18 / 12, 0),
    inherit: {
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
    },
  };

  return deepmerge(
    {
      htmlFontSize,
      pxToRem,
      fontFamily,
      fontSize,
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightBold,
      ...variants,
    },
    other,
    { clone: false },
  );
};
