import {
  ClearWaitingQueueParams,
  Id,
  ToastContent,
  ToastOptions as ToastifyOptions,
  UpdateOptions as ToastifyUpdateOptions,
  toast as toastify,
} from 'react-toastify';
import { IdOpts, OnChangeCallback } from 'react-toastify/dist/types';
import { ToastBody } from './toast-body/toast-body';

export interface ToastOptions
  extends Omit<
    ToastifyOptions,
    | 'closeButton'
    | 'closeOnClick'
    | 'data'
    | 'draggable'
    | 'draggableDirection'
    | 'draggablePercent'
    | 'hideProgressBar'
    | 'isLoading'
    | 'progress'
    | 'rtl'
    | 'theme'
  > {
  action?: ToastContent;
}

interface RemoveParams {
  id?: Id;
  containerId: Id;
}

interface UpdateOptions<T = unknown> extends ToastifyUpdateOptions<T> {
  action?: ToastContent<T>;
}

interface Toast {
  <TData = unknown>(content: ToastContent<TData>, options?: ToastOptions): Id;
  success<TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions,
  ): Id;
  error<TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions,
  ): Id;
  warning<TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions,
  ): Id;
  dismiss: {
    (id?: Id): void;
    (params: RemoveParams): void;
  };
  clearWaitingQueue(params?: ClearWaitingQueueParams): void;
  isActive(id: Id, containerId?: Id): boolean;
  update<TData = unknown>(toastId: Id, options?: UpdateOptions<TData>): void;
  done(id: Id): void;
  onChange(callback: OnChangeCallback): () => void;
  play(opts?: IdOpts): void;
  pause(opts?: IdOpts): void;
}

export const toast: Toast = ((): Toast => {
  const result = function <TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify<TData>(
      props => (
        <ToastBody
          type={props.toastProps.type}
          theme={props.toastProps.theme}
          onClose={props.closeToast}
          action={
            typeof options?.action === 'function'
              ? options.action(props)
              : options?.action
          }
        >
          {typeof content === 'function' ? content(props) : content}
        </ToastBody>
      ),
      options,
    );
  };

  result.success = function <TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify.success<TData>(
      props => (
        <ToastBody
          type={props.toastProps.type}
          theme={props.toastProps.theme}
          onClose={props.closeToast}
          action={
            typeof options?.action === 'function'
              ? options.action(props)
              : options?.action
          }
        >
          {typeof content === 'function' ? content(props) : content}
        </ToastBody>
      ),
      options,
    );
  };

  result.error = function <TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify.error<TData>(
      props => (
        <ToastBody
          type={props.toastProps.type}
          theme={props.toastProps.theme}
          onClose={props.closeToast}
          action={
            typeof options?.action === 'function'
              ? options.action(props)
              : options?.action
          }
        >
          {typeof content === 'function' ? content(props) : content}
        </ToastBody>
      ),
      options,
    );
  };

  result.warning = function <TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions,
  ): Id {
    return toastify.warning<TData>(
      props => (
        <ToastBody
          type={props.toastProps.type}
          theme={props.toastProps.theme}
          onClose={props.closeToast}
          action={
            typeof options?.action === 'function'
              ? options.action(props)
              : options?.action
          }
        >
          {typeof content === 'function' ? content(props) : content}
        </ToastBody>
      ),
      options,
    );
  };

  result.dismiss = toastify.dismiss;
  result.clearWaitingQueue = toastify.clearWaitingQueue;
  result.isActive = toastify.isActive;

  result.update = function <TData = unknown>(
    toastId: Id,
    options?: UpdateOptions<TData>,
  ) {
    return toastify.update(toastId, {
      ...options,
      render: options?.render
        ? props => (
            <ToastBody
              type={props.toastProps.type}
              theme={props.toastProps.theme}
              onClose={props.closeToast}
              action={
                typeof options?.action === 'function'
                  ? options.action(props)
                  : options?.action
              }
            >
              {typeof options.render === 'function'
                ? options.render(props)
                : options.render}
            </ToastBody>
          )
        : options?.render,
    });
  };

  result.done = toastify.done;
  result.onChange = toastify.onChange;
  result.play = toastify.play;
  result.pause = toastify.pause;
  return result;
})();
