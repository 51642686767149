import { Variant } from '@allganize/ui-theme';
import {
  getTypographyUtilityClass,
  TypographyClassKey,
  typographyClasses,
} from '@mui/material/Typography';
import { Variant as MuiVariant } from '@mui/material/styles/createTypography';
import { omit } from 'lodash-es';

export type TextClassKey = Exclude<TypographyClassKey, MuiVariant> | Variant;
export type TextClasses = Record<TextClassKey, string>;

export const textClasses: TextClasses = {
  ...omit(typographyClasses, [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    // 'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline',
  ]),
  title24: getTypographyUtilityClass('title24'),
  title18: getTypographyUtilityClass('title18'),
  // title20: getTypographyUtilityClass('title20'),
  title16: getTypographyUtilityClass('title16'),
  title14: getTypographyUtilityClass('title14'),
  title12: getTypographyUtilityClass('title12'),
  subtitle16: getTypographyUtilityClass('subtitle16'),
  subtitle14: getTypographyUtilityClass('subtitle14'),
  subtitle12: getTypographyUtilityClass('subtitle12'),
  body16: getTypographyUtilityClass('body16'),
  body14: getTypographyUtilityClass('body14'),
  body12: getTypographyUtilityClass('body12'),
  label14: getTypographyUtilityClass('label14'),
  label12: getTypographyUtilityClass('label12'),
};
