import { Divider, dividerClasses } from '@allganize/ui-divider';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { Fragment, FunctionComponent } from 'react';
import { draftDocumentListClasses } from './draft-document-list-classes';
import { DraftDocumentListItem } from './draft-document-list-item';
import { DraftDocumentListProps } from './draft-document-list-type-map';

export const DraftDocumentList: FunctionComponent<
  DraftDocumentListProps
> = props => {
  const { classes, data, ...other } = props;

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <div
        data-testid="draft-document-list"
        css={css`
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 16px;
          padding-right: 16px;
          gap: 8px;

          > .${draftDocumentListClasses.document} {
            /* padding: 4px; */
          }
        `}
        {...other}
        className={clsx(
          draftDocumentListClasses.root,
          classes?.root,
          other.className,
        )}
      >
        {data.map(entity => {
          return (
            <div
              key={entity.key}
              css={css`
                max-width: 100%;
              `}
              className={clsx(
                draftDocumentListClasses.document,
                classes?.document,
              )}
            >
              <DraftDocumentListItem
                css={css`
                  max-width: 100%;
                `}
                entity={entity}
              />
            </div>
          );
        })}
      </div>
      <div
        css={css`
          padding: 0 16px;
          .${dividerClasses.wrapper} {
            margin: 16px 0;
          }
        `}
      >
        <Divider
          css={css`
            width: 100%;
            margin-top: 16px;
          `}
        />
      </div>
    </Fragment>
  );
};
