import { informationIconMap } from '@allganize/alli-app-launcher-icons';
import { ButtonBase } from '@allganize/ui-button';
import { Truncate } from '@allganize/truncate';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

import { recommendedAppClasses } from './recommended-app-classes';
import { RecommendedAppProps } from './recommended-app-type-map';

export const RecommendedApp: FunctionComponent<RecommendedAppProps> = ({
  classes,
  data,
  onClick,
  ...other
}) => {
  const theme = useTheme();
  const { icon, name, description } = data;
  const Icon = icon && (informationIconMap as any)[icon];

  return (
    <ButtonBase
      data-testid="recommended-app"
      css={css`
        width: 184px;
        height: 160px;
        padding: 24px 12px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        border: 1px solid ${theme.palette.unstable_border.divider.default};
        border-radius: ${theme.radius.sm}px;
        background-color: ${theme.palette.unstable_background.white};
      `}
      onClick={onClick}
      {...other}
      className={clsx(
        recommendedAppClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <div
        css={css`
          width: 20px;
          height: 20px;
          color: ${theme.palette.unstable_foreground.primary.default};
        `}
        className={clsx(
          recommendedAppClasses.icon,
          classes?.icon,
          other.className,
        )}
      >
        {Icon && <Icon />}
      </div>
      <Truncate clamp={2}>
        <Text
          variant="title14"
          className={clsx(
            recommendedAppClasses.title,
            classes?.title,
            other.className,
          )}
        >
          {name}
        </Text>
      </Truncate>
      <Truncate clamp={2}>
        <Text
          variant="body12"
          css={css`
            color: ${theme.palette.unstable_foreground.secondary};
          `}
          className={clsx(
            recommendedAppClasses.description,
            classes?.description,
            other.className,
          )}
        >
          {description}
        </Text>
      </Truncate>
    </ButtonBase>
  );
};
