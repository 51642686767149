import { ContentBlock, ContentState, DraftDecorator } from 'draft-js';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import { DraftLinkPluginOptions } from '../draft-link-plugin';
import { DraftPlugin } from '../draft-plugin/draft-plugin';
import { DraftAutoLink, DraftAutoLinkProps } from './draft-auto-link';

export interface DraftAutoLinkPluginOptions extends DraftLinkPluginOptions {}

export class DraftAutoLinkPlugin extends DraftPlugin {
  private static linkify = linkifyIt().tlds(tlds);

  private static extractLinks(text: string) {
    return DraftAutoLinkPlugin.linkify.match(text);
  }

  private static strategy(
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) {
    const links = DraftAutoLinkPlugin.extractLinks(block.getText());

    if (links) {
      for (const link of links) {
        callback(link.index, link.lastIndex);
      }
    }
  }

  constructor(private options: DraftAutoLinkPluginOptions = {}) {
    super();
  }

  public getDecorators(): DraftDecorator<DraftAutoLinkProps>[] | null {
    return [
      {
        strategy: DraftAutoLinkPlugin.strategy,
        component: DraftAutoLink,
        props: {
          classes: this.options.classes,
          extractor: DraftAutoLinkPlugin.extractLinks,
        },
      },
    ];
  }
}
