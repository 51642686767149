import { FunctionComponent } from 'react';
import { ProjectContext } from './contexts/project-context';
import {
  ProjectQuery,
  ProjectQueryVariables,
  useProjectQuerySuspenseQuery,
} from './graphql/project-query';
import { SuspenseQueryHookOptions } from '@apollo/client/react';

interface ProjectProviderProps
  extends SuspenseQueryHookOptions<ProjectQuery, ProjectQueryVariables> {
  children?: React.ReactNode;
}

export const ProjectProvider: FunctionComponent<
  ProjectProviderProps
> = props => {
  const { children } = props;
  const { data } = useProjectQuerySuspenseQuery(props);

  if (!data.project) {
    throw new Error('Project not found');
  }

  return (
    <ProjectContext.Provider value={data.project}>
      {children}
    </ProjectContext.Provider>
  );
};
