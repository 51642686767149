import { IconButton } from '@allganize/ui-button';
import { IcRefresh } from '@allganize/ui-icons';
import { toast } from '@allganize/ui-toast';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAlliClient } from '../alli-client/use-alli-client';
import { ChatListContext } from '../chat-list/chat-list-context';
import { ConversationContext } from '../conversation-detail/conversation-context';
import { DocumentPreviewContext } from '../document-preview/document-preview-context';
import { ChatFragment } from '../graphql/fragments/chat-fragment';
import { SendChatMutationVariables } from '../graphql/mutations/send-chat-mutation';
import { getMallyErrorMessageDescriptor } from '../i18n/mally-errors';
import { generateDocumentPreview } from './use-auto-open-document-preview';

interface SendChatOptions {
  customNavigateFunc?: NavigateFunction;
}

export const useSendChat = (opt?: SendChatOptions) => {
  const client = useAlliClient();
  const intl = useIntl();
  const navigateOrig = useNavigate();
  const navigate = opt?.customNavigateFunc ?? navigateOrig;
  const {
    data: { conversation },
  } = useContext(ConversationContext);
  const {
    appendChatEdges,
    data: chatData,
    refetch,
    setOptimisticChats,
  } = useContext(ChatListContext);
  const { documentPreviewState, openDocumentPreview } = useContext(
    DocumentPreviewContext,
  );
  const isTry = conversation?.isTry;
  const lastChatId = chatData?.chats?.edges
    ? chatData.chats.edges[chatData.chats.edges.length - 1]?.node?.id
    : undefined;

  const sendChat = async (
    variables: SendChatMutationVariables,
    optimisticChats: ChatFragment[] = [],
  ) => {
    setOptimisticChats(optimisticChats);

    try {
      if (isTry) {
        const result = await client.trySendChat({
          lastChatId,
          ...variables,
        });
        setOptimisticChats([]);

        const reloadConversation = result?.data?.trySendChat?.errors?.find(
          err => err?.key === 'RELOAD_CONVERSATION',
        );

        if (reloadConversation) {
          const descriptor = getMallyErrorMessageDescriptor(reloadConversation);

          toast.error(intl.formatMessage(descriptor, descriptor.values), {
            autoClose: false,
            action: props => (
              <IconButton
                onClick={() => {
                  refetch();
                  props.closeToast?.();
                }}
              >
                <IcRefresh />
              </IconButton>
            ),
          });
        }

        if (
          result?.data?.trySendChat?.responses &&
          result.data.trySendChat.responses.length > 0
        ) {
          appendChatEdges(
            result.data.trySendChat.responses.map(chat =>
              chat
                ? {
                    __typename: 'ChatEdge',
                    cursor: chat.id.toString(),
                    node: chat,
                  }
                : chat,
            ),
          );
        }

        if (
          result?.data?.trySendChat?.conversation &&
          result.data.trySendChat.conversation.id !== variables.conversationId
        ) {
          const documentPreview = result.data.trySendChat.responses?.reduce<
            ReturnType<typeof generateDocumentPreview>
          >((acc, chat) => {
            if (acc) {
              return acc;
            }

            const options = generateDocumentPreview({
              chat,
              documentPreviewState,
              fallbackText: intl.formatMessage(
                {
                  id: 'preview',
                  defaultMessage:
                    '{count, plural, one {Preview} other {Previews}}',
                  description: 'Document preview title missing fallback',
                },
                { count: 1 },
              ),
            });

            return options;
          }, null);

          if (documentPreview) {
            openDocumentPreview({
              ...documentPreview,
              trigger: 'auto',
            });
          }

          navigate(
            `/conversations/${result.data.trySendChat.conversation.id}`,
            { replace: true },
          );
        }

        return result;
      }

      const result = await client.sendChat({
        lastChatId,
        ...variables,
      });
      setOptimisticChats([]);

      const reloadConversation = result?.data?.sendChat?.errors?.find(
        err => err?.key === 'RELOAD_CONVERSATION',
      );

      if (reloadConversation) {
        const descriptor = getMallyErrorMessageDescriptor(reloadConversation);

        toast.error(intl.formatMessage(descriptor, descriptor.values), {
          autoClose: false,
          action: props => (
            <IconButton
              onClick={() => {
                refetch();
                props.closeToast?.();
              }}
            >
              <IcRefresh />
            </IconButton>
          ),
        });
      }

      if (
        result?.data?.sendChat?.responses &&
        result.data.sendChat.responses.length > 0
      ) {
        appendChatEdges(
          result.data.sendChat.responses.map(chat =>
            chat
              ? {
                  __typename: 'ChatEdge',
                  cursor: chat.id.toString(),
                  node: chat,
                }
              : chat,
          ),
        );
      }

      return result;
    } catch (err) {
      setOptimisticChats([]);
      throw err;
    }
  };

  return {
    sendChat,
  };
};
