/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ChatAgentFragment = { __typename: 'Agent' } & Pick<
  Types.Agent,
  'id' | 'firstName' | 'lastName' | 'displayName'
> & {
    avatar: Types.Maybe<
      { __typename: 'Media' } & Pick<Types.Media, 'id' | 'url'>
    >;
  };

export const ChatAgentFragmentDoc = gql`
  fragment ChatAgentFragment on Agent {
    id
    firstName
    lastName
    displayName
    avatar {
      id
      url
    }
  }
`;
