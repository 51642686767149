/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { SDKSettingsFragment } from './sdk-settings-fragment';
import { SDKUrlPatternFragment } from './sdk-url-pattern-fragment';
import { LLMAppFragment } from './llm-app-fragment';
import { gql } from '@apollo/client';
import { SDKSettingsFragmentDoc } from './sdk-settings-fragment';
import { SDKUrlPatternFragmentDoc } from './sdk-url-pattern-fragment';
import { LLMAppFragmentDoc } from './llm-app-fragment';
export type ProjectFragment = { __typename: 'Project' } & Pick<
  Types.Project,
  | 'id'
  | 'enabledAddOns'
  | 'apiKey'
  | 'usageType'
  | 'useAppLauncherNavigationBar'
  | 'useAppLauncherInSdk'
> & {
    sdkSettings: Types.Maybe<
      { __typename: 'SDKSettings' } & SDKSettingsFragment
    >;
    sdkUrlPatterns: Types.Maybe<
      Array<
        Types.Maybe<{ __typename: 'SDKUrlPattern' } & SDKUrlPatternFragment>
      >
    >;
    assistantEntryLlmApp: Types.Maybe<
      { __typename: 'LLMApp' } & LLMAppFragment
    >;
  };

export const ProjectFragmentDoc = gql`
  fragment ProjectFragment on Project {
    id
    enabledAddOns
    apiKey
    usageType
    useAppLauncherNavigationBar
    useAppLauncherInSdk
    sdkSettings {
      ...SDKSettingsFragment
    }
    sdkUrlPatterns {
      ...SDKUrlPatternFragment
    }
    assistantEntryLlmApp {
      ...LLMAppFragment
    }
  }
  ${SDKSettingsFragmentDoc}
  ${SDKUrlPatternFragmentDoc}
  ${LLMAppFragmentDoc}
`;
