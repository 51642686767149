/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { SingleActionAppFragment } from '../fragments/single-action-app-fragment';
import { gql } from '@apollo/client';
import { SingleActionAppFragmentDoc } from '../fragments/single-action-app-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleActionAppFromTokenQueryVariables = UserQueryTypes.Exact<{
  token: UserQueryTypes.Scalars['String']['input'];
}>;

export type SingleActionAppFromTokenQuery = { __typename: 'UserQuery' } & {
  singleActionAppFromToken: UserQueryTypes.Maybe<
    { __typename: 'SingleActionApp' } & SingleActionAppFragment
  >;
};

export const SingleActionAppFromTokenQueryDocument = gql`
  query SingleActionAppFromTokenQuery($token: String!) {
    singleActionAppFromToken(token: $token) {
      ...SingleActionAppFragment
    }
  }
  ${SingleActionAppFragmentDoc}
`;

/**
 * __useSingleActionAppFromTokenQuery__
 *
 * To run a query within a React component, call `useSingleActionAppFromTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleActionAppFromTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleActionAppFromTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSingleActionAppFromTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    SingleActionAppFromTokenQuery,
    SingleActionAppFromTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SingleActionAppFromTokenQuery,
    SingleActionAppFromTokenQueryVariables
  >(SingleActionAppFromTokenQueryDocument, options);
}
export function useSingleActionAppFromTokenQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SingleActionAppFromTokenQuery,
    SingleActionAppFromTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SingleActionAppFromTokenQuery,
    SingleActionAppFromTokenQueryVariables
  >(SingleActionAppFromTokenQueryDocument, options);
}
export function useSingleActionAppFromTokenQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SingleActionAppFromTokenQuery,
    SingleActionAppFromTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SingleActionAppFromTokenQuery,
    SingleActionAppFromTokenQueryVariables
  >(SingleActionAppFromTokenQueryDocument, options);
}
export type SingleActionAppFromTokenQueryHookResult = ReturnType<
  typeof useSingleActionAppFromTokenQuery
>;
export type SingleActionAppFromTokenQueryLazyQueryHookResult = ReturnType<
  typeof useSingleActionAppFromTokenQueryLazyQuery
>;
export type SingleActionAppFromTokenQuerySuspenseQueryHookResult = ReturnType<
  typeof useSingleActionAppFromTokenQuerySuspenseQuery
>;
export type SingleActionAppFromTokenQueryQueryResult = Apollo.QueryResult<
  SingleActionAppFromTokenQuery,
  SingleActionAppFromTokenQueryVariables
>;
