import {
  DraftInput,
  draftInputClasses,
  draftInputInternalClasses,
} from '@allganize/draft-input';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import { DocumentViewerPageWrapper } from '../document-viewer-page-wrapper';
import { draftPageClasses } from './draft-page-classes';
import { DraftPageProps } from './draft-page-type-map';

export const DraftPage = forwardRef<HTMLDivElement, DraftPageProps>(
  (props, ref) => {
    const { classes, page, plugins, ...other } = props;
    const theme = useTheme();
    const [editorState, setEditorState] = useState(page.editorState);

    useEffect(() => {
      setEditorState(page.editorState);
    }, [page.editorState]);

    return (
      <DocumentViewerPageWrapper
        data-testid="draft-page"
        {...other}
        ref={ref}
        className={clsx(draftPageClasses.root, classes?.root, other.className)}
      >
        <DraftInput
          css={css`
            .${draftInputInternalClasses.content} {
              border: 0;
              padding: 12px 16px;
            }

            &.${draftInputClasses.disabled} {
              .${draftInputInternalClasses.content} {
                color: ${theme.palette.text.primary};
                background-color: transparent;
              }
            }
          `}
          disabled
          plugins={plugins}
          value={editorState}
          onChange={setEditorState}
          className={clsx(draftPageClasses.input, classes?.input)}
        />
      </DocumentViewerPageWrapper>
    );
  },
);
