import { FunctionComponent } from 'react';

import { CarouselOptionFragment } from '../graphql/fragments/carousel-option-fragment';
import { RecommendationCarousel } from '../recommendation-carousel/recommendation-carousel';
import { RecommendationCarouselProps } from '../recommendation-carousel/recommendation-carousel-type-map';
import { RecommendationCarouselItemWrapper } from '../recommendation-carousel/recommendation-carousel-item-wrapper';
import { RecommendedItemProps } from './recommended-item-type-map';
import { RecommendedItem } from './recommended-item';

interface RecommendedItemCarouselProps extends RecommendationCarouselProps {
  data: CarouselOptionFragment[];
  onItemClick?: RecommendedItemProps['onClick'];
}

export const RecommendedItemCarousel: FunctionComponent<
  RecommendedItemCarouselProps
> = ({ data, onItemClick, ...other }) => {
  return (
    <RecommendationCarousel {...other}>
      {data.map(item => (
        <RecommendationCarouselItemWrapper key={item.id}>
          <RecommendedItem data={item} onClick={onItemClick} />
        </RecommendationCarouselItemWrapper>
      ))}
    </RecommendationCarousel>
  );
};
