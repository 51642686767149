/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { SingleActionInputFragment } from './single-action-input-fragment';
import { gql } from '@apollo/client';
import { SingleActionInputFragmentDoc } from './single-action-input-fragment';
export type SingleActionAppFragment = { __typename: 'SingleActionApp' } & Pick<
  UserQueryTypes.SingleActionApp,
  'id' | 'name' | 'description'
> & {
    singleActionInputs: UserQueryTypes.Maybe<
      Array<{ __typename: 'SingleActionInput' } & SingleActionInputFragment>
    >;
    project: { __typename: 'Project' } & Pick<UserQueryTypes.Project, 'id'>;
  };

export const SingleActionAppFragmentDoc = gql`
  fragment SingleActionAppFragment on SingleActionApp {
    id
    name
    description
    singleActionInputs {
      ...SingleActionInputFragment
    }
    project {
      id
    }
  }
  ${SingleActionInputFragmentDoc}
`;
