import { Skeleton } from '@allganize/ui-skeleton';
import { FunctionComponent } from 'react';
import { ChatContentBubble } from '../chat-content-bubble/chat-content-bubble';
import { ChatContentBubbleClasses } from '../chat-content-bubble/chat-content-bubble-classes';
import {
  ChatContentRow,
  ChatContentRowProps,
} from '../chat-content-row/chat-content-row';
import { css } from '@emotion/react';

interface ChatSkeletonProps extends Omit<ChatContentRowProps, 'avatar'> {
  BubbleClasses?: Partial<ChatContentBubbleClasses>;
}

export const ChatSkeleton: FunctionComponent<ChatSkeletonProps> = ({
  BubbleClasses,
  ...other
}) => {
  return (
    <ChatContentRow data-testid="chat-skeleton" {...other}>
      <ChatContentBubble
        css={css`
          padding: 16px;
        `}
        classes={BubbleClasses}
      >
        <Skeleton
          color="dark"
          variant="text"
          textVariant="body14"
          width={142}
        />
        <Skeleton color="dark" variant="text" textVariant="body14" width={71} />
      </ChatContentBubble>
    </ChatContentRow>
  );
};
