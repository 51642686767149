import { isDraftInputEmpty } from '@allganize/draft-input';
import { Types } from '@allganize/alli-sdk-interfaces';
import { useWindow } from '@allganize/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditorState } from 'draft-js';
import { filesize } from 'filesize';
import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { AgentSelectContext } from '../agent-select/agent-select-context';
import {
  AgentSelectOption,
  getAgentSelectOptions,
} from '../agent-select/agent-select-option';
import { TypingContext } from '../typing-context/typing-context';
import { ValidationContext } from '../validation-context/validation-context';
import { ContactAgentByEmailValidation } from './contact-agent-by-email-validation';

export interface ContactAgentByEmailFormValues {
  agent: AgentSelectOption | null;
  message: EditorState;
  attachments: File[];
}

const defaultValues: ContactAgentByEmailFormValues = {
  agent: null,
  message: EditorState.createEmpty(),
  attachments: [],
};

const errorMessages = defineMessages({
  'message-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  'attachments-type': {
    id: 'form.file.errors.type',
    defaultMessage:
      'Invalid file type selected. Allowed file types include the following: {type}.',
    description: 'File invalid type error message',
  },
  'attachments-size': {
    id: 'form.file.errors.max',
    defaultMessage: 'File too large (Max. {size})',
    description: 'File too large error message',
  },
  'attachments-max': {
    id: 'form.file.errors.max-count',
    defaultMessage: 'Select up to {count, number} files.',
    description: 'File max count error message',
  },
});

const createValidationSchema = (
  intl: IntlShape,
  options: ContactAgentByEmailValidation,
  win?: typeof globalThis,
) => {
  const FileKlass = win?.File ?? File;

  const agentSelectOptionSchema = z.object({
    label: z.string(),
    value: z.union([z.string(), z.number()]),
    data: z.object({
      __typename: z.enum(['Agent']),
      id: z.union([z.string(), z.number()]),
      firstName: z.string(),
      lastName: z.string().nullable(),
      displayName: z.string().nullable(),
      avatar: z
        .object({
          __typename: z.enum(['Media']),
          id: z.union([z.string(), z.number()]),
          url: z.string(),
        })
        .nullable(),
    }),
  });

  return z
    .object({
      agent: agentSelectOptionSchema.nullable(),
      message: z.instanceof(EditorState).refine(schema => {
        return schema.getCurrentContent().hasText();
      }, intl.formatMessage(errorMessages['message-required'])),
      attachments: z
        .array(
          z
            .union([z.instanceof(File), z.instanceof(FileKlass)])
            .refine(
              schema => {
                return schema.size <= options.attachments.maxSize;
              },
              intl.formatMessage(errorMessages['attachments-size'], {
                size: filesize(options.attachments.maxSize, {
                  output: 'string',
                  locale: intl.locale,
                  localeOptions: intl.formats.date,
                }),
              }),
            )
            .refine(schema => {
              return schema.type.startsWith('image/');
            }, intl.formatMessage(errorMessages['attachments-type'], { type: ['.png', '.jpg', '.jpeg'].join(', ') })),
        )
        .max(
          options.attachments.maxCount,
          intl.formatMessage(errorMessages['attachments-max'], {
            count: options.attachments.maxCount,
          }),
        ),
    })
    .required({});
};

export interface UseContactAgentByEmailFormOptions {
  defaultValues?: Partial<ContactAgentByEmailFormValues>;
  onSubmit(values: Types.ContactAgentByEmailInput): Promise<any>;
  readOnly?: boolean;
}

export const useContactAgentByEmailForm = ({
  defaultValues: defaultValuesOption,
  onSubmit,
  readOnly,
}: UseContactAgentByEmailFormOptions) => {
  const intl = useIntl();
  const { ref: formRef, window: win } = useWindow();
  const { contactAgentByEmail } = useContext(ValidationContext);
  const agentSelect = useContext(AgentSelectContext);
  const agentSelectOptions = useMemo(
    () => getAgentSelectOptions(agentSelect.options),
    [agentSelect.options],
  );
  const validationSchema = useMemo(
    () =>
      zodResolver(
        createValidationSchema(intl, contactAgentByEmail, win ?? undefined),
      ),
    [contactAgentByEmail, intl, win],
  );
  const form = useForm<ContactAgentByEmailFormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
      ...defaultValuesOption,
    },
    resolver: validationSchema,
  });

  const { setError, watch } = form;

  const submit = async (values: ContactAgentByEmailFormValues) => {
    try {
      await onSubmit({
        targetAgent: values.agent?.value ?? null,
        message: values.message.getCurrentContent().getPlainText('\n'),
        attachments: values.attachments.map(file => ({
          file,
          mediaType: 'IMAGE',
          metaInfo: JSON.stringify({
            lastModified: file.lastModified,
            lastModifiedDate: (file as any).lastModifiedDate,
            name: file.name,
            size: file.size,
            type: file.type,
          }),
        })),
      });
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }
    }
  };

  const { onTyping } = useContext(TypingContext);
  const hasText = (value: EditorState | null) => {
    return !isDraftInputEmpty(value);
  };

  useEffect(() => {
    if (!readOnly) {
      const subscription = watch(values =>
        onTyping(
          !!values.attachments?.length ||
            hasText(
              values.message instanceof EditorState ? values.message : null,
            ),
        ),
      );
      return () => subscription.unsubscribe();
    }
  }, [onTyping, watch, readOnly]);

  return {
    agentSelect: {
      ...agentSelect,
      options: agentSelectOptions,
    },
    form,
    formRef,
    submit,
  };
};
