import { useEventCallback, useUnmount } from '@allganize/hooks';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Id, ToastContent } from 'react-toastify';
import { toast, ToastOptions } from '../toast';

export interface ToastProps<TData = unknown>
  extends Omit<ToastOptions, 'autoClose'> {
  children: ToastContent<TData>;
}

export interface ToastRef {
  getId(): Id | null;
  close(): void;
}

export const Toast = forwardRef<ToastRef, ToastProps>(
  ({ children, ...other }, ref) => {
    const toastIdRef = useRef<Id | null>(null);

    const getId = useEventCallback(() => toastIdRef.current);

    const close = useEventCallback(() => {
      if (toastIdRef.current !== null) {
        toast.dismiss(toastIdRef.current);
        toastIdRef.current = null;
      }
    });

    useEffect(() => {
      if (toastIdRef.current === null) {
        toastIdRef.current = toast(children, {
          action: null,
          ...other,
          autoClose: false,
        });
      } else {
        toast.update(toastIdRef.current, {
          toastId: toastIdRef.current,
          action: null,
          ...other,
          render: children,
          autoClose: false,
        });

        toastIdRef.current = other.toastId || toastIdRef.current;
      }
    });

    useUnmount(() => {
      close();
    });

    useImperativeHandle(ref, () => ({ close, getId }), [close, getId]);

    return null;
  },
);
