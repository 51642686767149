import {
  loadingButtonClasses as muiLoadingButtonClasses,
  LoadingButtonClassKey as MuiLoadingButtonClassKey,
} from '@mui/lab/LoadingButton';
import { omit } from 'lodash-es';

export type LoadingButtonClassKey = Exclude<
  MuiLoadingButtonClassKey,
  | 'loadingIndicatorCenter'
  | 'loadingIndicatorStart'
  | 'loadingIndicatorEnd'
  | 'endIconLoadingEnd'
  | 'startIconLoadingStart'
>;
export type LoadingButtonClasses = Record<LoadingButtonClassKey, string>;

export const loadingButtonClasses: LoadingButtonClasses = omit(
  muiLoadingButtonClasses,
  [
    'loadingIndicatorCenter',
    'loadingIndicatorStart',
    'loadingIndicatorEnd',
    'endIconLoadingEnd',
    'startIconLoadingStart',
  ],
);
