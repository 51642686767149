import { css, useTheme } from '@emotion/react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '../../hooks/use-auth';
import { mallyErrors } from '../../i18n/mally-errors';
import { SignInPage } from '../../pages/sign-in';

interface SecureRouteProps {
  children?: React.ReactNode;
}

// https://github.com/okta/okta-react/blob/master/src/SecureRoute.tsx
// https://github.com/okta/okta-react/blob/master/src/OktaError.tsx
export const SecureRoute: FunctionComponent<SecureRouteProps> = ({
  children,
}) => {
  const theme = useTheme();
  const { isEnabled } = useAuth();
  const okta = useOktaAuth();
  const { oktaAuth, authState, _onAuthRequired } = okta ?? {};
  const pendingLogin = useRef(false);
  const [handleLoginError, setHandleLoginError] = useState<Error | null>(null);

  useEffect(() => {
    const handleLogin = async () => {
      if (pendingLogin.current) {
        return;
      }

      pendingLogin.current = true;

      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth?.setOriginalUri(originalUri);
      const onAuthRequiredFn = _onAuthRequired;
      await onAuthRequiredFn?.(oktaAuth);
    };

    if (!isEnabled) {
      return;
    }

    if (!oktaAuth) {
      return;
    }

    if (!authState) {
      return;
    }

    if (authState.isAuthenticated) {
      pendingLogin.current = false;
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if (!authState.isAuthenticated) {
      handleLogin().catch(err => {
        setHandleLoginError(err as Error);
      });
    }
  }, [_onAuthRequired, authState, isEnabled, oktaAuth]);

  if (handleLoginError) {
    return (
      <SignInPage>
        <span
          css={css`
            color: ${theme.palette.error.main};
          `}
        >
          <FormattedMessage {...mallyErrors.UNKNOWN} />
        </span>
      </SignInPage>
    );
  }

  if (isEnabled && (!authState || !authState.isAuthenticated)) {
    return <SignInPage />;
  }

  return <Fragment>{children}</Fragment>;
};
