import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';

interface SourceNumberProps {
  sourceNum: string;
}

export const SourceNumber = ({ sourceNum }: SourceNumberProps) => {
  const theme = useTheme();
  const SourceNumberCss = css`
    display: inline-flex;
    min-width: 18px;
    min-height: 18px;
    justify-content: center;
    align-items: center;
    ${theme.typography.label12}
    font-size: 12px !important;
    color: ${theme.taxPalette.grey[600]} !important;
    border: 1px solid ${theme.taxPalette.grey[300]};
    border-radius: ${theme.radius.xs}px;
    background: ${theme.taxPalette.white};
  `;

  return <div css={SourceNumberCss}>{sourceNum}</div>;
};
