/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationUserFragment } from '../fragments/conversation-user-fragment';
import { ConversationFragment } from '../fragments/conversation-fragment';
import {
  ChatFragment_AgentChat_,
  ChatFragment_BotChat_,
  ChatFragment_CarouselChat_,
  ChatFragment_ContactAgentByEmailChat_,
  ChatFragment_CustomUIChat_,
  ChatFragment_EventChat_,
  ChatFragment_FillSlotRichUXChat_,
  ChatFragment_SendFormChat_,
  ChatFragment_UserChat_,
} from '../fragments/chat-fragment';
import { ProjectFragment } from '../fragments/project-fragment';
import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ConversationUserFragmentDoc } from '../fragments/conversation-user-fragment';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import { ChatFragmentDoc } from '../fragments/chat-fragment';
import { ProjectFragmentDoc } from '../fragments/project-fragment';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrySendChatMutationVariables = Types.Exact<{
  chatId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  conversationId: Types.Scalars['ID']['input'];
  message?: Types.InputMaybe<Types.Scalars['String']['input']>;
  mediaInput?: Types.InputMaybe<Types.MediaInput>;
  fileInput?: Types.InputMaybe<Types.FileInput>;
  fillSlotInput?: Types.InputMaybe<Types.FillSlotRichUXInput>;
  selected?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  choices?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['Int']['input']>>
  >;
  contactAgentByEmailInput?: Types.InputMaybe<Types.ContactAgentByEmailInput>;
  sendFormInput?: Types.InputMaybe<Types.SendFormInput>;
  variables?: Types.InputMaybe<Array<Types.InputMaybe<Types.VariableInput>>>;
  locale?: Types.InputMaybe<Types.LocaleEnum>;
  textChoice?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type TrySendChatMutation = { __typename: 'UserMutation' } & {
  trySendChat: Types.Maybe<
    { __typename: 'UserTrySendChat' } & {
      user: Types.Maybe<{ __typename: 'User' } & ConversationUserFragment>;
      conversation: Types.Maybe<
        { __typename: 'Conversation' } & {
          project: Types.Maybe<{ __typename: 'Project' } & ProjectFragment>;
        } & ConversationFragment
      >;
      chat: Types.Maybe<
        | ({ __typename: 'AgentChat' } & ChatFragment_AgentChat_)
        | ({ __typename: 'BotChat' } & ChatFragment_BotChat_)
        | ({ __typename: 'CarouselChat' } & ChatFragment_CarouselChat_)
        | ({
            __typename: 'ContactAgentByEmailChat';
          } & ChatFragment_ContactAgentByEmailChat_)
        | ({ __typename: 'CustomUIChat' } & ChatFragment_CustomUIChat_)
        | ({ __typename: 'EventChat' } & ChatFragment_EventChat_)
        | ({
            __typename: 'FillSlotRichUXChat';
          } & ChatFragment_FillSlotRichUXChat_)
        | ({ __typename: 'SendFormChat' } & ChatFragment_SendFormChat_)
        | ({ __typename: 'UserChat' } & ChatFragment_UserChat_)
      >;
      responses: Types.Maybe<
        Array<
          Types.Maybe<
            | ({ __typename: 'AgentChat' } & ChatFragment_AgentChat_)
            | ({ __typename: 'BotChat' } & ChatFragment_BotChat_)
            | ({ __typename: 'CarouselChat' } & ChatFragment_CarouselChat_)
            | ({
                __typename: 'ContactAgentByEmailChat';
              } & ChatFragment_ContactAgentByEmailChat_)
            | ({ __typename: 'CustomUIChat' } & ChatFragment_CustomUIChat_)
            | ({ __typename: 'EventChat' } & ChatFragment_EventChat_)
            | ({
                __typename: 'FillSlotRichUXChat';
              } & ChatFragment_FillSlotRichUXChat_)
            | ({ __typename: 'SendFormChat' } & ChatFragment_SendFormChat_)
            | ({ __typename: 'UserChat' } & ChatFragment_UserChat_)
          >
        >
      >;
      errors: Types.Maybe<
        Array<Types.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const TrySendChatMutationDocument = gql`
  mutation TrySendChatMutation(
    $chatId: ID
    $conversationId: ID!
    $message: String
    $mediaInput: MediaInput
    $fileInput: FileInput
    $fillSlotInput: FillSlotRichUXInput
    $selected: Int
    $choices: [Int]
    $contactAgentByEmailInput: ContactAgentByEmailInput
    $sendFormInput: SendFormInput
    $variables: [VariableInput]
    $locale: LocaleEnum
    $textChoice: Boolean
  ) {
    trySendChat(
      chatId: $chatId
      conversationId: $conversationId
      message: $message
      mediaInput: $mediaInput
      fileInput: $fileInput
      fillSlotInput: $fillSlotInput
      selected: $selected
      choices: $choices
      contactAgentByEmailInput: $contactAgentByEmailInput
      sendFormInput: $sendFormInput
      variables: $variables
      locale: $locale
      textChoice: $textChoice
    ) {
      user {
        ...ConversationUserFragment
      }
      conversation {
        ...ConversationFragment
        project {
          ...ProjectFragment
        }
      }
      chat {
        ...ChatFragment
      }
      responses {
        ...ChatFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ConversationUserFragmentDoc}
  ${ConversationFragmentDoc}
  ${ProjectFragmentDoc}
  ${ChatFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TrySendChatMutationMutationFn = Apollo.MutationFunction<
  TrySendChatMutation,
  TrySendChatMutationVariables
>;

/**
 * __useTrySendChatMutation__
 *
 * To run a mutation, you first call `useTrySendChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrySendChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trySendChatMutation, { data, loading, error }] = useTrySendChatMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      conversationId: // value for 'conversationId'
 *      message: // value for 'message'
 *      mediaInput: // value for 'mediaInput'
 *      fileInput: // value for 'fileInput'
 *      fillSlotInput: // value for 'fillSlotInput'
 *      selected: // value for 'selected'
 *      choices: // value for 'choices'
 *      contactAgentByEmailInput: // value for 'contactAgentByEmailInput'
 *      sendFormInput: // value for 'sendFormInput'
 *      variables: // value for 'variables'
 *      locale: // value for 'locale'
 *      textChoice: // value for 'textChoice'
 *   },
 * });
 */
export function useTrySendChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrySendChatMutation,
    TrySendChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrySendChatMutation, TrySendChatMutationVariables>(
    TrySendChatMutationDocument,
    options,
  );
}
export type TrySendChatMutationHookResult = ReturnType<
  typeof useTrySendChatMutation
>;
export type TrySendChatMutationMutationResult =
  Apollo.MutationResult<TrySendChatMutation>;
export type TrySendChatMutationMutationOptions = Apollo.BaseMutationOptions<
  TrySendChatMutation,
  TrySendChatMutationVariables
>;
