import { FunctionComponent, useEffect } from 'react';
import { analytics } from '../analytics';
import { useCurrentUserQuerySuspenseQuery } from '../graphql/queries/current-user-query';
import { CurrentUserContext } from './current-user-context';

interface CurrentUserProviderProps {
  children?: React.ReactNode;
}

export const CurrentUserProvider: FunctionComponent<
  CurrentUserProviderProps
> = ({ children }) => {
  const { data } = useCurrentUserQuerySuspenseQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data.me) {
      analytics.setUserId(null);
      return;
    }

    const userId = data.me.ownUserId ?? data.me.id;
    analytics.setUserId(`${userId}`);

    analytics.user.mixpanel?.set({
      $email: data.me.email,
      $phone: data.me.phone,
      $first_name: data.me.firstName,
      $last_name: data.me.lastName,
      $name: data.me.name,
      $avatar: data.me.avatar,
      $created: data.me.createdAt,
      locale: data.me.locale,
      ownUserId: data.me.ownUserId,
      temporary: data.me.temporary,
      isTry: data.me.isTry,
    });
  }, [data.me]);

  return (
    <CurrentUserContext.Provider value={data}>
      {children}
    </CurrentUserContext.Provider>
  );
};
