import { useTheme } from '@allganize/ui-theme';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FunctionComponent } from 'react';
import {
  ChatContentBubble,
  ChatContentBubbleProps,
} from '../chat-content-bubble/chat-content-bubble';
import { ChatContentBubbleClasses } from '../chat-content-bubble/chat-content-bubble-classes';
import {
  ChatContentRow,
  ChatContentRowProps,
} from '../chat-content-row/chat-content-row';
import { GeneratingState } from '../generating-state/generating-state';
import { BotChatFragment } from '../graphql/fragments/bot-chat-fragment';

const dotAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0.4;
  }
  16.667% {
    transform: translateY(-6px);
    opacity: 1;
  }
  33.333% {
    transform: translateY(0);
    opacity: 0.4;
  }
  50% {
    transform: translateY(0);
    opacity: 0.4;
  }
  66.667% {
    transform: translateY(0);
    opacity: 0.4;
  }
  83.333% {
    transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 0.4;
  }
`;

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

const Dot = styled.span`
  position: relative;
  display: inline-block;
  width: 6px;
  height: 6px;
  transform: translateY(0);
  margin: 7px 0 7px 4px;
  background-color: currentColor;
  opacity: 0.4;
  animation: ${dotAnimation} 1.5s infinite linear;

  &:first-of-type {
    margin-left: 0;
  }

  &:nth-of-type(1) {
    animation-delay: 0ms;
  }

  &:nth-of-type(2) {
    animation-delay: 0.5s;
  }

  &:nth-of-type(3) {
    animation-delay: 1s;
  }
`;

interface ChatTypingProps
  extends Omit<ChatContentRowProps, 'avatar' | 'color'>,
    Pick<ChatContentBubbleProps, 'color'> {
  avatar?: React.ReactNode;
  chat?: BotChatFragment;
  BubbleClasses?: Partial<ChatContentBubbleClasses>;
}

export const ChatTyping: FunctionComponent<ChatTypingProps> = ({
  BubbleClasses,
  chat,
  color = 'default',
  ...other
}) => {
  const theme = useTheme();
  const dotColor =
    color === 'primary'
      ? theme.palette.primary.contrastText
      : theme.taxPalette.primary[300];

  const hasGeneratingStateMessage =
    chat && chat.generatingState && chat.generatingState !== 'COMPLETE';
  return (
    <ChatContentRow data-testid="chat-typing" {...other}>
      <ChatContentBubble
        css={css`
          text-align: center;
          padding-left: 16px;
          padding-right: 16px;
        `}
        classes={BubbleClasses}
        color={color}
      >
        {hasGeneratingStateMessage && (
          <GeneratingState state={chat.generatingState!} />
        )}

        <DotWrapper>
          <Dot
            css={css`
              color: ${dotColor};
              border-radius: ${theme.radius.round}px;
            `}
          />
          <Dot
            css={css`
              color: ${dotColor};
              border-radius: ${theme.radius.round}px;
            `}
          />
          <Dot
            css={css`
              color: ${dotColor};
              border-radius: ${theme.radius.round}px;
            `}
          />
        </DotWrapper>
      </ChatContentBubble>
    </ChatContentRow>
  );
};
