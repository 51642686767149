import { toast } from '@allganize/ui-toast';
import { compact } from 'lodash-es';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { AppContext } from './app-context';
import { GenerateInput, OutputContext } from './output-context';
import { useSingleActionAppResultPublicSubscription } from '../../graphql/subscriptions/single-action-app-result-public-subscription';
import { useUpdateSingleActionAppResultPublicMutation } from '../../graphql/mutations/update-single-action-app-result-public-mutation';
import { useGenerateSingleActionAppResultPublicMutation } from '../../graphql/mutations/generate-single-action-app-result-public-mutation';
import { useStopSingleActionAppResultPublicMutation } from '../../graphql/mutations/stop-single-action-app-result-public-mutation';
import { getMallyErrorMessageDescriptor } from '../utils/error';

interface OutputProviderProps {
  children?: ReactNode;
}

export const OutputProvider: FunctionComponent<OutputProviderProps> = ({
  children,
}) => {
  const intl = useIntl();
  const {
    data: appData,
    publicToken,
    accessToken,
    generateAccessToken,
  } = useContext(AppContext);
  const appId = appData?.id || '';
  const projectId = appData?.projectId || '';

  const { data } = useSingleActionAppResultPublicSubscription({
    skip: !publicToken || !accessToken || !projectId,
    variables: {
      where: { id: projectId },
      publicToken,
      accessToken,
    },
    onError: err =>
      toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
  });

  const outputData = useMemo(
    () => ({
      id: data?.singleActionAppResultPublic?.id || '',
      output: data?.singleActionAppResultPublic?.output || '',
      outputOriginal: data?.singleActionAppResultPublic?.outputOriginal || '',
      documentResults: data?.singleActionAppResultPublic?.documentResults || [],
    }),
    [data?.singleActionAppResultPublic],
  );

  const [mutate, { loading, called, reset }] =
    useGenerateSingleActionAppResultPublicMutation({
      onCompleted: res => {
        const error = compact(
          res.generateSingleActionAppResultPublic?.errors,
        )[0];

        if (error) {
          const errorMessage = intl.formatMessage(
            getMallyErrorMessageDescriptor(error),
          );

          if (error.key === 'INVALID_ACCESS_TOKEN') {
            toast.warning(errorMessage);
            generateAccessToken();
          } else {
            toast.error(errorMessage);
          }
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    });

  useEffect(() => {
    reset();
  }, [publicToken, reset]);

  const startGenerate = useCallback(
    ({
      userInput,
      singleActionAppSelectedDocumentInputInfo,
    }: GenerateInput) => {
      mutate({
        variables: {
          where: { id: projectId },
          appId: appId,
          accessToken,
          publicToken,
          userInput,
          singleActionAppSelectedDocumentInputInfo,
        },
      });
    },
    [projectId, appId, accessToken, publicToken, mutate],
  );

  const [stopGenerate] = useStopSingleActionAppResultPublicMutation({
    variables: {
      where: { id: projectId },
      publicToken,
      singleActionAppResultId: outputData.id,
    },
    onCompleted: res => {
      const error = compact(res.stopSingleActionAppResultPublic?.errors)[0];
      if (error) {
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(error)));
      }
    },
    onError: err =>
      toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
  });

  const [updateMutate, { loading: updating }] =
    useUpdateSingleActionAppResultPublicMutation({
      onCompleted: res => {
        const error = compact(res.updateSingleActionAppResultPublic?.errors)[0];
        if (error) {
          toast.error(
            intl.formatMessage(getMallyErrorMessageDescriptor(error)),
          );
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    });

  const update = useCallback(
    async (updatedOutput: string) => {
      await updateMutate({
        variables: {
          where: {
            id: projectId,
          },
          resultId: outputData.id,
          updatedOutput,
          accessToken,
          token: publicToken,
        },
      });
    },
    [projectId, accessToken, publicToken, outputData.id, updateMutate],
  );

  const [generateDisabled, setGenerateDisabled] = useState(false);

  const output = useMemo(() => {
    return {
      data: outputData,
      called,
      loading,
      updating,
      updated: outputData.output !== outputData.outputOriginal,
      update,
      revert: () => update(outputData.outputOriginal),
      stopGenerate,
      startGenerate,
      setGenerateDisabled: (disabled: boolean) => setGenerateDisabled(disabled),
      generateDisabled,
    };
  }, [
    outputData,
    called,
    loading,
    updating,
    update,
    stopGenerate,
    startGenerate,
    setGenerateDisabled,
    generateDisabled,
  ]);

  return (
    <OutputContext.Provider value={output}>{children}</OutputContext.Provider>
  );
};
