/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationFragment } from '../fragments/conversation-fragment';
import { ProjectFragment } from '../fragments/project-fragment';
import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import { ProjectFragmentDoc } from '../fragments/project-fragment';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StartConversationMutationVariables = Types.Exact<{
  placement?: Types.InputMaybe<Types.Scalars['String']['input']>;
  userAgent?: Types.InputMaybe<Types.Scalars['String']['input']>;
  locale?: Types.InputMaybe<Types.LocaleEnum>;
  debug?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  startOver?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  sdkReferrer?: Types.InputMaybe<Types.SDKReferrerInput>;
  variables?: Types.InputMaybe<Array<Types.InputMaybe<Types.VariableInput>>>;
  campaignToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type StartConversationMutation = { __typename: 'UserMutation' } & {
  startConversation: Types.Maybe<
    { __typename: 'UserStartConversation' } & Pick<
      Types.UserStartConversation,
      'debug'
    > & {
        conversation: Types.Maybe<
          { __typename: 'Conversation' } & {
            project: Types.Maybe<{ __typename: 'Project' } & ProjectFragment>;
          } & ConversationFragment
        >;
        errors: Types.Maybe<
          Array<Types.Maybe<{ __typename: 'Error' } & ErrorFragment>>
        >;
      }
  >;
};

export const StartConversationMutationDocument = gql`
  mutation StartConversationMutation(
    $placement: String
    $userAgent: String
    $locale: LocaleEnum
    $debug: Boolean
    $startOver: Boolean
    $sdkReferrer: SDKReferrerInput
    $variables: [VariableInput]
    $campaignToken: String
  ) {
    startConversation(
      placement: $placement
      userAgent: $userAgent
      locale: $locale
      debug: $debug
      startOver: $startOver
      sdkReferrer: $sdkReferrer
      variables: $variables
      campaignToken: $campaignToken
    ) {
      conversation {
        ...ConversationFragment
        project {
          ...ProjectFragment
        }
      }
      debug
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ConversationFragmentDoc}
  ${ProjectFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type StartConversationMutationMutationFn = Apollo.MutationFunction<
  StartConversationMutation,
  StartConversationMutationVariables
>;

/**
 * __useStartConversationMutation__
 *
 * To run a mutation, you first call `useStartConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startConversationMutation, { data, loading, error }] = useStartConversationMutation({
 *   variables: {
 *      placement: // value for 'placement'
 *      userAgent: // value for 'userAgent'
 *      locale: // value for 'locale'
 *      debug: // value for 'debug'
 *      startOver: // value for 'startOver'
 *      sdkReferrer: // value for 'sdkReferrer'
 *      variables: // value for 'variables'
 *      campaignToken: // value for 'campaignToken'
 *   },
 * });
 */
export function useStartConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartConversationMutation,
    StartConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartConversationMutation,
    StartConversationMutationVariables
  >(StartConversationMutationDocument, options);
}
export type StartConversationMutationHookResult = ReturnType<
  typeof useStartConversationMutation
>;
export type StartConversationMutationMutationResult =
  Apollo.MutationResult<StartConversationMutation>;
export type StartConversationMutationMutationOptions =
  Apollo.BaseMutationOptions<
    StartConversationMutation,
    StartConversationMutationVariables
  >;
