import { Carousel, carouselClasses } from '@allganize/ui-carousel';
import { List } from '@allganize/ui-list';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { uaParser } from '../utils/ua-parser';
import { chatOptionInfoClasses } from './chat-option-info-classes';
import {
  ChatOptionInfoListClasses,
  chatOptionInfoListClasses,
} from './chat-option-info-list-classes';
import { chatOptionInfoListItemClasses } from './chat-option-info-list-item-classes';

interface ChatOptionInfoListProps {
  carousel?: boolean;
  wrap?: boolean;
  children?: React.ReactNode;
  classes?: Partial<ChatOptionInfoListClasses>;
  className?: string;
}

export const ChatOptionInfoList: FunctionComponent<ChatOptionInfoListProps> = ({
  carousel,
  wrap,
  children,
  classes,
  className,
}) => {
  if (carousel) {
    return (
      <Carousel
        variableWidth
        css={[
          css`
            width: 100%;

            .${chatOptionInfoClasses.root} {
              width: 292px;
              height: 100%;
            }
          `,
          uaParser.hasCarouselHeightBug &&
            css`
              .${carouselClasses.slide} {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;

                > div {
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: stretch;
                  justify-content: flex-start;
                }
              }

              .${chatOptionInfoListItemClasses.carousel} {
                flex-grow: 1;
                display: inline-flex !important;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;
              }

              .${chatOptionInfoClasses.root} {
                height: auto;
                flex-grow: 1;
              }
            `,
        ]}
        className={clsx(
          chatOptionInfoListClasses.root,
          chatOptionInfoListClasses.carousel,
          classes?.root,
          classes?.carousel,
          className,
        )}
      >
        {children}
      </Carousel>
    );
  }

  return (
    <List
      component="div"
      css={[
        css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        `,
        wrap &&
          css`
            flex-flow: wrap;
            justify-content: flex-end;
            align-items: stretch;
          `,
      ]}
      disablePadding
      className={clsx(
        chatOptionInfoListClasses.root,
        { [chatOptionInfoListClasses.wrap]: wrap },
        classes?.root,
        classes?.wrap,
        className,
      )}
    >
      {children}
    </List>
  );
};
