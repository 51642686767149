import { noop } from 'lodash-es';
import { createContext } from 'react';
import { SingleActionAppResultFragment } from '../../graphql/fragments/single-action-app-result-fragment';

type ID = string | number;

interface OutputData {
  id: ID;
  output: string;
  outputOriginal: string;
  documentResults: SingleActionAppResultFragment['documentResults'];
}

export interface DocumentInput {
  knowledgeBaseId: ID;
  singleActionInputId: ID;
}

export interface GenerateInput {
  userInput: string;
  singleActionAppSelectedDocumentInputInfo: DocumentInput[];
}

interface Output {
  data: OutputData;
  called: boolean;
  loading: boolean;
  updating: boolean;
  updated: boolean;
  update: (updatedOutput: string) => void;
  revert: () => void;
  stopGenerate: () => void;
  startGenerate: (input: GenerateInput) => void;
  setGenerateDisabled: (disabled: boolean) => void;
  generateDisabled: boolean;
}

export const OutputContext = createContext<Output>({
  data: {
    id: '',
    output: '',
    outputOriginal: '',
    documentResults: [],
  },
  called: false,
  loading: false,
  updating: false,
  updated: false,
  update: noop,
  revert: noop,
  stopGenerate: noop,
  startGenerate: noop,
  setGenerateDisabled: noop,
  generateDisabled: false,
});
