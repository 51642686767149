/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ErrorFragment = { __typename: 'Error' } & Pick<
  Types.Error,
  'key' | 'message' | 'field' | 'info'
>;

export const ErrorFragmentDoc = gql`
  fragment ErrorFragment on Error {
    key
    message
    field
    info
  }
`;
