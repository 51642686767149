import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import MuiTooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { tooltipClasses } from './tooltip-classes';
import { TooltipProps } from './tooltip-type-map';

/**
 * Tooltips display informative text when users hover over, focus on,
 * or tap an element.
 */
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (props, ref) => {
    const theme = useTheme();
    const muiTheme = useMuiTheme();
    const { classes, color = 'default', ...other } = props;
    const isRtl = theme.direction === 'rtl';

    return (
      <ThemeProvider
        theme={{
          ...muiTheme,
          direction: theme.direction,
          transitions: theme.transitions,
        }}
      >
        <ClassNames>
          {({ css, cx }) => (
            <MuiTooltip
              {...other}
              ref={ref}
              className={clsx(
                {
                  [tooltipClasses.colorDefault]: color === 'default',
                  [tooltipClasses.colorError]: color === 'error',
                },
                {
                  [classes?.colorDefault ?? '']: color === 'default',
                  [classes?.colorError ?? '']: color === 'error',
                },
                other.className,
              )}
              classes={{
                ...classes,
                popper: cx(
                  css`
                    z-index: ${theme.zIndex.tooltip};
                  `,
                  classes?.popper,
                ),
                tooltip: cx(
                  css`
                    border-radius: ${theme.radius.sm}px;
                    ${theme.typography.body12};
                    padding: 8px 12px;
                    max-width: 320px;

                    .${tooltipClasses.popper}[data-popper-placement*="left"] & {
                      margin-right: 12px;

                      ${isRtl &&
                      css`
                        margin-left: 12px;
                      `}
                    }

                    .${tooltipClasses.popper}[data-popper-placement*="right"] & {
                      margin-left: 12px;

                      ${isRtl &&
                      css`
                        margin-right: 12px;
                      `}
                    }

                    .${tooltipClasses.popper}[data-popper-placement*="top"] & {
                      margin-bottom: 12px;
                    }

                    .${tooltipClasses.popper}[data-popper-placement*="bottom"] & {
                      margin-top: 12px;
                    }
                  `,
                  color === 'default' &&
                    css`
                      background-color: ${theme.palette.grey[700]};
                      color: ${theme.palette.common.white};
                    `,
                  color === 'error' &&
                    css`
                      background-color: ${theme.palette.error.main};
                      color: ${theme.palette.error.contrastText};
                    `,
                  classes?.tooltip,
                ),
                arrow: cx(
                  color === 'default' &&
                    css`
                      color: ${theme.palette.grey[700]};
                    `,
                  color === 'error' &&
                    css`
                      color: ${theme.palette.error.main};
                    `,
                  classes?.arrow,
                ),
              }}
            />
          )}
        </ClassNames>
      </ThemeProvider>
    );
  },
);
