import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { Toolbar } from '@mui/material';
import { FormHTMLAttributes, forwardRef } from 'react';
import { conversationDetailFooterMinHeight } from '../conversation-detail/conversation-detail-footer';

interface ChatFormRootProps extends FormHTMLAttributes<HTMLFormElement> {
  popupOpen?: boolean;
}

export const ChatFormRoot = forwardRef<HTMLFormElement, ChatFormRootProps>(
  ({ popupOpen, ...others }, ref) => {
    const theme = useTheme();

    return (
      <Toolbar
        css={[
          css`
            min-height: ${conversationDetailFooterMinHeight}px;
            align-items: flex-start;
            padding: 6px 16px;
          `,
          popupOpen &&
            css`
              background-color: ${theme.palette.unstable_background.white};
            `,
        ]}
        disableGutters
      >
        <form
          css={css`
            margin: 0;
            flex-grow: 1;
            position: relative;
          `}
          {...others}
          ref={ref}
        />
      </Toolbar>
    );
  },
);
