/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { CurrentUserFragment } from '../fragments/current-user-fragment';
import { gql } from '@apollo/client';
import { CurrentUserFragmentDoc } from '../fragments/current-user-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename: 'UserQuery' } & {
  me: Types.Maybe<{ __typename: 'User' } & CurrentUserFragment>;
};

export const CurrentUserQueryDocument = gql`
  query CurrentUserQuery {
    me {
      ...CurrentUserFragment
    }
  }
  ${CurrentUserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserQueryDocument,
    options,
  );
}
export function useCurrentUserQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserQueryDocument,
    options,
  );
}
export function useCurrentUserQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserQueryDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserQueryLazyQueryHookResult = ReturnType<
  typeof useCurrentUserQueryLazyQuery
>;
export type CurrentUserQuerySuspenseQueryHookResult = ReturnType<
  typeof useCurrentUserQuerySuspenseQuery
>;
export type CurrentUserQueryQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
