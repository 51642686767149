import { useEventCallback } from '@allganize/hooks';
import { toast } from '@allganize/ui-toast';
import { compact } from 'lodash-es';
import { FunctionComponent, ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';

import { DownloadFileFormValues } from '../download-file-dialog/download-file-form-values';
import { useSingleActionResultDownloadFilePublicMutation } from '../../graphql/mutations/single-action-result-download-file-public-mutation';
import { useSingleActionAppResultDownloadPublicSubscription } from '../../graphql/subscriptions/single-action-app-result-download-public-subscription';
import { downloadFile as downloadFileUtil } from '../utils/download-file';
import { getMallyErrorMessageDescriptor } from '../utils/error';
import { AppContext } from './app-context';
import { DownloadFileContext } from './download-file-context';
import { OutputContext } from './output-context';

interface DownloadFileProviderProps {
  url: string;
  clientHashId: string;
  children?: ReactNode;
}

export const DownloadFileProvider: FunctionComponent<
  DownloadFileProviderProps
> = ({ url, clientHashId, children }) => {
  const intl = useIntl();
  const { data: output } = useContext(OutputContext);
  const { data: app, mode, publicToken, accessToken } = useContext(AppContext);
  const projectId = app?.projectId || '';

  const [mutate, { data: mutateData, reset }] =
    useSingleActionResultDownloadFilePublicMutation({
      onCompleted: res => {
        const error = compact(
          res.asyncSingleActionResultDownloadFilePublic?.errors,
        )[0];
        if (error) {
          toast.error(
            intl.formatMessage(getMallyErrorMessageDescriptor(error)),
          );
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    });

  const { data } = useSingleActionAppResultDownloadPublicSubscription({
    skip: mode === 'agent' || !projectId || !accessToken || !publicToken,
    variables: {
      where: { id: projectId },
      accessToken,
      token: publicToken,
    },
    onData: res => {
      const error = compact(
        res.data.data?.singleActionAppResultDownloadPublic?.errors,
      )[0];
      if (error) {
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(error)));
      }
    },
    onError: err =>
      toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
  });

  const taskId = mutateData?.asyncSingleActionResultDownloadFilePublic?.taskId;
  const status =
    taskId === data?.singleActionAppResultDownloadPublic?.id
      ? data?.singleActionAppResultDownloadPublic?.status || null
      : null;

  const fileName = data?.singleActionAppResultDownloadPublic?.fileName || '';

  const download = useEventCallback((values: DownloadFileFormValues) => {
    mutate({
      variables: {
        where: { id: projectId },
        clientHashId,
        input: {
          fileName: values.fileName,
          fileType: values.fileType.value,
          singleActionResultId: output.id,
        },
        accessToken,
        token: publicToken,
      },
    });
  });

  const downloadFile = useEventCallback(async () => {
    if (taskId) {
      reset();
      await downloadFileUtil(`${url}?task_id=${taskId}`, fileName, {
        headers: {
          accessToken,
          publicToken,
        },
      });
    }
  });

  return (
    <DownloadFileContext.Provider value={{ status, download, downloadFile }}>
      {children}
    </DownloadFileContext.Provider>
  );
};
