import { createContext } from 'react';
import { ConversationQuerySuspenseQueryHookResult } from '../graphql/queries/conversation-query';

export const ConversationContext = createContext<
  Pick<ConversationQuerySuspenseQueryHookResult, 'data' | 'refetch'>
>({
  data: {
    __typename: 'UserQuery',
    conversation: null,
  },
  refetch: () =>
    Promise.reject('refetch called outside ChatListContext provider'),
});
