import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { EventEmitter } from 'eventemitter3';
import {
  AuthClientEvents,
  IsAuthenticatedOptions,
  SignInOptions,
  SignOutOptions,
} from './auth-client.types';

export class AuthClient extends EventEmitter<AuthClientEvents> {
  private oktaAuth: OktaAuth | null = null;

  public getOktaAuth() {
    return this.oktaAuth;
  }

  public setOktaAuth(oktaAuth: OktaAuth | null) {
    if (this.oktaAuth === oktaAuth) {
      return;
    }

    this.oktaAuth?.authStateManager.unsubscribe(
      this.handleOktaAuthStateChanged,
    );
    this.oktaAuth = oktaAuth;
    this.oktaAuth?.authStateManager.subscribe(this.handleOktaAuthStateChanged);
    this.emit('oktaAuthChanged', oktaAuth);
  }

  public isEnabled() {
    return this.oktaAuth !== null;
  }

  public async isAuthenticated(
    options?: IsAuthenticatedOptions,
  ): Promise<boolean> {
    return this.oktaAuth?.isAuthenticated(options?.okta) ?? false;
  }

  public async signIn(options?: SignInOptions) {
    await this.oktaAuth?.signInWithRedirect(options?.okta);
  }

  public async signOut(options?: SignOutOptions): Promise<boolean> {
    return this.oktaAuth?.signOut(options?.okta) ?? false;
  }

  public async getHeaders() {
    const result: Partial<Record<string, string>> = {};
    const idToken = this.oktaAuth?.getIdToken();

    if (idToken) {
      result['okta-sso-id-token'] = idToken;
    }

    return result;
  }

  private handleOktaAuthStateChanged = (authState: AuthState) => {
    this.emit('oktaAuthStateChanged', authState);
  };
}
