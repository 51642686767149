import { DraftField } from '@allganize/draft-input';
import { useId } from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { IconButton, LoadingButton } from '@allganize/ui-button';
import {
  FileDropzone,
  FileList,
  FileListItem,
  FileListItemIcon,
  FileListItemText,
} from '@allganize/ui-file-input';
import { FormControl, FormHelperText } from '@allganize/ui-form';
import { IcClose, IcUpload } from '@allganize/ui-icons';
import { InputLabel } from '@allganize/ui-input';
import { SelectField } from '@allganize/ui-select';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { AgentSelectOption } from '../agent-select/agent-select-option';
import { useAgentSelect } from '../agent-select/use-agent-select';
import {
  UseContactAgentByEmailFormOptions,
  useContactAgentByEmailForm,
} from './use-contact-agent-by-email-form';

interface ContactAgentByEmailFormProps
  extends UseContactAgentByEmailFormOptions {
  className?: string;
  readOnly?: boolean;
}

export const ContactAgentByEmailForm: FunctionComponent<
  ContactAgentByEmailFormProps
> = ({ className, defaultValues, onSubmit, readOnly }) => {
  const formId = useId();
  const theme = useTheme();
  const { agentSelect, form, formRef, submit } = useContactAgentByEmailForm({
    defaultValues,
    onSubmit,
    readOnly,
  });
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = form;
  const agentSelectProps = useAgentSelect<AgentSelectOption>({});

  return (
    <form
      css={css`
        margin: 0;

        > *:not(:first-child) {
          margin-top: 16px;
        }
      `}
      className={className}
      onSubmit={handleSubmit(submit)}
      ref={formRef}
    >
      <Controller
        control={control}
        name="agent"
        render={({ field, fieldState }) => {
          return (
            <SelectField<AgentSelectOption>
              {...field}
              label={
                <FormattedMessage
                  id="agent"
                  defaultMessage="{count, plural, one {Agent} other {Agents}}"
                  description="contact agent by email form agent field label"
                  values={{ count: 1 }}
                />
              }
              options={agentSelect.options}
              fullWidth
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              disabled={readOnly}
              SelectProps={{
                ...agentSelectProps,
                isClearable: true,
                isLoading: agentSelect.loading,
              }}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="message"
        render={({ field: { ref, ...field }, fieldState }) => {
          return (
            <DraftField
              {...field}
              label={
                <FormattedMessage
                  id="message"
                  defaultMessage="{count, plural, one {Message} other {Messages}}"
                  description="contact agent by email form message field label"
                  values={{ count: 1 }}
                />
              }
              fullWidth
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              disabled={readOnly}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="attachments"
        render={({ field, fieldState }) => {
          const id = `${formId}-attachments`;
          const helperTextId = `${id}-helper-text`;
          const inputLabelId = `${id}-label`;

          return (
            <FormControl
              fullWidth
              error={fieldState.invalid}
              disabled={readOnly}
            >
              <InputLabel htmlFor={id} id={inputLabelId}>
                <FormattedMessage
                  id="attachment"
                  defaultMessage="{count, plural, one {Attachment} other {Attachments}}"
                  description="contact agent by email form attachments field label"
                  values={{ count: 0 }}
                />
              </InputLabel>

              <FileDropzone
                disabled={readOnly}
                inputProps={{
                  name: field.name,
                  'aria-describedby': helperTextId,
                }}
                inputRef={field.ref}
                onDropAccepted={field.onChange}
                onBlur={field.onBlur}
                css={css`
                  padding: 12px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  text-align: center;
                `}
                dragOverlay={
                  <div
                    css={css`
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;
                      text-align: center;
                    `}
                  >
                    <IcUpload
                      css={css`
                        margin-bottom: 4px;
                      `}
                    />

                    <Text variant="body14">
                      <FormattedMessage
                        id="form.file.placeholder.drag"
                        defaultMessage="Drop file here"
                        description="File input placeholder dragging state"
                      />
                    </Text>
                  </div>
                }
              >
                <IcUpload
                  css={css`
                    margin-bottom: 4px;
                  `}
                />

                <Text variant="body14">
                  <FormattedMessage
                    id="form.file.placeholder"
                    defaultMessage="Click or drag & drop{br}a file to upload"
                    description="File input placeholder"
                    values={{
                      br: <br />,
                    }}
                  />
                </Text>
              </FileDropzone>

              {fieldState.error?.message && (
                <FormHelperText id={helperTextId}>
                  {fieldState.error.message}
                </FormHelperText>
              )}

              {field.value.length > 0 && (
                <FileList
                  css={css`
                    padding-bottom: 0;
                  `}
                >
                  {field.value.map((file, index) => {
                    const errorMessage = errors.attachments?.[index]?.message;

                    const handleRemove = () => {
                      field.onChange([
                        ...field.value.slice(0, index),
                        ...field.value.slice(index + 1),
                      ]);
                    };

                    return (
                      <FileListItem
                        key={[
                          file.name,
                          file.size,
                          file.type,
                          file.lastModified,
                          index,
                        ].join('-')}
                        secondaryAction={
                          !readOnly && (
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={handleRemove}
                            >
                              <IcClose />
                            </IconButton>
                          )
                        }
                      >
                        <FileListItemIcon />

                        <FileListItemText
                          primary={<Truncate clamp={1}>{file.name}</Truncate>}
                          primaryTextProps={{ title: file.name }}
                          secondary={
                            errorMessage && (
                              <div
                                css={css`
                                  color: ${theme.palette.error.main};
                                `}
                              >
                                {errorMessage}
                              </div>
                            )
                          }
                        />
                      </FileListItem>
                    );
                  })}
                </FileList>
              )}
            </FormControl>
          );
        }}
      />

      {errors.root?.message && (
        <FormHelperText error>{errors.root?.message}</FormHelperText>
      )}

      {!readOnly && (
        <LoadingButton
          type="submit"
          fullWidth
          color="primary"
          size="large"
          variant="filled"
          disabled={!isValid}
          loading={isSubmitting}
        >
          <FormattedMessage
            id="actions.submit"
            defaultMessage="Submit"
            description="Submit button text"
          />
        </LoadingButton>
      )}
    </form>
  );
};
