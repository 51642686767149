import {
  ChatFormAutocomplete,
  ChatFormInputAdornment,
  ChatFormInputField,
  ChatFormRoot,
  ChatFormSubmitButton,
} from '@allganize/alli-sdk-ui';
import { combineRefs } from '@allganize/hooks';
import { IconButton } from '@allganize/ui-button';
import { DatePicker, PickersModalDialogRoot } from '@allganize/ui-date-picker';
import { DialogProps } from '@allganize/ui-dialog';
import {
  DropzoneRef,
  FileDropzone,
  fileDropzoneClasses,
} from '@allganize/ui-file-input';
import { IcAttachment, IcClose, IcUpload } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { Tooltip } from '@allganize/ui-tooltip';
import { raf } from '@allganize/utils-timeout';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { config } from '../config';
import {
  ChatFormValues,
  dateFormat,
  useChatForm,
} from '../hooks/use-chat-form';
import { useLauncher } from '../hooks/use-launcher';
import { emotionCacheOptions } from '../styles/emotion-cache';
import { EmotionCacheProvider } from '../styles/emotion-cache-provider';
import { ButtonDatePicker } from './button-date-picker';
import { ChatFormErrorMessage } from './chat-form-error-message';

interface ChatFormProps {
  startAdornment?: (control: Control<ChatFormValues>) => ReactNode;
}

const DatePickerDialog = forwardRef<HTMLDivElement, DialogProps>(
  (props, ref) => {
    return (
      <EmotionCacheProvider options={emotionCacheOptions}>
        <PickersModalDialogRoot {...props} ref={ref} />
      </EmotionCacheProvider>
    );
  },
);

const StyledFileDropzone = styled(FileDropzone)`
  border: 0;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radius.round}px;

  .${fileDropzoneClasses.dragOverlay} {
    border: 1px dashed transparent;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-color: ${({ theme }) => theme.palette.grayAlpha[500]};
    border-radius: ${({ theme }) => theme.radius.round}px;
    color: ${({ theme }) => theme.palette.text.primary};
    transition: ${({ theme }) =>
      theme.transitions.create(['color', 'opacity', 'border-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
      })};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &.${fileDropzoneClasses.dragActive} .${fileDropzoneClasses.dragOverlay} {
    transition: ${({ theme }) =>
      theme.transitions.create(['color', 'opacity', 'border-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      })};
  }

  &.${fileDropzoneClasses.dragReject} .${fileDropzoneClasses.dragOverlay} {
    color: ${({ theme }) => theme.palette.error.main};
    border-color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const ChatForm = ({ startAdornment }: ChatFormProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const { open } = useLauncher();
  const {
    autocomplete,
    dateFieldEnabled,
    dateFieldOnly,
    disabled,
    dropzoneOptions,
    fileFieldEnabled,
    fileFieldOnly,
    form: {
      clearErrors,
      control,
      formState: { isSubmitting, isValid },
      handleSubmit,
      trigger,
      setFocus,
    },
    formDisabled,
    formRef,
    hintRef,
    messageFieldEnabled,
    placeholder,
    submit,
    waitUserAnswer,
  } = useChatForm({
    validation: config.validation.chatForm,
  });
  const [inputScrollLeft, setInputScrollLeft] = useState(0);
  const autoFocus =
    open && waitUserAnswer && !formDisabled && theme.deviceType === 'desktop';

  useEffect(() => {
    if (
      !dateFieldOnly &&
      !fileFieldOnly &&
      !(!messageFieldEnabled && !disabled) &&
      autoFocus
    ) {
      setFocus('message');
    }
  }, [
    dateFieldOnly,
    fileFieldOnly,
    messageFieldEnabled,
    disabled,
    autoFocus,
    setFocus,
  ]);

  const dropzoneRef = useRef<DropzoneRef>(null);

  const handleErrorMessageClear = () => {
    clearErrors();
  };

  const dragOverlay = (
    <div>
      <IcUpload
        css={css`
          display: inline-block;
          margin-right: 4px;
          vertical-align: middle;
        `}
      />

      <FormattedMessage
        id="form.file.placeholder"
        defaultMessage="Click or drag & drop{br}a file to upload"
        description="File input placeholder"
        values={{
          br: ' ',
        }}
      />
    </div>
  );

  const submitButton = (
    <ChatFormSubmitButton
      disabled={formDisabled || !isValid}
      isSubmitting={isSubmitting}
    />
  );

  const openFilePicker = () => {
    dropzoneRef.current?.open();
  };

  const popupOpen =
    autocomplete?.popupOpen && autocomplete.groupedOptions.length > 0;

  if (dateFieldOnly) {
    return (
      <ChatFormRoot
        onSubmit={handleSubmit(submit)}
        ref={formRef}
        popupOpen={popupOpen}
      >
        <Controller
          control={control}
          key="date"
          name="date"
          render={({ field, fieldState, formState }) => {
            const nonDateFieldErrorMessage =
              messageFieldEnabled || (!messageFieldEnabled && !fileFieldEnabled)
                ? formState.errors.message?.message ||
                  formState.errors.file?.message
                : formState.errors.file?.message ||
                  formState.errors.message?.message;
            const errorMessage =
              field.value !== null &&
              (fieldState.error?.message ||
                formState.errors.date?.message ||
                nonDateFieldErrorMessage ||
                formState.errors.root?.message);

            return (
              <DatePicker
                inputRef={field.ref}
                disabled={formDisabled}
                readOnly={formDisabled}
                value={field.value}
                onChange={field.onChange}
                format={dateFormat}
                slots={{
                  // @ts-expect-error internal prop
                  dialog: DatePickerDialog,
                  // @ts-expect-error internal prop
                  inputField: ChatFormInputField,
                }}
                slotProps={{
                  dialog: {
                    container: document.getElementById(theme.modalContainer.id),
                  },
                  field: {
                    clearable: true,
                    onClear: async () => {
                      await raf();
                      trigger('date');
                    },
                  },
                  clearIcon: { fontSize: 'medium' },
                  inputAdornment: { position: 'start' },
                  openPickerButton: { size: 'medium' },
                  inputField: {
                    name: field.name,
                    onBlur: field.onBlur,
                    ...(placeholder ? { placeholder } : {}),
                    InputProps: {
                      endAdornment: (
                        <ChatFormInputAdornment position="end">
                          {errorMessage ? (
                            <ChatFormErrorMessage
                              edge="end"
                              title={errorMessage}
                            />
                          ) : (
                            submitButton
                          )}
                        </ChatFormInputAdornment>
                      ),
                    },
                  },
                }}
              />
            );
          }}
        />
      </ChatFormRoot>
    );
  }

  if (fileFieldOnly) {
    return (
      <ChatFormRoot
        onSubmit={handleSubmit(submit)}
        ref={formRef}
        popupOpen={popupOpen}
      >
        <Controller
          control={control}
          key="file"
          name="file"
          render={({ field, fieldState, formState }) => {
            const nonFileFieldErrorMessage =
              messageFieldEnabled || (!messageFieldEnabled && !dateFieldEnabled)
                ? formState.errors.message?.message ||
                  formState.errors.date?.message
                : formState.errors.date?.message ||
                  formState.errors.message?.message;
            const errorMessage =
              field.value !== null &&
              (fieldState.error?.message ||
                formState.errors.file?.message ||
                nonFileFieldErrorMessage ||
                formState.errors.root?.message);

            return (
              <StyledFileDropzone
                {...dropzoneOptions}
                dragOverlay={dragOverlay}
                dropzoneRef={dropzoneRef}
                inputRef={field.ref}
                disabled={formDisabled}
                inputProps={{ name: field.name }}
                onDropAccepted={files => {
                  const file = files[0];

                  if (!file) {
                    return;
                  }

                  handleErrorMessageClear();
                  field.onChange(file);
                }}
              >
                <ChatFormInputField
                  name={`${field.name}-name`}
                  value={field.value?.name ?? ''}
                  disabled={formDisabled}
                  placeholder={
                    placeholder ||
                    intl.formatMessage(
                      {
                        id: 'form.file.placeholder',
                        defaultMessage:
                          'Click or drag & drop{br}a file to upload',
                        description: 'File input placeholder',
                      },
                      { br: ' ' },
                    )
                  }
                  onBlur={field.onBlur}
                  inputProps={{
                    onClick: openFilePicker,
                  }}
                  InputProps={{
                    startAdornment: (
                      <ChatFormInputAdornment position="start">
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'form.file.placeholder',
                            defaultMessage:
                              'Click or drag & drop a file to upload',
                            description: 'File input placeholder',
                          })}
                        >
                          <IconButton
                            edge="start"
                            disabled={formDisabled}
                            onClick={openFilePicker}
                          >
                            <IcAttachment />
                          </IconButton>
                        </Tooltip>
                      </ChatFormInputAdornment>
                    ),
                    endAdornment: (
                      <ChatFormInputAdornment position="end">
                        {field.value && !formDisabled && (
                          <IconButton
                            onClick={() => {
                              field.onChange(null);
                              trigger('file');
                            }}
                          >
                            <IcClose />
                          </IconButton>
                        )}

                        {errorMessage ? (
                          <ChatFormErrorMessage
                            edge="end"
                            title={errorMessage}
                          />
                        ) : (
                          submitButton
                        )}
                      </ChatFormInputAdornment>
                    ),
                  }}
                />
              </StyledFileDropzone>
            );
          }}
        />
      </ChatFormRoot>
    );
  }

  if (!messageFieldEnabled && !disabled) {
    invariant(dateFieldEnabled && fileFieldEnabled, 'Invalid form config');

    return (
      <ChatFormRoot
        onSubmit={handleSubmit(submit)}
        ref={formRef}
        popupOpen={popupOpen}
      >
        <Controller
          control={control}
          key="date-file"
          name="date"
          render={dateProps => {
            return (
              <Controller
                control={control}
                name="file"
                render={fileProps => {
                  const fileFieldErrorMessage =
                    fileProps.fieldState.error?.message ||
                    fileProps.formState.errors.file?.message ||
                    dateProps.formState.errors.file?.message;
                  const dateFieldErrorMessage =
                    dateProps.fieldState.error?.message ||
                    dateProps.formState.errors.date?.message ||
                    fileProps.formState.errors.date?.message;
                  const messageFieldErrorMessage =
                    fileProps.formState.errors.message?.message ||
                    dateProps.formState.errors.message?.message;
                  const rootErrorMessage =
                    fileProps.formState.errors.root?.message ||
                    dateProps.formState.errors.root?.message;

                  const errorMessage =
                    (dateProps.field.value !== null ||
                      fileProps.field.value !== null) &&
                    (fileFieldErrorMessage ||
                      dateFieldErrorMessage ||
                      messageFieldErrorMessage ||
                      rootErrorMessage);

                  return (
                    <StyledFileDropzone
                      {...dropzoneOptions}
                      dragOverlay={dragOverlay}
                      dropzoneRef={dropzoneRef}
                      inputRef={fileProps.field.ref}
                      disabled={formDisabled}
                      inputProps={{ name: fileProps.field.name }}
                      onDropAccepted={files => {
                        const file = files[0];

                        if (!file) {
                          return;
                        }

                        handleErrorMessageClear();
                        fileProps.field.onChange(file);
                      }}
                    >
                      <ChatFormInputField
                        disabled={formDisabled}
                        value=""
                        onBlur={() => {
                          dateProps.field.onBlur();
                          fileProps.field.onBlur();
                        }}
                        placeholder={
                          placeholder || '궁금한 것들을 더 찾아보세요'
                        }
                        InputProps={{
                          startAdornment: (
                            <ChatFormInputAdornment position="start">
                              {startAdornment?.(control)}
                              <IconButton
                                edge="start"
                                disabled={formDisabled}
                                onClick={openFilePicker}
                              >
                                <IcAttachment />
                              </IconButton>

                              <ButtonDatePicker
                                disabled={formDisabled}
                                value={dateProps.field.value}
                                onChange={dateProps.field.onChange}
                                format={dateFormat}
                                slots={{
                                  // @ts-expect-error internal prop
                                  dialog: DatePickerDialog,
                                }}
                                slotProps={{
                                  dialog: {
                                    container: document.getElementById(
                                      theme.modalContainer.id,
                                    ),
                                  },
                                  field: {
                                    // @ts-expect-error internal component
                                    edge: fileFieldEnabled
                                      ? undefined
                                      : 'start',
                                  },
                                }}
                              />
                            </ChatFormInputAdornment>
                          ),
                          endAdornment: (
                            <ChatFormInputAdornment position="end">
                              {(fileProps.field.value ||
                                dateProps.field.value) &&
                                !formDisabled && (
                                  <IconButton
                                    onClick={() => {
                                      fileProps.field.onChange(null);
                                      dateProps.field.onChange(null);
                                      trigger(['date', 'file']);
                                    }}
                                  >
                                    <IcClose />
                                  </IconButton>
                                )}

                              {errorMessage ? (
                                <ChatFormErrorMessage
                                  edge="end"
                                  title={errorMessage}
                                  onClear={handleErrorMessageClear}
                                />
                              ) : (
                                submitButton
                              )}
                            </ChatFormInputAdornment>
                          ),
                        }}
                      />
                    </StyledFileDropzone>
                  );
                }}
              />
            );
          }}
        />
      </ChatFormRoot>
    );
  }

  const autocompleteInputProps = autocomplete?.getInputProps();

  const calculateHintPosition = (ev: React.UIEvent) => {
    const win = (ev.target as HTMLElement).ownerDocument?.defaultView ?? window;

    if (
      !(
        (ev.target instanceof HTMLElement ||
          ev.target instanceof win.HTMLElement) &&
        (ev.target.nodeName === 'INPUT' || ev.target.nodeName === 'TEXTAREA')
      )
    ) {
      return;
    }

    const input = ev.target as HTMLInputElement | HTMLTextAreaElement;
    setInputScrollLeft(input.scrollLeft);
  };

  return (
    <ChatFormRoot
      onSubmit={handleSubmit(submit)}
      ref={formRef}
      popupOpen={popupOpen}
    >
      <Controller
        control={control}
        key="message"
        name="message"
        render={messageProps => {
          return (
            <Controller
              control={control}
              name="file"
              render={fileProps => {
                return (
                  <Controller
                    control={control}
                    name="date"
                    render={dateProps => {
                      const { ref, ...messageField } = messageProps.field;
                      const messageFieldErrorMessage =
                        messageProps.fieldState.error?.message ||
                        messageProps.formState.errors.message?.message ||
                        fileProps.formState.errors.message?.message ||
                        dateProps.formState.errors.message?.message;
                      const fileFieldErrorMessage =
                        fileProps.fieldState.error?.message ||
                        fileProps.formState.errors.file?.message ||
                        messageProps.formState.errors.file?.message ||
                        dateProps.formState.errors.file?.message;
                      const dateFieldErrorMessage =
                        dateProps.fieldState.error?.message ||
                        dateProps.formState.errors.date?.message ||
                        messageProps.formState.errors.date?.message ||
                        fileProps.formState.errors.date?.message;
                      const rootErrorMessage =
                        messageProps.formState.errors.root?.message ||
                        fileProps.formState.errors.root?.message ||
                        dateProps.formState.errors.root?.message;

                      // prefer showing file field error message first
                      const nonMessageFieldErrorMessage =
                        fileFieldEnabled ||
                        (!fileFieldEnabled && !dateFieldEnabled)
                          ? fileFieldErrorMessage || dateFieldErrorMessage
                          : dateFieldErrorMessage || fileFieldErrorMessage;
                      const errorMessage =
                        (messageProps.field.value ||
                          fileProps.field.value !== null ||
                          dateProps.field.value !== null) &&
                        (messageFieldErrorMessage ||
                          nonMessageFieldErrorMessage ||
                          rootErrorMessage);

                      const inputRef = combineRefs(
                        autocompleteInputProps?.ref ?? null,
                        messageProps.field.ref,
                      );

                      const inputField = (
                        <div
                          css={css`
                            position: relative;
                          `}
                          {...autocomplete?.getRootProps()}
                        >
                          {hintRef && (
                            <Text
                              component="div"
                              variant="body14"
                              css={css`
                                position: absolute;
                                top: 8px;
                                left: ${dateFieldEnabled || fileFieldEnabled
                                  ? 42 +
                                    (dateFieldEnabled && fileFieldEnabled
                                      ? 36
                                      : 0)
                                  : 16}px;
                                color: ${theme.palette.text.secondary};
                                pointer-events: none;
                                overflow: hidden;
                                bottom: 8px;
                                right: 16px;
                              `}
                            >
                              <div
                                css={css`
                                  position: absolute;
                                  top: 0;
                                  left: 0;
                                  line-height: 32px;
                                `}
                                style={{
                                  transform: `translateX(${inputScrollLeft}px)`,
                                }}
                              >
                                {hintRef.current}
                              </div>
                            </Text>
                          )}

                          <ChatFormInputField
                            {...messageField}
                            inputRef={inputRef}
                            disabled={formDisabled}
                            autoFocus={autoFocus}
                            multiline
                            onKeyUp={calculateHintPosition}
                            onScroll={calculateHintPosition}
                            onClick={calculateHintPosition}
                            onBlur={() => {
                              if (hintRef) {
                                hintRef.current = '';
                              }

                              messageField.onBlur();
                            }}
                            onKeyDown={async ev => {
                              if (
                                ev.key === 'Tab' &&
                                hintRef?.current &&
                                hintRef.current !== messageField.value
                              ) {
                                messageField.onChange(hintRef.current);
                                ev.preventDefault();

                                if (
                                  (ev.target as Node).nodeName === 'INPUT' ||
                                  (ev.target as Node).nodeName === 'TEXTAREA'
                                ) {
                                  const input = ev.target as
                                    | HTMLInputElement
                                    | HTMLTextAreaElement;

                                  await raf();
                                  input.selectionStart = input.value.length;
                                  input.selectionEnd = input.value.length;
                                  input.scrollLeft = input.scrollWidth;
                                }
                              } else if (
                                ev.key === 'Enter' &&
                                !ev.shiftKey &&
                                !ev.nativeEvent.isComposing
                              ) {
                                ev.preventDefault();
                                ev.stopPropagation();
                                handleSubmit(submit)();
                              }

                              if (errorMessage) {
                                handleErrorMessageClear();
                              }
                            }}
                            placeholder={
                              placeholder || '궁금한 것들을 더 찾아보세요'
                            }
                            InputLabelProps={autocomplete?.getInputLabelProps()}
                            InputProps={{
                              ref: autocomplete?.setAnchorEl,
                              onClick(ev) {
                                if (ev.target === ev.currentTarget) {
                                  autocompleteInputProps?.onMouseDown?.(
                                    ev as any,
                                  );
                                }
                              },
                              startAdornment: (fileFieldEnabled ||
                                dateFieldEnabled ||
                                startAdornment) && (
                                <ChatFormInputAdornment position="start">
                                  {startAdornment?.(control)}
                                  {fileFieldEnabled && (
                                    <IconButton
                                      edge="start"
                                      disabled={formDisabled}
                                      onClick={openFilePicker}
                                    >
                                      <IcAttachment />
                                    </IconButton>
                                  )}

                                  {dateFieldEnabled && (
                                    <ButtonDatePicker
                                      disabled={formDisabled}
                                      value={dateProps.field.value}
                                      onChange={dateProps.field.onChange}
                                      format={dateFormat}
                                      slots={{
                                        // @ts-expect-error internal prop
                                        dialog: DatePickerDialog,
                                      }}
                                      slotProps={{
                                        dialog: {
                                          container: document.getElementById(
                                            theme.modalContainer.id,
                                          ),
                                        },
                                        field: {
                                          // @ts-expect-error internal component
                                          edge: fileFieldEnabled
                                            ? undefined
                                            : 'start',
                                        },
                                      }}
                                    />
                                  )}
                                </ChatFormInputAdornment>
                              ),
                              endAdornment: (
                                <ChatFormInputAdornment position="end">
                                  {errorMessage ? (
                                    <ChatFormErrorMessage
                                      edge="end"
                                      title={errorMessage}
                                    />
                                  ) : (
                                    submitButton
                                  )}
                                </ChatFormInputAdornment>
                              ),
                            }}
                            inputProps={autocompleteInputProps}
                          />
                        </div>
                      );

                      if (fileFieldEnabled) {
                        return (
                          <StyledFileDropzone
                            {...dropzoneOptions}
                            dragOverlay={dragOverlay}
                            dropzoneRef={dropzoneRef}
                            inputRef={fileProps.field.ref}
                            disabled={formDisabled}
                            inputProps={{ name: fileProps.field.name }}
                            onDropAccepted={files => {
                              const file = files[0];

                              if (!file) {
                                return;
                              }

                              handleErrorMessageClear();
                              fileProps.field.onChange(file);
                            }}
                          >
                            {inputField}
                          </StyledFileDropzone>
                        );
                      }

                      return inputField;
                    }}
                  />
                );
              }}
            />
          );
        }}
      />

      {popupOpen && <ChatFormAutocomplete autocomplete={autocomplete} />}
    </ChatFormRoot>
  );
};
