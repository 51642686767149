import { FunctionComponent } from 'react';
import { MessengerFooter } from '../messenger/messenger-footer';
import { css } from '@emotion/react';

export const conversationDetailFooterMinHeight = 60;

interface ConversationDetailFooterProps {
  className?: string;
  children?: React.ReactNode;
  navigation?: React.ReactNode;
}

export const ConversationDetailFooter: FunctionComponent<
  ConversationDetailFooterProps
> = ({ className, children, navigation }) => (
  <MessengerFooter
    css={css`
      min-height: ${conversationDetailFooterMinHeight}px;
    `}
    className={className}
  >
    {children}
    {navigation}
  </MessengerFooter>
);
