/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatAgentFragment } from './chat-agent-fragment';
import { gql } from '@apollo/client';
import { ChatAgentFragmentDoc } from './chat-agent-fragment';
export type EventChatFragment = { __typename: 'EventChat' } & Pick<
  Types.EventChat,
  | 'id'
  | 'createdAt'
  | 'hidden'
  | 'eventType'
  | 'eventFallbackMessage'
  | 'eventAdditionalInfo'
> & { enteringAgent: Types.Maybe<{ __typename: 'Agent' } & ChatAgentFragment> };

export const EventChatFragmentDoc = gql`
  fragment EventChatFragment on EventChat {
    id
    createdAt
    hidden
    eventType
    eventFallbackMessage
    eventAdditionalInfo
    enteringAgent {
      ...ChatAgentFragment
    }
  }
  ${ChatAgentFragmentDoc}
`;
