/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserQueryVariables = UserQueryTypes.Exact<{
  [key: string]: never;
}>;

export type CurrentUserQuery = { __typename: 'UserQuery' } & {
  me: UserQueryTypes.Maybe<
    { __typename: 'User' } & Pick<
      UserQueryTypes.User,
      | 'id'
      | 'email'
      | 'phone'
      | 'firstName'
      | 'lastName'
      | 'name'
      | 'avatar'
      | 'createdAt'
      | 'locale'
      | 'ownUserId'
      | 'isTry'
      | 'temporary'
    >
  >;
};

export const CurrentUserQueryDocument = gql`
  query CurrentUserQuery {
    me {
      id
      email
      phone
      firstName
      lastName
      name
      avatar
      createdAt
      locale
      ownUserId
      isTry
      temporary
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserQueryDocument,
    options,
  );
}
export function useCurrentUserQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserQueryDocument,
    options,
  );
}
export function useCurrentUserQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserQueryDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserQueryLazyQueryHookResult = ReturnType<
  typeof useCurrentUserQueryLazyQuery
>;
export type CurrentUserQuerySuspenseQueryHookResult = ReturnType<
  typeof useCurrentUserQuerySuspenseQuery
>;
export type CurrentUserQueryQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
