import { Fade } from '@allganize/ui-transition';
import { useMobilePicker as useMuiMobilePicker } from '@mui/x-date-pickers/internals/hooks/useMobilePicker/useMobilePicker';
import { PickersLayout } from '../../../pickers-layout';
import { PickersModalDialogRoot } from '../../../pickers-modal-dialog';
import { DateOrTimeViewWithMeridiem } from '../../models';
import {
  UseMobilePickerParams,
  UseMobilePickerProps,
} from './use-mobile-picker.types';

export const useMobilePicker = <
  TView extends DateOrTimeViewWithMeridiem,
  TExternalProps extends UseMobilePickerProps<TView, any, TExternalProps>,
>(
  params: UseMobilePickerParams<TView, TExternalProps>,
) => {
  const { props, ...pickerParams } = params;
  const { slots, ...other } = props;

  // @ts-expect-error internal component
  return useMuiMobilePicker<Date, TView, TExternalProps>({
    ...pickerParams,
    props: {
      ...other,
      slots: {
        layout: PickersLayout,
        dialog: PickersModalDialogRoot,
        mobileTransition: Fade,
        ...slots,
      },
    },
  });
};
