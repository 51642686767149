/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatKnowledgeBasePreviewFragment } from './chat-knowledge-base-preview-fragment';
import { gql } from '@apollo/client';
import { ChatKnowledgeBasePreviewFragmentDoc } from './chat-knowledge-base-preview-fragment';
export type ChatMRCInfoFragment = { __typename: 'ChatMRCInfo' } & Pick<
  Types.ChatMRCInfo,
  'id' | 'answer' | 'rating' | 'ratable' | 'mrcAnswerType'
> & {
    knowledgeBasePreview: Types.Maybe<
      { __typename: 'KnowledgeBasePreview' } & ChatKnowledgeBasePreviewFragment
    >;
  };

export const ChatMRCInfoFragmentDoc = gql`
  fragment ChatMRCInfoFragment on ChatMRCInfo {
    id
    answer
    rating
    ratable
    mrcAnswerType
    knowledgeBasePreview {
      ...ChatKnowledgeBasePreviewFragment
    }
  }
  ${ChatKnowledgeBasePreviewFragmentDoc}
`;
