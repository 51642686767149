/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatFileFragment } from './chat-file-fragment';
import { ChatVariableFragment } from './chat-variable-fragment';
import { ChatMediaFragment } from './chat-media-fragment';
import { gql } from '@apollo/client';
import { ChatFileFragmentDoc } from './chat-file-fragment';
import { ChatVariableFragmentDoc } from './chat-variable-fragment';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
export type FormValueFragment = { __typename: 'FormValue' } & Pick<
  Types.FormValue,
  'display' | 'value' | 'mandatory' | 'choices' | 'multipleOption' | 'inputType'
> & {
    file: Types.Maybe<{ __typename: 'File' } & ChatFileFragment>;
    variable: Types.Maybe<{ __typename: 'Variable' } & ChatVariableFragment>;
    formValueInfos: Types.Maybe<
      Array<
        Types.Maybe<{ __typename: 'FormValueInfo' } & FormValueInfoFragment>
      >
    >;
  };

export type FormValueInfoFragment = { __typename: 'FormValueInfo' } & Pick<
  Types.FormValueInfo,
  'shortenOption' | 'longOption'
> & {
    style: { __typename: 'OptionStyle' } & Pick<
      Types.OptionStyle,
      'bgColor' | 'bold' | 'fontColor' | 'italic' | 'lineColor' | 'underline'
    >;
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
  };

export const FormValueInfoFragmentDoc = gql`
  fragment FormValueInfoFragment on FormValueInfo {
    shortenOption
    longOption
    style {
      bgColor
      bold
      fontColor
      italic
      lineColor
      underline
    }
    media {
      ...ChatMediaFragment
    }
  }
  ${ChatMediaFragmentDoc}
`;
export const FormValueFragmentDoc = gql`
  fragment FormValueFragment on FormValue {
    display
    value
    mandatory
    choices
    multipleOption
    inputType
    file {
      ...ChatFileFragment
    }
    variable {
      ...ChatVariableFragment
    }
    formValueInfos {
      ...FormValueInfoFragment
    }
  }
  ${ChatFileFragmentDoc}
  ${ChatVariableFragmentDoc}
  ${FormValueInfoFragmentDoc}
`;
