import { ExtendPaperTypeMap, Paper } from '@allganize/ui-paper';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import MuiPopper, { PopperPlacementType } from '@mui/material/Popper';
import { ThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { pickersPopperClasses } from './pickers-popper-classes';
import { PickersPopperProps } from './pickers-popper-type-map';

// @ts-expect-error internal component
export const PickersPopperRoot: typeof MuiPopper = forwardRef((props, ref) => {
  const theme = useTheme();
  const muiTheme = useMuiTheme();

  return (
    <ThemeProvider theme={{ ...muiTheme, direction: theme.direction }}>
      <MuiPopper
        css={css`
          z-index: ${theme.zIndex.modal};
        `}
        {...props}
        ref={ref}
      />
    </ThemeProvider>
  );
});

type PickersPopperPaperTypeMap<
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {},
  D extends React.ElementType = 'div',
> = ExtendPaperTypeMap<{
  props: P & {
    ownerState: PickersPopperProps & {
      placement: PopperPlacementType | undefined;
    };
  };
  defaultComponent: D;
}>;

// @ts-expect-error overridable component
export const PickersPopperPaper: OverridableComponent<PickersPopperPaperTypeMap> =
  forwardRef((props, ref) => {
    const { ownerState, ...other } = props;
    const theme = useTheme();
    const isRtl = theme.direction === 'rtl';

    return (
      <Paper
        css={[
          css`
            transform-origin: top center;
            outline: 0;

            .${pickersPopperClasses.root}[data-popper-placement*="left"] & {
              margin-right: 8px;

              ${isRtl &&
              css`
                margin-left: 8px;
              `}
            }

            .${pickersPopperClasses.root}[data-popper-placement*="right"] & {
              margin-left: 8px;

              ${isRtl &&
              css`
                margin-right: 8px;
              `}
            }

            .${pickersPopperClasses.root}[data-popper-placement*="top"] & {
              margin-bottom: 8px;
            }

            .${pickersPopperClasses.root}[data-popper-placement*="bottom"] & {
              margin-top: 8px;
            }
          `,
          ownerState.placement === 'top' &&
            css`
              transform-origin: bottom center;
            `,
        ]}
        {...other}
        ref={ref}
      />
    );
  });
