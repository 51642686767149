import { AlliBranding } from '@allganize/alli-sdk-ui';
import { css } from '@emotion/react';
import { Fragment, FunctionComponent } from 'react';
import { analytics } from '../analytics';
import {
  ChatListErrorState,
  ChatListErrorStateProps,
} from '../chat-list/chat-list-error-state';

export const ConversationDetailErrorState: FunctionComponent<
  Pick<ChatListErrorStateProps, 'onRetry'> & {
    conversationId?: string | number;
  }
> = ({ conversationId, onRetry }) => {
  return (
    <Fragment>
      <ChatListErrorState
        css={css`
          margin: 48px 0;
          padding: 0 16px;
        `}
        onRetry={onRetry}
      />

      <AlliBranding
        onClick={() =>
          analytics.track('click_alli_branding', {
            conversationId,
            source: 'conversation-detail',
          })
        }
      />
    </Fragment>
  );
};
