import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, ApolloLink, split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

import { AuthClient } from '../auth/auth-client';
import { logger } from '../core/logger/logger';

import { cache } from './cache';
import { createErrorLink } from './error-link';
import { httpLink } from './http-link';
import { SubscriptionClient } from './subscription-client';

export const createRequestLink = (subscriptionClient: SubscriptionClient) =>
  split(
    op => {
      const definition = getMainDefinition(op.query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    new GraphQLWsLink(subscriptionClient),
    httpLink,
  );

export const createApolloClient = (
  authClient: AuthClient,
  ...links: ApolloLink[]
) =>
  new ApolloClient<NormalizedCacheObject>({
    version: 'version',
    cache,
    link: ApolloLink.from([createErrorLink(authClient), ...links]),
    connectToDevTools: logger.enabled,
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
      },
    },
  });
