import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Components } from 'react-markdown/lib/ast-to-react';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Pre: Components['pre'] = ({ children, ...other }) => {
  const theme = useTheme();
  return (
    <pre
      css={css`
        padding: 24px 20px;
        border-radius: ${theme.radius.xs}px;
        background-color: ${theme.palette.unstable_background.default};
        color: ${theme.palette.unstable_foreground.secondary};

        code {
          padding: 0;
          background-color: transparent;
        }
      `}
      {...other}
    >
      {children}
    </pre>
  );
};

export const Code: Components['code'] = ({ children, ...other }) => {
  const theme = useTheme();
  return (
    <code
      css={css`
        padding: 2px 6px;
        border-radius: ${theme.radius.xs}px;
        background-color: ${theme.palette.unstable_background.default};
        color: ${theme.palette.unstable_foreground.secondary};
        white-space: break-spaces;
      `}
      {...other}
    >
      {children}
    </code>
  );
};

export const Blockquote: Components['blockquote'] = ({
  children,
  ...other
}) => {
  const theme = useTheme();
  return (
    <blockquote
      css={css`
        margin: 16px 0;
        padding-left: 20px;
        color: ${theme.palette.unstable_foreground.secondary};
        border-left: 4px solid ${theme.palette.unstable_border.divider.default};
      `}
      {...other}
    >
      {children}
    </blockquote>
  );
};

export const Table: Components['table'] = ({ children, ...other }) => {
  const theme = useTheme();
  return (
    <table
      css={css`
        th,
        tr,
        td {
          border: 1px solid ${theme.palette.unstable_border.divider.default};
        }

        th,
        td {
          padding: 8px 12px;
        }
      `}
      {...other}
    >
      {children}
    </table>
  );
};

export const Img = styled.img`
  max-width: 100%;
  max-height: 400px;
`;
