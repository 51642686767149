import { AgentSelectContextValue } from '@allganize/alli-sdk-chat';
import { useChatAgentsQuery } from '../graphql/queries/chat-agents-query';

export const useAgentSelect = (): AgentSelectContextValue => {
  const { data, loading } = useChatAgentsQuery();

  return {
    loading,
    options: data?.agents ?? [],
  };
};
