/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type SDKUrlPatternFragment = { __typename: 'SDKUrlPattern' } & Pick<
  Types.SDKUrlPattern,
  'id' | 'urlPattern' | 'regex'
>;

export const SDKUrlPatternFragmentDoc = gql`
  fragment SDKUrlPatternFragment on SDKUrlPattern {
    id
    urlPattern
    regex
  }
`;
