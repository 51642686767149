import { AlliAppLauncherAnalytics } from '@allganize/alli-app-launcher-analytics';
import { environment } from './environments/environment';

export const analytics = new AlliAppLauncherAnalytics({
  mixpanel: environment.mixpanel
    ? {
        token: environment.mixpanel.token,
        name: 'alli-app-launcher',
        config: {
          track_pageview: false,
        },
      }
    : undefined,
});
