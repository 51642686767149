/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type ChatVariableFragment = { __typename: 'Variable' } & Pick<
  Types.Variable,
  | 'id'
  | 'name'
  | 'description'
  | 'displayText'
  | 'validationCustom'
  | 'validationFailedMessage'
  | 'type'
>;

export const ChatVariableFragmentDoc = gql`
  fragment ChatVariableFragment on Variable {
    id
    name
    description
    displayText
    validationCustom
    validationFailedMessage
    type
  }
`;
