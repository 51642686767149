/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type AnswerHighlightFragment = { __typename: 'AnswerHighlight' } & Pick<
  Types.AnswerHighlight,
  'type'
> & {
    bbox: { __typename: 'AnswerHighlightBox' } & Pick<
      Types.AnswerHighlightBox,
      'bottom' | 'left' | 'right' | 'top'
    >;
  };

export const AnswerHighlightFragmentDoc = gql`
  fragment AnswerHighlightFragment on AnswerHighlight {
    type
    bbox {
      bottom
      left
      right
      top
    }
  }
`;
