/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { ChatVariableFragment } from './chat-variable-fragment';
import { ChatMRCInfoFragment } from './chat-mrc-info-fragment';
import { ChatOptionInfoFragment } from './chat-option-info-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
import { ChatVariableFragmentDoc } from './chat-variable-fragment';
import { ChatMRCInfoFragmentDoc } from './chat-mrc-info-fragment';
import { ChatOptionInfoFragmentDoc } from './chat-option-info-fragment';
export type CarouselChatFragment = { __typename: 'CarouselChat' } & Pick<
  Types.CarouselChat,
  | 'id'
  | 'createdAt'
  | 'message'
  | 'messageContentState'
  | 'hidden'
  | 'plainText'
  | 'placeholder'
  | 'multipleOption'
  | 'nextInputType'
  | 'useAutoComplete'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    saveAsVariable: Types.Maybe<
      { __typename: 'Variable' } & ChatVariableFragment
    >;
    chatMrcInfos: Types.Maybe<
      Array<Types.Maybe<{ __typename: 'ChatMRCInfo' } & ChatMRCInfoFragment>>
    >;
    chatOptionInfos: Types.Maybe<
      Array<
        Types.Maybe<{ __typename: 'ChatOptionInfo' } & ChatOptionInfoFragment>
      >
    >;
  };

export const CarouselChatFragmentDoc = gql`
  fragment CarouselChatFragment on CarouselChat {
    id
    createdAt
    message
    messageContentState @client
    hidden
    plainText
    placeholder
    media {
      ...ChatMediaFragment
    }
    saveAsVariable {
      ...ChatVariableFragment
    }
    chatMrcInfos {
      ...ChatMRCInfoFragment
    }
    multipleOption
    nextInputType
    useAutoComplete
    chatOptionInfos {
      ...ChatOptionInfoFragment
    }
  }
  ${ChatMediaFragmentDoc}
  ${ChatVariableFragmentDoc}
  ${ChatMRCInfoFragmentDoc}
  ${ChatOptionInfoFragmentDoc}
`;
