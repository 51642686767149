import { ProjectProvider } from '@allganize/alli-app-market-project';
import { ToastContainer } from '@allganize/ui-toast';
import { ErrorBoundary } from '@sentry/react';
import { css, useTheme } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import { CurrentUserProvider } from '../auth/current-user-provider';
import { ErrorPage } from '../pages/ErrorPage';
import { LoadingPage } from '../pages/LoadingPage';
import { OktaSecurity } from '../services/okta/okta-security';
import { GlobalStyles } from '../styles/global-styles';
import { Meta } from './Meta';

export const App = () => {
  const theme = useTheme();

  return (
    <OktaSecurity>
      <Meta />
      <GlobalStyles />
      <ToastContainer />
      <ErrorBoundary
        fallback={
          <ErrorPage
            css={css`
              background-color: ${theme.palette.grey[50]};
            `}
            title={
              <FormattedMessage
                id="error-page.title"
                defaultMessage="Oops! It looks like this link has expired"
                description="Error page title text"
              />
            }
          >
            <FormattedMessage
              id="error-page.description"
              defaultMessage="Please contact the administrator for assistance or request a new link."
              description="Error page description text"
            />
          </ErrorPage>
        }
      >
        <Suspense fallback={<LoadingPage />}>
          <ProjectProvider fetchPolicy="network-only">
            <CurrentUserProvider>
              <Outlet />
            </CurrentUserProvider>
          </ProjectProvider>
        </Suspense>
      </ErrorBoundary>
    </OktaSecurity>
  );
};
