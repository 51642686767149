/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { gql } from '@apollo/client';
export type LLMAppFragment = { __typename: 'LLMApp' } & Pick<
  UserQueryTypes.LLMApp,
  'id' | 'name' | 'description' | 'type' | 'category' | 'icon'
> & {
    campaign: UserQueryTypes.Maybe<
      { __typename: 'Campaign' } & Pick<
        UserQueryTypes.Campaign,
        'id' | 'popupUrl'
      >
    >;
    singleActionApp: UserQueryTypes.Maybe<
      { __typename: 'SingleActionApp' } & Pick<
        UserQueryTypes.SingleActionApp,
        'id' | 'publicToken'
      >
    >;
  };

export const LLMAppFragmentDoc = gql`
  fragment LLMAppFragment on LLMApp {
    id
    name
    description
    type
    category
    icon
    campaign {
      id
      popupUrl
    }
    singleActionApp {
      id
      publicToken
    }
  }
`;
