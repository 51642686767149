import { Types } from '@allganize/alli-sdk-interfaces';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { ErrorFragment } from '../graphql/fragments/error-fragment';

export const mallyErrors = defineMessages({
  UNKNOWN: {
    id: 'MallyError.UNKNOWN',
    defaultMessage: 'Something went wrong. Please try again later.',
    description: 'MallyError.UNKNOWN',
  },
}) satisfies Partial<Record<Types.MallyError, MessageDescriptor>>;

export const getMallyErrorMessageDescriptor = (
  err: ErrorFragment,
): MessageDescriptor & { values?: Record<string, any> } => {
  let values: Record<string, any> | undefined = undefined;

  if (err.info) {
    try {
      values = JSON.parse(err.info);
    } catch {
      // nothing to do
    }
  }

  return {
    id: `MallyError.${err.key}`,
    defaultMessage:
      err.message ||
      (mallyErrors as Partial<Record<Types.MallyError, MessageDescriptor>>)[
        err.key
      ]?.defaultMessage ||
      err.key,
    description: `MallyError.${err.key}`,
    values,
  };
};
