import { Slide, SlideProps } from '@allganize/ui-transition';
import { FunctionComponent } from 'react';
import {
  collapseToast,
  ToastPosition,
  ToastTransitionProps,
} from 'react-toastify';

const directionMap: Record<ToastPosition, SlideProps['direction']> = {
  'top-right': 'left',
  'top-center': 'down',
  'top-left': 'right',
  'bottom-right': 'left',
  'bottom-center': 'up',
  'bottom-left': 'right',
};

export const ToastTransition: FunctionComponent<ToastTransitionProps> = ({
  isIn,
  done,
  position,
  nodeRef,
  children,
}) => {
  const handleEntered = () => {
    nodeRef.current?.dispatchEvent(new Event('d'));
  };

  const handleExited = () => {
    if (nodeRef.current) {
      collapseToast(nodeRef.current, done, 300);
    } else {
      done();
    }
  };

  return (
    <Slide
      appear
      in={isIn}
      direction={
        directionMap[position as keyof typeof directionMap] || undefined
      }
      onEntered={handleEntered}
      onExited={handleExited}
    >
      {children as React.ReactElement}
    </Slide>
  );
};
