import { useConversationList } from '@allganize/alli-sdk/hooks/use-conversation-list';
import { useWindow } from '@allganize/hooks';
import { List } from '@allganize/ui-list';
import styled from '@emotion/styled';
import { compact } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import {
  Virtualizer,
  VirtualizerOptions,
  observeWindowOffset,
  observeWindowRect,
  useVirtualizer,
  windowScroll,
} from '@tanstack/react-virtual';
import { HistoryListItem, listItemSize } from './history-list-item';

const listItemVerticalPadding = 8;
const listVerticalPadding = 16;
const skeletonCount = 3;

const itemSize = listItemSize + listItemVerticalPadding;

const ListWrapper = styled.div`
  height: 100%;
  overflow: hidden auto;
  flex: 1;
`;

const ListContent = styled(List)`
  display: flex;
  flex-direction: column;
  gap: ${listItemVerticalPadding}px;
`;

export const HistoryList = () => {
  const { window: contentWindow, ref: rootRef } = useWindow();
  const { data, loadNextPage } = useConversationList();
  const nodes = useMemo(
    () => compact(data.conversations?.edges.map(edge => edge?.node) ?? []),
    [data?.conversations?.edges],
  );
  const virtualizer = useVirtualizer({
    getScrollElement: () => contentWindow as unknown as Element | null,
    observeElementRect: observeWindowRect as unknown as VirtualizerOptions<
      Element,
      Element
    >['observeElementRect'],
    observeElementOffset: observeWindowOffset as unknown as VirtualizerOptions<
      Element,
      Element
    >['observeElementOffset'],
    scrollToFn(
      offset,
      { adjustments = contentWindow?.scrollY ?? window.scrollY, ...options },
      instance,
    ) {
      windowScroll(
        offset,
        { adjustments, ...options },
        instance as unknown as Virtualizer<Window, Element>,
      );
    },
    count: nodes.length,
    estimateSize: () => itemSize,
    overscan: skeletonCount,
    paddingStart: listVerticalPadding,
    paddingEnd: listVerticalPadding,
  });
  const virtualItems = virtualizer.getVirtualItems();
  const lastItem = virtualItems[virtualItems.length - 1];

  useEffect(() => {
    if (!lastItem) {
      return;
    }

    if (lastItem.index >= nodes.length - 1 - skeletonCount) {
      loadNextPage();
    }
  }, [lastItem, loadNextPage, nodes.length]);

  if (virtualItems.length === 0) return null;

  return (
    <ListWrapper>
      <ListContent
        style={{
          width: '100%',
          height: virtualizer.getTotalSize(),
        }}
        ref={rootRef}
      >
        {virtualItems.map(virtualRow => {
          const conversation = nodes[virtualRow.index];

          return (
            <li key={virtualRow.index}>
              <HistoryListItem data={conversation} />
            </li>
          );
        })}
      </ListContent>
    </ListWrapper>
  );
};
