/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatAgentFragment } from '../fragments/chat-agent-fragment';
import { gql } from '@apollo/client';
import { ChatAgentFragmentDoc } from '../fragments/chat-agent-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatAgentsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ChatAgentsQuery = { __typename: 'UserQuery' } & {
  agents: Array<Types.Maybe<{ __typename: 'Agent' } & ChatAgentFragment>>;
};

export const ChatAgentsQueryDocument = gql`
  query ChatAgentsQuery {
    agents {
      ...ChatAgentFragment
    }
  }
  ${ChatAgentFragmentDoc}
`;

/**
 * __useChatAgentsQuery__
 *
 * To run a query within a React component, call `useChatAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatAgentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatAgentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChatAgentsQuery,
    ChatAgentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatAgentsQuery, ChatAgentsQueryVariables>(
    ChatAgentsQueryDocument,
    options,
  );
}
export function useChatAgentsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatAgentsQuery,
    ChatAgentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatAgentsQuery, ChatAgentsQueryVariables>(
    ChatAgentsQueryDocument,
    options,
  );
}
export function useChatAgentsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ChatAgentsQuery,
    ChatAgentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ChatAgentsQuery, ChatAgentsQueryVariables>(
    ChatAgentsQueryDocument,
    options,
  );
}
export type ChatAgentsQueryHookResult = ReturnType<typeof useChatAgentsQuery>;
export type ChatAgentsQueryLazyQueryHookResult = ReturnType<
  typeof useChatAgentsQueryLazyQuery
>;
export type ChatAgentsQuerySuspenseQueryHookResult = ReturnType<
  typeof useChatAgentsQuerySuspenseQuery
>;
export type ChatAgentsQueryQueryResult = Apollo.QueryResult<
  ChatAgentsQuery,
  ChatAgentsQueryVariables
>;
