import { Button } from '@allganize/ui-button';
import { IcAdd } from '@allganize/ui-icons';
import { List, ListItem, ListProps } from '@allganize/ui-list';
import { css } from '@emotion/react';
import { useMatch } from 'react-router-dom';
import { Link } from '../common/link';
import { analytics } from '../../analytics';
import { useTheme } from '@allganize/ui-theme';

export const MenuList = ({ children, ...others }: ListProps) => {
  const matchIndex = useMatch('/');
  const handleClickNewChat = () => {
    analytics.track('click_nav_item', {
      destination: 'new_home',
    });
  };

  const theme = useTheme();
  const ButtonStyle = css`
    display: flex;
    justify-content: center;
    width: 26px;
    &:hover {
      background-color: ${theme.taxPalette.grey[100]};
    }
    &:active {
      background-color: ${theme.taxPalette.grey[200]};
    }
  `;

  return (
    <List disablePadding {...others}>
      <ListItem disablePadding>
        <Button
          css={ButtonStyle}
          size="small"
          shape="rounded"
          color="secondary"
          endIcon={<IcAdd fontSize="small" />}
          component={Link}
          to="/"
          disabled={!!matchIndex}
          onClick={handleClickNewChat}
        ></Button>
      </ListItem>
      {children}
    </List>
  );
};
