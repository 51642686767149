import { AgentQueryTypes } from '@allganize/alli-interfaces';
import { InputField } from '@allganize/ui-input';
import { SelectField } from '@allganize/ui-select';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { Container } from '../components/styled-components';
import { SingleActionInput } from '../contexts/app-context';
import { DocumentUploadInput } from '../document-upload-input/document-upload-input';
import { getIsDocumentInput } from '../utils/misc';
import { SingleActionFormValues } from './single-action-form-values';
import { SubmitButton } from './submit-button';

interface SingleActionFormProps {
  singleActionInputs: SingleActionInput[] | null;
}

export const SingleActionForm: FunctionComponent<SingleActionFormProps> = ({
  singleActionInputs,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<SingleActionFormValues>({
    defaultValues: {
      userInput: (singleActionInputs || []).map(input => ({
        ...input,
        value: '',
      })),
    },
  });

  const { fields } = useFieldArray({
    control: control,
    name: 'userInput',
  });

  return (
    <Container className="single-action__single-action-form">
      <form
        css={css`
          display: flex;
          flex-direction: column;
          gap: 32px;
        `}
      >
        {fields.map((item, i) => {
          const { id, inputType, title, placeholder, options, knowledgeBases } =
            item;
          if (
            inputType === AgentQueryTypes.SingleActionInputType.TEXT ||
            inputType === AgentQueryTypes.SingleActionInputType.PARAGRAPH
          ) {
            return (
              <Controller
                key={id}
                control={control}
                name={`userInput.${i}.value`}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, ref, ...field } }) => {
                  return (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={title}
                      onChange={onChange}
                      placeholder={placeholder || ''}
                      multiline={
                        inputType ===
                        AgentQueryTypes.SingleActionInputType.PARAGRAPH
                      }
                      rows={8}
                      fullWidth
                      required
                    />
                  );
                }}
              />
            );
          }

          if (inputType === AgentQueryTypes.SingleActionInputType.SINGLE) {
            return (
              <Controller
                key={id}
                control={control}
                name={`userInput.${i}.value`}
                rules={{
                  required: true,
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <SelectField
                      {...field}
                      selectRef={ref}
                      label={title}
                      options={options || []}
                      fullWidth
                      required
                    />
                  );
                }}
              />
            );
          }

          if (inputType === AgentQueryTypes.SingleActionInputType.MULTI) {
            return (
              <Controller
                key={id}
                control={control}
                name={`userInput.${i}.value`}
                rules={{
                  required: true,
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <SelectField
                      {...field}
                      selectRef={ref}
                      label={title}
                      options={options || []}
                      fullWidth
                      required
                      isMulti
                    />
                  );
                }}
              />
            );
          }

          const { isDocumentInput, selectMode } = getIsDocumentInput(
            inputType,
            knowledgeBases,
          );

          if (isDocumentInput) {
            if (selectMode) {
              return (
                <Controller
                  key={id}
                  control={control}
                  name={`userInput.${i}.value`}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { ref, ...field } }) => {
                    return (
                      <SelectField
                        {...field}
                        selectRef={ref}
                        label={title}
                        options={knowledgeBases}
                        fullWidth
                        required
                      />
                    );
                  }}
                />
              );
            } else {
              return (
                <Controller
                  key={id}
                  control={control}
                  name={`userInput.${i}.value`}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange } }) => {
                    return (
                      <DocumentUploadInput
                        id={id}
                        label={title}
                        inputType={inputType}
                        onChange={onChange}
                        required
                      />
                    );
                  }}
                />
              );
            }
          }

          return null;
        })}
        <SubmitButton
          disabled={!isValid}
          handleSubmit={handleSubmit}
          css={css`
            align-self: flex-end;
          `}
        />
      </form>
    </Container>
  );
};
