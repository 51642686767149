import {
  IcDocumentSearch,
  IcDescription,
  IcSendMessage,
  IcGavel,
  SvgIconOwnProps,
} from '@allganize/ui-icons';
import { Interpolation, Theme } from '@emotion/react';

interface getTaxIconProps extends SvgIconOwnProps {
  appName: string;
  css?: Interpolation<Theme>;
}

const getTaxIcon = ({ appName, css, ...others }: getTaxIconProps) => {
  switch (appName) {
    case '통합':
      return <IcDocumentSearch {...others} />;
    case '법령':
      return <IcDescription {...others} />;
    case '세법해석례':
      return <IcSendMessage {...others} />;
    case '판례・결정례':
      return <IcGavel {...others} />;
  }
};

export default getTaxIcon;
