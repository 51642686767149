import { Carousel, CarouselRef, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { uaParser } from '../utils/ua-parser';
import { ChatMrcInfo } from './chat-mrc-info';
import { chatMrcInfoClasses } from './chat-mrc-info-classes';
import { chatMrcInfoListClasses } from './chat-mrc-info-list-classes';
import { ChatMrcInfoListProps } from './chat-mrc-info-list-type-map';

export const ChatMrcInfoList = forwardRef<CarouselRef, ChatMrcInfoListProps>(
  (props, ref) => {
    const { classes, data, onUserFeedbackSubmit, ...other } = props;

    if (data.length === 0) {
      return null;
    }

    return (
      <Carousel
        data-testid="chat-mrc-info-list"
        css={[
          css`
            .${chatMrcInfoClasses.root} {
              width: 292px;
            }
          `,
          uaParser.hasCarouselHeightBug &&
            css`
              .${carouselClasses.slide} {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;

                > div {
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: stretch;
                  justify-content: flex-start;
                }
              }
            `,
        ]}
        variableWidth
        {...other}
        ref={ref}
        className={clsx(
          chatMrcInfoListClasses.root,
          classes?.root,
          other.className,
        )}
      >
        {data.map(info => {
          if (!info) {
            return null;
          }

          return (
            <div
              key={info.id}
              css={[
                css`
                  padding: 0 4px;
                `,
                uaParser.hasCarouselHeightBug &&
                  css`
                    flex-grow: 1;
                    display: inline-flex !important;
                    flex-direction: column;
                    align-items: stretch;
                    justify-content: flex-start;
                  `,
              ]}
              className={clsx(chatMrcInfoListClasses.info, classes?.info)}
            >
              <ChatMrcInfo
                css={[
                  css`
                    height: 100%;
                  `,
                  uaParser.hasCarouselHeightBug &&
                    css`
                      height: auto;
                      flex-grow: 1;
                    `,
                ]}
                data={info}
                onUserFeedbackSubmit={onUserFeedbackSubmit}
              />
            </div>
          );
        })}
      </Carousel>
    );
  },
);
