import { CircularProgress } from '@allganize/ui-circular-progress';
import styled from '@emotion/styled';

const Root = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingPage = () => {
  return (
    <Root>
      <CircularProgress color="primary" size="xl" />
    </Root>
  );
};
