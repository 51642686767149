import { css } from '@emotion/react';
import MuiListItem from '@mui/material/ListItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { listItemButtonClasses } from '../list-item-button';
import { ListItemSecondaryAction } from '../list-item-secondary-action';
import { listItemClasses } from './list-item-classes';
import { ListItemTypeMap } from './list-item-type-map';

// @ts-expect-error overridable component
export const ListItem: OverridableComponent<ListItemTypeMap> = forwardRef(
  (props, ref) => {
    const {
      classes,
      children,
      disablePadding = false,
      secondaryAction,
      ...other
    } = props;

    return (
      <MuiListItem
        data-testid="list-item"
        css={[
          !disablePadding &&
            css`
              padding: 6px 12px;
            `,
          secondaryAction &&
            css`
              ${!disablePadding &&
              css`
                padding-right: 40px;
              `}

              & > .${listItemButtonClasses.root} {
                padding-right: 40px;
              }
            `,
        ]}
        classes={classes}
        disablePadding={disablePadding}
        {...other}
        ref={ref}
        className={clsx(
          { [listItemClasses.secondaryAction]: secondaryAction },
          { [classes?.secondaryAction ?? '']: secondaryAction },
          other.className,
        )}
      >
        {children}

        {secondaryAction && (
          <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
        )}
      </MuiListItem>
    );
  },
);
