/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { AgentChatFragment } from './agent-chat-fragment';
import { BotChatFragment } from './bot-chat-fragment';
import { CarouselChatFragment } from './carousel-chat-fragment';
import { ContactAgentByEmailChatFragment } from './contact-agent-by-email-chat-fragment';
import { CustomUIChatFragment } from './custom-ui-chat-fragment';
import { EventChatFragment } from './event-chat-fragment';
import { FillSlotRichUXChatFragment } from './fill-slot-rich-ux-chat-fragment';
import { SendFormChatFragment } from './send-form-chat-fragment';
import { UserChatFragment } from './user-chat-fragment';
import { gql } from '@apollo/client';
import { AgentChatFragmentDoc } from './agent-chat-fragment';
import { BotChatFragmentDoc } from './bot-chat-fragment';
import { CarouselChatFragmentDoc } from './carousel-chat-fragment';
import { ContactAgentByEmailChatFragmentDoc } from './contact-agent-by-email-chat-fragment';
import { CustomUIChatFragmentDoc } from './custom-ui-chat-fragment';
import { EventChatFragmentDoc } from './event-chat-fragment';
import { FillSlotRichUXChatFragmentDoc } from './fill-slot-rich-ux-chat-fragment';
import { SendFormChatFragmentDoc } from './send-form-chat-fragment';
import { UserChatFragmentDoc } from './user-chat-fragment';
export type ChatFragment_AgentChat_ = {
  __typename: 'AgentChat';
} & AgentChatFragment;

export type ChatFragment_BotChat_ = { __typename: 'BotChat' } & BotChatFragment;

export type ChatFragment_CarouselChat_ = {
  __typename: 'CarouselChat';
} & CarouselChatFragment;

export type ChatFragment_ContactAgentByEmailChat_ = {
  __typename: 'ContactAgentByEmailChat';
} & ContactAgentByEmailChatFragment;

export type ChatFragment_CustomUIChat_ = {
  __typename: 'CustomUIChat';
} & CustomUIChatFragment;

export type ChatFragment_EventChat_ = {
  __typename: 'EventChat';
} & EventChatFragment;

export type ChatFragment_FillSlotRichUXChat_ = {
  __typename: 'FillSlotRichUXChat';
} & FillSlotRichUXChatFragment;

export type ChatFragment_SendFormChat_ = {
  __typename: 'SendFormChat';
} & SendFormChatFragment;

export type ChatFragment_UserChat_ = {
  __typename: 'UserChat';
} & UserChatFragment;

export type ChatFragment =
  | ChatFragment_AgentChat_
  | ChatFragment_BotChat_
  | ChatFragment_CarouselChat_
  | ChatFragment_ContactAgentByEmailChat_
  | ChatFragment_CustomUIChat_
  | ChatFragment_EventChat_
  | ChatFragment_FillSlotRichUXChat_
  | ChatFragment_SendFormChat_
  | ChatFragment_UserChat_;

export const ChatFragmentDoc = gql`
  fragment ChatFragment on Chat {
    ... on AgentChat {
      ...AgentChatFragment
    }
    ... on BotChat {
      ...BotChatFragment
    }
    ... on CarouselChat {
      ...CarouselChatFragment
    }
    ... on ContactAgentByEmailChat {
      ...ContactAgentByEmailChatFragment
    }
    ... on CustomUIChat {
      ...CustomUIChatFragment
    }
    ... on EventChat {
      ...EventChatFragment
    }
    ... on FillSlotRichUXChat {
      ...FillSlotRichUXChatFragment
    }
    ... on SendFormChat {
      ...SendFormChatFragment
    }
    ... on UserChat {
      ...UserChatFragment
    }
  }
  ${AgentChatFragmentDoc}
  ${BotChatFragmentDoc}
  ${CarouselChatFragmentDoc}
  ${ContactAgentByEmailChatFragmentDoc}
  ${CustomUIChatFragmentDoc}
  ${EventChatFragmentDoc}
  ${FillSlotRichUXChatFragmentDoc}
  ${SendFormChatFragmentDoc}
  ${UserChatFragmentDoc}
`;
