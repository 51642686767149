/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
export type ContactAgentByEmailChatFragment = {
  __typename: 'ContactAgentByEmailChat';
} & Pick<
  Types.ContactAgentByEmailChat,
  | 'id'
  | 'createdAt'
  | 'message'
  | 'messageContentState'
  | 'hidden'
  | 'plainText'
  | 'nextInputType'
  | 'useAutoComplete'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    attachments: Types.Maybe<
      Array<Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>>
    >;
    assigned: Types.Maybe<
      Array<Types.Maybe<{ __typename: 'Agent' } & Pick<Types.Agent, 'id'>>>
    >;
  };

export const ContactAgentByEmailChatFragmentDoc = gql`
  fragment ContactAgentByEmailChatFragment on ContactAgentByEmailChat {
    id
    createdAt
    message
    messageContentState @client
    hidden
    plainText
    media {
      ...ChatMediaFragment
    }
    attachments {
      ...ChatMediaFragment
    }
    nextInputType
    assigned {
      id
    }
    useAutoComplete
  }
  ${ChatMediaFragmentDoc}
`;
