import { Text } from '@allganize/ui-text';
import styled from '@emotion/styled';
import { HTMLAttributes, ReactNode } from 'react';
import { MenuList } from './menu-list';
import { AlliBranding } from '@allganize/alli-sdk-ui';
import { useAuth } from '../../hooks/use-auth';
import { Button } from '@allganize/ui-button';
import { FormattedMessage } from 'react-intl';
import { HistoryList } from './history-list';

const LNB_WIDTH = 240;

const Wrapper = styled.aside`
  flex: 0 0 ${LNB_WIDTH}px;
  width: ${LNB_WIDTH}px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid;
  border-color: ${props => props.theme.taxPalette.grey[200]};
  background-color: ${props => props.theme.taxPalette.grey[50]};
  overflow: hidden;
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

const Title = styled(Text)`
  ${props => props.theme.typography.title16}
`;

const ContentArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 0 8px;
`;

const FooterArea = styled.footer`
  text-align: center;
`;

const AuthArea = styled.div`
  margin-bottom: 10px;
`;

interface LNBProps extends HTMLAttributes<HTMLDivElement> {
  logo: ReactNode;
}

export const LNB = ({ logo, children, ...others }: LNBProps) => {
  const auth = useAuth();

  return (
    <Wrapper {...others}>
      <HeaderArea>
        <Title>목록</Title>
        <MenuList />
      </HeaderArea>
      <ContentArea>
        <HistoryList />
      </ContentArea>
      <FooterArea>
        {auth.isEnabled && (
          <AuthArea>
            {auth.isAuthenticated && (
              <Button
                onClick={() => {
                  auth.signOut();
                }}
              >
                <FormattedMessage
                  id="sign-out"
                  defaultMessage="Sign out"
                  description="Sign out text"
                />
              </Button>
            )}

            {!auth.isAuthenticated && (
              <Button
                onClick={() => {
                  auth.signIn();
                }}
              >
                <FormattedMessage
                  id="sign-in"
                  defaultMessage="Sign in"
                  description="Sign in text"
                />
              </Button>
            )}
          </AuthArea>
        )}
        <AlliBranding />
      </FooterArea>
    </Wrapper>
  );
};
