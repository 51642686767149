import { Chip } from '@allganize/ui-chip';
import { useTheme } from '@allganize/ui-theme';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { assistantFallbackActionCandidatesClasses } from './assistant-fallback-action-candidates-classes';
import { AssistantFallbackActionCandidatesProps } from './assistant-fallback-action-candidates-type-map';

const fallbackActionCandidatesActionDefaultMessage = {
  CREATE_APP: 'Create App',
  RECOMMEND_OTHER_APPS: 'Recommend Apps',
  RECOMMEND_MORE_APPS: 'Recommend more Apps',
  SEARCH: 'Find Answers to My Question',
  SMALLTALK: 'Small talk',
  NO_RECOMMEND: '',
};

export const AssistantFallbackActionCandidates: FunctionComponent<
  AssistantFallbackActionCandidatesProps
> = ({ classes, data, onAssistantFallbackActionClick, ...other }) => {
  const theme = useTheme();
  return (
    <div
      data-testid="fallback-action-candidates"
      className={clsx(
        assistantFallbackActionCandidatesClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <Text
        variant="title12"
        align="right"
        css={css`
          margin-bottom: 8px;
          color: ${theme.palette.unstable_foreground.secondary};
        `}
        className={clsx(
          assistantFallbackActionCandidatesClasses.message,
          classes?.message,
        )}
      >
        <FormattedMessage
          id="fallback-action-candidates.message"
          defaultMessage="Does this answer not match your question?"
          description="fallback action candidates message"
        />
      </Text>
      <ul
        css={css`
          list-style: none;
          padding-left: 0;
          display: flex;
          gap: 4px;
          flex-wrap: wrap;
          justify-content: flex-end;
        `}
        className={clsx(
          assistantFallbackActionCandidatesClasses.list,
          classes?.list,
        )}
      >
        {data.map(action => (
          <li
            key={action}
            className={clsx(
              assistantFallbackActionCandidatesClasses.item,
              classes?.item,
            )}
          >
            <Chip
              label={
                <FormattedMessage
                  id={`fallback-action-candidates.action.${action}`}
                  defaultMessage={
                    fallbackActionCandidatesActionDefaultMessage[action]
                  }
                  description="fallback action candidates message"
                />
              }
              onClick={() => onAssistantFallbackActionClick?.(action)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
