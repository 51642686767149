/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type FAQCarouselFragment = { __typename: 'FAQCarousel' } & Pick<
  Types.FAQCarousel,
  'question' | 'answer' | 'reusable' | 'optionBoxTemplate'
>;

export const FAQCarouselFragmentDoc = gql`
  fragment FAQCarouselFragment on FAQCarousel {
    question
    answer
    reusable
    optionBoxTemplate
  }
`;
