/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { gql } from '@apollo/client';
export type ErrorFragment = { __typename: 'Error' } & Pick<
  UserQueryTypes.Error,
  'key' | 'message' | 'field' | 'info'
>;

export const ErrorFragmentDoc = gql`
  fragment ErrorFragment on Error {
    key
    message
    field
    info
  }
`;
