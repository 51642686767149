import { Types } from '@allganize/alli-sdk-interfaces';
import {
  SubscriptionHookOptions,
  SuspenseQueryHookOptions,
} from '@apollo/client/react';
import {
  ConversationQuery,
  ConversationQueryVariables,
  useConversationQuerySuspenseQuery,
} from '../graphql/queries/conversation-query';
import {
  ConversationSubscription,
  ConversationSubscriptionVariables,
  useConversationSubscription,
} from '../graphql/subscriptions/conversation-subscription';

export interface UseConversationOptions {
  conversationId: Types.Scalars['ID']['input'];
  query?: Omit<
    SuspenseQueryHookOptions<ConversationQuery, ConversationQueryVariables>,
    'variables'
  >;
  subscription?: Omit<
    SubscriptionHookOptions<
      ConversationSubscription,
      ConversationSubscriptionVariables
    >,
    'variables'
  >;
}

export const useConversation = ({
  conversationId,
  query,
  subscription,
}: UseConversationOptions) => {
  const result = useConversationQuerySuspenseQuery({
    ...query,
    variables: { where: { id: conversationId } },
  });

  useConversationSubscription({
    ...subscription,
    variables: { where: { id: conversationId } },
    skip: !!result.data.conversation?.isTry || subscription?.skip,
  });

  return result;
};
