import { useCombinedRef } from '@allganize/hooks';
import { IconButton, IconButtonProps } from '@allganize/ui-button';
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DateValidationError,
  FieldSection,
  UseDateFieldProps,
} from '@allganize/ui-date-picker';
import { IcCalendar } from '@allganize/ui-icons';
import React, { forwardRef, useEffect, useState } from 'react';

interface ButtonDateFieldProps
  extends UseDateFieldProps<Date>,
    BaseSingleInputFieldProps<
      Date | null,
      Date,
      FieldSection,
      DateValidationError
    >,
    Pick<IconButtonProps, 'edge' | 'onClick'> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ButtonDateField = forwardRef<HTMLButtonElement, ButtonDateFieldProps>(
  (props, ref) => {
    const {
      disabled,
      edge,
      id,
      InputProps: { ref: InputPropsRef } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
      onClick,
    } = props;
    const combinedRef = useCombinedRef<HTMLButtonElement>(
      InputPropsRef ?? null,
      ref,
    );

    return (
      <IconButton
        id={id}
        edge={edge}
        disabled={disabled}
        aria-label={ariaLabel}
        onClick={onClick}
        ref={combinedRef}
      >
        <IcCalendar />
      </IconButton>
    );
  },
);

export const ButtonDatePicker: typeof DatePicker = forwardRef((props, ref) => {
  const { onClose, onOpen, open: openProp, slots, slotProps, ...other } = props;
  const [open, setOpen] = useState(openProp ?? false);

  useEffect(() => {
    if (typeof openProp === 'undefined') {
      return;
    }

    setOpen(openProp);
  }, [openProp]);

  const toggle = () => {
    setOpen(prev => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleOpen = () => {
    setOpen(true);
    onOpen?.();
  };

  return (
    <DatePicker
      open={open}
      {...other}
      ref={ref}
      onClose={handleClose}
      onOpen={handleOpen}
      slots={{
        // @ts-expect-error internal component
        field: ButtonDateField,
        ...slots,
      }}
      slotProps={{
        ...slotProps,
        // @ts-expect-error internal component
        field(ownerState) {
          const fieldProps =
            typeof slotProps?.field === 'function'
              ? slotProps.field(ownerState)
              : slotProps?.field;

          return {
            onClick(ev: React.MouseEvent<HTMLButtonElement>) {
              toggle();
              // @ts-expect-error internal component
              fieldProps?.onClick?.(ev);
            },
            ...fieldProps,
          };
        },
      }}
    />
  );
});
