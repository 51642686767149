import { IconButton, IconButtonProps } from '@allganize/ui-button';
import { IcClose, IcError } from '@allganize/ui-icons';
import { Tooltip, TooltipProps, tooltipClasses } from '@allganize/ui-tooltip';
import { ClassNames } from '@emotion/react';
import { forwardRef, useState } from 'react';

interface ChatFormErrorMessageProps
  extends Pick<TooltipProps, 'title'>,
    Omit<IconButtonProps, 'title'> {
  onClear?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChatFormErrorMessage = forwardRef<
  HTMLButtonElement,
  ChatFormErrorMessageProps
>((props, ref) => {
  const { onClear, onClick, title, ...other } = props;
  const [open, setOpen] = useState(false);

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(ev);
    setOpen(p => !p);
  };

  const handleClose = (ev: React.MouseEvent<HTMLButtonElement>) => {
    onClear?.(ev);
    setOpen(false);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <Tooltip
          color="error"
          placement="top-end"
          classes={{
            tooltip: css`
              .${tooltipClasses.popper}[data-popper-placement*="top"] & {
                margin-bottom: -4px;
              }
            `,
          }}
          title={
            <div
              css={css`
                display: flex;
                align-items: flex-start;
              `}
            >
              <div
                css={css`
                  flex-grow: 1;
                  padding: 5px 0;
                `}
              >
                {title}
              </div>

              <IconButton
                css={css`
                  margin-left: 12px;
                `}
                variant="filled"
                size="small"
                onClick={handleClose}
              >
                <IcClose fontSize="small" />
              </IconButton>
            </div>
          }
          open={open}
        >
          <IconButton color="error" {...other} ref={ref} onClick={handleClick}>
            <IcError />
          </IconButton>
        </Tooltip>
      )}
    </ClassNames>
  );
});
