import { Types } from '@allganize/alli-sdk-interfaces';
import { TypingContext } from '@allganize/alli-sdk-chat';
import { ContentState } from '@allganize/draft-input';
import { useDebounce, useEventCallback, useWindow } from '@allganize/hooks';
import { FileDropzoneProps } from '@allganize/ui-file-input';
import { zodResolver } from '@hookform/resolvers/zod';
import useAutocomplete from '@mui/material/useAutocomplete';
import { format, isValid } from 'date-fns';
import { filesize } from 'filesize';
import { compact, some, uniq } from 'lodash-es';
import { useContext, useMemo, useRef, useEffect, useState } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import {
  FormattedMessage,
  IntlShape,
  defineMessages,
  useIntl,
} from 'react-intl';
import { createFilter } from 'react-select';
import { z } from 'zod';
import { analytics } from '../analytics';
import { useAlliClient } from '../alli-client/use-alli-client';
import { ChatListContext } from '../chat-list/chat-list-context';
import {
  CONVERSATION_STATE_ENDED,
  CONVERSATION_STATE_WAIT_USER_ANSWER,
} from '../constants/conversation';
import { ConversationContext } from '../conversation-detail/conversation-context';
import { useAutoCompletionQuery } from '../graphql/queries/auto-completion-query';
import {
  getMallyErrorMessageDescriptor,
  mallyErrors,
} from '../i18n/mally-errors';
import { toast } from '@allganize/ui-toast';
import { useProject } from '../project/use-project';
import { SDKGraphQLError } from '../utils/sdk-graphql-error';
import { useSendChat } from './use-send-chat';
import { useNavigate } from 'react-router-dom';

export interface ChatFormValues {
  message: string;
  file: File | null;
  date: Date | null;
  appType?: Types.TaxAppTypes;
}

type TaxAppName = '통합' | '법령' | '세법해석례' | '판례・결정례';
type ChatFormErrorFieldName = FieldPath<ChatFormValues> | 'root';

const appTypeByName: Record<string, Types.TaxAppTypes> = {
  통합: 'TONG_HAP',
  법령: 'BEOB_RYEONG',
  세법해석례: 'HAE_SEOK_RYE',
  '판례・결정례': 'GYEOL_JUNG_RYE',
};

const errorFieldNames: ChatFormErrorFieldName[] = [
  'root',
  'message',
  'file',
  'date',
];

const isErrorFieldName = (field: any): field is ChatFormErrorFieldName =>
  errorFieldNames.includes(field);

const errorMessages = defineMessages({
  fieldRequired: {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  fieldInvalidFormat: {
    id: 'form.errors.invalid-format',
    defaultMessage: 'Invalid format',
    description: 'Field invalid format error message',
  },
  fileExtensions: {
    id: 'form.file.errors.type',
    defaultMessage:
      'Invalid file type. Allowed file types include the following: {extensions}',
    description: 'File extensions error message',
  },
  maxFileSize: {
    id: 'form.file.errors.max',
    defaultMessage: 'File too large (Max. {size})',
    description: 'File too large error message',
  },
});

export const dateFormat = 'yyyy-MM-dd';

const enabledFields: Record<keyof ChatFormValues, Types.ChatInputType[]> = {
  message: ['BOOLEAN', 'NUMBER', 'STRING'],
  file: ['FILE', 'IMAGE'],
  date: ['DATETIME'],
  appType: ['BOOLEAN', 'NUMBER', 'STRING', 'FILE', 'IMAGE', 'DATETIME'], // always enabled
};

export interface ChatFormValidation {
  file: {
    extensions?: string[];
    maxSize: {
      default: number;
      image: number;
    };
  };
}

interface ChatFormValidationExtended extends ChatFormValidation {
  nextInputType: Types.ChatInputType[];
  customValidation?: {
    regex: string;
    message?: string;
  };
}

const createValidationSchema = (
  intl: IntlShape,
  options: ChatFormValidationExtended,
  win?: typeof globalThis,
) => {
  const FileKlass = win?.File ?? File;
  const messageFieldEnabled =
    options.nextInputType.length === 0 ||
    options.nextInputType.findIndex(type =>
      enabledFields.message.includes(type),
    ) >= 0;
  const fileFieldEnabled =
    options.nextInputType.findIndex(type =>
      enabledFields.file.includes(type),
    ) >= 0;
  const imageFileOnly =
    fileFieldEnabled && !options.nextInputType.includes('FILE');
  const dateFieldEnabled =
    options.nextInputType.findIndex(type =>
      enabledFields.date.includes(type),
    ) >= 0;
  const messageFieldOnly =
    messageFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.message.includes(type),
    ) < 0;
  const messageNumberOnly =
    messageFieldEnabled &&
    options.nextInputType.length > 0 &&
    options.nextInputType.findIndex(type => type !== 'NUMBER') < 0;
  const fileFieldOnly =
    fileFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.file.includes(type),
    ) < 0;
  const dateFieldOnly =
    dateFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.date.includes(type),
    ) < 0;

  return z
    .object({
      message: z.string().refine(schema => {
        if (!messageNumberOnly) {
          return true;
        }

        if (!schema) {
          return true;
        }

        const numeric = Number(schema);
        return !Number.isNaN(numeric);
      }, intl.formatMessage(errorMessages['fieldInvalidFormat'])),
      file: z
        .union([z.instanceof(File), z.instanceof(FileKlass)], {
          invalid_type_error: intl.formatMessage(
            errorMessages['fieldInvalidFormat'],
          ),
        })
        .nullable()
        .refine(schema => {
          if (!schema) {
            return true;
          }

          if (!imageFileOnly) {
            return true;
          }

          return schema.type.startsWith('image/');
        }, intl.formatMessage(errorMessages['fieldInvalidFormat']))
        .refine(schema => {
          if (!schema) {
            return true;
          }

          if (
            !(options.file.extensions && options.file.extensions.length > 0)
          ) {
            return true;
          }

          const extension = schema.name.split('.').pop();
          return extension && options.file.extensions.includes(`.${extension}`);
        }, intl.formatMessage(errorMessages['fileExtensions'], { type: options.file.extensions?.join(', ') }))
        .refine(
          schema => {
            if (!fileFieldEnabled) {
              return true;
            }

            if (!schema) {
              return true;
            }

            if (schema.type.startsWith('image/')) {
              return schema.size <= options.file.maxSize.image;
            }

            return schema.size <= options.file.maxSize.default;
          },
          schema => {
            if (schema?.type.startsWith('image/')) {
              return {
                message: intl.formatMessage(errorMessages['maxFileSize'], {
                  size: filesize(options.file.maxSize.image, {
                    output: 'string',
                    locale: intl.locale,
                    localeOptions: intl.formats.date,
                  }),
                }),
              };
            }

            return {
              message: intl.formatMessage(errorMessages['maxFileSize'], {
                size: filesize(options.file.maxSize.default, {
                  output: 'string',
                  locale: intl.locale,
                  localeOptions: intl.formats.date,
                }),
              }),
            };
          },
        ),
      date: z
        .date()
        .nullable()
        .refine(schema => {
          if (!dateFieldEnabled) {
            return true;
          }

          if (!schema) {
            return true;
          }

          return isValid(schema);
        }, intl.formatMessage(errorMessages['fieldInvalidFormat'])),
      appType: z.string().nullable().optional(),
    })
    .refine(
      schema => {
        if (!messageFieldEnabled && !fileFieldEnabled && !dateFieldEnabled) {
          return true;
        }

        if (fileFieldEnabled && schema.file !== null) {
          return true;
        }

        if (dateFieldEnabled && schema.date !== null) {
          return true;
        }

        if (messageFieldEnabled && schema.message.replace(/\s/g, '')) {
          return true;
        }

        return false;
      },
      {
        path: (fileFieldOnly && ['file']) ||
          (dateFieldOnly && ['date']) ||
          (messageFieldOnly && ['message']) || ['root'],
        message: intl.formatMessage(errorMessages['fieldRequired']),
      },
    )
    .refine(
      schema => {
        if (!options.customValidation) {
          return true;
        }

        const regExp = new RegExp(options.customValidation.regex);

        if (dateFieldEnabled && schema.date !== null) {
          return regExp.test(format(schema.date, dateFormat));
        }

        if (messageFieldEnabled) {
          return regExp.test(schema.message);
        }

        return true;
      },
      schema => {
        return {
          path:
            dateFieldEnabled && schema.date !== null ? ['date'] : ['message'],
          message:
            options.customValidation?.message ??
            intl.formatMessage(errorMessages['fieldInvalidFormat']),
        };
      },
    );
};

const autocompleteFilter = createFilter<string>({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: 'any',
  stringify(option) {
    return [option.label, option.value].join(' ');
  },
});

export interface UseChatFormOptions {
  validation: ChatFormValidation;
}

export const useChatForm = ({ validation }: UseChatFormOptions) => {
  const {
    data: { conversation },
  } = useContext(ConversationContext);
  const conversationAppName = conversation?.llmApp?.name ?? '통합';
  const conversationAppType = appTypeByName[conversationAppName];

  const [defaultAppType, setDefaultAppType] = useState(conversationAppType);
  const defaultValues: ChatFormValues = {
    message: '',
    file: null,
    date: null,
    appType: defaultAppType,
  };

  useEffect(() => {
    setDefaultAppType(conversationAppType);
  }, [conversationAppType]);

  const intl = useIntl();
  const client = useAlliClient();
  const navigate = useNavigate();
  // const navigate = customNavigateFunc || navigateOrig;
  const { ref: formRef, window: win } = useWindow();
  const { project } = useProject();

  const { data: chatListData } = useContext(ChatListContext);

  const { sendChat } = useSendChat();
  const sdkSettings = project?.sdkSettings;
  const conversationEnded =
    conversation?.state &&
    CONVERSATION_STATE_ENDED.includes(conversation.state);
  const waitUserAnswer =
    conversation?.state &&
    CONVERSATION_STATE_WAIT_USER_ANSWER.includes(conversation.state);
  const hasNonCompletedEdges = chatListData?.chats?.edges.some(
    edge =>
      edge?.node?.__typename === 'BotChat' && edge.node.completed === false,
  );
  const lastEdge = chatListData?.chats?.edges
    ? chatListData.chats.edges[chatListData.chats.edges.length - 1]
    : null;
  const lastChat = lastEdge?.node;
  const placeholder =
    (lastChat?.__typename === 'BotChat' ||
      lastChat?.__typename === 'CarouselChat') &&
    lastChat.placeholder;
  const useAutoComplete =
    sdkSettings?.useAutoComplete &&
    lastChat &&
    lastChat.__typename !== 'EventChat'
      ? lastChat.useAutoComplete
      : null;
  const numAutoComplete = sdkSettings?.numAutoComplete ?? 3;
  const allowKnowledgeBaseFile =
    lastChat?.__typename === 'BotChat' ? lastChat.allowKnowledgeBaseFile : null;
  const nextInputType = useMemo(
    () =>
      lastChat && lastChat.__typename !== 'EventChat'
        ? compact(lastChat.nextInputType)
        : [],
    [lastChat],
  );
  const messageFieldEnabled =
    nextInputType.length === 0 ||
    nextInputType.findIndex(type => enabledFields.message.includes(type)) >= 0;
  const fileFieldEnabled =
    nextInputType.findIndex(type => enabledFields.file.includes(type)) >= 0;
  const dateFieldEnabled =
    nextInputType.findIndex(type => enabledFields.date.includes(type)) >= 0;
  const disabled =
    conversationEnded ||
    hasNonCompletedEdges ||
    !(messageFieldEnabled || fileFieldEnabled || dateFieldEnabled);

  const validationExtended = useMemo<ChatFormValidationExtended>(
    () => ({
      ...validation,
      file: {
        ...validation.file,
        extensions:
          allowKnowledgeBaseFile?.extensions ?? validation.file.extensions,
        maxSize:
          allowKnowledgeBaseFile?.maxSize === null ||
          typeof allowKnowledgeBaseFile?.maxSize === 'undefined'
            ? validation.file.maxSize
            : {
                default: allowKnowledgeBaseFile.maxSize,
                image: allowKnowledgeBaseFile.maxSize,
              },
      },
      nextInputType,
      customValidation:
        lastChat?.__typename === 'BotChat' &&
        lastChat.saveAsVariable?.validationCustom
          ? {
              regex: lastChat.saveAsVariable.validationCustom,
              message:
                lastChat.saveAsVariable.validationFailedMessage ?? undefined,
            }
          : undefined,
    }),
    [allowKnowledgeBaseFile, lastChat, nextInputType, validation],
  );

  const validationSchema = useMemo(
    () =>
      zodResolver(
        createValidationSchema(intl, validationExtended, win ?? undefined),
      ),
    [intl, validationExtended, win],
  );
  const form = useForm<ChatFormValues>({
    mode: 'all',
    defaultValues,
    resolver: validationSchema,
  });
  const {
    formState: { isSubmitting },
    reset,
    setError,
    setValue,
    watch,
  } = form;
  const formDisabled = disabled || isSubmitting;
  const [file, date, message, appType] = watch([
    'file',
    'date',
    'message',
    'appType',
  ]);

  useEffect(() => {
    setValue('appType', defaultAppType);
  }, [defaultAppType]);

  const hintRef = useRef('');
  const skipAutoCompletion =
    !conversation || !useAutoComplete || !messageFieldEnabled;
  const {
    data: autoCompletion,
    fetchMore,
    variables,
  } = useAutoCompletionQuery({
    variables: {
      partial: '',
      where: { id: conversation?.id ?? '' },
    },
    skip: skipAutoCompletion,
  });
  const autocompleteOptions = useMemo<string[]>(
    () => compact(autoCompletion?.autoCompletion?.questions ?? []),
    [autoCompletion?.autoCompletion?.questions],
  );
  const autocomplete = useAutocomplete<string, false, true, true>({
    autoComplete: false,
    autoHighlight: false,
    autoSelect: false,
    blurOnSelect: false,
    clearOnBlur: false,
    clearOnEscape: false,
    disableClearable: true,
    disableCloseOnSelect: false,
    disabled: formDisabled,
    freeSolo: true,
    options: autocompleteOptions,
    inputValue: message,
    onInputChange(ev, value) {
      if (hintRef) {
        const matchingOption = autocompleteOptions.find(option =>
          option.toLowerCase().startsWith(value.toLowerCase()),
        );

        if (
          value &&
          matchingOption &&
          typeof matchingOption === 'string' &&
          matchingOption.length > value.length
        ) {
          hintRef.current = value + matchingOption.slice(value.length);
        } else {
          hintRef.current = '';
        }
      }

      setValue('message', value, { shouldValidate: true, shouldTouch: true });
    },
    filterOptions: (options, state) => {
      if (state.inputValue.trim() === '') {
        return [];
      }

      return options
        .filter(option =>
          autocompleteFilter(
            {
              value: option,
              label: state.getOptionLabel(option),
              data: option,
            },
            state.inputValue,
          ),
        )
        .slice(0, numAutoComplete);
    },
  });

  const fileFieldOnly =
    fileFieldEnabled &&
    (nextInputType.findIndex(type => !enabledFields.file.includes(type)) < 0 ||
      file !== null);
  const dateFieldOnly =
    dateFieldEnabled &&
    (nextInputType.findIndex(type => !enabledFields.date.includes(type)) < 0 ||
      date !== null);

  const { onTyping } = useContext(TypingContext);

  useEffect(() => {
    const subscription = watch(values => onTyping(some(values, v => !!v)));
    return () => subscription.unsubscribe();
  }, [onTyping, watch]);

  const acceptImage = nextInputType.includes('IMAGE');
  const acceptFile = nextInputType.includes('FILE');
  const acceptImageOnly = acceptImage && !acceptFile;
  const accept = acceptImageOnly ? { 'image/*': [] } : undefined;

  const dropzoneOptions = {
    accept,
    maxFiles: 1,
    noClick: true,
  } satisfies FileDropzoneProps;

  const appNameByType: Record<Types.TaxAppTypes, TaxAppName> = {
    TONG_HAP: '통합',
    BEOB_RYEONG: '법령',
    HAE_SEOK_RYE: '세법해석례',
    GYEOL_JUNG_RYE: '판례・결정례',
  };

  let selectedTaxApp: TaxAppName;
  if (appType && appType in appNameByType) {
    selectedTaxApp = appNameByType[appType];
  } else {
    selectedTaxApp = '통합';
  }

  const submit = async (values: ChatFormValues) => {
    if (!conversation) {
      throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
    }

    // TaxAppSelect campaign !== Current campaign
    if (selectedTaxApp !== conversation?.llmApp?.name) {
      const CampaignTokenByApp: Record<TaxAppName, string> = {
        통합: '8becF9umPlDnbhAGRxbVfYujtShreuDNkrTCbg4jNoZcqSPeNwrdEl8MPNpvKe3ZR1gxsLxUjMUCF9L4jL1AGm1hPqEn8625JNlbPI96Vcc9Pk84CB-GSL4XcE5LqglbsCPAshz9VVpFprEBzjhg0oM83Qssm5KCkBd65NeAd5w=',
        법령: 'id5P0gPECFGnrSgLnLZv5AmYPbemdwgI5UWkwdX96mFTO4XlpXfOtKov4yfKHK+tzk9w4e55fbUXdU9MZyqj1wwmic9AhcyvVr7myjt+z+PUZPip9Pyos9zvQVgS9pRtO2vxDPNGaM2zGQ+iQryzuanAxYTmUmQHEjD7ZOdLAao=',
        세법해석례:
          'UTmMt8ioyXo5zpyo0sl8WwPsMO2wj6UZ1skm-ThkjqDpcZPEQB+vYpQldAyTD0mMniZBwUkarJvjThQru15M5ADUVDSw5mE5l516RsniIKlwGU4+vvYqZZQXvtZX0b2OK90yb5vFEATZvbrijMVGp34wTt-l4+XEBxVPp11n4Gk=',
        '판례・결정례':
          'sWiR9Vp2SVRcqCy+165+rGeQomiX5GpWjW4ZWGtgKhQjHkS-x7Mg8Bl53DVKLxQEisPOCaeF8hFHjyIPbw5mpj2006nHC+g-BdEce5Y8Jjhxe3utyIaqQk8LG1yWN5vKq4UhzXJlTg-vrs--nRjZ9l+mCxTT-R1bmHNvAIQIaa8=',
      };

      try {
        const selectedTaxCampaignToken = CampaignTokenByApp[selectedTaxApp];
        const result = await client.startConversation({
          campaignToken: selectedTaxCampaignToken,
          startOver: true,
        });

        if (!result?.data) {
          throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
        }

        const response =
          'startConversation' in result.data
            ? result.data.startConversation
            : result.data.tryConversation;

        if (response?.conversation) {
          client.sendChat({
            conversationId: response.conversation.id,
            message: values.message,
          });
          client.apolloClient?.refetchQueries({
            include: ['ConversationsQuery'],
          });
          return navigate(`/conversations/${response.conversation.id}`);
        }

        if (response?.errors?.length) {
          throw new SDKGraphQLError(response.errors);
        }
      } catch (err) {
        if (err instanceof SDKGraphQLError) {
          const error = compact(err.errors)[0];

          if (error) {
            toast.error(
              <FormattedMessage {...getMallyErrorMessageDescriptor(error)} />,
            );
            return;
          }
        }

        if (err instanceof Error) {
          toast.error(err.message);
        }

        if (typeof err === 'string') {
          toast.error(err);
        }
      }
    } else {
      // TaxAppSelect campaign === Current campaign
      try {
        // analytics.track('conversation-detail::send-chat-button.click', {
        //   conversationId: conversation.id,
        //   appId: conversation.llmApp?.id,
        // });

        const createdAt = Date.now();

        let message = values.message;

        if (dateFieldEnabled && values.date !== null) {
          message = format(values.date, dateFormat);
        }

        const result = await sendChat(
          {
            conversationId: conversation.id,
            message: message || undefined,
            mediaInput: values.file?.type.startsWith('image/')
              ? {
                  file: values.file,
                  mediaType: 'IMAGE',
                  metaInfo: JSON.stringify({
                    lastModified: values.file.lastModified,
                    lastModifiedDate: (values.file as any).lastModifiedDate,
                    name: values.file.name,
                    size: values.file.size,
                    type: values.file.type,
                  }),
                }
              : undefined,
            fileInput:
              !values.file || values.file?.type.startsWith('image/')
                ? undefined
                : {
                    file: values.file,
                    metaInfo: JSON.stringify({
                      lastModified: values.file.lastModified,
                      lastModifiedDate: (values.file as any).lastModifiedDate,
                      name: values.file.name,
                      size: values.file.size,
                      type: values.file.type,
                    }),
                  },
          },
          [
            {
              __typename: 'UserChat',
              id: `optimistic-${createdAt}`,
              message,
              messageContentState: ContentState.createFromText(message, '\n'),
              createdAt,
              nextInputType: [],
              useAutoComplete: false,
              hidden: null,
              media: values.file?.type.startsWith('image/')
                ? {
                    __typename: 'Media',
                    id: `optimistic-${createdAt}-media`,
                    mediaType: 'IMAGE',
                    url: URL.createObjectURL(values.file),
                    metaInfo: JSON.stringify({
                      lastModified: values.file.lastModified,
                      lastModifiedDate: (values.file as any).lastModifiedDate,
                      name: values.file.name,
                      size: values.file.size,
                      type: values.file.type,
                    }),
                    width: null,
                    height: null,
                  }
                : null,
              file:
                !values.file || values.file.type.startsWith('image/')
                  ? null
                  : {
                      __typename: 'File',
                      id: `optimistic-${createdAt}-file`,
                      fileId: `optimistic-${createdAt}-file`,
                      url: URL.createObjectURL(values.file),
                      metaInfo: JSON.stringify({
                        lastModified: values.file.lastModified,
                        lastModifiedDate: (values.file as any).lastModifiedDate,
                        name: values.file.name,
                        size: values.file.size,
                        type: values.file.type,
                      }),
                      createdAt: new Date(createdAt).toISOString(),
                      modifiedAt: new Date(createdAt).toISOString(),
                    },
              formValues: null,
            },
          ],
        );

        if (!result?.data) {
          throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
        }

        if ('trySendChat' in result.data) {
          if (result.data.trySendChat?.chat) {
            hintRef.current = '';
            reset({
              ...defaultValues,
            });
            return;
          }

          if (
            result.data.trySendChat?.errors &&
            result.data.trySendChat.errors.length > 0
          ) {
            throw new SDKGraphQLError(result.data.trySendChat.errors);
          }

          throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
        }

        if (result.data.sendChat?.chat) {
          hintRef.current = '';
          reset();
          return;
        }

        if (
          result.data.sendChat?.errors &&
          result.data.sendChat.errors.length > 0
        ) {
          throw new SDKGraphQLError(result.data.sendChat.errors);
        }

        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      } catch (err) {
        if (err instanceof SDKGraphQLError) {
          const errorGroups = err.errors.reduce<
            Partial<Record<ChatFormErrorFieldName, string[]>>
          >((acc, curr) => {
            // Skip if the current error is null or undefined
            if (!curr) {
              return acc;
            }

            // Determine the field associated with the error, defaulting to 'root' if not specified
            const field = isErrorFieldName(curr.field) ? curr.field : 'root';
            const descriptor = getMallyErrorMessageDescriptor(curr);

            // Add the error message to the corresponding error group
            return {
              ...acc,
              [field]: [
                ...(acc[field] ?? []),
                intl.formatMessage(descriptor, descriptor.values),
              ],
            };
          }, {});

          // Set the errors in the form using setError
          Object.entries(errorGroups).forEach(([field, errors]) => {
            setError(field as ChatFormErrorFieldName, {
              message: errors[0],
            });
          });

          return;
        }

        if (err instanceof Error) {
          setError('root', { message: err.message });
          return;
        }

        if (typeof err === 'string') {
          setError('root', { message: err });
          return;
        }

        setError('root', {
          message: intl.formatMessage(mallyErrors.UNKNOWN),
        });
      }
    }
  };

  const getAutocompleteOptions = useEventCallback((inputValue: string) => {
    if (skipAutoCompletion) {
      return;
    }
    return fetchMore({
      variables: {
        ...variables,
        partial: inputValue,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (
          !(
            fetchMoreResult.autoCompletion?.questions &&
            fetchMoreResult.autoCompletion.questions.length > 0
          )
        ) {
          return prev;
        }

        return {
          ...prev,
          ...fetchMoreResult,
          autoCompletion: {
            ...prev.autoCompletion,
            ...fetchMoreResult.autoCompletion,
            questions: uniq([
              ...(prev.autoCompletion?.questions ?? []),
              ...fetchMoreResult.autoCompletion.questions,
            ]),
          },
        };
      },
    });
  });

  useDebounce(
    () => {
      getAutocompleteOptions(message);
    },
    500,
    [skipAutoCompletion, getAutocompleteOptions, message],
  );

  return {
    autocomplete: useAutoComplete ? autocomplete : null,
    dateFieldEnabled,
    dateFieldOnly,
    disabled,
    dropzoneOptions,
    fileFieldEnabled,
    fileFieldOnly,
    form,
    formDisabled,
    formRef,
    hintRef: useAutoComplete ? hintRef : null,
    messageFieldEnabled,
    nextInputType,
    placeholder,
    submit,
    waitUserAnswer,
  };
};
