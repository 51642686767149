import { Types } from '@allganize/alli-sdk-interfaces';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { carouselArrowClasses, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { Fragment, FunctionComponent } from 'react';
import { getAgentSelectOption } from '../agent-select/agent-select-option';
import {
  ChatContentBubble,
  ChatContentBubbleProps,
} from '../chat-content-bubble/chat-content-bubble';
import { ChatContentBubbleClasses } from '../chat-content-bubble/chat-content-bubble-classes';
import {
  ChatContentRow,
  ChatContentRowProps,
  avatarWidth,
  horizontalPadding,
} from '../chat-content-row/chat-content-row';
import { chatContentRowClasses } from '../chat-content-row/chat-content-row-classes';
import { ChatForm } from '../chat-form/chat-form';
import { ChatMedia } from '../chat-media/chat-media';
import { ChatOptionInfoForm } from '../chat-option-info/chat-option-info-form';
import {
  ChatOptionInfoFormValue,
  ChatOptionInfoFormValues,
} from '../chat-option-info/use-chat-option-info-form';
import { ChatTyping } from '../chat-typing/chat-typing';
import { ContactAgentByEmailForm } from '../contact-agent-by-email-chat/contact-agent-by-email-form';
import { DraftDocumentList } from '../draft-document-list/draft-document-list';
import { useDocumentEntities } from '../draft-document-list/use-document-entities';
import { AssistantFallbackActionCandidates } from '../assistant-fallback-action-candidates/assistant-fallback-action-candidates';
import { AssistantFallbackActionCandidatesProps } from '../assistant-fallback-action-candidates/assistant-fallback-action-candidates-type-map';
import { FaqCarouselForm } from '../faq-carousel/faq-carousel-form';
import {
  FaqCarouselFormValue,
  FaqCarouselFormValues,
} from '../faq-carousel/use-faq-carousel-form';
import { GeneratingErrorState } from '../generating-error-state/generating-error-state';
import { BotChatFragment } from '../graphql/fragments/bot-chat-fragment';
import { ChatAgentFragment } from '../graphql/fragments/chat-agent-fragment';
import {
  RecommendedAppCarousel,
  RecommendedAppCarouselProps,
} from '../recommended-app/recommended-app-carousel';
import { RecommendedItemCarousel } from '../recommended-item/recommended-item-carousel';
import { RecommendedItemProps } from '../recommended-item/recommended-item-type-map';
import { RichText } from '../rich-text/rich-text';
import { useRichText } from '../rich-text/use-rich-text';
import { TimestampTooltip } from '../timestamp-tooltip/timestamp-tooltip';
import { UserFeedbackImproverFormValues } from '../user-feedback/use-user-feedback-improver-form';
import {
  UserFeedback,
  UserFeedbackSubmitValues,
} from '../user-feedback/user-feedback';
import { AssistantCreatedLLMAppOptionsProps } from '../assistant-created-llm-app-options/assistant-created-llm-app-options-type-map';
import { AssistantCreatedLLMAppOptions } from '../assistant-created-llm-app-options/assistant-created-llm-app-options';
import { createSvgIcon } from '@allganize/ui-icons';
import { OtherSkillList } from '../other-skill-list/other-skill-list';
import { ConversationFragment } from '../graphql/fragments/conversation-fragment';
import { ChatFragment } from '../graphql/fragments/chat-fragment';

export interface BotChatProps
  extends Partial<Pick<ChatContentRowProps, 'align' | 'classes'>>,
    Pick<ChatContentBubbleProps, 'color' | 'loading'> {
  lastUserChatNode: ChatFragment | null;
  onClickOtherApp: (appName: string, lastUserChatMessage: string) => void;
  conversation: ConversationFragment | null;
  llmAppsByGeneralCategory: any; // TODO: clarify type after refactoring
  assignee?: ChatAgentFragment | null;
  avatar?: React.ReactNode;
  BubbleClasses?: Partial<ChatContentBubbleClasses>;
  chat: BotChatFragment;
  assistantCreatedAppEditLink?: string;
  isValidCreatedLLMApp?: boolean;
  chatOptionAlign?: Types.ChatOptionAlign;
  isOptionDisabled?(
    option: ChatOptionInfoFormValue | FaqCarouselFormValue,
  ): boolean;
  onChatOptionInfoFormSubmit(values: ChatOptionInfoFormValues): Promise<any>;
  onContactAgentByEmailFormSubmit(
    values: Types.ContactAgentByEmailInput,
  ): Promise<any>;
  readOnly?: Partial<Record<Types.ChatInputType, boolean>> | boolean;
  onFaqCarouselFormSubmit(values: FaqCarouselFormValues): Promise<any>;
  onUserFeedbackSubmit?: (values: UserFeedbackSubmitValues) => void;
  onUserFeedbackImproverSubmit(
    values: UserFeedbackImproverFormValues,
  ): Promise<any>;
  onRecommendationCarouselItemClick: RecommendedItemProps['onClick'];
  onAssistantRecommendedAppClick: RecommendedAppCarouselProps['onAppClick'];
  onAssistantFallbackActionClick: AssistantFallbackActionCandidatesProps['onAssistantFallbackActionClick'];
  onAssistantCreatedAppRun: AssistantCreatedLLMAppOptionsProps['onRunApp'];
}

const TaxResearchLogo = createSvgIcon(
  <Fragment>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1002 19.8002L19.2002 4.2002H15.3002L4.2002 19.8002H8.1002Z"
        fill="black"
      />
      <rect
        x="14.0996"
        y="15"
        width="4.8"
        height="4.8"
        rx="2.4"
        fill="#0066FF"
      />
      <rect
        x="5.09961"
        y="4.2002"
        width="4.8"
        height="4.8"
        rx="2.4"
        fill="#0066FF"
      />
    </svg>
  </Fragment>,
  'TaxResearchLogo',
);

const RichTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 0 16px;
`;

export const BotChat: FunctionComponent<BotChatProps> = ({
  lastUserChatNode,
  onClickOtherApp,
  conversation,
  llmAppsByGeneralCategory,
  align = 'left',
  assignee,
  avatar,
  BubbleClasses,
  chat,
  classes,
  color = 'default',
  assistantCreatedAppEditLink,
  isValidCreatedLLMApp,
  chatOptionAlign,
  isOptionDisabled,
  loading,
  onChatOptionInfoFormSubmit,
  onContactAgentByEmailFormSubmit,
  onFaqCarouselFormSubmit,
  onUserFeedbackSubmit,
  onUserFeedbackImproverSubmit,
  onRecommendationCarouselItemClick,
  onAssistantRecommendedAppClick,
  onAssistantFallbackActionClick,
  onAssistantCreatedAppRun,
  readOnly,
}) => {
  const { value: message, onChange: onMessageChange } = useRichText({
    data: chat.messageContentState,
  });
  const botChatMessage = message?.getCurrentContent().getPlainText() || null;

  const { documentEntities } = useDocumentEntities({
    data: message,
    knowledgeBasePreview: chat.knowledgeBasePreview,
  });

  const lastUserChatMessage =
    lastUserChatNode?.__typename === 'UserChat'
      ? String(lastUserChatNode.message)
      : '';

  const hasMedia = !!chat.media;
  const hasMessage = !hasMedia && !isDraftInputEmpty(message);
  const hasDocumentEntities = documentEntities.length > 0;
  const hasCreatedLLMApp = !!chat.assistantCreatedLlmApp;
  const hasContactAgentByEmailForm = chat.nextInputType.includes(
    'CONTACT_AGENT_BY_EMAIL_RICH_UX',
  );
  const contactAgentByEmailFormReadOnly =
    typeof readOnly === 'boolean'
      ? readOnly
      : readOnly?.CONTACT_AGENT_BY_EMAIL_RICH_UX;
  const hasFaqCarousel = chat.faqCarousel && chat.faqCarousel?.length > 0;
  const chatOptionInfos = chat.chatOptionInfos ?? [];
  const hasChatOptionInfos =
    chatOptionInfos && chatOptionInfos.length > 0 && !hasFaqCarousel;
  const horizontalOptions =
    chatOptionInfos.filter(chatOptionInfo => chatOptionInfo?.media).length ===
      0 && chatOptionAlign === 'HORIZONTAL';
  const chatOptionInfoFormReadOnly =
    typeof readOnly === 'boolean' ? readOnly : readOnly?.CHOICE;

  const hasGeneratingStateMessage =
    chat.generatingState && chat.generatingState !== 'COMPLETE';
  const hasTypingIndicator =
    hasGeneratingStateMessage &&
    !(
      chat.generatingState === 'GENERATING_ANSWER' &&
      !isDraftInputEmpty(message)
    );
  const hasError =
    chat.completeState !== null &&
    chat.completeState !== 'SUCCESS' &&
    chat.completeState !== 'PROGRESS';
  const hasCarouselOptions =
    chat.carouselOptions && chat.carouselOptions.length > 0;
  const hasAssistantRecommendedApps =
    chat.assistantRecommendedApps && chat.assistantRecommendedApps.length > 0;
  const hasAssistantFallbackActionCandidates =
    chat.assistantFallbackActionCandidates &&
    chat.assistantFallbackActionCandidates.length > 0;

  const feedback = chat.ratable && (
    <UserFeedback
      botMessage={botChatMessage}
      improver={chat.mrcAnswerType === 'GENERATED'}
      onUserFeedbackImproverSubmit={onUserFeedbackImproverSubmit}
      onUserFeedbackSubmit={onUserFeedbackSubmit}
      rating={chat.rating}
    />
  );

  return (
    <Fragment>
      {hasTypingIndicator && <ChatTyping avatar={avatar} chat={chat} />}

      {(hasMessage || hasDocumentEntities) && (
        <ChatContentRow
          avatar={avatar}
          classes={classes}
          css={
            hasTypingIndicator &&
            css`
              margin-top: 16px;
            `
          }
        >
          <TimestampTooltip timestamp={chat.createdAt}>
            <ChatContentBubble
              classes={BubbleClasses}
              color={color}
              loading={loading}
            >
              <RichTextWrapper>
                <TaxResearchLogo fontSize="medium" />
                <RichText value={message} onChange={onMessageChange} />
              </RichTextWrapper>

              {hasDocumentEntities && (
                <Fragment>
                  <DraftDocumentList
                    css={[
                      !hasMessage &&
                        css`
                          margin-top: -2px;
                        `,
                      css`
                        padding-left: 44px;
                      `,
                    ]}
                    data={documentEntities}
                  />
                  <OtherSkillList
                    lastUserChatMessage={lastUserChatMessage}
                    onClickOtherApp={onClickOtherApp}
                    conversation={conversation}
                    llmApps={llmAppsByGeneralCategory}
                  />
                </Fragment>
              )}

              {feedback}
            </ChatContentBubble>
          </TimestampTooltip>
        </ChatContentRow>
      )}

      {chat.media && (
        <ChatContentRow
          css={
            (hasTypingIndicator || hasMessage || hasDocumentEntities) &&
            css`
              margin-top: 16px;
            `
          }
          avatar={avatar}
          classes={classes}
        >
          <TimestampTooltip timestamp={chat.createdAt}>
            <ChatContentBubble
              classes={BubbleClasses}
              color={color}
              loading={loading}
            >
              <ChatMedia media={chat.media} />

              {feedback}
            </ChatContentBubble>
          </TimestampTooltip>
        </ChatContentRow>
      )}

      {hasError && (
        <ChatContentRow
          css={
            (hasTypingIndicator ||
              hasMessage ||
              hasDocumentEntities ||
              hasMedia) &&
            css`
              margin-top: 16px;
            `
          }
          avatar={avatar}
          classes={classes}
        >
          <TimestampTooltip timestamp={chat.createdAt}>
            <ChatContentBubble
              classes={BubbleClasses}
              color={color}
              loading={loading}
            >
              <GeneratingErrorState state={chat.completeState} />
            </ChatContentBubble>
          </TimestampTooltip>
        </ChatContentRow>
      )}

      {hasCarouselOptions && chat.carouselOptions && (
        <ChatContentRow
          css={[
            (hasTypingIndicator ||
              hasMessage ||
              hasDocumentEntities ||
              hasMedia ||
              hasError) &&
              css`
                margin-top: 16px;
              `,
            css`
              padding: 0;
              overflow-x: hidden;

              .${chatContentRowClasses.content} {
                max-width: 100%;
                flex-grow: 1;
              }

              .${chatContentRowClasses.horizontalMargin} {
                width: 0;
              }

              .${carouselArrowClasses.root}.${carouselArrowClasses.prev} {
                right: ${horizontalPadding + 40}px;
              }

              .${carouselArrowClasses.root}.${carouselArrowClasses.next} {
                right: ${horizontalPadding}px;
              }
            `,
            align === 'left' &&
              css`
                .${carouselClasses.list} {
                  padding-left: ${horizontalPadding +
                  (avatar ? avatarWidth - 4 : 0)}px;
                }
              `,
          ]}
          classes={classes}
        >
          <RecommendedItemCarousel
            data={chat.carouselOptions}
            onItemClick={onRecommendationCarouselItemClick}
          />
        </ChatContentRow>
      )}

      {hasAssistantRecommendedApps && chat.assistantRecommendedApps && (
        <ChatContentRow
          css={[
            (hasTypingIndicator ||
              hasMessage ||
              hasDocumentEntities ||
              hasMedia ||
              hasError ||
              hasCarouselOptions) &&
              css`
                margin-top: 16px;
              `,
            css`
              padding: 0;
              overflow-x: hidden;

              .${chatContentRowClasses.content} {
                max-width: 100%;
                flex-grow: 1;
              }

              .${chatContentRowClasses.horizontalMargin} {
                width: 0;
              }

              .${carouselArrowClasses.root}.${carouselArrowClasses.prev} {
                right: ${horizontalPadding + 40}px;
              }

              .${carouselArrowClasses.root}.${carouselArrowClasses.next} {
                right: ${horizontalPadding}px;
              }
            `,
            align === 'left' &&
              css`
                .${carouselClasses.list} {
                  padding-left: ${horizontalPadding +
                  (avatar ? avatarWidth - 4 : 0)}px;
                }
              `,
          ]}
          classes={classes}
        >
          <RecommendedAppCarousel
            data={chat.assistantRecommendedApps}
            onAppClick={onAssistantRecommendedAppClick}
          />
        </ChatContentRow>
      )}

      {hasAssistantFallbackActionCandidates &&
        chat.assistantFallbackActionCandidates && (
          <ChatContentRow
            css={
              (hasTypingIndicator ||
                hasMessage ||
                hasDocumentEntities ||
                hasMedia ||
                hasError ||
                hasCarouselOptions ||
                hasAssistantRecommendedApps) &&
              css`
                margin-top: 16px;
              `
            }
            classes={classes}
          >
            <AssistantFallbackActionCandidates
              data={chat.assistantFallbackActionCandidates}
              onAssistantFallbackActionClick={onAssistantFallbackActionClick}
            />
          </ChatContentRow>
        )}

      {hasFaqCarousel && (
        <ChatContentRow
          css={
            (hasTypingIndicator ||
              hasMessage ||
              hasDocumentEntities ||
              hasMedia ||
              hasError ||
              hasCarouselOptions ||
              hasAssistantRecommendedApps ||
              hasAssistantFallbackActionCandidates) &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <FaqCarouselForm
            faqCarouselOptions={chat.faqCarousel ?? undefined}
            onSubmit={onFaqCarouselFormSubmit}
            isDisabled={isOptionDisabled}
          />
        </ChatContentRow>
      )}

      {hasCreatedLLMApp && chat.assistantCreatedLlmApp && (
        <ChatContentRow
          css={
            (hasTypingIndicator ||
              hasMessage ||
              hasDocumentEntities ||
              hasMedia ||
              hasError ||
              hasCarouselOptions ||
              hasAssistantRecommendedApps ||
              hasAssistantFallbackActionCandidates ||
              hasFaqCarousel) &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <AssistantCreatedLLMAppOptions
            appName={chat.assistantCreatedLlmApp.name}
            editLink={assistantCreatedAppEditLink}
            isValidApp={isValidCreatedLLMApp}
            onRunApp={onAssistantCreatedAppRun}
          />
        </ChatContentRow>
      )}

      {hasChatOptionInfos && (
        <ChatContentRow
          css={
            (hasTypingIndicator ||
              hasMessage ||
              hasDocumentEntities ||
              hasMedia ||
              hasError ||
              hasCarouselOptions ||
              hasAssistantRecommendedApps ||
              hasAssistantFallbackActionCandidates ||
              hasFaqCarousel ||
              hasCreatedLLMApp) &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <ChatOptionInfoForm
            chatOptionInfos={chatOptionInfos}
            isChatOptionInfoDisabled={isOptionDisabled}
            multipleOption={chat.multipleOption ?? false}
            onSubmit={onChatOptionInfoFormSubmit}
            readOnly={chatOptionInfoFormReadOnly}
            wrap={horizontalOptions}
          />
        </ChatContentRow>
      )}

      {hasContactAgentByEmailForm && !contactAgentByEmailFormReadOnly && (
        <ChatContentRow
          css={
            (hasTypingIndicator ||
              hasMessage ||
              hasDocumentEntities ||
              hasMedia ||
              hasError ||
              hasCarouselOptions ||
              hasAssistantRecommendedApps ||
              hasAssistantFallbackActionCandidates ||
              hasFaqCarousel ||
              hasCreatedLLMApp ||
              hasChatOptionInfos) &&
            css`
              margin-top: 16px;
            `
          }
          classes={classes}
        >
          <ChatForm>
            <ContactAgentByEmailForm
              defaultValues={{
                agent: assignee ? getAgentSelectOption(assignee) : null,
              }}
              onSubmit={onContactAgentByEmailFormSubmit}
              readOnly={contactAgentByEmailFormReadOnly}
            />
          </ChatForm>
        </ChatContentRow>
      )}
    </Fragment>
  );
};
function useAlliClient() {
  throw new Error('Function not implemented.');
}
