/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type CarouselOptionTextFragment = {
  __typename: 'CarouselOptionText';
} & Pick<Types.CarouselOptionText, 'text'> & {
    style: Types.Maybe<
      { __typename: 'CarouselOptionTextStyle' } & Pick<
        Types.CarouselOptionTextStyle,
        'color'
      >
    >;
  };

export const CarouselOptionTextFragmentDoc = gql`
  fragment CarouselOptionTextFragment on CarouselOptionText {
    text
    style {
      color
    }
  }
`;
