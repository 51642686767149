import { Types } from '@allganize/alli-sdk-interfaces';
import { isDraftInputEmpty } from '@allganize/draft-input';
import { useWindow } from '@allganize/hooks';
import { MakeRequired } from '@allganize/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, isValid, parse } from 'date-fns';
import { ContentState, EditorState } from 'draft-js';
import { filesize } from 'filesize';
import { compact } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { z } from 'zod';
import { AgentSelectOption } from '../agent-select/agent-select-option';
import { ChatFileFragment } from '../graphql/fragments/chat-file-fragment';
import { ChatVariableFragment } from '../graphql/fragments/chat-variable-fragment';
import {
  FormValueFragment,
  FormValueInfoFragment,
} from '../graphql/fragments/form-value-fragment';
import { ValidationContext } from '../validation-context/validation-context';
import { FormValueValidation } from './form-value-validation';

type FormValueFieldType =
  | 'text'
  | 'number'
  | 'date'
  | 'multi-select'
  | 'select'
  | 'checkbox'
  | 'radio'
  | 'file'
  | 'boolean';

export interface FormValueOptionType {
  value: number;
  label: string;
  data?: FormValueInfoFragment;
}

export interface FormValue {
  readonly display: string;
  readonly mandatory: boolean;
  readonly variable: ChatVariableFragment;
  readonly fieldType: FormValueFieldType;
  booleanValue: boolean;
  stringValue: EditorState;
  numberValue: number | null;
  dateValue: Date | null;
  checkboxValue: number[];
  checkboxOptions: MakeRequired<FormValueOptionType, 'data'>[];
  radioValue: number | null;
  radioOptions: MakeRequired<FormValueOptionType, 'data'>[];
  multiSelectValue: FormValueOptionType[];
  multiSelectOptions: MakeRequired<FormValueOptionType, 'data'>[];
  selectValue: FormValueOptionType | null;
  selectOptions: MakeRequired<FormValueOptionType, 'data'>[];
  fileValue: ChatFileFragment | File | null;
}

interface AgentSelectValues {
  enabled: boolean;
  value: AgentSelectOption[];
  options: AgentSelectOption[];
}

export interface FormValueFormValues {
  formValues: FormValue[];
  agentSelect: AgentSelectValues;
}

const defaultValues: FormValueFormValues = {
  formValues: [],
  agentSelect: {
    enabled: false,
    value: [],
    options: [],
  },
};

const errorMessages = defineMessages({
  fieldRequired: {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  fieldInvalidOption: {
    id: 'form.errors.invalid-option',
    defaultMessage: 'Invalid option',
    description: 'Field invalid option error message',
  },
  fieldInvalidFormat: {
    id: 'form.errors.invalid-format',
    defaultMessage: 'Invalid format',
    description: 'Field invalid format error message',
  },
  maxFileSize: {
    id: 'form.file.errors.max',
    defaultMessage: 'File too large (Max. {size})',
    description: 'File too large error message',
  },
});

const createValidationSchema = (
  intl: IntlShape,
  options: FormValueValidation,
  win?: typeof globalThis,
) => {
  const FileKlass = win?.File ?? File;

  const agentSelectOptionSchema = z.object({
    label: z.string(),
    value: z.union([z.string(), z.number()]),
    data: z.object({
      __typename: z.enum(['Agent']),
      id: z.union([z.string(), z.number()]),
      firstName: z.string(),
      lastName: z.string().nullable(),
      displayName: z.string().nullable(),
      avatar: z
        .object({
          __typename: z.enum(['Media']),
          id: z.union([z.string(), z.number()]),
          url: z.string(),
        })
        .nullable(),
    }),
  });

  const chatVariableSchema = z.object({
    __typename: z.enum(['Variable']),
    id: z.union([z.string(), z.number()]),
    name: z.string(),
    description: z.string(),
    displayText: z.string(),
    type: z.string(),
    validationCustom: z.string().nullable(),
    validationFailedMessage: z.string().nullable(),
  });

  const chatFileSchema = z.object({
    __typename: z.enum(['File']),
    id: z.union([z.string(), z.number()]),
    fileId: z.string().nullable(),
    metaInfo: z.string().nullable(),
    url: z.string(),
    createdAt: z.string().nullable(),
    modifiedAt: z.string().nullable(),
  });

  const chatMediaSchema = z.object({
    __typename: z.enum(['Media']),
    id: z.union([z.string(), z.number()]),
    mediaType: z.string().nullable(),
    metaInfo: z.string().nullable(),
    url: z.string(),
    width: z.number().nullable(),
    height: z.number().nullable(),
  });

  const formValueInfoSchema = z.object({
    __typename: z.enum(['FormValueInfo']),
    shortenOption: z.string(),
    longOption: z.string(),
    media: chatMediaSchema.nullable(),
    style: z.object({
      __typename: z.string(),
      bgColor: z.string().nullable(),
      bold: z.boolean().nullable(),
      fontColor: z.string().nullable(),
      italic: z.boolean().nullable(),
      lineColor: z.string().nullable(),
      underline: z.boolean().nullable(),
    }),
  });

  return z
    .object({
      formValues: z.array(
        z
          .object({
            display: z.string(),
            mandatory: z.boolean(),
            variable: chatVariableSchema,
            fieldType: z.string(),
            booleanValue: z.boolean({
              invalid_type_error: intl.formatMessage(
                errorMessages.fieldInvalidFormat,
              ),
            }),
            numberValue: z
              .number({
                invalid_type_error: intl.formatMessage(
                  errorMessages.fieldInvalidFormat,
                ),
              })
              .nullable(),
            stringValue: z.instanceof(EditorState),
            dateValue: z
              .date({
                // https://github.com/colinhacks/zod/issues/1526
                errorMap: (issue, { defaultError }) => ({
                  message:
                    issue.code === 'invalid_date'
                      ? intl.formatMessage(errorMessages.fieldInvalidFormat)
                      : defaultError,
                }),
              })
              .nullable(),
            checkboxValue: z.array(z.number()),
            checkboxOptions: z.array(
              z
                .object({
                  label: z.string(),
                  value: z.number(),
                  data: formValueInfoSchema,
                })
                .required({ value: true, data: true }),
            ),
            radioValue: z
              .number({
                invalid_type_error: intl.formatMessage(
                  errorMessages.fieldInvalidFormat,
                ),
              })
              .nullable(),
            radioOptions: z.array(
              z
                .object({
                  label: z.string(),
                  value: z.number(),
                  data: formValueInfoSchema,
                })
                .required({ value: true, data: true }),
            ),
            multiSelectValue: z.array(
              z
                .object(
                  {
                    label: z.string(),
                    value: z.number(),
                    data: formValueInfoSchema.optional(),
                  },
                  {
                    invalid_type_error: intl.formatMessage(
                      errorMessages.fieldInvalidFormat,
                    ),
                  },
                )
                .required({ value: true }),
            ),
            multiSelectOptions: z.array(
              z
                .object({
                  label: z.string(),
                  value: z.number(),
                  data: formValueInfoSchema,
                })
                .required({ value: true, data: true }),
            ),
            selectValue: z
              .object(
                {
                  label: z.string(),
                  value: z.number(),
                  data: formValueInfoSchema.optional(),
                },
                {
                  invalid_type_error: intl.formatMessage(
                    errorMessages.fieldInvalidFormat,
                  ),
                },
              )
              .required({ value: true })
              .nullable(),
            selectOptions: z.array(
              z
                .object({
                  label: z.string(),
                  value: z.number(),
                  data: formValueInfoSchema,
                })
                .required({ value: true, data: true }),
            ),
            fileValue: z
              .union([
                chatFileSchema.required({
                  id: true,
                  url: true,
                }),
                z.instanceof(File),
                z.instanceof(FileKlass),
              ])
              .nullable(),
          })
          .refine(
            schema => {
              if (schema.fieldType !== 'number') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return (
                schema.numberValue !== null && !Number.isNaN(schema.numberValue)
              );
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['numberValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'text') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return !isDraftInputEmpty(schema.stringValue);
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['stringValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'text') {
                return true;
              }

              if (!schema.variable.validationCustom) {
                return true;
              }

              const regexp = new RegExp(schema.variable.validationCustom);
              const plainText = schema.stringValue
                .getCurrentContent()
                .getPlainText('\n');
              return regexp.test(plainText);
            },
            schema => {
              return {
                message:
                  schema.variable.validationFailedMessage ||
                  intl.formatMessage(errorMessages.fieldInvalidFormat),
                path: ['stringValue'],
              };
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'date') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return schema.dateValue !== null;
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['dateValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'checkbox') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return schema.checkboxValue.length > 0;
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['checkboxValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'checkbox') {
                return true;
              }

              if (schema.checkboxValue.length === 0) {
                return true;
              }

              return schema.checkboxValue.every(
                val =>
                  schema.checkboxOptions.findIndex(opt => opt.value === val) >=
                  0,
              );
            },
            {
              message: intl.formatMessage(errorMessages.fieldInvalidOption),
              path: ['checkboxValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'radio') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return schema.radioValue !== null;
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['radioValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'radio') {
                return true;
              }

              if (schema.radioValue === null) {
                return true;
              }

              return (
                schema.radioOptions.findIndex(
                  option => option.value === schema.radioValue,
                ) >= 0
              );
            },
            {
              message: intl.formatMessage(errorMessages.fieldInvalidOption),
              path: ['radioValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'select') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return schema.selectValue !== null;
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['selectValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'select') {
                return true;
              }

              if (schema.selectValue === null) {
                return true;
              }

              return (
                schema.selectOptions.findIndex(
                  option => option.value === schema.selectValue?.value,
                ) >= 0
              );
            },
            {
              message: intl.formatMessage(errorMessages.fieldInvalidOption),
              path: ['selectValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'multi-select') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return schema.multiSelectValue.length > 0;
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['multiSelectValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'multi-select') {
                return true;
              }

              if (schema.multiSelectValue.length === 0) {
                return true;
              }

              return schema.multiSelectValue.every(
                val =>
                  schema.multiSelectOptions.findIndex(
                    opt => opt.value === val.value,
                  ) >= 0,
              );
            },
            {
              message: intl.formatMessage(errorMessages.fieldInvalidOption),
              path: ['multiSelectValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'file') {
                return true;
              }

              if (!schema.mandatory) {
                return true;
              }

              return schema.fileValue !== null;
            },
            {
              message: intl.formatMessage(errorMessages.fieldRequired),
              path: ['fileValue'],
            },
          )
          .refine(
            schema => {
              if (schema.fieldType !== 'file') {
                return true;
              }

              if (
                !(
                  schema.fileValue instanceof File ||
                  schema.fileValue instanceof FileKlass
                )
              ) {
                return true;
              }

              if (schema.fileValue.type.startsWith('image/')) {
                return (
                  schema.fileValue.size <=
                  options.formValues.fileValue.maxSize.image
                );
              }

              return (
                schema.fileValue.size <=
                options.formValues.fileValue.maxSize.default
              );
            },
            schema => {
              const isImageFile =
                (schema.fileValue instanceof File ||
                  schema.fileValue instanceof FileKlass) &&
                schema.fileValue.type.startsWith('image/');

              return {
                message: isImageFile
                  ? intl.formatMessage(errorMessages.maxFileSize, {
                      size: filesize(
                        options.formValues.fileValue.maxSize.image,
                        {
                          output: 'string',
                          locale: intl.locale,
                          localeOptions: intl.formats.date,
                        },
                      ),
                    })
                  : intl.formatMessage(errorMessages.maxFileSize, {
                      size: filesize(
                        options.formValues.fileValue.maxSize.default,
                        {
                          output: 'string',
                          locale: intl.locale,
                          localeOptions: intl.formats.date,
                        },
                      ),
                    }),
                path: ['fileValue'],
              };
            },
          ),
      ),
      agentSelect: z.object({
        enabled: z.boolean(),
        value: z.array(agentSelectOptionSchema),
        options: z.array(agentSelectOptionSchema),
      }),
    })
    .required({});
};

const getFormValueFieldType = (
  fragment: FormValueFragment,
): FormValueFieldType => {
  const formValueInfos = compact(fragment.formValueInfos ?? []);

  if (fragment.inputType === 'BUTTON' && formValueInfos.length > 0) {
    if (fragment.multipleOption) {
      return 'checkbox';
    }

    return 'radio';
  }

  if (fragment.inputType === 'DROPDOWN' && formValueInfos.length > 0) {
    if (fragment.multipleOption) {
      return 'multi-select';
    }

    return 'select';
  }

  if (fragment.variable?.type === 'NUMBER') {
    return 'number';
  }

  if (fragment.variable?.type === 'BOOLEAN') {
    return 'boolean';
  }

  if (fragment.variable?.type === 'DATETIME') {
    return 'date';
  }

  // FILE input type only when variable type is FILE
  if (fragment.variable?.type === 'FILE') {
    return 'file';
  }

  return 'text';
};

export const dateFormat = 'yyyy-MM-dd';

export const formValuesReducer = (
  values: FormValue[],
  fragment: Types.Maybe<FormValueFragment>,
): FormValue[] => {
  if (!fragment) {
    return values;
  }

  if (!fragment.variable) {
    return values;
  }

  const formValueInfos = fragment.formValueInfos ?? [];
  const options = formValueInfos.reduce<
    MakeRequired<FormValueOptionType, 'data'>[]
  >((acc, curr, i) => {
    if (!curr) {
      return acc;
    }

    return [...acc, { value: i, label: curr.longOption, data: curr }];
  }, []);
  const fieldType = getFormValueFieldType(fragment);
  const numberValue = fragment.value ? Number(fragment.value) : null;
  let dateValue: Date | null = null;

  if (fragment.value) {
    try {
      dateValue = parse(fragment.value, dateFormat, new Date());

      if (!isValid(dateValue)) {
        dateValue = null;
      }
    } catch (err) {
      dateValue = null;
    }
  }

  const choiceValues = (fragment.choices ?? []).reduce<FormValueOptionType[]>(
    (acc, curr) => {
      if (curr === null) {
        return acc;
      }

      const option = options[curr];

      return [
        ...acc,
        {
          value: curr,
          label: option?.label ?? '',
          data: option?.data,
        },
      ];
    },
    [],
  );

  return [
    ...values,
    {
      display: fragment.display,
      mandatory: fragment.mandatory,
      variable: fragment.variable,
      fieldType,
      booleanValue: fragment.value?.toLowerCase() === 'true' ? true : false,
      stringValue: EditorState.createWithContent(
        ContentState.createFromText(fragment.value ?? '', '\n'),
      ),
      numberValue:
        numberValue !== null && Number.isNaN(numberValue) ? null : numberValue,
      dateValue,
      checkboxValue: choiceValues.map(choice => choice.value),
      checkboxOptions: options,
      radioValue: choiceValues[0]?.value ?? null,
      radioOptions: options,
      multiSelectValue: choiceValues,
      multiSelectOptions: options,
      selectValue: choiceValues[0] ?? null,
      selectOptions: options,
      fileValue: fragment.file,
    },
  ];
};

export interface UseFormValueFormOptions {
  defaultValues: Partial<FormValueFormValues>;
  onSubmit?(
    values: Types.SendFormInput,
    optimisticFormValues?: FormValueFragment[],
  ): Promise<any>;
}

export const useFormValueForm = ({
  defaultValues: defaultValuesOption,
  onSubmit,
}: UseFormValueFormOptions) => {
  const intl = useIntl();
  const { ref: formRef, window: win } = useWindow();
  const { formValue } = useContext(ValidationContext);
  const validationSchema = useMemo(
    () =>
      zodResolver(createValidationSchema(intl, formValue, win ?? undefined)),
    [formValue, intl, win],
  );

  const form = useForm<FormValueFormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
      ...defaultValuesOption,
    },
    resolver: validationSchema,
  });

  const { setError } = form;

  const submit = async (values: FormValueFormValues) => {
    try {
      const FileKlass = win?.File ?? File;

      await onSubmit?.(
        {
          formValues: values.formValues.map(formValue => {
            const value: Types.FormValueInput = {
              variable: formValue.variable.id,
            };

            switch (formValue.fieldType) {
              case 'boolean':
                value.value = formValue.booleanValue ? 'true' : 'false';
                break;
              case 'checkbox':
                value.choices = formValue.checkboxValue;
                break;
              case 'date':
                if (formValue.dateValue !== null) {
                  value.value = format(formValue.dateValue, dateFormat);
                }

                break;
              case 'file':
                if (
                  formValue.fileValue instanceof FileKlass ||
                  formValue.fileValue instanceof File
                ) {
                  value.file = {
                    file: formValue.fileValue,
                    metaInfo: JSON.stringify({
                      lastModified: formValue.fileValue.lastModified,
                      lastModifiedDate: (formValue.fileValue as any)
                        .lastModifiedDate,
                      name: formValue.fileValue.name,
                      size: formValue.fileValue.size,
                      type: formValue.fileValue.type,
                    }),
                  };
                } else if (formValue.fileValue !== null) {
                  value.file = { id: formValue.fileValue.id };
                }

                break;
              case 'multi-select':
                value.choices = formValue.multiSelectValue.map(
                  opt => opt.value,
                );
                break;
              case 'number':
                if (formValue.numberValue !== null) {
                  value.value = formValue.numberValue.toString();
                }

                break;
              case 'radio':
                if (formValue.radioValue !== null) {
                  value.choices = [formValue.radioValue];
                }

                break;
              case 'select':
                if (formValue.selectValue !== null) {
                  value.choices = [formValue.selectValue.value];
                }

                break;
              case 'text':
                value.value = formValue.stringValue
                  .getCurrentContent()
                  .getPlainText('\n');
                break;
            }

            return value;
          }),
          targetAgents: values.agentSelect.enabled
            ? values.agentSelect.value.map(option => option.value)
            : null,
        },
        values.formValues.map(formValue => {
          const result: FormValueFragment = {
            __typename: 'FormValue',
            display: formValue.display,
            mandatory: formValue.mandatory,
            variable: formValue.variable,
            choices: null,
            file: null,
            formValueInfos: null,
            inputType: null,
            multipleOption: null,
            value: null,
          };

          switch (formValue.fieldType) {
            case 'boolean':
              result.value = formValue.booleanValue ? 'true' : 'false';
              break;
            case 'checkbox':
              result.choices = formValue.checkboxValue;
              result.formValueInfos = formValue.checkboxOptions.map(
                opt => opt.data,
              );
              result.inputType = 'BUTTON';
              result.multipleOption = true;
              break;
            case 'date':
              if (formValue.dateValue !== null) {
                result.value = format(formValue.dateValue, dateFormat);
              }

              break;
            case 'file':
              if (
                formValue.fileValue instanceof File ||
                formValue.fileValue instanceof FileKlass
              ) {
                result.file = {
                  __typename: 'File',
                  id: [
                    formValue.fileValue.name,
                    formValue.fileValue.lastModified,
                    formValue.fileValue.size,
                    formValue.fileValue.type,
                    Date.now(),
                  ].join('-'),
                  fileId: null,
                  url: URL.createObjectURL(formValue.fileValue),
                  metaInfo: JSON.stringify({
                    lastModified: formValue.fileValue.lastModified,
                    lastModifiedDate: (formValue.fileValue as any)
                      .lastModifiedDate,
                    name: formValue.fileValue.name,
                    size: formValue.fileValue.size,
                    type: formValue.fileValue.type,
                  }),
                  createdAt: null,
                  modifiedAt: null,
                };
              } else if (formValue.fileValue !== null) {
                result.file = formValue.fileValue;
              }

              result.inputType = 'FILE';
              break;
            case 'multi-select':
              result.choices = formValue.multiSelectValue.map(opt => opt.value);
              result.formValueInfos = formValue.multiSelectOptions.map(
                opt => opt.data,
              );
              result.inputType = 'DROPDOWN';
              result.multipleOption = true;
              break;
            case 'number':
              if (formValue.numberValue !== null) {
                result.value = formValue.numberValue.toString();
              }

              break;
            case 'select':
              if (formValue.selectValue) {
                result.choices = [formValue.selectValue.value];
              }

              result.formValueInfos = formValue.selectOptions.map(
                opt => opt.data,
              );
              result.inputType = 'DROPDOWN';
              break;
            case 'radio':
              if (formValue.radioValue !== null) {
                result.choices = [formValue.radioValue];
              }

              result.formValueInfos = formValue.radioOptions.map(
                opt => opt.data,
              );
              result.inputType = 'BUTTON';
              break;
            case 'text':
              result.inputType = 'TEXT';
              result.value = formValue.stringValue
                .getCurrentContent()
                .getPlainText('\n');
              break;
          }

          return result;
        }),
      );
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }
    }
  };

  return {
    form,
    formRef,
    submit,
  };
};
