import { DraftDocumentContext } from '@allganize/draft-input';
import { Chip } from '@allganize/ui-chip';
import { CircularProgress } from '@allganize/ui-circular-progress';
import clsx from 'clsx';
import { forwardRef, useContext, useState } from 'react';
import { draftDocumentListItemClasses } from './draft-document-list-item-classes';
import { DraftDocumentListItemProps } from './draft-document-list-item-type-map';
import { css } from '@emotion/react';
import { SourceNumber } from './source-number';

export const DraftDocumentListItem = forwardRef<
  HTMLDivElement,
  DraftDocumentListItemProps
>((props, ref) => {
  const { classes, entity, onClick: onClickProp, ...other } = props;
  const { isSelected, onClick } = useContext(DraftDocumentContext);
  const [loading, setLoading] = useState(false);
  const selected = isSelected({ entity });
  const clickable = onClickProp || onClick;

  const handleClick = async (ev?: React.MouseEvent<HTMLDivElement>) => {
    if (ev) {
      onClickProp?.(ev);
    }

    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await onClick?.({ entity });
    } catch {
      // noop
    }

    setLoading(false);
  };

  const extractedSourceNumber = entity.text.slice(1, 2);

  return (
    <Chip
      css={css`
        padding: 16px;
      `}
      color={selected ? 'gray-dark' : 'gray-subtle'}
      icon={
        loading ? (
          <CircularProgress size="xs" />
        ) : (
          <SourceNumber sourceNum={extractedSourceNumber} />
        )
      }
      disabled={loading}
      label={entity.data.knowledgeBasePreview.knowledgeBase?.title}
      {...other}
      ref={ref}
      onClick={clickable ? handleClick : undefined}
      className={clsx(
        draftDocumentListItemClasses.root,
        {
          [draftDocumentListItemClasses.loading]: loading,
          [draftDocumentListItemClasses.selected]: selected,
        },
        classes?.root,
        {
          [classes?.loading ?? '']: loading,
          [classes?.selected ?? '']: selected,
        },
        other.className,
      )}
    />
  );
});
