import { useCombinedRef, useMeasure } from '@allganize/hooks';
import { Paper } from '@allganize/ui-paper';
import { useTheme } from '@allganize/ui-theme';
import { Grow } from '@allganize/ui-transition';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { GroupBase, MenuProps } from 'react-select';
import { useMenuPlacer } from './menu-placer';
import { selectClasses } from './select-classes';

export const Menu = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: MenuProps<Option, IsMulti, Group>,
) => {
  const { children, className, getClassNames, innerProps, innerRef, theme } =
    props;
  const uiTheme = useTheme();
  const {
    ref,
    placerProps: { placement, maxHeight },
    updateComputedPosition,
  } = useMenuPlacer(props);
  const { measureRef } = useMeasure({
    offset: true,
    onResize(contentRect) {
      if (!contentRect.offset?.height) {
        return;
      }

      updateComputedPosition();
    },
  });
  const combinedRef = useCombinedRef(ref, measureRef, innerRef);

  return (
    <div
      css={[
        css`
          position: absolute;
          z-index: ${uiTheme.zIndex.modal};
          width: 100%;
          margin: ${theme.spacing.menuGutter}px 0;

          .${selectClasses.menuList} {
            max-height: ${maxHeight}px;
          }
        `,
        placement === 'top' &&
          css`
            bottom: 100%;
          `,
        placement === 'bottom' &&
          css`
            top: 100%;
          `,
      ]}
      {...innerProps}
      ref={combinedRef}
      className={clsx(
        selectClasses.menu,
        {
          [selectClasses.menuPlacementTop]: placement === 'top',
          [selectClasses.menuPlacementBottom]: placement === 'bottom',
        },
        getClassNames('menu', props),
        className,
        innerProps?.className,
      )}
    >
      <Grow appear in timeout="auto">
        <Paper elevation={3}>{children}</Paper>
      </Grow>
    </div>
  );
};
