import { EventEmitter } from 'eventemitter3';
import { config, Region } from '../../config';
import { AlliClientEntryType } from '../alli-client/alli-client-options';
import { AlliUserManager } from '../alli-user-manager/alli-user-manager';
import {
  AlliApiEndpoints,
  AlliAuthClientEvents,
  AlliAuthClientOptions,
  AlliSdkType,
} from './alli-auth-client-options';
import { AlliDeviceIdManager } from './alli-device-id-manager';
import { AlliPersistedUserIdManager } from './alli-persisted-user-id-manager';

const textEncoder = new TextEncoder();

export class AlliAuthClient extends EventEmitter<AlliAuthClientEvents> {
  public static entryTypeToSdkType(
    entryType: AlliClientEntryType,
  ): AlliSdkType {
    return (
      {
        web: 'WEB_SDK',
        popup: 'WEB_SDK',
        android: 'ANDROID_SDK',
        ios: 'IOS_SDK',
        preview: 'INTERNAL_SDK',
        market: 'WEB_SDK',
      } as const
    )[entryType];
  }

  public static getRegionFromApiKey(apiKey: string): Region {
    const region = apiKey.substring(0, 2);

    if (region === 'AJ') {
      return 'ja';
    }

    if (region === 'RK') {
      return 'kr';
    }

    return 'default';
  }

  public static utoa(value: string | number) {
    const bytes = textEncoder.encode(`${value}`);
    const binString = String.fromCodePoint(...bytes);
    return `base64:${btoa(binString)}`;
  }

  public readonly endpoints: AlliApiEndpoints = config.api.sdk.default;
  public readonly sdkType: AlliSdkType = 'WEB_SDK';
  public readonly deviceIdManager: AlliDeviceIdManager;
  public readonly persistedUserIdManager: AlliPersistedUserIdManager;
  public readonly userManager: AlliUserManager;

  constructor({
    endpoints,
    sdkType,
    deviceIdManager,
    persistedUserIdManager,
    userManager,
  }: AlliAuthClientOptions) {
    super();
    this.endpoints = endpoints ?? this.endpoints;

    if (sdkType) {
      this.sdkType = sdkType;
    }

    this.deviceIdManager = deviceIdManager;
    this.persistedUserIdManager = persistedUserIdManager;
    this.userManager = userManager;
  }

  public getGraphQLEndpoint() {
    return this.endpoints;
  }

  public async getGraphQLHeaders(): Promise<Record<string, string>> {
    const deviceId = await this.deviceIdManager.getDeviceId();
    const persistedUserId = await this.persistedUserIdManager.getUserId();

    const result: Record<string, string> = {
      'alli-sdk-type': this.sdkType,
      'alli-sdk-version': config.version,
      'finger-print': deviceId,
    };

    if (persistedUserId) {
      result['cookie-user-id'] = AlliAuthClient.utoa(persistedUserId);
    }

    if (this.userManager.user.id !== null) {
      result['own-user-id'] = AlliAuthClient.utoa(this.userManager.user.id);
    }

    return result;
  }

  public getRegionFromEndpoint(): Region {
    return Object.entries(config.api.sdk).find(
      ([_, endpoints]) => endpoints.http === this.endpoints.http,
    )?.[0] as Region;
  }
}
