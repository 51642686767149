/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateSingleActionAppAccessTokenPublicMutationVariables =
  UserQueryTypes.Exact<{
    publicToken: UserQueryTypes.Scalars['String']['input'];
  }>;

export type GenerateSingleActionAppAccessTokenPublicMutation = {
  __typename: 'UserMutation';
} & {
  generateSingleActionAppAccessTokenPublic: UserQueryTypes.Maybe<
    { __typename: 'GenerateSingleActionAppAccessTokenPublic' } & Pick<
      UserQueryTypes.GenerateSingleActionAppAccessTokenPublic,
      'accessToken'
    > & {
        errors: UserQueryTypes.Maybe<
          Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
        >;
      }
  >;
};

export const GenerateSingleActionAppAccessTokenPublicMutationDocument = gql`
  mutation GenerateSingleActionAppAccessTokenPublicMutation(
    $publicToken: String!
  ) {
    generateSingleActionAppAccessTokenPublic(token: $publicToken) {
      accessToken
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GenerateSingleActionAppAccessTokenPublicMutationMutationFn =
  Apollo.MutationFunction<
    GenerateSingleActionAppAccessTokenPublicMutation,
    GenerateSingleActionAppAccessTokenPublicMutationVariables
  >;

/**
 * __useGenerateSingleActionAppAccessTokenPublicMutation__
 *
 * To run a mutation, you first call `useGenerateSingleActionAppAccessTokenPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSingleActionAppAccessTokenPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSingleActionAppAccessTokenPublicMutation, { data, loading, error }] = useGenerateSingleActionAppAccessTokenPublicMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *   },
 * });
 */
export function useGenerateSingleActionAppAccessTokenPublicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSingleActionAppAccessTokenPublicMutation,
    GenerateSingleActionAppAccessTokenPublicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSingleActionAppAccessTokenPublicMutation,
    GenerateSingleActionAppAccessTokenPublicMutationVariables
  >(GenerateSingleActionAppAccessTokenPublicMutationDocument, options);
}
export type GenerateSingleActionAppAccessTokenPublicMutationHookResult =
  ReturnType<typeof useGenerateSingleActionAppAccessTokenPublicMutation>;
export type GenerateSingleActionAppAccessTokenPublicMutationMutationResult =
  Apollo.MutationResult<GenerateSingleActionAppAccessTokenPublicMutation>;
export type GenerateSingleActionAppAccessTokenPublicMutationMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateSingleActionAppAccessTokenPublicMutation,
    GenerateSingleActionAppAccessTokenPublicMutationVariables
  >;
