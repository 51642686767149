import { Types } from '@allganize/alli-sdk-interfaces';

export const CONVERSATION_STATE_WITH_AGENT: Types.ConversationStateEnum[] = [
  'WAIT_AGENT',
  'INTERRUPTED',
];

export const CONVERSATION_STATE_WAIT_USER_ANSWER: Types.ConversationStateEnum[] =
  ['WAIT_USER_ANSWER', 'WAIT_USER_ANSWER_2'];

export const CONVERSATION_STATE_ENDED: Types.ConversationStateEnum[] = [
  'END_BY_USER',
  'END_OF_CHATBOT',
  'END_BY_AGENT',
  'END_OF_OBSERVER',
];
