import mixpanel, { Mixpanel, RequestOptions, Response } from 'mixpanel-browser';
import { AlliSdkAnalyticsEventTypeMap } from './alli-sdk-analytics-event-type-map';
import { AlliSdkAnalyticsOptions } from './alli-sdk-analytics-options';

export class AlliSdkAnalytics {
  private static defaultName = 'alli-sdk-analytics';
  public readonly mixpanel: Mixpanel | null = null;

  constructor(options: AlliSdkAnalyticsOptions) {
    if (options.mixpanel) {
      this.mixpanel = mixpanel.init(
        options.mixpanel.token,
        options.mixpanel.config ?? {},
        options.mixpanel.name || AlliSdkAnalytics.defaultName,
      );
    }
  }

  public setUserId(id: string | null) {
    if (id === null) {
      this.mixpanel?.reset();
    } else {
      this.mixpanel?.identify(id);
    }
  }

  public get user() {
    return {
      mixpanel: this.mixpanel?.people,
    };
  }

  public async track<K extends keyof AlliSdkAnalyticsEventTypeMap>(
    name: K,
    properties?: AlliSdkAnalyticsEventTypeMap[K],
    options?: { mixpanel?: RequestOptions },
  ) {
    const mixpanel = await new Promise<Response | void>(resolve => {
      if (this.mixpanel) {
        this.mixpanel?.track(
          name,
          properties || undefined,
          options?.mixpanel,
          resolve,
        );
      } else {
        resolve();
      }
    });

    return { mixpanel };
  }

  public reset() {
    this.mixpanel?.reset();
  }
}
