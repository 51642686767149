import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useAuthClient } from '../auth/use-auth-client';

export const useAuth = () => {
  const authClient = useAuthClient();
  const oktaAuth = useOktaAuth();
  const [isEnabled, setIsEnabled] = useState(authClient?.isEnabled() ?? false);
  const isAuthenticated = oktaAuth?.authState?.isAuthenticated ?? false;

  const signIn = () => {
    return authClient?.signIn({
      okta: { originalUri: window.location.toString() },
    });
  };

  const signOut = () => {
    return authClient?.signOut({
      okta: {
        state: window.encodeURIComponent(
          JSON.stringify({
            next: toRelativeUrl(
              window.location.toString(),
              window.location.origin,
            ),
          }),
        ),
      },
    });
  };

  useEffect(() => {
    const handleOktaAuthChanged = () => {
      setIsEnabled(authClient?.isEnabled() ?? false);
    };

    authClient?.on('oktaAuthChanged', handleOktaAuthChanged);

    return () => {
      authClient?.off('oktaAuthChanged', handleOktaAuthChanged);
    };
  }, [authClient]);

  return {
    authClient,
    isAuthenticated,
    isEnabled,
    signIn,
    signOut,
  };
};
