/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import {
  SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_,
} from '../fragments/single-action-input-knowledge-base-fragment';
import { gql } from '@apollo/client';
import { SingleActionInputKnowledgeBaseFragmentDoc } from '../fragments/single-action-input-knowledge-base-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KnowledgeBasesPublicSubscriptionVariables = UserQueryTypes.Exact<{
  where?: UserQueryTypes.InputMaybe<UserQueryTypes.ProjectWhereUniqueInput>;
  ids?: UserQueryTypes.InputMaybe<
    Array<UserQueryTypes.InputMaybe<UserQueryTypes.Scalars['ID']['input']>>
  >;
  accessToken: UserQueryTypes.Scalars['String']['input'];
}>;

export type KnowledgeBasesPublicSubscription = {
  __typename: 'UserSubscription';
} & {
  knowledgeBasesPublic: UserQueryTypes.Maybe<
    | ({
        __typename: 'CSVKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_)
    | ({
        __typename: 'GoogleDocsKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_)
    | ({
        __typename: 'HWPKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_)
    | ({
        __typename: 'ImageKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_)
    | ({
        __typename: 'MSDocsKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_)
    | ({
        __typename: 'MSExcelKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_)
    | ({
        __typename: 'MSPPTKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_)
    | ({
        __typename: 'OldMSDocsKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_)
    | ({
        __typename: 'PDFKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_)
    | ({
        __typename: 'TextKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_)
  >;
};

export const KnowledgeBasesPublicSubscriptionDocument = gql`
  subscription KnowledgeBasesPublicSubscription(
    $where: ProjectWhereUniqueInput
    $ids: [ID]
    $accessToken: String!
  ) {
    knowledgeBasesPublic(where: $where, ids: $ids, accessToken: $accessToken) {
      ...SingleActionInputKnowledgeBaseFragment
    }
  }
  ${SingleActionInputKnowledgeBaseFragmentDoc}
`;

/**
 * __useKnowledgeBasesPublicSubscription__
 *
 * To run a query within a React component, call `useKnowledgeBasesPublicSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeBasesPublicSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeBasesPublicSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      ids: // value for 'ids'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useKnowledgeBasesPublicSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    KnowledgeBasesPublicSubscription,
    KnowledgeBasesPublicSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    KnowledgeBasesPublicSubscription,
    KnowledgeBasesPublicSubscriptionVariables
  >(KnowledgeBasesPublicSubscriptionDocument, options);
}
export type KnowledgeBasesPublicSubscriptionHookResult = ReturnType<
  typeof useKnowledgeBasesPublicSubscription
>;
export type KnowledgeBasesPublicSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<KnowledgeBasesPublicSubscription>;
