/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserTypingMutationVariables = Types.Exact<{
  isTyping: Types.Scalars['Boolean']['input'];
  where: Types.ConversationWhereUniqueInput;
}>;

export type UserTypingMutation = { __typename: 'UserMutation' } & {
  userTyping: Types.Maybe<
    { __typename: 'UserTyping' } & Pick<Types.UserTyping, 'ok'>
  >;
};

export const UserTypingMutationDocument = gql`
  mutation UserTypingMutation(
    $isTyping: Boolean!
    $where: ConversationWhereUniqueInput!
  ) {
    userTyping(isTyping: $isTyping, where: $where) {
      ok
    }
  }
`;
export type UserTypingMutationMutationFn = Apollo.MutationFunction<
  UserTypingMutation,
  UserTypingMutationVariables
>;

/**
 * __useUserTypingMutation__
 *
 * To run a mutation, you first call `useUserTypingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTypingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTypingMutation, { data, loading, error }] = useUserTypingMutation({
 *   variables: {
 *      isTyping: // value for 'isTyping'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserTypingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserTypingMutation,
    UserTypingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserTypingMutation, UserTypingMutationVariables>(
    UserTypingMutationDocument,
    options,
  );
}
export type UserTypingMutationHookResult = ReturnType<
  typeof useUserTypingMutation
>;
export type UserTypingMutationMutationResult =
  Apollo.MutationResult<UserTypingMutation>;
export type UserTypingMutationMutationOptions = Apollo.BaseMutationOptions<
  UserTypingMutation,
  UserTypingMutationVariables
>;
