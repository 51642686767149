import { createContext } from 'react';
import { DraftDocumentEntity } from './draft-document-entity';

export interface DraftDocumentContext {
  projectId: string | number | null;
  isSelected(args: { entity: DraftDocumentEntity }): boolean;
  onClick?(args: { entity: DraftDocumentEntity }): Promise<void>;
}

export const DraftDocumentContext = createContext<DraftDocumentContext>({
  projectId: null,
  isSelected: () => false,
  onClick: undefined,
});
