import { Fragment, useContext } from 'react';
import { OutputContext } from '../contexts/output-context';
import { FinalOutput } from '../final-output/final-output';
import { IntermediateOutput } from '../intermediate-output/intermediate-output';

export const Output = () => {
  const {
    data,
    called,
    loading,
    updating,
    updated,
    update,
    revert,
    setGenerateDisabled,
  } = useContext(OutputContext);

  if (!called) {
    return null;
  }

  return (
    <Fragment>
      <IntermediateOutput data={data.documentResults} loading={loading} />
      <FinalOutput
        output={data.output}
        loading={loading}
        updating={updating}
        updated={updated}
        update={update}
        revert={revert}
        onEditModeChange={setGenerateDisabled}
      />
    </Fragment>
  );
};
