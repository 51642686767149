import { IconButton } from '@allganize/ui-button';
import { Text } from '@allganize/ui-text';
import { IcArrowExpandLess, IcArrowExpandMore } from '@allganize/ui-icons';
import { Collapse } from '@allganize/ui-transition';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { BaseOutput, baseOutputClasses } from '../base-output';
import { Container } from '../components/styled-components';
import { intermediateOutputClasses } from './intermediate-output-classes';
import { IntermediateOutputProps } from './intermediate-output-type-map';

export const IntermediateOutput: FunctionComponent<IntermediateOutputProps> = ({
  classes,
  data,
  loading,
  ...other
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => setExpanded(prev => !prev);

  useEffect(() => {
    setExpanded(loading);
  }, [loading, setExpanded]);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Container
      css={css`
        display: flex;
        flex-direction: column;
        max-height: 800px;
      `}
      {...other}
      className={clsx(
        intermediateOutputClasses.root,
        classes?.root,
        other.className,
      )}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
        className={clsx(
          intermediateOutputClasses.header,
          classes?.header,
          other.className,
        )}
      >
        <Text
          variant="title12"
          className={clsx(
            intermediateOutputClasses.title,
            classes?.title,
            other.className,
          )}
        >
          <FormattedMessage
            id="single-action.intermediate-output.title"
            defaultMessage="Intermediate output"
            description="single action intermediate output title"
          />
        </Text>
        <IconButton
          size="small"
          onClick={toggleExpanded}
          className={clsx(
            intermediateOutputClasses.expandButton,
            classes?.expandButton,
            other.className,
          )}
        >
          {expanded ? (
            <IcArrowExpandLess fontSize="large" />
          ) : (
            <IcArrowExpandMore fontSize="large" />
          )}
        </IconButton>
      </div>
      <Collapse
        in={expanded}
        css={css`
          flex-grow: 1;
          overflow-y: scroll;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 32px;
            padding: 16px 0;

            .${baseOutputClasses.title} {
              color: ${theme.palette.unstable_foreground.secondary};
            }
          `}
        >
          {data.map(({ dynamicInputsItemName, output }) => (
            <BaseOutput
              key={dynamicInputsItemName}
              label={
                <FormattedMessage
                  id="single-action.intermediate-output.document-result-title"
                  defaultMessage="{inputTitle} output"
                  description="single action intermediate output document result title"
                  values={{
                    inputTitle: dynamicInputsItemName,
                  }}
                />
              }
              loading={loading}
              output={output}
            />
          ))}
        </div>
      </Collapse>
    </Container>
  );
};
