import { Chip, ChipProps, chipClasses } from '@allganize/ui-chip';
import { css } from '@emotion/react';
import { DraftDecoratorComponentProps } from 'draft-js';
import { forwardRef, useContext } from 'react';
import { DraftLink, DraftLinkProps } from '../draft-link-plugin/draft-link';
import { DraftDocumentContext } from './draft-document-context';
import { DraftDocumentEntity } from './draft-document-entity';

export type DraftDocumentLinkProps = Omit<ChipProps, 'label'> & DraftLinkProps;

export const DraftDocumentLink = forwardRef<
  HTMLDivElement,
  DraftDecoratorComponentProps & DraftDocumentLinkProps
>((props, ref) => {
  const {
    blockKey,
    contentState,
    decoratedText,
    dir,
    end,
    entityKey,
    offsetKey,
    start,
    children,
    ...other
  } = props;
  const { isSelected, onClick } = useContext(DraftDocumentContext);
  const entity = entityKey ? contentState.getEntity(entityKey) : null;
  const entityData = entity?.getData();
  const documentEntity: DraftDocumentEntity | null =
    entityData && entityKey
      ? {
          key: entityKey,
          text: decoratedText,
          data: entityData,
        }
      : null;
  const selected = documentEntity
    ? isSelected({ entity: documentEntity })
    : false;
  const clickable = other.onClick || (onClick && documentEntity);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    other.onClick?.(ev);

    if (!documentEntity) {
      return;
    }

    onClick?.({ entity: documentEntity });
  };

  if (!clickable) {
    return <DraftLink data-testid="draft-document-link" {...props} />;
  }

  return (
    <Chip
      data-testid="draft-document-link"
      css={css`
        height: auto;
        font: inherit;
        letter-spacing: inherit;
        vertical-align: inherit;
        font-size: 85.71%;
        line-height: ${16 / 12};
        padding: 0 2px;

        &.${chipClasses.colorGraySubtle} {
          color: inherit;
        }
      `}
      color={selected ? 'gray-dark' : 'gray-subtle'}
      {...other}
      ref={ref}
      label={children}
      onClick={clickable ? handleClick : undefined}
    />
  );
});
