/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import {
  ChatFragment_AgentChat_,
  ChatFragment_BotChat_,
  ChatFragment_CarouselChat_,
  ChatFragment_ContactAgentByEmailChat_,
  ChatFragment_CustomUIChat_,
  ChatFragment_EventChat_,
  ChatFragment_FillSlotRichUXChat_,
  ChatFragment_SendFormChat_,
  ChatFragment_UserChat_,
} from '../fragments/chat-fragment';
import { gql } from '@apollo/client';
import { ChatFragmentDoc } from '../fragments/chat-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatsSubscriptionVariables = Types.Exact<{
  where: Types.ChatWhereInput;
  after?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type ChatsSubscription = { __typename: 'UserSubscription' } & {
  chats: Types.Maybe<
    { __typename: 'ChatEdge' } & Pick<Types.ChatEdge, 'cursor'> & {
        node: Types.Maybe<
          | ({ __typename: 'AgentChat' } & ChatFragment_AgentChat_)
          | ({ __typename: 'BotChat' } & ChatFragment_BotChat_)
          | ({ __typename: 'CarouselChat' } & ChatFragment_CarouselChat_)
          | ({
              __typename: 'ContactAgentByEmailChat';
            } & ChatFragment_ContactAgentByEmailChat_)
          | ({ __typename: 'CustomUIChat' } & ChatFragment_CustomUIChat_)
          | ({ __typename: 'EventChat' } & ChatFragment_EventChat_)
          | ({
              __typename: 'FillSlotRichUXChat';
            } & ChatFragment_FillSlotRichUXChat_)
          | ({ __typename: 'SendFormChat' } & ChatFragment_SendFormChat_)
          | ({ __typename: 'UserChat' } & ChatFragment_UserChat_)
        >;
      }
  >;
};

export const ChatsSubscriptionDocument = gql`
  subscription ChatsSubscription($where: ChatWhereInput!, $after: ID) {
    chats(where: $where, after: $after) {
      cursor
      node {
        ...ChatFragment
      }
    }
  }
  ${ChatFragmentDoc}
`;

/**
 * __useChatsSubscription__
 *
 * To run a query within a React component, call `useChatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useChatsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ChatsSubscription,
    ChatsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<ChatsSubscription, ChatsSubscriptionVariables>(
    ChatsSubscriptionDocument,
    options,
  );
}
export type ChatsSubscriptionHookResult = ReturnType<
  typeof useChatsSubscription
>;
export type ChatsSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ChatsSubscription>;
