import {
  getChipUtilityClass,
  chipClasses as muiChipClasses,
  ChipClassKey as MuiChipClassKey,
} from '@mui/material/Chip';
import { omit } from 'lodash-es';

export type ChipClassKey =
  | Exclude<
      MuiChipClassKey,
      | 'sizeSmall'
      | 'sizeMedium'
      | 'colorError'
      | 'colorInfo'
      | 'colorPrimary'
      | 'colorSecondary'
      | 'colorSuccess'
      | 'colorWarning'
      | 'clickableColorPrimary'
      | 'clickableColorSecondary'
      | 'deletableColorPrimary'
      | 'deletableColorSecondary'
      | 'outlined'
      | 'filled'
      | 'outlinedPrimary'
      | 'outlinedSecondary'
      | 'filledPrimary'
      | 'filledSecondary'
      | 'avatarSmall'
      | 'avatarMedium'
      | 'avatarColorPrimary'
      | 'avatarColorSecondary'
      | 'iconSmall'
      | 'iconMedium'
      | 'iconColorPrimary'
      | 'iconColorSecondary'
      | 'labelSmall'
      | 'labelMedium'
      | 'deleteIconSmall'
      | 'deleteIconMedium'
      | 'deleteIconColorPrimary'
      | 'deleteIconColorSecondary'
      | 'deleteIconOutlinedColorPrimary'
      | 'deleteIconOutlinedColorSecondary'
      | 'deleteIconFilledColorPrimary'
      | 'deleteIconFilledColorSecondary'
    >
  | 'colorGrayDark'
  | 'colorGraySubtle';
export type ChipClasses = Record<ChipClassKey, string>;

export const chipClasses: ChipClasses = {
  ...omit(muiChipClasses, [
    'sizeSmall',
    'sizeMedium',
    'colorError',
    'colorInfo',
    'colorPrimary',
    'colorSecondary',
    'colorSuccess',
    'colorWarning',
    'clickableColorPrimary',
    'clickableColorSecondary',
    'deletableColorPrimary',
    'deletableColorSecondary',
    'outlined',
    'filled',
    'outlinedPrimary',
    'outlinedSecondary',
    'filledPrimary',
    'filledSecondary',
    'avatarSmall',
    'avatarMedium',
    'avatarColorPrimary',
    'avatarColorSecondary',
    'iconSmall',
    'iconMedium',
    'iconColorPrimary',
    'iconColorSecondary',
    'labelSmall',
    'labelMedium',
    'deleteIconSmall',
    'deleteIconMedium',
    'deleteIconColorPrimary',
    'deleteIconColorSecondary',
    'deleteIconOutlinedColorPrimary',
    'deleteIconOutlinedColorSecondary',
    'deleteIconFilledColorPrimary',
    'deleteIconFilledColorSecondary',
  ]),
  colorGrayDark: getChipUtilityClass('colorGrayDark'),
  colorGraySubtle: getChipUtilityClass('colorGraySubtle'),
};
