import { Types } from '@allganize/alli-sdk-interfaces';
import { useEventCallback } from '@allganize/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';
import { FAQCarouselFragment } from '../graphql/fragments/faq-carousel-fragment';

export interface FaqCarouselFormValue {
  readonly optionBoxTemplate: Types.OptionBoxTemplate | null;
  readonly reusable: boolean;
  value: number;
  question: string | null;
  answer: string | null;
}

export interface FaqCarouselFormValues {
  faqCarouselOption: FaqCarouselFormValue | null;
}

const errorMessages = defineMessages({
  'faqCarouselOption-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  const faqCarouselFormValueSchema = z.object(
    {
      optionBoxTemplate: z.string().nullable(),
      reusable: z.boolean(),
      value: z.number(),
      question: z.string().nullable(),
      answer: z.string().nullable(),
    },
    {
      invalid_type_error: intl.formatMessage(
        errorMessages['faqCarouselOption-required'],
      ),
      required_error: intl.formatMessage(
        errorMessages['faqCarouselOption-required'],
      ),
    },
  );

  return z.object({
    faqCarouselOption: faqCarouselFormValueSchema.nullable(),
  });
};

export interface UseFaqCarouselFormOptions {
  faqCarouselOptions?: Types.Maybe<FAQCarouselFragment>[];
  onSubmit: SubmitHandler<FaqCarouselFormValues>;
}

export const useFaqCarouselForm = ({
  faqCarouselOptions,
  onSubmit,
}: UseFaqCarouselFormOptions) => {
  const intl = useIntl();
  const validationSchema = useMemo(
    () => zodResolver(createValidationSchema(intl)),
    [intl],
  );

  const options = useMemo(
    () =>
      (faqCarouselOptions ?? []).reduce<FaqCarouselFormValue[]>(
        (acc, curr, i) => {
          if (!curr) {
            return acc;
          }

          return [
            ...acc,
            {
              optionBoxTemplate: curr.optionBoxTemplate,
              reusable: curr.reusable ?? false,
              value: i,
              question: curr.question,
              answer: curr.answer,
            },
          ];
        },
        [],
      ),
    [faqCarouselOptions],
  );

  const form = useForm<FaqCarouselFormValues>({
    mode: 'all',
    defaultValues: {
      faqCarouselOption: null,
    },
    resolver: validationSchema,
  });

  const { reset, setError } = form;

  const submit = useEventCallback(async (values: FaqCarouselFormValues) => {
    try {
      await onSubmit?.(values);
      reset();
    } catch (err) {
      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }
    }
  });

  return {
    form,
    options,
    submit,
  };
};
