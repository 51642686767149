/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type SlotValueFragment = { __typename: 'SlotValue' } & Pick<
  Types.SlotValue,
  'id' | 'displayText' | 'value'
>;

export const SlotValueFragmentDoc = gql`
  fragment SlotValueFragment on SlotValue {
    id
    displayText
    value
  }
`;
