import { ListItem, listItemButtonClasses } from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { FileListItemSecondaryAction } from '../file-list-item-secondary-action';
import { fileListItemClasses } from './file-list-item-classes';
import { FileListItemTypeMap } from './file-list-item-type-map';

// @ts-expect-error overridable component
export const FileListItem: OverridableComponent<FileListItemTypeMap> =
  forwardRef((props, ref) => {
    const { children, classes, disablePadding, secondaryAction, ...other } =
      props;
    const theme = useTheme();

    return (
      <ListItem
        data-testid="file-list-item"
        css={[
          css`
            border: 1px solid ${theme.palette.grayAlpha[300]};
            border-radius: ${theme.radius.sm}px;
            align-items: flex-start;
          `,
          !disablePadding &&
            css`
              padding: 5px 11px;
            `,
          secondaryAction &&
            css`
              ${!disablePadding &&
              css`
                padding-right: 45px;
              `}

              & > .${listItemButtonClasses.root} {
                padding-right: 45px;
              }
            `,
        ]}
        disablePadding={disablePadding}
        {...other}
        ref={ref}
        classes={{
          ...classes,
          root: clsx(fileListItemClasses.root, classes?.root),
          alignItemsFlexStart: clsx(
            fileListItemClasses.alignItemsFlexStart,
            classes?.alignItemsFlexStart,
          ),
          container: clsx(fileListItemClasses.container, classes?.container),
          padding: clsx(fileListItemClasses.padding, classes?.padding),
        }}
        className={clsx(
          {
            [fileListItemClasses.secondaryAction]: secondaryAction,
            [classes?.secondaryAction ?? '']: secondaryAction,
          },
          other.className,
        )}
      >
        {children}

        {secondaryAction && (
          <FileListItemSecondaryAction>
            {secondaryAction}
          </FileListItemSecondaryAction>
        )}
      </ListItem>
    );
  });
