import { Types } from '@allganize/alli-sdk-interfaces';
import {
  ContentState,
  DraftDocumentEntity,
  DraftDocumentPlugin,
} from '@allganize/draft-input';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ChatKnowledgeBasePreviewFragment } from '../graphql/fragments/chat-knowledge-base-preview-fragment';
import { UseRichTextOptions, useRichText } from '../rich-text/use-rich-text';

export const getDocumentEntities = ({
  contentState,
  knowledgeBasePreview,
  knowledgeBasePreviewKeyPrefix,
  fallbackText = 'Preview',
}: {
  contentState?: ContentState;
  knowledgeBasePreview?: Types.Maybe<ChatKnowledgeBasePreviewFragment>;
  knowledgeBasePreviewKeyPrefix?: string;
  fallbackText?: string;
}) => {
  const result: DraftDocumentEntity[] = [];

  if (contentState) {
    result.push(...DraftDocumentPlugin.getDocumentEntities(contentState));
  }

  if (knowledgeBasePreview?.draftjs || knowledgeBasePreview?.knowledgeBase) {
    result.push({
      key: [
        knowledgeBasePreviewKeyPrefix,
        knowledgeBasePreview.draftjs?.id,
        knowledgeBasePreview.knowledgeBase?.id,
        knowledgeBasePreview.exactAnswer,
        knowledgeBasePreview.pageNo,
        knowledgeBasePreview.totalPageCount,
        knowledgeBasePreview.highlights?.indexes?.join('--'),
        knowledgeBasePreview.highlights?.pageIndexes?.map(pi =>
          [pi.page, pi.indexes.join('----')].join('---'),
        ),
        `kbp-${result.length}`,
      ].join('-'),
      text: knowledgeBasePreview.knowledgeBase?.title ? '' : fallbackText,
      data: {
        knowledgeBasePreview: {
          pageNo: knowledgeBasePreview.pageNo,
          totalPageCount: knowledgeBasePreview.totalPageCount,
          draftjs: knowledgeBasePreview.draftjs,
          highlightIndexes: knowledgeBasePreview.highlightIndexes,
          highlights: knowledgeBasePreview.highlights
            ? omit(
                {
                  ...knowledgeBasePreview.highlights,
                  pageIndexes: knowledgeBasePreview.highlights.pageIndexes?.map(
                    pi => (pi ? omit(pi, '__typename') : pi),
                  ),
                },
                '__typename',
              )
            : knowledgeBasePreview.highlights,
          exactAnswer: knowledgeBasePreview.exactAnswer,
          knowledgeBase: knowledgeBasePreview.knowledgeBase,
        },
      },
    });
  }

  return result;
};

export interface UseDocumentEntitiesOptions extends UseRichTextOptions {
  knowledgeBasePreview?: Types.Maybe<ChatKnowledgeBasePreviewFragment>;
  knowledgeBasePreviewKeyPrefix?: string;
}

export const useDocumentEntities = (options: UseDocumentEntitiesOptions) => {
  const { knowledgeBasePreview, knowledgeBasePreviewKeyPrefix } = options;
  const intl = useIntl();
  const { value } = useRichText(options);

  const documentEntities = useMemo(
    () =>
      getDocumentEntities({
        contentState: value?.getCurrentContent(),
        knowledgeBasePreview,
        knowledgeBasePreviewKeyPrefix,
        fallbackText: intl.formatMessage(
          {
            id: 'preview',
            defaultMessage: '{count, plural, one {Preview} other {Previews}}',
            description: 'Document preview title missing fallback',
          },
          { count: 1 },
        ),
      }),
    [intl, knowledgeBasePreview, knowledgeBasePreviewKeyPrefix, value],
  );

  return {
    documentEntities,
  };
};
