import { AlliSdkAnalytics } from '@allganize/alli-sdk-analytics';
import { config } from './config';

export const analytics = new AlliSdkAnalytics({
  mixpanel: config.mixpanel
    ? {
        token: config.mixpanel.token,
        name: `alli-sdk-${config.version}`,
        config: {
          track_pageview: false,
          api_host: config.mixpanel.apiHost,
        },
      }
    : undefined,
});
