/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectMaxKBFileCountQueryVariables = UserQueryTypes.Exact<{
  where: UserQueryTypes.ProjectWhereUniqueInput;
}>;

export type ProjectMaxKBFileCountQuery = { __typename: 'UserQuery' } & {
  project: UserQueryTypes.Maybe<
    { __typename: 'Project' } & Pick<
      UserQueryTypes.Project,
      'id' | 'maxTrialKbFileCount' | 'trialExpiredAt'
    >
  >;
};

export const ProjectMaxKBFileCountQueryDocument = gql`
  query ProjectMaxKBFileCountQuery($where: ProjectWhereUniqueInput!) {
    project(where: $where) {
      id
      maxTrialKbFileCount
      trialExpiredAt
    }
  }
`;

/**
 * __useProjectMaxKBFileCountQuery__
 *
 * To run a query within a React component, call `useProjectMaxKBFileCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMaxKBFileCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMaxKBFileCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectMaxKBFileCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectMaxKBFileCountQuery,
    ProjectMaxKBFileCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectMaxKBFileCountQuery,
    ProjectMaxKBFileCountQueryVariables
  >(ProjectMaxKBFileCountQueryDocument, options);
}
export function useProjectMaxKBFileCountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectMaxKBFileCountQuery,
    ProjectMaxKBFileCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectMaxKBFileCountQuery,
    ProjectMaxKBFileCountQueryVariables
  >(ProjectMaxKBFileCountQueryDocument, options);
}
export function useProjectMaxKBFileCountQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectMaxKBFileCountQuery,
    ProjectMaxKBFileCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectMaxKBFileCountQuery,
    ProjectMaxKBFileCountQueryVariables
  >(ProjectMaxKBFileCountQueryDocument, options);
}
export type ProjectMaxKBFileCountQueryHookResult = ReturnType<
  typeof useProjectMaxKBFileCountQuery
>;
export type ProjectMaxKBFileCountQueryLazyQueryHookResult = ReturnType<
  typeof useProjectMaxKBFileCountQueryLazyQuery
>;
export type ProjectMaxKBFileCountQuerySuspenseQueryHookResult = ReturnType<
  typeof useProjectMaxKBFileCountQuerySuspenseQuery
>;
export type ProjectMaxKBFileCountQueryQueryResult = Apollo.QueryResult<
  ProjectMaxKBFileCountQuery,
  ProjectMaxKBFileCountQueryVariables
>;
