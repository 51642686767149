/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationFragment } from '../fragments/conversation-fragment';
import {
  ChatFragment_AgentChat_,
  ChatFragment_BotChat_,
  ChatFragment_CarouselChat_,
  ChatFragment_ContactAgentByEmailChat_,
  ChatFragment_CustomUIChat_,
  ChatFragment_EventChat_,
  ChatFragment_FillSlotRichUXChat_,
  ChatFragment_SendFormChat_,
  ChatFragment_UserChat_,
} from '../fragments/chat-fragment';
import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import { ChatFragmentDoc } from '../fragments/chat-fragment';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendChatMutationVariables = Types.Exact<{
  chatId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  conversationId: Types.Scalars['ID']['input'];
  message?: Types.InputMaybe<Types.Scalars['String']['input']>;
  mediaInput?: Types.InputMaybe<Types.MediaInput>;
  fileInput?: Types.InputMaybe<Types.FileInput>;
  fillSlotInput?: Types.InputMaybe<Types.FillSlotRichUXInput>;
  selected?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  choices?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['Int']['input']>>
  >;
  contactAgentByEmailInput?: Types.InputMaybe<Types.ContactAgentByEmailInput>;
  sendFormInput?: Types.InputMaybe<Types.SendFormInput>;
  lastChatId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  textChoice?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  fallbackAction?: Types.InputMaybe<Types.IntentClassificationIntents>;
}>;

export type SendChatMutation = { __typename: 'UserMutation' } & {
  sendChat: Types.Maybe<
    { __typename: 'UserSendChat' } & {
      conversation: Types.Maybe<
        { __typename: 'Conversation' } & ConversationFragment
      >;
      chat: Types.Maybe<
        | ({ __typename: 'AgentChat' } & ChatFragment_AgentChat_)
        | ({ __typename: 'BotChat' } & ChatFragment_BotChat_)
        | ({ __typename: 'CarouselChat' } & ChatFragment_CarouselChat_)
        | ({
            __typename: 'ContactAgentByEmailChat';
          } & ChatFragment_ContactAgentByEmailChat_)
        | ({ __typename: 'CustomUIChat' } & ChatFragment_CustomUIChat_)
        | ({ __typename: 'EventChat' } & ChatFragment_EventChat_)
        | ({
            __typename: 'FillSlotRichUXChat';
          } & ChatFragment_FillSlotRichUXChat_)
        | ({ __typename: 'SendFormChat' } & ChatFragment_SendFormChat_)
        | ({ __typename: 'UserChat' } & ChatFragment_UserChat_)
      >;
      responses: Types.Maybe<
        Array<
          Types.Maybe<
            | ({ __typename: 'AgentChat' } & ChatFragment_AgentChat_)
            | ({ __typename: 'BotChat' } & ChatFragment_BotChat_)
            | ({ __typename: 'CarouselChat' } & ChatFragment_CarouselChat_)
            | ({
                __typename: 'ContactAgentByEmailChat';
              } & ChatFragment_ContactAgentByEmailChat_)
            | ({ __typename: 'CustomUIChat' } & ChatFragment_CustomUIChat_)
            | ({ __typename: 'EventChat' } & ChatFragment_EventChat_)
            | ({
                __typename: 'FillSlotRichUXChat';
              } & ChatFragment_FillSlotRichUXChat_)
            | ({ __typename: 'SendFormChat' } & ChatFragment_SendFormChat_)
            | ({ __typename: 'UserChat' } & ChatFragment_UserChat_)
          >
        >
      >;
      errors: Types.Maybe<
        Array<Types.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const SendChatMutationDocument = gql`
  mutation SendChatMutation(
    $chatId: ID
    $conversationId: ID!
    $message: String
    $mediaInput: MediaInput
    $fileInput: FileInput
    $fillSlotInput: FillSlotRichUXInput
    $selected: Int
    $choices: [Int]
    $contactAgentByEmailInput: ContactAgentByEmailInput
    $sendFormInput: SendFormInput
    $lastChatId: ID
    $textChoice: Boolean
    $fallbackAction: IntentClassificationIntents
  ) {
    sendChat(
      chatId: $chatId
      conversationId: $conversationId
      message: $message
      mediaInput: $mediaInput
      fileInput: $fileInput
      fillSlotInput: $fillSlotInput
      selected: $selected
      choices: $choices
      contactAgentByEmailInput: $contactAgentByEmailInput
      sendFormInput: $sendFormInput
      lastChatId: $lastChatId
      textChoice: $textChoice
      fallbackAction: $fallbackAction
    ) {
      conversation {
        ...ConversationFragment
      }
      chat {
        ...ChatFragment
      }
      responses {
        ...ChatFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ConversationFragmentDoc}
  ${ChatFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SendChatMutationMutationFn = Apollo.MutationFunction<
  SendChatMutation,
  SendChatMutationVariables
>;

/**
 * __useSendChatMutation__
 *
 * To run a mutation, you first call `useSendChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMutation, { data, loading, error }] = useSendChatMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      conversationId: // value for 'conversationId'
 *      message: // value for 'message'
 *      mediaInput: // value for 'mediaInput'
 *      fileInput: // value for 'fileInput'
 *      fillSlotInput: // value for 'fillSlotInput'
 *      selected: // value for 'selected'
 *      choices: // value for 'choices'
 *      contactAgentByEmailInput: // value for 'contactAgentByEmailInput'
 *      sendFormInput: // value for 'sendFormInput'
 *      lastChatId: // value for 'lastChatId'
 *      textChoice: // value for 'textChoice'
 *      fallbackAction: // value for 'fallbackAction'
 *   },
 * });
 */
export function useSendChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChatMutation,
    SendChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendChatMutation, SendChatMutationVariables>(
    SendChatMutationDocument,
    options,
  );
}
export type SendChatMutationHookResult = ReturnType<typeof useSendChatMutation>;
export type SendChatMutationMutationResult =
  Apollo.MutationResult<SendChatMutation>;
export type SendChatMutationMutationOptions = Apollo.BaseMutationOptions<
  SendChatMutation,
  SendChatMutationVariables
>;
