import { useProject } from '@allganize/alli-app-market-project';
import { Button } from '@allganize/ui-button';
import { Paper } from '@allganize/ui-paper';
import { Text } from '@allganize/ui-text';
import { css, useTheme } from '@emotion/react';
import { setLightness } from 'polished';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import logoOkta from '../assets/logo-okta.png';
import logoOkta2x from '../assets/logo-okta@2x.png';
import logoOkta3x from '../assets/logo-okta@3x.png';
import { useAuth } from '../hooks/use-auth';

interface SignInPageProps {
  children?: React.ReactNode;
}

export const SignInPage: FunctionComponent<SignInPageProps> = ({
  children,
}) => {
  const auth = useAuth();
  const project = useProject();
  const theme = useTheme();
  const primaryColor =
    project.appCollectionSettings?.themeColor ?? theme.palette.primary.main;
  const backgroundColor = setLightness(0.96, primaryColor);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background-color: ${backgroundColor};
      `}
    >
      <div
        css={css`
          width: 100%;
          max-width: 360px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 32px;
        `}
      >
        {project.appCollectionSettings?.companyLogo && (
          <img
            css={css`
              display: inline-block;
              height: 48px;
            `}
            src={project.appCollectionSettings.companyLogo.url}
            alt={project.appCollectionSettings.companyName ?? undefined}
          />
        )}

        <Paper
          css={css`
            padding: 24px;
          `}
          elevation={3}
        >
          <Text
            css={css`
              margin-bottom: 16px;
            `}
            variant="title24"
            align="center"
          >
            <FormattedMessage
              id="sign-into-app-market"
              defaultMessage="Sign into App Market"
              description="sign in page title"
            />
          </Text>

          <Text variant="body14" align="center">
            {children || (
              <FormattedMessage
                id="okta-sign-in"
                defaultMessage="Secure your access with Okta for seamless AI app experiences."
                description="sign in page description"
              />
            )}
          </Text>

          <Button
            css={css`
              margin-top: 24px;
            `}
            variant="outlined"
            fullWidth
            startIcon={
              <img
                css={css`
                  display: inline-block;
                  font-size: inherit;
                  width: 1em;
                  height: 1em;
                `}
                src={logoOkta}
                srcSet={`${logoOkta} 1x, ${logoOkta2x} 2x, ${logoOkta3x} 3x`}
                alt="Okta"
              />
            }
            onClick={() => {
              auth.signIn();
            }}
          >
            <FormattedMessage
              id="sign-in-with-okta"
              defaultMessage="Continue with Okta"
              description="sign in with Okta button"
            />
          </Button>
        </Paper>
      </div>
    </div>
  );
};
