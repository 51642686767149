import { IcArrowNavigateNext } from '@allganize/ui-icons';
import {
  ListItem,
  ListItemButton,
  ListItemCheckbox,
  ListItemText,
  ListItemTextProps,
  listItemButtonClasses,
  listItemTextClasses,
} from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import {
  FaqCarouselOptionClasses,
  faqCarouselOptionClasses,
} from './faq-carousel-option-classes';
import { FaqCarouselFormValue } from './use-faq-carousel-form';

interface FaqCarouselOptionProps {
  classes?: Partial<FaqCarouselOptionClasses>;
  className?: string;
  control: React.ReactNode;
  data: FaqCarouselFormValue;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readOnly?: boolean;
  textProps?: Omit<ListItemTextProps, 'ref'>;
}

export const FaqCarouselOption: FunctionComponent<FaqCarouselOptionProps> = ({
  classes,
  className,
  control,
  data,
  onBlur,
  onClick,
  readOnly,
  textProps,
}) => {
  const theme = useTheme();

  return (
    <ListItem
      css={[
        css`
          display: inline-flex;
          flex-direction: column;
          align-items: stretch;
          border-radius: ${theme.radius.md}px;
          border: 1px solid ${theme.palette.grayAlpha[300]};
          background-color: ${theme.palette.unstable_background.white};
          overflow: hidden;
          width: auto;
          max-width: 480px;
        `,
        readOnly &&
          css`
            border-color: ${theme.palette.action.disabledBackground};
          `,
      ]}
      component="div"
      disablePadding
      className={clsx(faqCarouselOptionClasses.root, classes?.root, className)}
    >
      <ListItemButton
        css={css`
          padding: 7px 15px;
          align-items: flex-start;
        `}
        role={undefined}
        onClick={onClick}
        onBlur={onBlur}
        disabled={readOnly}
        className={clsx(faqCarouselOptionClasses.button, classes?.button)}
      >
        <ListItemCheckbox
          className={clsx(faqCarouselOptionClasses.checkbox, classes?.checkbox)}
        >
          {control}
        </ListItemCheckbox>

        <ListItemText
          css={css`
            .${listItemTextClasses.primary}
              + .${listItemTextClasses.secondary} {
              margin-top: 8px;
            }

            .${listItemTextClasses.secondary} {
              color: ${theme.palette.text.primary};

              .${listItemTextClasses.disabled} & {
                color: ${theme.palette.text.disabled};
              }
            }
          `}
          primary={data.question}
          secondary={data.answer}
          {...textProps}
          primaryTextProps={{
            variant: 'title14',
            ...textProps?.primaryTextProps,
          }}
          secondaryTextProps={{
            variant: 'body14',
            ...textProps?.secondaryTextProps,
          }}
        />

        {data.optionBoxTemplate === 'NEXT_PAGE' && (
          <div
            css={css`
              margin-left: 4px;
              padding: 4px 0;
            `}
          >
            <IcArrowNavigateNext
              css={css`
                .${listItemButtonClasses.disabled} & {
                  color: ${theme.palette.text.disabled};
                }
              `}
            />
          </div>
        )}
      </ListItemButton>
    </ListItem>
  );
};
