import { useTheme } from '@allganize/ui-theme';
import { Tooltip, TooltipProps, tooltipClasses } from '@allganize/ui-tooltip';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

interface TimestampTooltipProps
  extends Omit<TooltipProps, 'placement' | 'title'> {
  align?: 'left' | 'right';
  timestamp: number;
}

export const TimestampTooltip = forwardRef<
  HTMLDivElement,
  TimestampTooltipProps
>((props, ref) => {
  const { align, classes, timestamp, ...other } = props;
  const theme = useTheme();
  const intl = useIntl();
  const title = intl.formatTime(timestamp);
  const placement = align === 'left' ? 'bottom-start' : 'bottom-end';

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Tooltip
          enterDelay={500}
          enterNextDelay={500}
          {...other}
          ref={ref}
          classes={{
            ...classes,
            popper: cx(
              css`
                z-index: ${Math.min(
                  theme.zIndex.tooltip,
                  theme.zIndex.appBar - 1,
                )};
              `,
              classes?.popper,
            ),
            tooltip: cx(
              css`
                .${tooltipClasses.popper}[data-popper-placement*="top"] & {
                  margin: 0 12px -8px;
                }

                .${tooltipClasses.popper}[data-popper-placement*="bottom"] & {
                  margin: -8px 12px 0;
                }
              `,
              classes?.tooltip,
            ),
          }}
          placement={placement}
          title={title}
          slotProps={{
            ...other.slotProps,
            popper: {
              disablePortal: true,
              ...other.slotProps?.popper,
            },
          }}
        />
      )}
    </ClassNames>
  );
});
