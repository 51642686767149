import { css } from '@emotion/react';
import { forwardRef, useMemo } from 'react';
import {
  Link as LinkOrig,
  NavLink as NavLinkOrig,
  To,
  useResolvedPath,
  useSearchParams,
  useNavigate as useNavigateOrig,
  NavigateOptions,
} from 'react-router-dom';

const linkCommonStyle = css`
  text-decoration: none;
`;

const setParameter = (to: To): To => {
  const urlStr =
    typeof to === 'string'
      ? to
      : `${to.pathname ?? ''}${to.search ?? ''}${to.hash ?? ''}`;
  if (urlStr.startsWith('http')) return to;

  const currentSearch = new URLSearchParams(window.location.search);
  const url = new URL(urlStr, window.location.origin);
  url.searchParams.set(
    'project_token',
    currentSearch.get('project_token') ?? '',
  );

  if (currentSearch.has('region')) {
    url.searchParams.set('region', currentSearch.get('region') ?? '');
  }

  return {
    pathname: url.pathname,
    search: url.search,
    hash: url.hash,
  };
};

/**
 * @deprecated no need to support other router types except for BrowserRouter
 */
export const useToWithProjectToken = (to: To): To => {
  const [currentSearch] = useSearchParams();
  const { search, ...others } = useResolvedPath(to);
  const searchWithProjectToken = useMemo(() => {
    const newSearch = new URLSearchParams(search);
    newSearch.set('project_token', currentSearch.get('project_token') ?? '');
    return '?' + newSearch.toString();
  }, [search, currentSearch]);

  return {
    ...others,
    search: searchWithProjectToken,
  };
};

/**
 * Link component which preserves project_token & region parameter in the URL.
 *
 * CAUTION: This component is only available in BrowserRoute context.
 */
export const Link: typeof LinkOrig = forwardRef(({ to, ...others }, ref) => {
  const toWithProjectToken = useMemo(() => setParameter(to), [to]);

  return (
    <LinkOrig
      css={linkCommonStyle}
      to={toWithProjectToken}
      {...others}
      ref={ref}
    />
  );
});

/**
 * NavLink component which preserves project_token & region parameter in the URL.
 *
 * CAUTION: This component is only available in BrowserRoute context.
 */
export const NavLink: typeof NavLinkOrig = forwardRef(
  ({ to, ...others }, ref) => {
    const toWithProjectToken = useMemo(() => setParameter(to), [to]);

    return (
      <NavLinkOrig
        css={linkCommonStyle}
        to={toWithProjectToken}
        {...others}
        ref={ref}
      />
    );
  },
);

/**
 * useNavigate which preserves project_token & region parameter in the URL.
 *
 * CAUTION: This hook is only available in BrowserRoute context.
 */
export const useNavigate: typeof useNavigateOrig = () => {
  const navigate = useNavigateOrig();
  const navigateWithProjectToken: typeof navigate = (
    arg0,
    arg1?: NavigateOptions,
  ) => {
    if (typeof arg0 === 'number') {
      return navigate(arg0);
    } else {
      return navigate(setParameter(arg0), arg1);
    }
  };
  return navigateWithProjectToken;
};
