import { IconButton } from '@allganize/ui-button';
import { IcClose } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { Theme, TypeOptions } from 'react-toastify';
import { ToastIcon } from '../toast-icon/toast-icon';

interface ToastBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  type: TypeOptions;
  theme: Theme;
  action?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ToastBody = forwardRef<HTMLDivElement, ToastBodyProps>(
  ({ type, theme: themeProp, action, onClose, children, ...other }, ref) => {
    const theme = useTheme();

    return (
      <div
        data-testid="toast-body"
        css={css`
          margin: auto 0;
          flex: 1 1 auto;
          display: flex;
          align-items: flex-start;
        `}
        {...other}
        ref={ref}
      >
        <div
          css={css`
            line-height: 0;
            padding: 8px;
            margin-right: -12px;
          `}
        >
          <ToastIcon type={type} theme={themeProp} />
        </div>

        <div
          css={css`
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            color: ${theme.palette.text.primary};
          `}
        >
          <div
            css={css`
              word-break: break-word;
              flex-grow: 1;
              padding: 8px;
            `}
          >
            {children}
          </div>

          <div>
            {typeof action === 'undefined' ? (
              <IconButton onClick={onClose}>
                <IcClose />
              </IconButton>
            ) : (
              action
            )}
          </div>
        </div>
      </div>
    );
  },
);
