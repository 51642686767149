import { Truncate } from '@allganize/truncate';
import { Text } from '@allganize/ui-text';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';

interface SingleActionHeaderProps {
  name: string;
  description: string;
}

export const SingleActionHeader: FunctionComponent<SingleActionHeaderProps> = ({
  name,
  description,
}) => {
  return (
    <header
      css={css`
        max-width: 720px;
        margin-bottom: 36px;
      `}
    >
      <Truncate clamp={2}>
        <Text variant="title18" align="center" gutterBottom>
          {name}
        </Text>
      </Truncate>
      <Truncate clamp={4}>
        <Text variant="body16" align="center">
          {description}
        </Text>
      </Truncate>
    </header>
  );
};
