/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import {
  SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_,
} from '../fragments/single-action-input-knowledge-base-fragment';
import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { SingleActionInputKnowledgeBaseFragmentDoc } from '../fragments/single-action-input-knowledge-base-fragment';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadKnowledgeFileWithTokenMutationVariables =
  UserQueryTypes.Exact<{
    file?: UserQueryTypes.InputMaybe<UserQueryTypes.Scalars['Upload']['input']>;
    fileName?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
    filePath?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
    where: UserQueryTypes.ProjectWhereUniqueInput;
    accessToken?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
  }>;

export type UploadKnowledgeFileWithTokenMutation = {
  __typename: 'UserMutation';
} & {
  uploadKnowledgeFileWithToken: UserQueryTypes.Maybe<
    { __typename: 'UploadKnowledgeFileWithToken' } & {
      knowledgeBase: UserQueryTypes.Maybe<
        | ({
            __typename: 'CSVKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_)
        | ({
            __typename: 'GoogleDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_)
        | ({
            __typename: 'HWPKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_)
        | ({
            __typename: 'ImageKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_)
        | ({
            __typename: 'MSDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_)
        | ({
            __typename: 'MSExcelKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_)
        | ({
            __typename: 'MSPPTKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_)
        | ({
            __typename: 'OldMSDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_)
        | ({
            __typename: 'PDFKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_)
        | ({
            __typename: 'TextKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_)
      >;
      duplicates: UserQueryTypes.Maybe<
        Array<
          UserQueryTypes.Maybe<
            | ({
                __typename: 'CSVKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_)
            | ({
                __typename: 'GoogleDocsKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_)
            | ({
                __typename: 'HWPKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_)
            | ({
                __typename: 'ImageKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_)
            | ({
                __typename: 'MSDocsKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_)
            | ({
                __typename: 'MSExcelKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_)
            | ({
                __typename: 'MSPPTKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_)
            | ({
                __typename: 'OldMSDocsKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_)
            | ({
                __typename: 'PDFKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_)
            | ({
                __typename: 'TextKnowledgeBase';
              } & SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_)
          >
        >
      >;
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const UploadKnowledgeFileWithTokenMutationDocument = gql`
  mutation UploadKnowledgeFileWithTokenMutation(
    $file: Upload
    $fileName: String
    $filePath: String
    $where: ProjectWhereUniqueInput!
    $accessToken: String
  ) {
    uploadKnowledgeFileWithToken(
      file: $file
      fileName: $fileName
      filePath: $filePath
      where: $where
      accessToken: $accessToken
    ) {
      knowledgeBase {
        ...SingleActionInputKnowledgeBaseFragment
      }
      duplicates {
        ...SingleActionInputKnowledgeBaseFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SingleActionInputKnowledgeBaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UploadKnowledgeFileWithTokenMutationMutationFn =
  Apollo.MutationFunction<
    UploadKnowledgeFileWithTokenMutation,
    UploadKnowledgeFileWithTokenMutationVariables
  >;

/**
 * __useUploadKnowledgeFileWithTokenMutation__
 *
 * To run a mutation, you first call `useUploadKnowledgeFileWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadKnowledgeFileWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadKnowledgeFileWithTokenMutation, { data, loading, error }] = useUploadKnowledgeFileWithTokenMutation({
 *   variables: {
 *      file: // value for 'file'
 *      fileName: // value for 'fileName'
 *      filePath: // value for 'filePath'
 *      where: // value for 'where'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useUploadKnowledgeFileWithTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadKnowledgeFileWithTokenMutation,
    UploadKnowledgeFileWithTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadKnowledgeFileWithTokenMutation,
    UploadKnowledgeFileWithTokenMutationVariables
  >(UploadKnowledgeFileWithTokenMutationDocument, options);
}
export type UploadKnowledgeFileWithTokenMutationHookResult = ReturnType<
  typeof useUploadKnowledgeFileWithTokenMutation
>;
export type UploadKnowledgeFileWithTokenMutationMutationResult =
  Apollo.MutationResult<UploadKnowledgeFileWithTokenMutation>;
export type UploadKnowledgeFileWithTokenMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UploadKnowledgeFileWithTokenMutation,
    UploadKnowledgeFileWithTokenMutationVariables
  >;
