import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiFormHelperText from '@mui/material/FormHelperText';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { forwardRef } from 'react';
import { formHelperTextClasses } from './form-helper-text-classes';
import { FormHelperTextTypeMap } from './form-helper-text-type-map';

// @ts-expect-error overridable component
export const FormHelperText: OverridableComponent<FormHelperTextTypeMap> =
  forwardRef((props, ref) => {
    const theme = useTheme();

    return (
      <MuiFormHelperText
        data-testid="form-helper-text"
        css={css`
          ${theme.typography.body12}
          color: ${theme.palette.text.secondary};
          margin-top: 4px;
          margin-left: 0;
          margin-right: 0;

          &.${formHelperTextClasses.disabled} {
            color: ${theme.palette.text.disabled};
          }

          &.${formHelperTextClasses.error} {
            color: ${theme.palette.error.main};
          }
        `}
        variant="outlined"
        {...props}
        ref={ref}
      />
    );
  });
