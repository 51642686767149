import { isDraftInputEmpty } from '@allganize/draft-input';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DraftDocumentList } from '../draft-document-list/draft-document-list';
import { useDocumentEntities } from '../draft-document-list/use-document-entities';
import { RichText } from '../rich-text/rich-text';
import { useRichText } from '../rich-text/use-rich-text';
import { UserFeedback } from '../user-feedback/user-feedback';
import { chatMrcInfoClasses } from './chat-mrc-info-classes';
import { ChatMrcInfoProps } from './chat-mrc-info-type-map';

export const ChatMrcInfo = forwardRef<HTMLDivElement, ChatMrcInfoProps>(
  (props, ref) => {
    const { classes, data, onUserFeedbackSubmit, ...other } = props;
    const theme = useTheme();
    const { value: answer, onChange: onAnswerChange } = useRichText({
      data: data.answer,
    });
    const { documentEntities } = useDocumentEntities({
      data: answer,
      knowledgeBasePreview: data.knowledgeBasePreview,
      knowledgeBasePreviewKeyPrefix: data.id,
    });
    const hasMessage = !isDraftInputEmpty(answer);
    const hasDocumentEntities = documentEntities.length > 0;

    return (
      <div
        data-testid="chat-mrc-info"
        css={css`
          background-color: ${theme.palette.common.white};
          border-radius: ${theme.radius.sm}px;
          border: 1px solid ${theme.palette.grayAlpha[300]};
          padding: 11px 15px;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-start;
        `}
        {...other}
        ref={ref}
        className={clsx(
          chatMrcInfoClasses.root,
          classes?.root,
          other.className,
        )}
      >
        <div
          css={css`
            flex-grow: 1;
          `}
        >
          <RichText value={answer} onChange={onAnswerChange} />

          {hasDocumentEntities && (
            <DraftDocumentList
              css={[
                !hasMessage &&
                  css`
                    margin-top: -2px;
                  `,
              ]}
              data={documentEntities}
            />
          )}
        </div>

        {data.ratable && (
          <UserFeedback
            botMessage={null}
            onUserFeedbackSubmit={values =>
              onUserFeedbackSubmit?.({
                rating: values.rating,
                mrcInfoId: data.id,
              })
            }
            rating={data.rating}
          />
        )}
      </div>
    );
  },
);
