import { useImageLoaded } from '@allganize/hooks';
import { ButtonBase, buttonBaseClasses } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcImageNotSupported } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { BlockRendererProps } from '../draft-plugin/draft-plugin';
import { DraftImageClasses, draftImageClasses } from './draft-image-classes';

const defaultWidthStyles = (width?: number) => css`
  min-width: 232px;
  max-width: 480px;
`;

const responsiveWidthStyles = (width?: number) => css`
  min-width: ${width === undefined || width > 232 ? 232 : width}px;
  max-width: ${width === undefined || width > 519 ? 519 : width}px;

  @media (min-width: 640px) {
    min-width: ${width === undefined || width > 350 ? 350 : width}px;
    max-width: ${width === undefined || width > 530 ? 530 : width}px;
  }

  @media (min-width: 980px) {
    min-width: ${width === undefined || width > 500 ? 500 : width}px;
    max-width: ${width === undefined || width > 560 ? 560 : width}px;
  }
`;

export interface DraftImageBlockProps {
  classes?: Partial<DraftImageClasses>;
  data?: {
    id?: string | number | null;
    metaInfo?: string | null;
    src?: string | null;
    width?: number | null;
    height?: number | null;
    isOptionType?: boolean;
  };
  preserveRatio?: boolean;
}

const getAlt = (
  blockProps?: DraftImageBlockProps,
): string | null | undefined => {
  const metaInfo = blockProps?.data?.metaInfo;

  if (!metaInfo) {
    return blockProps?.data?.src;
  }

  try {
    const parsedMetaInfo = JSON.parse(metaInfo);
    return parsedMetaInfo.name || parsedMetaInfo.filename;
  } catch {
    return blockProps?.data?.src;
  }
};

export const DraftImage = forwardRef<
  HTMLDivElement,
  BlockRendererProps<DraftImageBlockProps>
>((props, ref) => {
  const { blockProps } = props;
  const theme = useTheme();
  const loaded = useImageLoaded({ src: blockProps?.data?.src || undefined });
  // 16:9 ratio by default
  let ratio = '56.25%';

  if (blockProps?.preserveRatio) {
    if (blockProps.data?.width && blockProps.data.height) {
      ratio = `${(blockProps.data.height / blockProps.data.width) * 100}%`;
    }
  }

  const handleClick = () => {
    if (!blockProps?.data?.src) {
      return;
    }

    window.open(
      blockProps.data.src,
      `draft-image-${blockProps.data.id || blockProps.data.src}`,
      `titlebar=no,toolbar=no,status=no,menubar=no,location=no${
        blockProps.data.width ? `,width=${blockProps.data.width}` : ''
      }${blockProps.data.height ? `,height=${blockProps.data.height}` : ''}`,
    );
  };
  return (
    <div
      data-testid="draft-image"
      css={[
        defaultWidthStyles(blockProps?.data?.width || undefined),
        !blockProps?.data?.isOptionType &&
          responsiveWidthStyles(blockProps?.data?.width || undefined),
        css`
          position: relative;
          width: 100%;
          border: 1px solid ${theme.palette.divider};
          border-radius: ${theme.radius.xs}px;
          overflow: hidden;
          background-color: ${theme.palette.grey[200]};

          &::before {
            content: '';
            display: block;
            padding-bottom: ${ratio};
          }
        `,
      ]}
      className={clsx(draftImageClasses.root, blockProps?.classes?.root)}
      ref={ref}
    >
      <ButtonBase
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &::after {
            display: block;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: transparent;
            pointer-events: none;
            transition: ${theme.transitions.create('background-color', {
              duration: theme.transitions.duration.short,
            })};
          }

          &:hover,
          &.${buttonBaseClasses.focusVisible} {
            &::after {
              background-color: ${theme.palette.grayAlpha[300]};
            }
          }

          &:active {
            &::after {
              background-color: ${theme.palette.grayAlpha[500]};
            }
          }

          &.${buttonBaseClasses.disabled} {
            &::after {
              background-color: transparent;
            }
          }
        `}
        disabled={loaded !== 'loaded'}
        className={clsx(draftImageClasses.button, blockProps?.classes?.button)}
        onClick={handleClick}
      >
        {loaded === 'loaded' && (
          <img
            css={css`
              display: block;
              width: 100%;
              height: 100%;
              user-select: none;
              object-fit: cover;
              word-break: break-all;
              background-color: ${theme.palette.common.white};
            `}
            src={blockProps?.data?.src || undefined}
            alt={getAlt(blockProps) || undefined}
            className={clsx(
              draftImageClasses.image,
              blockProps?.classes?.image,
            )}
          />
        )}

        {loaded === 'error' && (
          <span
            css={css`
              color: ${theme.palette.text.secondary};
              font-size: 36px;
            `}
          >
            <IcImageNotSupported color="inherit" fontSize="inherit" />
          </span>
        )}

        {!loaded && <CircularProgress color="primary" />}
      </ButtonBase>
    </div>
  );
});
