import { CircularProgress } from '@allganize/ui-circular-progress';
import { Global, css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { environment } from '../../environments/environment';
import { useAlliSDKModule } from '../../hooks/use-alli-sdk-module';
import { FullscreenLayout } from '../../components/layout/templates/FullscreenLayout';

export const SkillPage = () => {
  const [searchParams] = useSearchParams();
  const { campaignToken, region, userId, chatToken, fullscreen, version } =
    useMemo(
      () => ({
        campaignToken: searchParams.get('campaign_token') || undefined,
        region: searchParams.get('region'),
        userId: searchParams.get('user_id'),
        chatToken: searchParams.get('token') || undefined,
        fullscreen: searchParams.get('fullscreen')?.toLowerCase() !== 'false',
        version: searchParams.get('v') || undefined,
      }),
      [searchParams],
    );
  const { Alli, loading, error } = useAlliSDKModule({ version });

  useEffect(() => {
    if (!Alli || (!campaignToken && !chatToken)) return;

    const errorManager = new Alli.AlliPageErrorManager();
    const errorRenderer = new Alli.AlliPageErrorRenderer(errorManager);
    let rendered = false;

    const handleError = async () => {
      Alli.destroy();

      if (rendered) {
        return;
      }

      errorRenderer.unmount();
      await errorRenderer.render();
      rendered = true;
    };

    const handleClear = () => {
      errorRenderer.unmount();
      rendered = false;
    };

    errorManager.on('error', handleError);
    errorManager.on('clear', handleClear);

    const config = {
      endpoints:
        region === 'ja' || region === 'kr'
          ? environment.api[region]
          : environment.api.default,
      entryType: 'popup',
      launcher: { hidden: fullscreen },
      fullscreen,
      errorManager,
      hideCloseButton: fullscreen,
      render: {
        navigate: {
          to: '/conversations/new',
        },
      },
    };

    const initialize = campaignToken
      ? Alli.initializeWithCampaignToken
      : Alli.initializeWithChatToken;
    initialize({
      campaignToken,
      chatToken,
      ...config,
    } as any).then(() => {
      if (userId) {
        Alli.getAlliClient()?.setUserId(userId);
      }
    });

    return () => {
      Alli.destroy();
      errorManager.off('error', handleError);
      errorManager.off('clear', handleClear);
      handleClear();
    };
  }, [Alli, campaignToken, chatToken, userId, region, fullscreen]);

  if (!campaignToken && !chatToken) {
    throw new Error('Token is required');
  }

  if (loading || error) {
    return (
      <FullscreenLayout>
        <div
          css={css`
            align-self: center;
            text-align: center;
            width: 100%;
          `}
        >
          <CircularProgress />
        </div>
      </FullscreenLayout>
    );
  }

  return (
    <FullscreenLayout>
      <Global
        styles={css`
          #alli-container {
            position: relative;
            width: 100%;

            .alli-messenger-container {
              position: absolute;
            }
          }
        `}
      />

      <div id="alli-error-container" />

      <div id="alli-container" />
    </FullscreenLayout>
  );
};
