import { useEventCallback } from '@allganize/hooks';
import { Iframe } from '@allganize/react-iframe';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { DocumentViewerPageWrapper } from '../document-viewer-page-wrapper';
import { PageProxy } from '../hooks/use-scale';
import { htmlPageClasses } from './html-page-classes';
import { HtmlPageProps } from './html-page-type-map';

export const HtmlPage = forwardRef<HTMLDivElement, HtmlPageProps>(
  (props, ref) => {
    const {
      classes,
      page,
      pageRef,
      scale = 1,
      style: styleProp,
      ...other
    } = props;
    const theme = useTheme();
    const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
    const contentWindow = iframeRef?.contentWindow;
    const iframeDoc = contentWindow?.document;
    const imagesLoaded = useRef(false);
    const [size, setSize] = useState({
      width: iframeDoc?.documentElement.clientWidth,
      height: iframeDoc?.documentElement.clientHeight,
    });
    const style = typeof styleProp === 'function' ? styleProp(size) : styleProp;

    const updateSize = useEventCallback(() => {
      const docEl = iframeDoc?.documentElement;

      if (!docEl) {
        return;
      }

      const newWidth = Math.ceil(
        Math.max(docEl.clientWidth, docEl.scrollWidth, size.width ?? 0),
      );
      const newHeight = Math.ceil(
        Math.max(docEl.clientHeight, docEl.scrollHeight, size.height ?? 0),
      );

      if (newWidth === size.width && newHeight === size.height) {
        return;
      }

      setSize({
        width: newWidth,
        height: newHeight,
      });
    });

    useImperativeHandle<PageProxy | null, PageProxy | null>(
      pageRef,
      () => ({
        getKey() {
          return page.key;
        },
        getSize() {
          if (
            typeof size.width === 'undefined' ||
            typeof size.height === 'undefined' ||
            size.width === 0 ||
            size.height === 0
          ) {
            return null;
          }

          // make sure size is calculated once all images are loaded
          if (!imagesLoaded.current) {
            return null;
          }

          return { width: size.width, height: size.height };
        },
      }),
      [page.key, size.height, size.width],
    );

    useEffect(() => {
      const images = contentWindow?.document.querySelectorAll('img');
      const totalCount = images?.length ?? 0;
      let loadedCount = 0;

      const checkImagesLoaded = () => {
        if (loadedCount >= totalCount) {
          imagesLoaded.current = true;
          return;
        }

        imagesLoaded.current = false;
      };

      const handleImageLoad = () => {
        loadedCount += 1;
        checkImagesLoaded();
        updateSize();
      };

      const attachImageLoadEvent = (image: HTMLImageElement) => {
        if (image.complete) {
          handleImageLoad();
        } else {
          image.addEventListener('load', handleImageLoad);
          image.addEventListener('error', handleImageLoad);
        }
      };

      const removeImageLoadEvent = (image: HTMLImageElement) => {
        image.removeEventListener('load', handleImageLoad);
        image.removeEventListener('error', handleImageLoad);
      };

      imagesLoaded.current = false;
      images?.forEach(attachImageLoadEvent);
      checkImagesLoaded();

      return () => {
        images?.forEach(removeImageLoadEvent);
      };
    }, [page.body, page.css, contentWindow?.document, updateSize]);

    useLayoutEffect(() => {
      updateSize();
    });

    /**
     * Fixes QA-569, QA-566
     * This is a hack to fix iframe going empty on FireFox.
     *
     * Reference:
     * - https://bugzilla.mozilla.org/show_bug.cgi?id=297685
     * - https://bugzilla.mozilla.org/show_bug.cgi?id=1745638
     * - https://stackoverflow.com/questions/60814167/firefox-deleted-innerhtml-of-generated-iframe
     */
    useLayoutEffect(() => {
      let timeoutId: number | null = null;

      const cleanup = () => {
        if (timeoutId !== null) {
          window.clearTimeout(timeoutId);
          timeoutId = null;
        }
      };

      if (iframeDoc?.head || iframeDoc?.body) {
        timeoutId = window.setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          cleanup();
        }, 0);
      }

      return cleanup;
    }, [iframeDoc?.body, iframeDoc?.head, size.width, size.height]);

    return (
      <DocumentViewerPageWrapper
        data-testid="html-page"
        {...other}
        ref={ref}
        className={clsx(htmlPageClasses.root, classes?.root, other.className)}
        style={{
          width: size.width,
          height: size.height,
          ...style,
        }}
      >
        <Iframe
          data-testid="html-page__iframe"
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            min-width: 100%;
          `}
          title={other.title}
          allowFullScreen
          frameBorder={0}
          ref={setIframeRef}
          style={{
            width: size.width,
            height: size.height,
            transform: `translate(-50%, -50%) scale(${scale})`,
          }}
          className={clsx(htmlPageClasses.iframe, classes?.iframe)}
          head={
            <Fragment>
              <meta charSet="UTF-8" />
              <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
              <base target="_blank" />
              <title>{other.title}</title>
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              <style>{`
                * {
                  box-sizing: border-box;
                }

                html, body {
                  position: relative;
                  margin: 0;
                  padding: 0;
                  border: 0;
                }

                div, span, a {
                  border: 0;
                  vertical-align: baseline;
                }

                .highlight {
                  color: ${theme.palette.text.primary};
                  background-color: ${theme.palette.background.textHighlight};
                }

                .awpage {
                  border: 0;
                  margin: 0;
                }
              `}</style>

              <style>{page.css}</style>
            </Fragment>
          }
        >
          <div dangerouslySetInnerHTML={{ __html: page.body }} />
        </Iframe>
      </DocumentViewerPageWrapper>
    );
  },
);
