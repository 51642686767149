import { Types } from '@allganize/alli-sdk-interfaces';
import { ConversationContext } from '@allganize/alli-sdk/conversation-detail/conversation-context';
import { combineRefs, useEventCallback } from '@allganize/hooks';
import {
  ButtonBase,
  ButtonBaseProps,
  buttonBaseClasses,
} from '@allganize/ui-button';
import {
  IcArrowExpandLess,
  IcArrowExpandMore,
  IcDescription,
  IcDocumentSearch,
  IcGavel,
  IcSendMessage,
  svgIconClasses,
} from '@allganize/ui-icons';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, MenuItem } from '@allganize/ui-menu';
import { paperClasses } from '@allganize/ui-paper';
import { Text } from '@allganize/ui-text';
import { Tooltip } from '@allganize/ui-tooltip';
import styled from '@emotion/styled';
import {
  Fragment,
  MouseEvent,
  ReactNode,
  forwardRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

interface SelectOption {
  label: '통합' | '법령' | '세법해석례' | '판례・결정례';
  value: Types.TaxAppTypes;
  icon: ReactNode;
}

interface TaxAppSelectProps
  extends Omit<ButtonBaseProps, 'onChange' | 'value'> {
  initialTaxAppType?: Types.TaxAppTypes;
  value?: Types.TaxAppTypes;
  onChange?: (appType: Types.TaxAppTypes) => void;
  minimize?: boolean;
}

const Chip = styled(ButtonBase)`
  display: flex;
  height: 28px;
  min-width: 28px;
  align-items: center;
  gap: ${props => props.theme.spacing(0.5)};
  padding: ${props => props.theme.spacing(0.5, 1.5)};
  border-radius: ${props => props.theme.radius.round}px;
  border: 1px solid ${props => props.theme.taxPalette.grey[300]};
  color: ${props => props.theme.taxPalette.grey[600]};
  background-color: ${props => props.theme.taxPalette.white};

  &:hover {
    background-color: ${props => props.theme.taxPalette.grey[50]};
  }
  &:active {
    background-color: ${props => props.theme.taxPalette.primary[50]};
    border: 1px solid ${props => props.theme.taxPalette.primary[500]};
  }

  &.${buttonBaseClasses.disabled} {
    background-color: ${props => props.theme.taxPalette.white};
  }

  .${svgIconClasses.root} {
    font-size: 16px;
  }
`;

const StyledMenu = styled(Menu)`
  .${paperClasses.root} {
    color: ${props => props.theme.palette.unstable_foreground.secondary};
    margin: ${props => props.theme.spacing(1, 0)};
  }

  .${svgIconClasses.root} {
    font-size: 16px;
    color: ${props => props.theme.palette.unstable_foreground.secondary};
  }
`;

export const taxAppSelectOptions: Record<string, SelectOption> = {
  TONG_HAP: {
    label: '통합',
    value: 'TONG_HAP',
    icon: <IcDocumentSearch />,
  },
  BEOB_RYEONG: {
    label: '법령',
    value: 'BEOB_RYEONG',
    icon: <IcDescription />,
  },
  HAE_SEOK_RYE: {
    label: '세법해석례',
    value: 'HAE_SEOK_RYE',
    icon: <IcSendMessage />,
  },
  GYEOL_JUNG_RYE: {
    label: '판례・결정례',
    value: 'GYEOL_JUNG_RYE',
    icon: <IcGavel />,
  },
} satisfies Partial<Record<Types.TaxAppTypes, SelectOption>>;

export const TaxAppSelect = forwardRef<HTMLButtonElement, TaxAppSelectProps>(
  ({ onChange, value, minimize, ...others }, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const mergedRef = combineRefs(ref, buttonRef);
    const [open, setOpen] = useState(false);

    const {
      data: { conversation },
    } = useContext(ConversationContext);
    if (!conversation) {
      console.log('no valid conversation');
    }

    const appTypeByName: Record<string, Types.TaxAppTypes> = {
      통합: 'TONG_HAP',
      법령: 'BEOB_RYEONG',
      세법해석례: 'HAE_SEOK_RYE',
      '판례・결정례': 'GYEOL_JUNG_RYE',
    };
    const conversationAppName = conversation?.llmApp?.name ?? '통합';
    const conversationAppType = appTypeByName[conversationAppName];
    const initialTaxAppType = conversationAppType ?? 'TONG_HAP';

    const selectedApp = useMemo(() => {
      console.log('value in memo', value);
      if (value && value in taxAppSelectOptions) {
        return taxAppSelectOptions[value];
      }
      return taxAppSelectOptions[initialTaxAppType];
    }, [value, initialTaxAppType]);

    const handleToggleSelect = useEventCallback((e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setOpen(prev => !prev);
    });

    const handleClose = useEventCallback(() => {
      setOpen(false);
    });

    const handleChange = useEventCallback((opt: SelectOption) => {
      if (opt.value !== value) {
        onChange?.(opt.value);
      }
      setOpen(false);
    });

    return (
      <Fragment>
        <Tooltip title={minimize ? selectedApp.label : ''}>
          <Chip
            ref={mergedRef}
            onClick={handleToggleSelect}
            type="button"
            {...others}
          >
            {selectedApp.icon}
            {!minimize && <Text variant="label14">{selectedApp.label}</Text>}
            {open ? <IcArrowExpandLess /> : <IcArrowExpandMore />}
          </Chip>
        </Tooltip>
        <StyledMenu
          open={open}
          anchorEl={buttonRef.current}
          onClose={handleClose}
        >
          {Object.values(taxAppSelectOptions).map(option => (
            <MenuItem
              key={option.value ?? 'default'}
              onClick={() => handleChange(option)}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </StyledMenu>
      </Fragment>
    );
  },
);
