import {
  ContentBlock,
  ContentState,
  DraftDecorator,
  EntityInstance,
} from 'draft-js';
import { uniqBy } from 'lodash-es';
import { DraftPlugin } from '../draft-plugin/draft-plugin';
import { DraftDocumentEntity } from './draft-document-entity';
import {
  DraftDocumentLink,
  DraftDocumentLinkProps,
} from './draft-document-link';

export class DraftDocumentPlugin extends DraftPlugin {
  public static readonly ENTITY_TYPE = 'LINK';

  public static isDocumentEntity(entity: EntityInstance) {
    const type = entity.getType();

    if (type !== DraftDocumentPlugin.ENTITY_TYPE) {
      return false;
    }

    const data = entity.getData();
    return !!data?.knowledgeBasePreview;
  }

  public static strategy(
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) {
    block.findEntityRanges(character => {
      const entityKey = character.getEntity();

      if (!entityKey) {
        return false;
      }

      const entity = contentState.getEntity(entityKey);

      if (!entity) {
        return false;
      }

      return DraftDocumentPlugin.isDocumentEntity(entity);
    }, callback);
  }

  public static getDocumentEntities(
    contentState: ContentState,
  ): DraftDocumentEntity[] {
    const blockMap = contentState.getBlockMap();
    const result: DraftDocumentEntity[] = [];

    blockMap.forEach(block => {
      if (!block) {
        return;
      }

      DraftDocumentPlugin.strategy(
        block,
        (start, end) => {
          const text = block.getText().slice(start, end);
          const entityKey = block.getEntityAt(start);
          const entity = contentState.getEntity(entityKey);

          result.push({
            key: entityKey,
            text,
            data: entity.getData(),
          });
        },
        contentState,
      );
    });

    return uniqBy(result, item => item.key);
  }

  public getDecorators(): DraftDecorator<DraftDocumentLinkProps>[] | null {
    return [
      {
        strategy: DraftDocumentPlugin.strategy,
        component: DraftDocumentLink,
      },
    ];
  }
}
