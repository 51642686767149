/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type CurrentUserFragment = { __typename: 'User' } & Pick<
  Types.User,
  | 'id'
  | 'locale'
  | 'ownUserId'
  | 'avatar'
  | 'createdAt'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'name'
  | 'phone'
  | 'temporary'
  | 'isTry'
>;

export const CurrentUserFragmentDoc = gql`
  fragment CurrentUserFragment on User {
    id
    locale
    ownUserId
    avatar
    createdAt
    email
    firstName
    lastName
    name
    phone
    temporary
    isTry
  }
`;
