import { ChatForm } from '@allganize/alli-sdk/chat-form/chat-form';
import { PreviewContext } from '@allganize/alli-sdk/preview/preview-context';
import { css, useTheme } from '@emotion/react';
import { useContext, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { contentMaxWidth } from '../layout/templates/ConversationDetailLayout';
import { TaxAppSelect } from './tax-app-select';

export const Footer = () => {
  const theme = useTheme();
  const footerRef = useRef<HTMLElement>(null);
  const preview = useContext(PreviewContext);

  return (
    <footer
      ref={footerRef}
      css={css`
        position: sticky;
        bottom: 0;
        max-width: ${contentMaxWidth}px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 12px;
        background-color: ${theme.taxPalette.white};
      `}
    >
      <ChatForm
        startAdornment={control => (
          <Controller
            control={control}
            name="appType"
            render={({ field: { onChange, value }, formState }) => (
              <TaxAppSelect
                onChange={onChange}
                minimize={preview.previewOpen}
                disabled={formState.isSubmitting}
                value={value}
              />
            )}
          />
        )}
      />
    </footer>
  );
};
