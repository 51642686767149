/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type PageInfoFragment = { __typename: 'PageInfo' } & Pick<
  Types.PageInfo,
  'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
>;

export const PageInfoFragmentDoc = gql`
  fragment PageInfoFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
