import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftDecorator,
  EditorProps,
  EditorState,
  SelectionState,
} from 'draft-js';

interface EditorStateGetter {
  getEditorState(): EditorState;
}

interface EditorStateSetter {
  setEditorState(state: EditorState): void;
}

export interface PluginFunctions extends EditorStateGetter, EditorStateSetter {
  getDisabled(): boolean;
}

export interface BlockRendererProps<T>
  extends Pick<
    EditorProps,
    'blockStyleFn' | 'customStyleFn' | 'customStyleMap'
  > {
  block?: ContentBlock;
  blockProps?: T;
  contentState?: ContentState;
  decorator?: CompositeDecorator | null;
  direction?: EditorProps['textDirectionality'];
  forceSelection?: boolean;
  offsetKey?: string;
  preventScroll?: unknown;
  selection?: SelectionState;
  tree?: Immutable.List<any>;
}

export class DraftPlugin {
  public blockRendererFn(
    block: ContentBlock,
    pluginFunctions: PluginFunctions,
  ): any {
    return null;
  }

  public getDecorators(): DraftDecorator[] | null {
    return null;
  }
}
