import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  listItemTextClasses,
} from '@allganize/ui-list';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { AssistantCreatedLLMAppOptionsProps } from './assistant-created-llm-app-options-type-map';
import { FormattedMessage } from 'react-intl';
import { assistantCreatedLLMAppOptionClasses } from './assistant-created-llm-app-options-classes';

export const AssistantCreatedLLMAppOptions: FunctionComponent<
  AssistantCreatedLLMAppOptionsProps
> = ({ appName, editLink, isValidApp, onRunApp, classes }) => {
  const theme = useTheme();

  if (!editLink && !isValidApp) {
    return null;
  }

  return (
    <div
      css={css`
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <List
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin: -4px;
        `}
        disablePadding
      >
        {isValidApp && (
          <li
            css={css`
              padding: 4px;
            `}
          >
            <ListItem
              css={css`
                display: inline-flex;
                flex-direction: column;
                align-items: stretch;
                border-radius: ${theme.radius.md}px;
                border: 1px solid ${theme.palette.grayAlpha[300]};
                background-color: ${theme.palette.unstable_background.white};
                overflow: hidden;
                width: auto;
                max-width: 480px;
              `}
              component="div"
              disablePadding
              className={clsx(
                assistantCreatedLLMAppOptionClasses.root,
                classes?.root,
              )}
            >
              <ListItemButton
                css={css`
                  padding: 7px 15px;
                  align-items: flex-start;
                `}
                onClick={onRunApp}
                className={clsx(
                  assistantCreatedLLMAppOptionClasses.button,
                  classes?.button,
                )}
              >
                <ListItemText
                  css={css`
                    .${listItemTextClasses.primary}
                      + .${listItemTextClasses.secondary} {
                      margin-top: 8px;
                    }

                    .${listItemTextClasses.secondary} {
                      color: ${theme.palette.text.primary};

                      .${listItemTextClasses.disabled} & {
                        color: ${theme.palette.text.disabled};
                      }
                    }
                  `}
                  primary={
                    <FormattedMessage
                      id="assistant-created-llm-app.run-button-primary"
                      defaultMessage="Run App"
                      description="AssistantCreatedLLMApp run primary text"
                    />
                  }
                  secondary={appName}
                  primaryTextProps={{
                    variant: 'title14',
                  }}
                  secondaryTextProps={{
                    variant: 'body14',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </li>
        )}
        {editLink && (
          <li
            css={css`
              padding: 4px;
            `}
          >
            <ListItem
              css={css`
                display: inline-flex;
                flex-direction: column;
                align-items: stretch;
                border-radius: ${theme.radius.md}px;
                border: 1px solid ${theme.palette.grayAlpha[300]};
                background-color: ${theme.palette.unstable_background.white};
                overflow: hidden;
                width: auto;
                max-width: 480px;
              `}
              component="div"
              disablePadding
              className={clsx(
                assistantCreatedLLMAppOptionClasses.root,
                classes?.root,
              )}
            >
              <ListItemButton
                css={css`
                  padding: 7px 15px;
                  align-items: flex-start;
                `}
                href={editLink}
                target="_blank"
                className={clsx(
                  assistantCreatedLLMAppOptionClasses.button,
                  classes?.button,
                )}
              >
                <ListItemText
                  css={css`
                    .${listItemTextClasses.primary}
                      + .${listItemTextClasses.secondary} {
                      margin-top: 8px;
                    }

                    .${listItemTextClasses.secondary} {
                      color: ${theme.palette.text.primary};

                      .${listItemTextClasses.disabled} & {
                        color: ${theme.palette.text.disabled};
                      }
                    }
                  `}
                  primary={
                    <FormattedMessage
                      id="assistant-created-llm-app.edit-button-primary"
                      defaultMessage="Edit"
                      description="AssistantCreatedLLMApp edit primary text"
                    />
                  }
                  secondary={
                    <FormattedMessage
                      id="assistant-created-llm-app.edit-button-secondary"
                      defaultMessage="(Accessible to admin only)"
                      description="AssistantCreatedLLMApp edit secondary text"
                    />
                  }
                  primaryTextProps={{
                    variant: 'title14',
                  }}
                  secondaryTextProps={{
                    variant: 'body14',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </li>
        )}
      </List>
    </div>
  );
};
