import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { IcDownload, IcFile, IcOpenInNew } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { BlockRendererProps } from '../draft-plugin/draft-plugin';
import { DraftFileClasses, draftFileClasses } from './draft-file-classes';

export interface DraftFileBlockProps {
  action?: 'download' | 'open';
  classes?: Partial<DraftFileClasses>;
  data?: {
    id?: string | number | null;
    metaInfo?: string | null;
    url?: string | null;
    createdAt?: string | null;
  };
}

const getFilename = (blockProps?: DraftFileBlockProps) => {
  const metaInfo = blockProps?.data?.metaInfo;

  if (!metaInfo) {
    return null;
  }

  try {
    const parsed = JSON.parse(metaInfo);
    return parsed?.filename || parsed?.name;
  } catch {
    return null;
  }
};

export const DraftFile = forwardRef<
  HTMLDivElement,
  BlockRendererProps<DraftFileBlockProps>
>((props, ref) => {
  const { blockProps } = props;
  const { action = 'download', classes, data } = blockProps || {};
  const theme = useTheme();
  const filename = getFilename(blockProps) || data?.url || undefined;

  return (
    <div
      data-testid="draft-file"
      css={css`
        display: inline-flex;
        align-items: center;
        border: 1px solid ${theme.palette.grayAlpha[300]};
        border-radius: ${theme.radius.sm}px;
        background-color: ${theme.palette.common.white};
        color: ${theme.palette.text.primary};
        padding: 4px 12px;
        min-height: 44px;
        width: 232px;
        max-width: 100%;
      `}
      className={clsx(draftFileClasses.root, classes?.root)}
      ref={ref}
    >
      <IcFile />

      <div
        css={css`
          flex-grow: 1;
          padding: 0 4px;
          word-break: break-all;
          ${theme.typography.title14}
        `}
        title={filename}
      >
        <Truncate clamp={1}>{filename}</Truncate>
      </div>

      <IconButton
        css={css`
          margin-left: 4px;
        `}
        data-testid="draft-file__action"
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        size="small"
        edge="end"
        href={data?.url || undefined}
        download={action === 'download' ? filename : undefined}
      >
        {action === 'download' ? <IcDownload /> : <IcOpenInNew />}
      </IconButton>
    </div>
  );
});
