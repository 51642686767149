import {
  DraftInput,
  DraftInputProps,
  DraftInputRef,
  draftInputClasses,
  draftInputInternalClasses,
} from '@allganize/draft-input';
import { ClassNames } from '@emotion/react';
import { forwardRef } from 'react';
import { UseRichTextOptions, useRichText } from './use-rich-text';

export interface RichTextProps
  extends Omit<DraftInputProps, 'onChange' | 'value'>,
    Pick<UseRichTextOptions, 'onChange'> {
  value?: UseRichTextOptions['data'];
}

export const RichText = forwardRef<DraftInputRef, RichTextProps>(
  (props, ref) => {
    const { classes, onChange: onChangeProp, ...other } = props;
    const { onChange, plugins, value } = useRichText({
      data: props.value ?? null,
      onChange: onChangeProp,
      plugins: props.plugins,
    });

    if (!value) {
      return null;
    }

    return (
      <ClassNames>
        {({ css, cx }) => (
          <DraftInput
            disabled
            {...other}
            ref={ref}
            onChange={onChange}
            plugins={plugins}
            value={value}
            classes={{
              ...classes,
              root: cx(
                css`
                  .${draftInputInternalClasses.content} {
                    border: 0;
                  }

                  &.${draftInputClasses.disabled} {
                    color: inherit;
                    font: inherit;
                    letter-spacing: inherit;

                    .${draftInputInternalClasses.content} {
                      padding: 0;
                      background-color: transparent;
                    }
                  }
                `,
                classes?.root,
              ),
            }}
          />
        )}
      </ClassNames>
    );
  },
);
