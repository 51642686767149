import {
  Theme as UITheme,
  ThemeOptions as UIThemeOptions,
  createTheme as createUITheme,
} from '@allganize/alli-sdk-ui';
import {
  createTheme as createBaseTheme,
  Theme as BaseTheme,
} from '@allganize/ui-theme';

type DeviceType = 'desktop' | 'mobile';

type UILauncherTheme = UITheme['launcher'];
type UIMessengerTheme = UITheme['messenger'];
type UIMessagePreviewTheme = UITheme['messagePreview'];

interface LauncherTheme extends UILauncherTheme {
  zIndex: number;
  verticalPadding: number;
  horizontalPadding: number;
  hidden: boolean;
}

type LauncherThemeOptions = Partial<
  Omit<LauncherTheme, 'verticalPadding' | 'horizontalPadding'>
>;

interface MessengerTheme extends UIMessengerTheme {
  bottomOffset: number;
  zIndex: number;
  verticalPadding: number;
  horizontalPadding: number;
}

type MessengerThemeOptions = Partial<
  Omit<MessengerTheme, 'verticalPadding' | 'horizontalPadding'>
>;

interface MessagePreviewTheme extends UIMessagePreviewTheme {
  bottomOffset: number;
  zIndex: number;
  verticalPadding: number;
  horizontalPadding: number;
}

type MessagePreviewThemeOptions = Partial<
  Omit<MessagePreviewTheme, 'verticalPadding' | 'horizontalPadding'>
>;

export interface ThemeOptions extends UIThemeOptions {
  deviceType?: DeviceType;
  fullscreen?: boolean;
  primary?: string;
  verticalPadding?: number;
  horizontalPadding?: number;
  launcher?: LauncherThemeOptions;
  messenger?: MessengerThemeOptions;
  messagePreview?: MessagePreviewThemeOptions;
  bgColor?: string;
}

export interface Theme extends BaseTheme, UITheme {
  deviceType: DeviceType;
  fullscreen: boolean;
  verticalPadding: number;
  horizontalPadding: number;
  launcher: LauncherTheme;
  messenger: MessengerTheme;
  messagePreview: MessagePreviewTheme;
  modalContainer: {
    id: string;
    zIndex: number;
  };
  sidebar: {
    minWidth: number;
  };
}

export const createTheme = (options: ThemeOptions = {}): Theme => {
  const launcherZIndex = options.launcher?.zIndex ?? 2147483000;
  const messengerZIndex = options.messenger?.zIndex ?? launcherZIndex + 10;

  const baseTheme = createBaseTheme({
    palette: {
      primary: options.primary ? { main: options.primary } : undefined,
      unstable_background: {
        primary: {
          default: options.bgColor ?? '#fff',
        },
      },
    },
    typography: {
      pxToRem(size) {
        return `${size}px`;
      },
    },
    zIndex: {
      tooltip: messengerZIndex + 1,
    },
  });

  const fullscreen = options.fullscreen ?? false;

  const uiTheme = createUITheme({
    ...options,
    launcher: {
      ...options.launcher,
      boxShadow: baseTheme.elevations[4],
    },
    messenger: {
      ...options.messenger,
      boxShadow: fullscreen ? baseTheme.elevations[0] : baseTheme.elevations[4],
      borderRadius: fullscreen ? 0 : baseTheme.radius.xl,
    },
    messagePreview: {
      ...options.messagePreview,
      boxShadow: baseTheme.elevations[4],
      borderRadius: baseTheme.radius.xl,
    },
  });

  const verticalPadding = options.verticalPadding ?? 24;
  const horizontalPadding = options.horizontalPadding ?? 24;

  const launcher = {
    ...uiTheme.launcher,
    zIndex: launcherZIndex,
    verticalPadding,
    horizontalPadding,
    hidden: options.launcher?.hidden ?? false,
  } satisfies LauncherTheme;

  const messenger = {
    ...uiTheme.messenger,
    zIndex: messengerZIndex,
    bottomOffset:
      options.messenger?.bottomOffset ??
      (fullscreen
        ? 0
        : verticalPadding + (launcher.hidden ? 0 : launcher.size + 12)),
    horizontalPadding: fullscreen ? 0 : horizontalPadding,
    verticalPadding: fullscreen ? 0 : verticalPadding,
  } satisfies MessengerTheme;

  const messagePreview = {
    ...uiTheme.messagePreview,
    zIndex: options.messagePreview?.zIndex ?? launcher.zIndex + 10,
    bottomOffset:
      options.messagePreview?.bottomOffset ??
      verticalPadding + (launcher.hidden ? 0 : launcher.size + 12),
    horizontalPadding,
    verticalPadding,
  } satisfies MessagePreviewTheme;

  return {
    ...baseTheme,
    ...uiTheme,
    deviceType: options.deviceType ?? 'desktop',
    fullscreen,
    verticalPadding,
    horizontalPadding,
    launcher,
    messenger,
    messagePreview,
    modalContainer: {
      id: 'alli-modal-container',
      zIndex: launcher.zIndex + 10,
    },
    sidebar: {
      minWidth: 700,
    },
  };
};
