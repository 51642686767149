import { useEventCallback } from '@allganize/hooks';
import { startTransition } from 'react';
import {
  ConversationsQueryVariables,
  useConversationsQuerySuspenseQuery,
} from '../graphql/queries/conversations-query';
import { useConversationsSubscription } from '../graphql/subscriptions/conversations-subscription';

export const useConversationList = () => {
  const variables: ConversationsQueryVariables = {
    order: 'LAST_CHAT_TIME_DESC',
    first: 20,
  };

  const result = useConversationsQuerySuspenseQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const { data, fetchMore } = result;
  const pageInfo = data.conversations?.pageInfo;
  const endCursor = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage;

  useConversationsSubscription({ variables });

  const loadNextPage = useEventCallback(() => {
    if (!hasNextPage || !endCursor) {
      return;
    }

    startTransition(() => {
      fetchMore({
        variables: {
          after: endCursor,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (!fetchMoreResult.conversations?.edges) {
            return prev;
          }

          return {
            ...prev,
            conversations: prev.conversations
              ? {
                  ...prev.conversations,
                  edges: [
                    ...prev.conversations.edges,
                    ...fetchMoreResult.conversations.edges,
                  ],
                  pageInfo: {
                    ...prev.conversations.pageInfo,
                    hasNextPage:
                      fetchMoreResult.conversations.pageInfo.hasNextPage,
                    endCursor: fetchMoreResult.conversations.pageInfo.endCursor,
                  },
                }
              : fetchMoreResult.conversations,
          };
        },
      });
    });
  });

  return {
    ...result,
    loadNextPage,
  };
};
