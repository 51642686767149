import { Truncate } from '@allganize/truncate';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { FunctionComponent, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import Markdown from '../markdown/markdown';
import { baseOutputClasses } from './base-output-classes';
import { BaseOutputProps } from './base-output-type-map';

export const BaseOutput: FunctionComponent<BaseOutputProps> = ({
  classes,
  loading,
  label,
  output,
  ...other
}) => {
  const theme = useTheme();

  return (
    <div
      {...other}
      className={clsx(baseOutputClasses.root, classes?.root, other.className)}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
        `}
        className={clsx(baseOutputClasses.header, classes?.header)}
      >
        <Truncate clamp={1}>
          <Text
            variant="title12"
            className={clsx(baseOutputClasses.title, classes?.title)}
          >
            {label}
          </Text>
        </Truncate>
        {loading && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            <CircularProgress size="sm" />
            <Text variant="body12">
              <FormattedMessage
                id="single-action.base-output.generate-loading"
                defaultMessage="Generating..."
                description="single action generate loading text"
              />
            </Text>
          </div>
        )}
      </div>
      <div
        css={css`
          min-height: 160px;
          padding: 12px;
          border: 1px solid ${theme.palette.unstable_border.divider.default};
          border-radius: ${theme.radius.xs}px;
        `}
        className={clsx(baseOutputClasses.output, classes?.output)}
      >
        <Suspense fallback={<CircularProgress size="lg" />}>
          <Markdown markdown={output} />
        </Suspense>
      </div>
    </div>
  );
};
