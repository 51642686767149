/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutoCompletionQueryVariables = Types.Exact<{
  partial: Types.Scalars['String']['input'];
  where: Types.ConversationWhereUniqueInput;
}>;

export type AutoCompletionQuery = { __typename: 'UserQuery' } & {
  autoCompletion: Types.Maybe<
    { __typename: 'AutoCompletion' } & Pick<Types.AutoCompletion, 'questions'>
  >;
};

export const AutoCompletionQueryDocument = gql`
  query AutoCompletionQuery(
    $partial: String!
    $where: ConversationWhereUniqueInput!
  ) {
    autoCompletion(partial: $partial, where: $where) {
      questions
    }
  }
`;

/**
 * __useAutoCompletionQuery__
 *
 * To run a query within a React component, call `useAutoCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoCompletionQuery({
 *   variables: {
 *      partial: // value for 'partial'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAutoCompletionQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutoCompletionQuery,
    AutoCompletionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutoCompletionQuery, AutoCompletionQueryVariables>(
    AutoCompletionQueryDocument,
    options,
  );
}
export function useAutoCompletionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutoCompletionQuery,
    AutoCompletionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutoCompletionQuery, AutoCompletionQueryVariables>(
    AutoCompletionQueryDocument,
    options,
  );
}
export function useAutoCompletionQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AutoCompletionQuery,
    AutoCompletionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AutoCompletionQuery,
    AutoCompletionQueryVariables
  >(AutoCompletionQueryDocument, options);
}
export type AutoCompletionQueryHookResult = ReturnType<
  typeof useAutoCompletionQuery
>;
export type AutoCompletionQueryLazyQueryHookResult = ReturnType<
  typeof useAutoCompletionQueryLazyQuery
>;
export type AutoCompletionQuerySuspenseQueryHookResult = ReturnType<
  typeof useAutoCompletionQuerySuspenseQuery
>;
export type AutoCompletionQueryQueryResult = Apollo.QueryResult<
  AutoCompletionQuery,
  AutoCompletionQueryVariables
>;
