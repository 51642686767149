/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { ChatOptionInfoFragment } from './chat-option-info-fragment';
import { ChatVariableFragment } from './chat-variable-fragment';
import { FAQCarouselFragment } from './faq-carousel-fragment';
import { ChatKnowledgeBasePreviewFragment } from './chat-knowledge-base-preview-fragment';
import { LLMAppFragment } from './llm-app-fragment';
import { CarouselOptionFragment } from './carousel-option-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
import { ChatOptionInfoFragmentDoc } from './chat-option-info-fragment';
import { ChatVariableFragmentDoc } from './chat-variable-fragment';
import { FAQCarouselFragmentDoc } from './faq-carousel-fragment';
import { ChatKnowledgeBasePreviewFragmentDoc } from './chat-knowledge-base-preview-fragment';
import { LLMAppFragmentDoc } from './llm-app-fragment';
import { CarouselOptionFragmentDoc } from './carousel-option-fragment';
export type BotChatFragment = { __typename: 'BotChat' } & Pick<
  Types.BotChat,
  | 'id'
  | 'message'
  | 'messageContentState'
  | 'createdAt'
  | 'hidden'
  | 'mrcAnswerType'
  | 'completed'
  | 'completeState'
  | 'placeholder'
  | 'generatingState'
  | 'multipleOption'
  | 'nextInputType'
  | 'knowledgeBaseUserAskId'
  | 'assistantFallbackActionCandidates'
  | 'reusable'
  | 'ratable'
  | 'rating'
  | 'useAutoComplete'
  | 'chatBoxTemplate'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    chatOptionInfos: Types.Maybe<
      Array<
        Types.Maybe<{ __typename: 'ChatOptionInfo' } & ChatOptionInfoFragment>
      >
    >;
    faqCarousel: Types.Maybe<
      Array<Types.Maybe<{ __typename: 'FAQCarousel' } & FAQCarouselFragment>>
    >;
    saveAsVariable: Types.Maybe<
      { __typename: 'Variable' } & ChatVariableFragment
    >;
    knowledgeBasePreview: Types.Maybe<
      { __typename: 'KnowledgeBasePreview' } & ChatKnowledgeBasePreviewFragment
    >;
    assistantRecommendedApps: Types.Maybe<
      Array<{ __typename: 'LLMApp' } & LLMAppFragment>
    >;
    assistantCreatedLlmApp: Types.Maybe<
      { __typename: 'LLMApp' } & LLMAppFragment
    >;
    allowKnowledgeBaseFile: Types.Maybe<
      { __typename: 'AllowKnowledgeBaseFileInfo' } & Pick<
        Types.AllowKnowledgeBaseFileInfo,
        'extensions' | 'maxSize'
      >
    >;
    carouselOptions: Types.Maybe<
      Array<{ __typename: 'CarouselOption' } & CarouselOptionFragment>
    >;
  };

export const BotChatFragmentDoc = gql`
  fragment BotChatFragment on BotChat {
    id
    message
    messageContentState @client
    createdAt
    hidden
    mrcAnswerType
    completed
    completeState
    placeholder
    generatingState
    media {
      ...ChatMediaFragment
    }
    chatOptionInfos {
      ...ChatOptionInfoFragment
    }
    multipleOption
    nextInputType
    faqCarousel {
      ...FAQCarouselFragment
    }
    saveAsVariable {
      ...ChatVariableFragment
    }
    knowledgeBasePreview {
      ...ChatKnowledgeBasePreviewFragment
    }
    knowledgeBaseUserAskId
    assistantRecommendedApps {
      ...LLMAppFragment
    }
    assistantCreatedLlmApp {
      ...LLMAppFragment
    }
    assistantFallbackActionCandidates
    reusable
    ratable
    rating
    useAutoComplete
    chatBoxTemplate
    allowKnowledgeBaseFile {
      extensions
      maxSize
    }
    carouselOptions {
      ...CarouselOptionFragment
    }
  }
  ${ChatMediaFragmentDoc}
  ${ChatOptionInfoFragmentDoc}
  ${FAQCarouselFragmentDoc}
  ${ChatVariableFragmentDoc}
  ${ChatKnowledgeBasePreviewFragmentDoc}
  ${LLMAppFragmentDoc}
  ${CarouselOptionFragmentDoc}
`;
