/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import {
  SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_,
} from './single-action-input-knowledge-base-fragment';
import { gql } from '@apollo/client';
import { SingleActionInputKnowledgeBaseFragmentDoc } from './single-action-input-knowledge-base-fragment';
export type SingleActionInputFragment = {
  __typename: 'SingleActionInput';
} & Pick<
  UserQueryTypes.SingleActionInput,
  'id' | 'title' | 'inputType' | 'dynamicInputsItemName' | 'placeholder'
> & {
    options: UserQueryTypes.Maybe<
      Array<
        { __typename: 'SingleActionOption' } & Pick<
          UserQueryTypes.SingleActionOption,
          'name' | 'value'
        >
      >
    >;
    knowledgeBases: UserQueryTypes.Maybe<
      Array<
        | ({
            __typename: 'CSVKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_)
        | ({
            __typename: 'GoogleDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_)
        | ({
            __typename: 'HWPKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_)
        | ({
            __typename: 'ImageKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_)
        | ({
            __typename: 'MSDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_)
        | ({
            __typename: 'MSExcelKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_)
        | ({
            __typename: 'MSPPTKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_)
        | ({
            __typename: 'OldMSDocsKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_)
        | ({
            __typename: 'PDFKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_)
        | ({
            __typename: 'TextKnowledgeBase';
          } & SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_)
      >
    >;
  };

export const SingleActionInputFragmentDoc = gql`
  fragment SingleActionInputFragment on SingleActionInput {
    id
    title
    inputType
    dynamicInputsItemName
    placeholder
    options {
      name
      value
    }
    knowledgeBases {
      ...SingleActionInputKnowledgeBaseFragment
    }
  }
  ${SingleActionInputKnowledgeBaseFragmentDoc}
`;
