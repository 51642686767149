import {
  IcCheckBox,
  IcCheckBoxBlank,
  IcCheckBoxFilled,
  IcIndeterminateCheckBox,
  IcIndeterminateCheckBoxFilled,
} from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import MuiCheckbox from '@mui/material/Checkbox';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { checkboxClasses } from './checkbox-classes';
import { CheckboxProps } from './checkbox-type-map';

/**
 * Checkboxes allow the user to select one or more items from a set.
 */
export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (props, ref) => {
    const theme = useTheme();
    const { classes, edge = false, disabled, ...other } = props;

    return (
      <MuiCheckbox
        data-testid="checkbox"
        css={[
          css`
            padding: 2px;
            border-radius: ${theme.radius.xs}px;
            transition: ${theme.transitions.create(
              ['color', 'background-color'],
              {
                duration: theme.transitions.duration.shortest,
              },
            )};
            color: ${theme.palette.unstable_borderInteractive.gray.default};

            &:hover {
              color: ${theme.palette.unstable_borderInteractive.gray.hover};
              background-color: ${theme.palette.unstable_backgroundInteractive
                .grayGhostAlpha.hover};

              @media (hover: none) {
                background-color: transparent;
              }
            }

            &:active {
              color: ${theme.palette.unstable_borderInteractive.gray.pressed};
              background-color: ${theme.palette.unstable_backgroundInteractive
                .grayGhostAlpha.pressed};

              @media (hover: none) {
                background-color: transparent;
              }
            }

            &.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate} {
              color: ${theme.taxPalette.primary[500]};

              &:hover {
                color: ${theme.taxPalette.primary[600]};
                background-color: ${theme.taxPalette.primary[50]};

                @media (hover: none) {
                  background-color: transparent;
                }
              }

              &:active {
                color: ${theme.taxPalette.primary[700]};
                background-color: ${theme.taxPalette.primary[100]};

                @media (hover: none) {
                  background-color: transparent;
                }
              }
            }

            &.${checkboxClasses.disabled} {
              color: ${theme.palette.unstable_foreground.gray.disabled};
            }

            &.${checkboxClasses.focusVisible} {
              outline: ${theme.palette.unstable_border.focused} solid 2px;
              outline-offset: -2px;
            }
          `,
          edge === 'start' &&
            css`
              margin-left: -4px;
            `,
          edge === 'end' &&
            css`
              margin-right: -4px;
            `,
        ]}
        checkedIcon={disabled ? <IcCheckBox /> : <IcCheckBoxFilled />}
        icon={<IcCheckBoxBlank />}
        indeterminateIcon={
          disabled ? (
            <IcIndeterminateCheckBox />
          ) : (
            <IcIndeterminateCheckBoxFilled />
          )
        }
        color="primary"
        classes={classes}
        edge={edge}
        disabled={disabled}
        {...other}
        ref={ref}
        focusVisibleClassName={clsx(
          checkboxClasses.focusVisible,
          classes?.focusVisible,
          other.focusVisibleClassName,
        )}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        focusRipple={false}
      />
    );
  },
);
