/* eslint-disable */
import * as UserQueryTypes from '@allganize/alli-interfaces/user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../fragments/error-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSingleActionAppResultPublicMutationVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    resultId: UserQueryTypes.Scalars['ID']['input'];
    updatedOutput: UserQueryTypes.Scalars['String']['input'];
    token: UserQueryTypes.Scalars['String']['input'];
    accessToken?: UserQueryTypes.InputMaybe<
      UserQueryTypes.Scalars['String']['input']
    >;
  }>;

export type UpdateSingleActionAppResultPublicMutation = {
  __typename: 'UserMutation';
} & {
  updateSingleActionAppResultPublic: UserQueryTypes.Maybe<
    { __typename: 'UpdateSingleActionAppResultPublic' } & {
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const UpdateSingleActionAppResultPublicMutationDocument = gql`
  mutation UpdateSingleActionAppResultPublicMutation(
    $where: ProjectWhereUniqueInput!
    $resultId: ID!
    $updatedOutput: String!
    $token: String!
    $accessToken: String
  ) {
    updateSingleActionAppResultPublic(
      projectWhere: $where
      singleActionAppResultId: $resultId
      updatedOutput: $updatedOutput
      token: $token
      accessToken: $accessToken
    ) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdateSingleActionAppResultPublicMutationMutationFn =
  Apollo.MutationFunction<
    UpdateSingleActionAppResultPublicMutation,
    UpdateSingleActionAppResultPublicMutationVariables
  >;

/**
 * __useUpdateSingleActionAppResultPublicMutation__
 *
 * To run a mutation, you first call `useUpdateSingleActionAppResultPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSingleActionAppResultPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSingleActionAppResultPublicMutation, { data, loading, error }] = useUpdateSingleActionAppResultPublicMutation({
 *   variables: {
 *      where: // value for 'where'
 *      resultId: // value for 'resultId'
 *      updatedOutput: // value for 'updatedOutput'
 *      token: // value for 'token'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useUpdateSingleActionAppResultPublicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSingleActionAppResultPublicMutation,
    UpdateSingleActionAppResultPublicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSingleActionAppResultPublicMutation,
    UpdateSingleActionAppResultPublicMutationVariables
  >(UpdateSingleActionAppResultPublicMutationDocument, options);
}
export type UpdateSingleActionAppResultPublicMutationHookResult = ReturnType<
  typeof useUpdateSingleActionAppResultPublicMutation
>;
export type UpdateSingleActionAppResultPublicMutationMutationResult =
  Apollo.MutationResult<UpdateSingleActionAppResultPublicMutation>;
export type UpdateSingleActionAppResultPublicMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSingleActionAppResultPublicMutation,
    UpdateSingleActionAppResultPublicMutationVariables
  >;
