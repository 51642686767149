/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type LLMAppFragment = { __typename: 'LLMApp' } & Pick<
  Types.LLMApp,
  'id' | 'name' | 'description' | 'type' | 'category' | 'icon'
> & {
    campaign: Types.Maybe<
      { __typename: 'Campaign' } & Pick<Types.Campaign, 'id' | 'campaignToken'>
    >;
    singleActionApp: Types.Maybe<
      { __typename: 'SingleActionApp' } & Pick<
        Types.SingleActionApp,
        'id' | 'publicToken' | 'editUrl'
      >
    >;
  };

export const LLMAppFragmentDoc = gql`
  fragment LLMAppFragment on LLMApp {
    id
    name
    description
    type
    category
    icon
    campaign {
      id
      campaignToken
    }
    singleActionApp {
      id
      publicToken
      editUrl
    }
  }
`;
