import { createTheme as createMuiTheme } from '@mui/material/styles';
import { elevations as defaultElevations } from '../elevations/elevations';
import { createMixins } from '../mixins/create-mixins';
import { createPalette } from '../palette/create-palette';
import { radius as defaultRadius } from '../radius/radius';
import { createTypography } from '../typography/create-typography';
import { Theme, ThemeOptions } from './theme';

export const createTheme = (
  {
    elevations = defaultElevations,
    mixins: mixinsOptions,
    radius,
    typography,
    ...options
  }: ThemeOptions = {},
  ...args: object[]
): Theme => {
  const palette = createPalette(options.palette ?? {});
  const muiTheme = createMuiTheme({ ...options, palette }, ...args);
  const mixins = createMixins(muiTheme.breakpoints, mixinsOptions ?? {});

  return {
    ...muiTheme,
    palette,
    elevations,
    mixins,
    radius: {
      ...defaultRadius,
      ...radius,
    },
    typography: createTypography(palette, typography ?? {}),
    taxPalette: {
      primary: {
        50: '#EFF4FF',
        100: '#CADAFE',
        200: '#A5C2F9',
        300: '#7EAAF0',
        400: '#5892E1',
        500: '#327BCC',
        600: '#0765B0',
        700: '#004F8F',
        800: '#003A6B',
        900: '#0C2644',
        950: '#0D131F',
      },
      grey: {
        50: '#F3F4F3',
        100: '#D9DAD9',
        200: '#BFC1C0',
        300: '#BCC2C2',
        400: '#8E9190',
        500: '#777A79',
        600: '#616462',
        700: '#4B4E4D',
        800: '#373A39',
        900: '#252625',
        950: '#131313',
      },
      red: {
        50: '#FCF2F2',
        100: '#FADBDC',
        200: '#F7B6B6',
        300: '#F29191',
        400: '#F06A6A',
        500: '#EF3E3E',
        600: '#CD2E2D',
        700: '#AA1313',
        800: '#780E0E',
        900: '#3F0808',
        950: '#280606',
      },
      blue: {
        50: '#F4F7FB',
        100: '#DEE9F7',
        200: '#B9D0F4',
        300: '#8FB8F4',
        400: '#659EF6',
        500: '#3F85F5',
        600: '#1969E1',
        700: '#1151B0',
        800: '#133D7C',
        900: '#102649',
        950: '#0D192B',
      },
      green: {
        50: '#F0F9F3',
        100: '#DDF3E5',
        200: '#A8E6BF',
        300: '#7ADE9D',
        400: '#46D67C',
        500: '#21C55D',
        600: '#1F984C',
        700: '#1C6E3A',
        800: '#164B2A',
        900: '#0D2A19',
        950: '#0B1A11',
      },
      white: '#FFFFFF',
    },
  };
};
