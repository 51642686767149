/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ConversationFragment } from '../fragments/conversation-fragment';
import { gql } from '@apollo/client';
import { ConversationFragmentDoc } from '../fragments/conversation-fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversationSubscriptionVariables = Types.Exact<{
  where: Types.ConversationWhereUniqueInput;
}>;

export type ConversationSubscription = { __typename: 'UserSubscription' } & {
  conversation: Types.Maybe<
    { __typename: 'ConversationEdge' } & {
      node: Types.Maybe<{ __typename: 'Conversation' } & ConversationFragment>;
    }
  >;
};

export const ConversationSubscriptionDocument = gql`
  subscription ConversationSubscription($where: ConversationWhereUniqueInput!) {
    conversation(where: $where) {
      node {
        ...ConversationFragment
      }
    }
  }
  ${ConversationFragmentDoc}
`;

/**
 * __useConversationSubscription__
 *
 * To run a query within a React component, call `useConversationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConversationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useConversationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ConversationSubscription,
    ConversationSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ConversationSubscription,
    ConversationSubscriptionVariables
  >(ConversationSubscriptionDocument, options);
}
export type ConversationSubscriptionHookResult = ReturnType<
  typeof useConversationSubscription
>;
export type ConversationSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ConversationSubscription>;
