import {
  linkClasses as muiLinkClasses,
  LinkClassKey as MuiLinkClassKey,
} from '@mui/material/Link';

export type LinkClassKey = MuiLinkClassKey | 'disabled';
export type LinkClasses = Record<LinkClassKey, string>;

export const linkClasses: LinkClasses = {
  ...muiLinkClasses,
  disabled: 'link-disabled',
};
