import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { forwardRef } from 'react';
import {
  ChatContentRowClasses,
  chatContentRowClasses,
} from './chat-content-row-classes';

export const horizontalPadding = 16;
export const avatarWidth = 24;
const horizontalMarginWidth = 32;
const horizontalMarginBreakpoint = 380;
const horizontalMarginMediaQuery = `@media (min-width: ${horizontalMarginBreakpoint}px)`;

const ChatContentRowAvatar = styled.div`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  width: ${avatarWidth}px;
`;

const ChatContentRowMargin = styled.div`
  width: 0;
  flex-shrink: 0;

  ${horizontalMarginMediaQuery} {
    width: ${horizontalMarginWidth}px;
  }
`;

export interface ChatContentRowProps
  extends React.HTMLAttributes<HTMLDivElement> {
  align?: 'left' | 'right';
  avatar?: 'inset' | React.ReactNode;
  classes?: Partial<ChatContentRowClasses>;
}

export const ChatContentRow = forwardRef<HTMLDivElement, ChatContentRowProps>(
  ({ align, avatar, classes, children, ...other }, ref) => {
    const theme = useTheme();

    return (
      <div
        data-testid="chat-content-row"
        css={[
          css`
            padding: 0 ${horizontalPadding}px;
            display: flex;
            align-items: flex-end;
          `,
          align === 'left' &&
            css`
              justify-content: flex-start;
            `,
          align === 'right' &&
            css`
              justify-content: flex-end;
            `,
        ]}
        {...other}
        ref={ref}
        className={clsx(
          chatContentRowClasses.root,
          {
            [chatContentRowClasses.alignLeft]: align === 'left',
            [chatContentRowClasses.alignRight]: align === 'right',
          },
          classes?.root,
          {
            [classes?.alignLeft ?? '']: align === 'left',
            [classes?.alignRight ?? '']: align === 'right',
          },
          other.className,
        )}
      >
        {align === 'left' && avatar && (
          <ChatContentRowAvatar
            css={css`
              justify-content: flex-start;
              color: ${theme.palette.primary.main};
            `}
            className={clsx(chatContentRowClasses.avatar, classes?.avatar)}
          >
            {avatar !== 'inset' && avatar}
          </ChatContentRowAvatar>
        )}

        {align === 'right' && (
          <ChatContentRowMargin
            className={clsx(
              chatContentRowClasses.horizontalMargin,
              classes?.horizontalMargin,
            )}
          />
        )}

        <div
          css={css`
            width: 100%;
          `}
          className={clsx(chatContentRowClasses.content, classes?.content)}
        >
          {children}
        </div>

        {align === 'left' && (
          <ChatContentRowMargin
            className={clsx(
              chatContentRowClasses.horizontalMargin,
              classes?.horizontalMargin,
            )}
          />
        )}

        {align === 'right' && avatar && (
          <ChatContentRowAvatar
            css={css`
              justify-content: flex-end;
              color: ${theme.palette.primary.main};
            `}
            className={clsx(chatContentRowClasses.avatar, classes?.avatar)}
          >
            {avatar !== 'inset' && avatar}
          </ChatContentRowAvatar>
        )}
      </div>
    );
  },
);
