import { useId } from '@allganize/hooks';
import { FormControl, FormGroup, FormHelperText } from '@allganize/ui-form';
import { List } from '@allganize/ui-list';
import { Radio } from '@allganize/ui-radio';
import { css } from '@emotion/react';
import { FunctionComponent, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { FaqCarouselOption } from './faq-carousel-option';
import { faqCarouselOptionClasses } from './faq-carousel-option-classes';
import {
  FaqCarouselFormValue,
  useFaqCarouselForm,
  UseFaqCarouselFormOptions,
} from './use-faq-carousel-form';

interface FaqCarouselFormProps extends UseFaqCarouselFormOptions {
  className?: string;
  isDisabled?(option: FaqCarouselFormValue): boolean;
  readOnly?: boolean;
}

export const FaqCarouselForm: FunctionComponent<FaqCarouselFormProps> = ({
  faqCarouselOptions,
  className,
  isDisabled,
  onSubmit,
  readOnly,
}) => {
  const formId = useId();

  const { form, options, submit } = useFaqCarouselForm({
    faqCarouselOptions,
    onSubmit,
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = form;

  useEffect(() => {
    let subscription: Subscription | null = null;

    subscription = watch(values => {
      if (values.faqCarouselOption) {
        handleSubmit(submit)();
      }
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
    };
  }, [handleSubmit, submit, watch]);

  return (
    <form
      css={css`
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
      className={className}
      onSubmit={handleSubmit(submit)}
    >
      <Controller
        control={control}
        name="faqCarouselOption"
        render={({ field, fieldState }) => {
          return (
            <FormControl
              fullWidth
              required
              error={fieldState.invalid}
              disabled={readOnly}
            >
              <FormGroup role="radiogroup" onBlur={field.onBlur}>
                <List
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    margin: -4px;
                  `}
                  disablePadding
                >
                  {options.map(option => {
                    const labelId = `faq-carousel-form-${formId}-${option.value}`;
                    const checked = option.value === field.value?.value;
                    // form gets submitted on click for single choice, so we have to disable
                    const disabled =
                      isSubmitting || readOnly || isDisabled?.(option);

                    return (
                      <li
                        key={option.value}
                        css={css`
                          padding: 4px;
                        `}
                      >
                        <FaqCarouselOption
                          css={css`
                            .${faqCarouselOptionClasses.checkbox} {
                              display: none;
                            }
                          `}
                          control={
                            <Radio
                              edge="start"
                              checked={checked}
                              tabIndex={-1}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          }
                          data={option}
                          readOnly={disabled}
                          textProps={{ id: labelId }}
                          onClick={() => {
                            if (!checked) {
                              field.onChange(option);
                            }
                          }}
                          onBlur={field.onBlur}
                        />
                      </li>
                    );
                  })}
                </List>
              </FormGroup>

              {fieldState.error?.message && (
                <FormHelperText
                  css={css`
                    text-align: right;
                  `}
                >
                  {fieldState.error.message}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />

      {errors.root?.message && (
        <FormHelperText error>{errors.root?.message}</FormHelperText>
      )}
    </form>
  );
};
