import { Truncate } from '@allganize/truncate';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { IcGenerate } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { LLMAppFragment } from '../graphql/fragments/llm-app-fragment';
import { RecommendationCarousel } from '../recommendation-carousel/recommendation-carousel';
import { RecommendationCarouselProps } from '../recommendation-carousel/recommendation-carousel-type-map';
import { RecommendationCarouselItemWrapper } from '../recommendation-carousel/recommendation-carousel-item-wrapper';
import { RecommendedApp } from './recommended-app';

export interface RecommendedAppCarouselProps
  extends RecommendationCarouselProps {
  data: LLMAppFragment[];
  onAppClick?: (app: LLMAppFragment, recommendedRank: number) => void;
}

export const RecommendedAppCarousel: FunctionComponent<
  RecommendedAppCarouselProps
> = ({ data, onAppClick, ...other }) => {
  const theme = useTheme();
  return (
    <RecommendationCarousel
      title={
        <Text
          variant="title12"
          css={css`
            display: flex;
            align-items: center;
            color: ${theme.palette.unstable_foreground.secondary};
          `}
        >
          <IcGenerate />
          <Truncate clamp={1}>
            <FormattedMessage
              id="recommended-app-carousel.title"
              defaultMessage="Recommended Apps"
              description="recommended app carousel title"
            />
          </Truncate>
        </Text>
      }
      {...other}
    >
      {data.map((item, idx) => (
        <RecommendationCarouselItemWrapper key={item.id}>
          <RecommendedApp
            data={item}
            onClick={() => onAppClick?.(item, idx + 1)}
          />
        </RecommendationCarouselItemWrapper>
      ))}
    </RecommendationCarousel>
  );
};
