import { UAParser } from 'ua-parser-js';

const parser = new UAParser();
const browser = parser.getBrowser();

let major = browser.version ? Number.parseInt(browser.version) : null;

if (major !== null && Number.isNaN(major)) {
  major = null;
}

export const uaParser = {
  // [QA-542] cross browser height issue
  hasCarouselHeightBug:
    (browser.name === 'Chrome' && major !== null && major <= 73) ||
    (browser.name === 'Safari' && major !== null && major <= 13) ||
    browser.name === 'Mobile Safari' ||
    (browser.name === 'Opera' && major !== null && major <= 60),
};
