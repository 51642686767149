/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { gql } from '@apollo/client';
export type SDKSettingsFragment = { __typename: 'SDKSettings' } & Pick<
  Types.SDKSettings,
  | 'companyName'
  | 'showContactAgentButton'
  | 'showSendConversationButton'
  | 'hasNewConversationButton'
  | 'hasBackButton'
  | 'themeColor'
  | 'bgColor'
  | 'chatBubblePosition'
  | 'useAutoComplete'
  | 'numAutoComplete'
  | 'chatBubbleOffsetX'
  | 'chatBubbleOffsetY'
  | 'chatWindowWidth'
  | 'chatWindowHeight'
  | 'popUpWidth'
  | 'popUpCustomMessage'
  | 'popUpUseCustomMessage'
  | 'chatOptionAlign'
> & {
    companyLogo: Types.Maybe<
      { __typename: 'Media' } & SDKSettingsMediaFragment
    >;
  };

export type SDKSettingsMediaFragment = { __typename: 'Media' } & Pick<
  Types.Media,
  'id' | 'mediaType' | 'metaInfo' | 'url' | 'width' | 'height' | 'resizedUrls'
>;

export const SDKSettingsMediaFragmentDoc = gql`
  fragment SDKSettingsMediaFragment on Media {
    id
    mediaType
    metaInfo
    url
    width
    height
    resizedUrls(
      argument: [
        { width: 64, height: 64 }
        { width: 128, height: 128 }
        { width: 192, height: 192 }
      ]
    )
  }
`;
export const SDKSettingsFragmentDoc = gql`
  fragment SDKSettingsFragment on SDKSettings {
    companyLogo {
      ...SDKSettingsMediaFragment
    }
    companyName
    showContactAgentButton
    showSendConversationButton
    hasNewConversationButton
    hasBackButton
    themeColor
    bgColor
    chatBubblePosition
    useAutoComplete
    numAutoComplete
    chatBubbleOffsetX
    chatBubbleOffsetY
    chatWindowWidth
    chatWindowHeight
    popUpWidth
    popUpCustomMessage
    popUpUseCustomMessage
    chatOptionAlign
  }
  ${SDKSettingsMediaFragmentDoc}
`;
