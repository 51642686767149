import {
  ListItemButton,
  ListItemButtonProps,
  listItemButtonClasses,
} from '@allganize/ui-list';
import styled from '@emotion/styled';
import { Link } from '../common/link';
import { useMatch } from 'react-router-dom';
import { forwardRef, useEffect, useState } from 'react';
import { useConversationList } from '@allganize/alli-sdk/hooks/use-conversation-list';
import { css, useTheme } from '@emotion/react';
import getTaxIcon from '../../utils/get-tax-icon';

export const listItemSize = 34;

const ItemWrapper = styled(ListItemButton)`
  border-radius: ${props => props.theme.radius.sm}px;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  column-gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  min-height: ${listItemSize}px;

  &.${listItemButtonClasses.selected} {
    &,
    &:hover {
      background-color: ${props => props.theme.taxPalette.white};
      cursor: auto;
    }
  }
`;

const ItemTitle = styled.div`
  ${props => props.theme.typography.body14};
  color: ${props => props.theme.taxPalette.grey[950]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ItemIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  height: 24px;
  max-width: fit-content;
  padding: 2px 8px;
  border-radius: ${props => props.theme.radius.xs}px;
  background-color: ${props => props.theme.taxPalette.grey[100]};
`;

const ItemTimestamp = styled.div`
  ${props => props.theme.typography.body12};
  font-weight: 400;
  text-align: right;
  white-space: nowrap;
  color: ${props => props.theme.taxPalette.grey[600]};
`;

type ConversationsQuery = ReturnType<typeof useConversationList>['data'];
type ConversationNode = NonNullable<
  NonNullable<
    NonNullable<ConversationsQuery['conversations']>['edges'][number]
  >['node']
>;

interface HistoryListItemProps extends ListItemButtonProps {
  data: Pick<
    ConversationNode,
    'id' | 'lastChatTime' | 'llmApp' | 'title' | 'shareUrl'
  >;
}

const HistoryListItemBase = forwardRef<HTMLDivElement, HistoryListItemProps>(
  ({ data, selected, ...others }: HistoryListItemProps, ref) => {
    const { lastChatTime, llmApp } = data;
    const theme = useTheme();

    const ItemIndicatorCss = css`
      color: ${theme.taxPalette.grey[950]};
    `;

    const Icon = getTaxIcon({
      appName: llmApp?.name || '통합',
      fontSize: 'small',
      css: { ItemIndicatorCss },
    });

    const [formattedTime, setFormattedTime] = useState('');

    const formatRelativeTime = (lastChatTime: number) => {
      const rtf = new Intl.RelativeTimeFormat('ko', { numeric: 'auto' });
      const differenceInSeconds = Math.round(
        (lastChatTime - Date.now()) / 1000,
      );
      if (Math.abs(differenceInSeconds) < 60) {
        return rtf.format(Math.round(differenceInSeconds), 'second');
      } else if (Math.abs(differenceInSeconds) < 3600) {
        return rtf.format(Math.round(differenceInSeconds / 60), 'minute');
      } else if (Math.abs(differenceInSeconds) < 86400) {
        return rtf.format(Math.round(differenceInSeconds / 3600), 'hour');
      } else {
        return rtf.format(Math.round(differenceInSeconds / 86400), 'day');
      }
    };

    useEffect(() => {
      const updateTime = () => {
        if (lastChatTime) {
          setFormattedTime(formatRelativeTime(lastChatTime));
        }
      };

      updateTime();
      const intervalId = setInterval(updateTime, 60000);

      return () => clearInterval(intervalId);
    }, [lastChatTime]);

    return (
      <ItemWrapper {...others} selected={selected} ref={ref}>
        <ItemIndicatorWrapper>{Icon}</ItemIndicatorWrapper>
        <ItemTitle>{data.title}</ItemTitle>
        {lastChatTime && <ItemTimestamp>{formattedTime}</ItemTimestamp>}
      </ItemWrapper>
    );
  },
);

export const HistoryListItem = ({
  selected,
  ...others
}: HistoryListItemProps) => {
  const match = useMatch(`/conversations/${others.data.id}`);

  if (match) {
    return <HistoryListItemBase selected {...others} />;
  }

  return (
    <Link to={`/conversations/${others.data.id}`}>
      <HistoryListItemBase {...others} />
    </Link>
  );
};
