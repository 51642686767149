import { IcClose } from '@allganize/ui-icons';
import { useTheme } from '@allganize/ui-theme';
import { ClassNames } from '@emotion/react';
import MuiChip from '@mui/material/Chip';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { forwardRef } from 'react';
import { chipClasses } from './chip-classes';
import { ChipTypeMap } from './chip-type-map';

// @ts-expect-error overridable component
export const Chip: OverridableComponent<ChipTypeMap> = forwardRef(
  (props, ref) => {
    const {
      classes,
      color = 'gray-subtle',
      onDelete,
      clickable: clickableProp,
      ...other
    } = props;
    const theme = useTheme();
    const clickable =
      clickableProp !== false && other.onClick ? true : clickableProp;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <MuiChip
            data-testid="chip"
            color="default"
            deleteIcon={<IcClose />}
            size="small"
            variant="filled"
            onDelete={onDelete}
            clickable={clickable}
            {...other}
            ref={ref}
            {...(clickable || onDelete
              ? {
                  centerRipple: false,
                  disableRipple: true,
                  disableTouchRipple: true,
                  focusRipple: false,
                }
              : {})}
            classes={{
              ...classes,
              root: cx(
                css`
                  height: 52px;
                  border-radius: ${theme.radius.sm}px;
                  transition: ${theme.transitions.create([
                    'background-color',
                    'color',
                  ])};
                  display: inline-flex;
                  padding: 16px;
                  gap: 8px;
                  ${theme.typography.title12};

                  &. ${chipClasses.disabled} {
                    color: ${theme.palette.unstable_foreground.gray.disabled};
                    background-color: ${theme.palette.unstable_background
                      .grayDisabled.alpha};

                    .${chipClasses.avatar} {
                      opacity: ${theme.palette.action.disabledOpacity};
                    }

                    .${chipClasses.deleteIcon} {
                      background-color: transparent;
                      color: ${theme.palette.unstable_foreground.gray.disabled};
                    }
                  }

                  .${chipClasses.avatar} {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    color: ${theme.palette.unstable_foregroundInteractive.gray
                      .subtle};
                    margin: 0;
                  }

                  .${chipClasses.icon} {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    color: inherit;
                    margin: 0;
                  }

                  .${chipClasses.deleteIcon} {
                    transition: ${theme.transitions.create([
                      'background-color',
                      'color',
                    ])};
                    background-color: transparent;
                    border-radius: ${theme.radius.xs}px;
                    font-size: 16px;
                    margin: 0;
                  }

                  &.${chipClasses.focusVisible} {
                    // TODO: mixin으로 교체
                    outline: ${theme.palette.unstable_border.focused} solid 2px;
                    outline-offset: 2px;
                  }

                  &:hover {
                    box-shadow: none;
                  }
                `,

                color === 'gray-dark' &&
                  css`
                    &,
                    &.${chipClasses.focusVisible} {
                      color: ${theme.palette.unstable_foreground.inverse};
                      background-color: ${theme.palette
                        .unstable_backgroundInteractive.grayDarkAlpha.default};
                    }

                    .${chipClasses.deleteIcon} {
                      color: ${theme.palette.unstable_foregroundInteractive
                        .inverse};

                      &:hover {
                        color: ${theme.palette.unstable_foregroundInteractive
                          .inverse};
                        background-color: ${theme.palette
                          .unstable_backgroundInteractive.inverseAlpha.hover};
                      }

                      &:active {
                        background-color: ${theme.palette
                          .unstable_backgroundInteractive.inverseAlpha.pressed};
                      }
                    }

                    &:hover {
                      background-color: ${theme.palette
                        .unstable_backgroundInteractive.grayDarkAlpha.hover};
                    }

                    &:active {
                      background-color: ${theme.palette
                        .unstable_backgroundInteractive.grayDarkAlpha.pressed};
                    }
                  `,
                color === 'gray-subtle' &&
                  css`
                    &,
                    &.${chipClasses.focusVisible} {
                      color: ${theme.taxPalette.grey[950]};
                      background-color: ${theme.taxPalette.grey[50]};
                    }

                    .${chipClasses.deleteIcon} {
                      color: ${theme.palette.unstable_foregroundInteractive.gray
                        .default};

                      &:hover {
                        color: ${theme.palette.unstable_foregroundInteractive
                          .gray.default};
                        background-color: ${theme.palette
                          .unstable_backgroundInteractive.grayGhostAlpha.hover};
                      }

                      &:active {
                        background-color: ${theme.palette
                          .unstable_backgroundInteractive.grayGhostAlpha
                          .pressed};
                      }
                    }

                    &:hover {
                      background-color: ${theme.palette
                        .unstable_backgroundInteractive.graySubtleAlpha.hover};
                    }

                    &:active {
                      background-color: ${theme.palette
                        .unstable_backgroundInteractive.graySubtleAlpha
                        .pressed};
                    }
                  `,
                classes?.root,
              ),
              label: cx(
                css`
                  padding-left: 0;
                  padding-right: 0;
                `,
                classes?.label,
              ),
            }}
            className={cx(
              {
                [chipClasses.colorGrayDark]: color === 'gray-dark',
                [chipClasses.colorGraySubtle]: color === 'gray-subtle',
              },
              other.className,
            )}
          />
        )}
      </ClassNames>
    );
  },
);
