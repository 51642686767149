import { useTheme } from '@allganize/ui-theme';
import { Fade } from '@allganize/ui-transition';
import { css } from '@emotion/react';
import MuiBackdrop from '@mui/material/Backdrop';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { forwardRef } from 'react';
import { BackdropTypeMap } from './backdrop-type-map';

// @ts-expect-error overridable component
export const Backdrop: OverridableComponent<BackdropTypeMap> = forwardRef(
  (props, ref) => {
    const { invisible, ...other } = props;
    const theme = useTheme();

    return (
      <MuiBackdrop
        data-testid="backdrop"
        css={[
          css`
            background-color: ${theme.palette.grayAlpha[600]};
          `,
          invisible &&
            css`
              background-color: transparent;
            `,
        ]}
        invisible={invisible}
        TransitionComponent={Fade}
        {...other}
        ref={ref}
      />
    );
  },
);
