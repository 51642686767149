/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces/types';

import { ChatMediaFragment } from './chat-media-fragment';
import { ChatFileFragment } from './chat-file-fragment';
import { ChatAgentFragment } from './chat-agent-fragment';
import { gql } from '@apollo/client';
import { ChatMediaFragmentDoc } from './chat-media-fragment';
import { ChatFileFragmentDoc } from './chat-file-fragment';
import { ChatAgentFragmentDoc } from './chat-agent-fragment';
export type AgentChatFragment = { __typename: 'AgentChat' } & Pick<
  Types.AgentChat,
  | 'id'
  | 'message'
  | 'messageContentState'
  | 'createdAt'
  | 'hidden'
  | 'nextInputType'
  | 'useAutoComplete'
> & {
    media: Types.Maybe<{ __typename: 'Media' } & ChatMediaFragment>;
    file: Types.Maybe<{ __typename: 'File' } & ChatFileFragment>;
    agent: { __typename: 'Agent' } & ChatAgentFragment;
  };

export const AgentChatFragmentDoc = gql`
  fragment AgentChatFragment on AgentChat {
    id
    message
    messageContentState @client
    createdAt
    hidden
    media {
      ...ChatMediaFragment
    }
    file {
      ...ChatFileFragment
    }
    agent {
      ...ChatAgentFragment
    }
    nextInputType
    useAutoComplete
  }
  ${ChatMediaFragmentDoc}
  ${ChatFileFragmentDoc}
  ${ChatAgentFragmentDoc}
`;
