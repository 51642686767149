import clsx from 'clsx';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { BaseOutput } from '../base-output';
import { Container } from '../components/styled-components';
import { FinalOutputActions } from './final-output-actions';
import { finalOutputClasses } from './final-output-classes';
import { FinalOutputEditor } from './final-output-editor';
import { FinalOutputProps } from './final-output-type-map';

export const FinalOutput: FunctionComponent<FinalOutputProps> = ({
  classes,
  output,
  loading,
  updating,
  updated,
  update,
  revert,
  onEditModeChange,
  ...other
}) => {
  const [editMode, setEditMode] = useState(false);
  const openEditor = () => setEditMode(true);
  const closeEditor = () => setEditMode(false);

  const handleUpdate = async (updatedOutput: string) => {
    if (updatedOutput) {
      await update(updatedOutput);
      closeEditor();
    }
  };

  useEffect(() => {
    onEditModeChange(editMode);
  }, [editMode, onEditModeChange]);

  return (
    <Container
      {...other}
      className={clsx(finalOutputClasses.root, classes?.root, other.className)}
    >
      {editMode && (
        <FinalOutputEditor
          output={output || ''}
          updating={updating}
          onSubmit={handleUpdate}
          onCancel={closeEditor}
        />
      )}
      {!editMode && (
        <Fragment>
          <BaseOutput
            loading={loading}
            output={output}
            label={
              <FormattedMessage
                id="single-action.final-output.title"
                defaultMessage="Final Output"
                description="single action final output title"
              />
            }
          />
          {!loading && (
            <FinalOutputActions
              output={output}
              updated={updated}
              updating={updating}
              onEdit={openEditor}
              onRevert={revert}
            />
          )}
        </Fragment>
      )}
    </Container>
  );
};
